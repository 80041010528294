.DropZoneClass {
  font-size: 24px;
}
  .chat-drop-file-container {
    position: absolute;
    background: #eaeaeaf5;
    width: 100%;
    z-index: 11;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* border: 1px dashed #9e9e9e; */
    height: calc(100% - 57px);
    top: 56px;
}
.chat-main-body {
  width: 100%;
  height: 100vh;
  padding-top: var(--spacing-64);
  overflow: hidden;
}

.chatlist-container {
  height: 100%;
}

.chat-sidebar-left {
  height: 100%;
  width: 360px;
  border-top: 1px solid var(--color-border-neutral-tertiary);
}

.chatlist-container {
  height: 100%;
}

.chat-center-sec {
  border-top: 1px solid var(--color-border-neutral-tertiary);
  /* background-color: red; */
  width: calc(100% - 360px);
}

.chat-center-sec .chatHeading-sec {
  max-width: 100%;
}

.chat-center-sec .chat-empty-sec,
.chat-center-sec .chat-nomessage-sec {
  margin: auto;
}

/* chat-message-wrapper (Center section) */
.chat-message-wrapper {
  width: 100%;
  border-width: 0px 1px 0 1px;
  border-color: var(--color-border-neutral-tertiary);
  border-style: solid;
  position: relative;
}

/* Chat list Dropdown list css */
.chatlist-container .dropdown-list {
  width: 220px;
}

/* Floating button */
.chat-floating-container {
  height: 100%;
  display: flex;
  overflow-x: hidden;
}

.chat-floating-area {
  bottom: 24px;
  right: 24px;
  z-index: 2;
  transform: scale(0);
  transform-origin: center bottom;
  transition: 0.4s all;
  pointer-events: none;
}

.chat-floating-area.active {
  transform: scale(1);
  transition: 0.4s all;
  pointer-events: all;
  transform-origin: center bottom;
}



/* Report Model box Body Design */

.report-model-body {
  gap: var(--spacing-24);
  width: 100%;
  padding: var(--spacing-48) var(--spacing-24);
}

.report-model-content {
  gap: var(--spacing-8);
}

.report-model-content h3,
.report-model-content p {
  max-width: 480px;
}

.report-model-content h3 {
  color: var(--color-text-neutral-strong);
}

.report-model-content p {
  font: var(--body-medium);
  color: var(--color-text-neutral-secondary);
}

.report-model-content p a {
  color: var(--color-text-brand-strong) !important;
  text-decoration: underline !important;
}

/* Right side bar transition */
/* 
.chat-right-inner-sec {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.2s all;
  transform: translateX(100%);
}

.chat-right-inner-sec.active{
  transition: 0.2s all;
  transform: translateX(0);
} */

/* char Module media preview  */

.chat-preview-header-btn button svg path {
  fill: var(--color-icon-static-white) !important;
}

.chat-preview-header-btn button:hover svg path {
  fill: var(--color-icon-static-darkgray) !important;
}

.chat-slider-preview{
  position: relative;
}
.chat-preview-slider-inner {
  width: 100%;
  position: relative;
  height: 100%;
}
.chat-preview-slider-item {
  display: none;
}
.chat-preview-slider-item.active {
  display: inline-flex;
}
.chat-preview-slider-item video {
  max-height: calc(100vh - 56px);
  margin: auto;
}
.chat-preview-slider-content {
  height: 100%;
}
.chat-preview-slider-item {
  height: 100%;
}
.chat-preview-slider-button {
  display: inline-flex;
    padding: var(--spacing-8, 8px);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.80));
    top:50%;
    margin-top: -20px; 
    border: none!important; 
        position: absolute;
        z-index: 99;
}
.chat-preview-slider-button:hover {
  background: var(--color-bg-blackout-strong-hovered, rgba(13, 13, 13, 0.70));
}

.chat-preview-slider-button:active {
  background: var(--color-bg-blackout-strong-pressed, rgba(13, 13, 13, 0.60));
}
.chat-slider-left-arrow {
  left: 0px;
}
.chat-slider-right-arrow{
  right: 0px;
}
.chat-preview-modal-delete {
  z-index: 999;
}

/* Chat Loader Conneting css */

.connecting-message-sec {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 12px;
  z-index: 2;
}

.connecting-message {
  display: flex;
  width: max-content;
  border: 1px solid #dddddd;
  text-align: center;
  margin: auto;
  background: #ffffffe6;
  border-radius: 30px;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 13px;
  margin-bottom: 12px;

}

.connecting-message img {
  width: 15px;
  height: 15px;
}

.connecting-message:before {
  content: '';
  height: 16px;
  width: 16px;
  background-image: url(../../../../Images/connectionloader.gif);
  background-size: cover;
  background-repeat: no-repeat;
}

.chat-loading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-4) var(--spacing-8);
  width: 100%;
}

.chat-loading:before {
  content: "";
  height: 16px;
  width: 16px;
  background-image: url(../../../../Images/connectionloader.gif);
  background-size: cover;
  background-repeat: no-repeat;
}

 .leave-group-message {
     display: flex;
     justify-content: center;
     padding: 20px;
     border-top: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
     font: var(--body-x-small-bold);
     background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
   }