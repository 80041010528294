.tabs-button {
    display: flex;
    /* Changed on 24-05-2024 */
    padding:  var(--spacing-16, 16px) var(--spacing-12, 12px);
    align-items: center;
    gap: var(--spacing-2, 2px);
    border: none;
    background-color: var(--bg-screen-strong);
    color: var(--color-text-neutral-strong-pressed, #060D14);
    /* Changed on 24-05-2024 */
    font: var(--body-small);
    font-weight: 600;
    letter-spacing: -0.1px;
    cursor: pointer;
    position: relative;
    text-align: center;
    justify-content: center;
}
.tabs-button:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}
.tabs-button:active {
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
}
.tabs-button.active:after {
    height: 4px;
    width: 100%;
    border-radius: var(--radius-x-small, 4px) var(--radius-x-small, 4px) 0px 0px;
    background: var(--color-bg-brand-strong, #0B42CF);
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
}
.tabs-has-badge {
    position: relative;
    margin-right: -8px;
    margin-top: -21px;
    right: -1px;
}
.tabs-button:focus-visible::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-text-neutral-strong);
    border-radius: 0;
    z-index: 11;
}

.tabs-button:focus-visible {
    position: relative;
    outline: none;
}