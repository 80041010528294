.chatmessagevideo-sec .chat-message,
.chatmessagevideo-sec.incoming .chat-message{
    background: transparent;
    padding: 0 0 0 var(--spacing-8);
}


.chatmessagevideo-sec .chat-reaction {
    margin-top: 0;
}

.chatmessagevideo-sec.outgoing .chat-message {
    background: transparent;
    padding: 0 var(--spacing-8) 0 0 ;
}