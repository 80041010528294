.select-ticket-page {
  padding-top: 56px;
}

.ticket-container {
  margin: auto;
  max-width: 1120px;
  width: 100%;
}

.ticket-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.ticket-row>.ticket-details {
  max-width: 648px;
  width: 100%;
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-48);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-48);
}

.ticket-row>.event-order-summary-wrapper {
  max-width: 392px;
  width: 100%;
  flex-shrink: 0;
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-48);
  display: grid;
  gap: var(--spacing-32);
}

.ticket-event-date-wrapper {
  border-radius: var(--radius-12);
  border: 1px solid var(--color-border-neutral-tertiary);
  padding: var(--spacing-24);
  display: flex;
  align-items: center;
}

.ticket-event-date-wrapper svg {
  margin-right: var(--spacing-24);
  flex-shrink: 0;
}

.ticket-event-date-wrapper p {
  font: var(--title-x-small);
  color: var(--color-text-static-darkgray);
  font-weight: 400;
  display: inline;
}

.ticket-event-date-wrapper .time-duration-wrapper p {
  font: var(--body-small);
  font-weight: 600;
}

.event-ticket-medium-card-item input {
  display: none;
}

.event-ticket-medium-card-item .event-ticket-medium-card {
  padding: var(--spacing-24);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  border-radius: var(--radius-12);
  border: 1px solid var(--color-border-neutral-tertiary);
  outline: 1px solid transparent;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  transition: all 0.5s ease;
}

.event-ticket-medium-card-item input:checked+label {
  border-color: var(--color-text-static-darkgray);
  outline: 1px solid var(--color-text-static-darkgray);
}

.event-ticket-medium-card-item .event-ticket-medium-card .event-ticket-text {
  max-width: 420px;
  width: 100%;
}

.event-ticket-medium-card-item .event-ticket-medium-card .event-ticket-text p {
  font: var(--body-small);
  color: var(--color-icon-neutral-secondary);
}

.event-ticket-medium-card-item .event-ticket-medium-card .event-ticket-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-ticket-medium-card-item .event-ticket-medium-card .event-ticket-text h3,
.event-ticket-medium-card-item .event-ticket-medium-card .event-ticket-controls .ticket-type {
  font: var(--body-large);
  font-weight: 600;
  color: var(--color-text-static-darkgray);
}

.button-stepper .button.medium.icon-only {
  padding: var(--spacing-8);
}

.button-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 112px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.button-stepper p {
  font: var(--body-x-large-bold);
  color: var(--color-text-static-darkgray);
}

.event-order-summary-wrapper .event-order-summary-box {
  border-radius: var(--radius-12);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-large);
  overflow: hidden;
  padding: var(--spacing-24);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-24);
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-details .event-thumb-img {
  max-width: 160px;
  padding-bottom: var(--spacing-16);
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-details h3 {
  font: var(--body-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-list p,
.event-order-summary-wrapper .event-order-summary-box .event-order-summary-details p {
  font: var(--body-small);
  color: var(--color-text-neutral-secondary);
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-12);
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-list .order-summary-list-item {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-list .order-summary-list-item-price {
  margin-left: 20px;
  flex-shrink: 0;
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
}

.event-order-summary-wrapper .event-order-summary-box .event-order-summary-total h4 {
  font: var(--body-x-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong, #060d14);
}

.w-fit {
  width: fit-content !important;
}

.ticket-row>.event-order-summary-wrapper {
  max-width: 392px;
  width: 100%;
  flex-shrink: 0;
  padding-top: var(--spacing-48);
  padding-bottom: var(--spacing-48);
  display: grid;
  gap: var(--spacing-32);
  position: sticky;
  top: 0;
}

.ticket-event-list-wrapper .error-text {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 12px auto;
  align-items: baseline;
  gap: 8px;
}

.ticket-event-list-wrapper {
  gap: inherit;
}

.ticket-event-list-wrapper .ticket-event-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.center-box {
  min-height: 100vh;
  display: flex;
}

.support-cancel-btn-wrapper {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.cstm-modal-body {
  padding: var(--spacing-24);
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  overflow: auto;
}

.cancel-order-body h3 {
  color: var(--color-text-neutral-strong);
}

.total-refun-text h3 {
  font: var(--title-small);
  color: var(--color-text-neutral-strong);
}

.cancel-order-body .refund-text {
  color: var(--color-text-neutral-secondary);
  font: var(--body-x-small);
}

.cancel-order-body .refund-text a {
  text-underline-offset: 4px;
}

.sold-out-cls .button-stepper:hover,
.sold-out-cls .event-ticket-medium-card {
  cursor: not-allowed;
}

.sold-out-cls .event-ticket-medium-card {
  background-color: #f2f5f7;
  border-color: #0d0d0d1a;
  color: red !important;
}

.sold-out-cls .event-ticket-medium-card .event-ticket-text h3,
.sold-out-cls .event-ticket-medium-card .event-ticket-text p,
.sold-out-cls .event-ticket-medium-card h4,
.sold-out-cls .button-stepper p {
  color: var(--color-text-disabled-onstrong) !important;
}

.sold-out-cls .button:disabled {
  background-color: transparent;
  border-color: #0d0d0d1a;
}

.sold-out-cls-tag {
  position: absolute;
  top: 0;
  right: 24px;
  transform: translateY(-50%);
  padding: 2px 15px;
  border-radius: 555px;
  background: red;
  color: #fff;
  font: var(--body-x-small);
}