.MessageBarReply-Sec {
  gap: var(--spacing-8);
  /* border-radius: var(--radius-8); */
  /* overflow: hidden; */
}

.reply-line {
  width: var(--spacing-2);
  height: 40px;
  background: var(--color-bg-info-strong);
  border-radius: 4px 0 0 4px;
}

.MessageBarReply-container {
  gap: var(--spacing-8);
  flex: 1 1 auto;
  width: calc(100% - 58px);
}

.MessageBarReply-container img {
  width: var(--spacing-40);
  height: var(--spacing-40);
  border-radius: var(--radius-8);
  overflow: hidden;
  object-fit: cover;
  border: 1px solid var(--color-border-neutral-strong);
}

.MessageBar-content {
  flex: 1 1 auto;
  width: 100%;
}

.ImageReply .MessageBar-content,
.VideoReply .MessageBar-content {
  width: calc(100% - 48px);
}

.MessageBar-content p {
  margin: 0;
}

.MessageBar-content .avtarName {
  color: var(--color-text-neutral-strong);
  font: var(--body-x-small-bold);
  margin-bottom: var(--spacing-2);
}

.MessageBar-content .replyText {
  max-width: 1046px;
  color: var(--color-text-neutral-strong);
  font: var(--chat-reglular);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.ImageReply .MessageBar-content .replyText,
.ImageReply .MessageBar-content .replyText,
.ImageReply .MessageBar-content .replyText,
.ImageReply .MessageBar-content .replyText,
.VideoReply .MessageBar-content .replyText {
  max-width: 998px;
}