.chat-details-edit-info {
  display: flex;
  padding: var(--spacing-32, 32px) var(--spacing-20, 20px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24, 24px);
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
}

.chat-details-edit-info-field {
  width: 100%;
}

.chat-details-edit-info-field .text-field-main {
  max-width: 100%;
}

.chat-details-edit-info-field .text-field-main .text-field {
  padding-right: 16px;
}
.chat-details-edit-main {
  display: flex;
  width: 400px;
  height: 100%;
  flex-direction: column;
  background: var(--color-bg-screen, #fff);
}
