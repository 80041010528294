.notification-banner-box {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  background-color: var(--color-bg-info-secondary);
}
.notification-banner-box .notification-banner-text-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  column-gap: 16px;
  width: 100%;
  word-break: break-word;
}
.notification-banner-box.notification-banner-medium {
  gap: var(--spacing-24);
  padding: var(--spacing-24);
}
.notification-banner-box.notification-banner-small {
  gap: var(--spacing-16);
  padding: var(--spacing-12) var(--spacing-16);
}
.notification-banner-box.notification-banner-small .notification-banner-text-wrapper {
  flex-direction: row;
  justify-content: space-between;
}
.notification-banner-box.notification-banner-bg-success {
  background-color: var(--color-bg-success-secondary);
}
.notification-banner-box.notification-banner-bg-warning {
  background-color: var(--color-bg-warning-secondary);
}
.notification-banner-box.notification-banner-bg-danger {
  background-color: var(--color-bg-danger-secondary);
}
.notification-banner-box.notification-banner-bg-info {
  background-color: var(--color-bg-info-secondary);
}
.notification-banner-box.notification-banner-bg-neutral {
  background-color: var(--color-bg-neutral-secondary);
}
.notification-banner-box .notification-banner-icon {
  flex-shrink: 0;
  display: flex;
}
.notification-banner-box .notification-banner-icon svg {
  width: 24px;
  height: auto;
}
.notification-banner-box .notification-banner-title {
  font: var(--body-small);
  font-weight: 600;
  color: var(--color-text-static-darkgray);
}
.notification-banner-box.notification-banner-small .notification-banner-title {
  display: none;
}
.notification-banner-box .notification-banner-text {
  font: var(--body-small);
  color: var(--color-text-static-darkgray);
}
.notification-banner-box .notification-banner-action-text {
  cursor: pointer;
  font: var(--body-small);
  font-weight: 600;
  flex-shrink: 0;
}
.notification-banner-box.notification-banner-medium .notification-banner-action-text {
  margin-top: var(--spacing-4);
}
.notification-banner-box.notification-banner-bg-success .notification-banner-action-text {
  color: var(--color-text-success-onsecondary);
}
.notification-banner-box.notification-banner-bg-warning .notification-banner-action-text {
  color: var(--color-text-warning-onsecondary);
}
.notification-banner-box.notification-banner-bg-danger .notification-banner-action-text {
  color: var(--color-text-danger-onsecondary);
}
.notification-banner-box.notification-banner-bg-info .notification-banner-action-text {
  color: var(--color-text-info-onsecondary);
}
.notification-banner-box.notification-banner-bg-neutral .notification-banner-action-text {
  color: var(--color-text-neutral-onsecondary-strong);
}
