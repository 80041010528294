.chatdata-sec {
    padding: 0px var(--spacing-24);
    color: var(--color-text-neutral-tertiary);
    font: var(--body-x-small);
    word-break: break-word;
    flex-wrap: wrap;
}

.chatdata-sec span {
    padding: 0px var(--spacing-4);
    color: var(--color-text-neutral-secondary);
    font: var(--body-x-small-bold);
    text-align: center;
}