/* Avtar size */
.avtar-60 {
  width: 60px;
  height: 60px;
  min-width: 60px; 
}

.avtar-48 {
  width: var(--spacing-48);
  height: var(--spacing-48);
  min-width: 48px;
}

.avtar-40 {
  width: var(--spacing-40);
  height: var(--spacing-40);
  min-width: 40px;
}

.avtar-32 {
  width: var(--spacing-32);
  height: var(--spacing-32);
  min-width: 32px;
}

.avtar-24 {
  width: var(--spacing-24);
  height: var(--spacing-24);
  min-width: 24px;
}

.avtar-16 {
  width: var(--spacing-16);
  height: var(--spacing-16);
  min-width: 16px;
}

/* Avtar rounded */
.avtar-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-neutral-strong);
}

.rounded .avtar-wrapper {
  border-radius: var(--radius-full) !important;
}

.avtar-60 .avtar-wrapper,
.avtar-48 .avtar-wrapper {
  border-radius: var(--radius-12);
}

.avtar-40 .avtar-wrapper,
.avtar-32 .avtar-wrapper {
  border-radius: var(--radius-8);
}

.avtar-24 .avtar-wrapper,
.avtar-16 .avtar-wrapper {
  border-radius: var(--radius-4);
}

/* Avtar type "Image" */

.avtar-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Avatar type "Icon" */

.avtar-wrapper.avtar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avtar-60 .avtar-icon svg {
  width: var(--spacing-32);
  height: var(--spacing-32);
}

.avtar-48 .avtar-icon svg,
.avtar-40 .avtar-icon svg {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.avtar-32 .avtar-icon svg {
  width: var(--spacing-20);
  height: var(--spacing-20);
}

.avtar-24 .avtar-icon svg {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

.avtar-16 .avtar-icon svg {
  width: var(--spacing-12);
  height: var(--spacing-12);
}

/* Avatar type "Letter" */
.avtar-wrapper.avtar-letter {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-inverse);
  text-align: center;
  text-transform: uppercase;
}

.avtar-60 .avtar-letter,
.avtar-48 .avtar-letter {
  font: var(--body-2x-large-bold);
}

.avtar-40 .avtar-letter {
  font: var(--body-x-large-bold);
}

.avtar-32 .avtar-letter {
  font: var(--body-medium-bold);
}

.avtar-24 .avtar-letter {
  font: var(--body-small-bold);
}

.avtar-16 .avtar-letter {
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

/* Avtar type "Logo" */

.avtar-wrapper.avtar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-neutral-secondary);
}

.avtar-60 .avtar-logo img,
.avtar-48 .avtar-logo img,
.avtar-40 .avtar-logo img {
  width: var(--spacing-40);
  height: var(--spacing-40);
}

.avtar-32 .avtar-logo img {
  width: var(--spacing-32);
  height: var(--spacing-32);
}

.avtar-24 .avtar-logo img {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.avtar-16 .avtar-logo img {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

/* Avtar type "MDS LOGO" */
.avtar-60 .avtar-mds svg {
  width: 60px;
  height: 60px;
}

.avtar-48 .avtar-mds svg {
  width: var(--spacing-48);
  height: var(--spacing-48);
}

.avtar-40 .avtar-mds svg {
  width: var(--spacing-40);
  height: var(--spacing-40);
}

.avtar-32 .avtar-mds svg {
  width: var(--spacing-32);
  height: var(--spacing-32);
}

.avtar-24 .avtar-mds svg {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.avtar-16 .avtar-mds svg {
  width: var(--spacing-16);
  height: var(--spacing-16);
}

/* Avtar Badge positions */
.avtar-badge {
  position: absolute;
}

/* Badge postion while Roundted= True */
.rounded.avtar-60 .avtar-badge {
  bottom: -1px;
  right: -1px;
}

.rounded.avtar-48 .avtar-badge {
  bottom: 0px;
  right: 0px;
}

.rounded.avtar-40 .avtar-badge,
.rounded.avtar-32 .avtar-badge {
  bottom: -2px;
  right: -2px;
}

.rounded.avtar-24 .avtar-badge {
  bottom: -3px;
  right: -3px;
}

/* Badge postion while Roundted= False */
.avtar-60 .avtar-badge {
  bottom: -6px;
  right: -6px;
}

.avtar-48 .avtar-badge,
.avtar-40 .avtar-badge,
.avtar-32 .avtar-badge,
.avtar-24 .avtar-badge {
  bottom: -4px;
  right: -4px;
}