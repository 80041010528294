.MessageVoiceMessage-sec {
  display: flex;
  padding: var(--spacing-2, 2px) 0px;
  align-items: center;
  gap: var(--spacing-8, 8px);
}

.MessageVoiceMessage-content {
  gap: var(--spacing-8);
  display: flex;
  padding-right: 8px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
}

.MessageVoiceMessage-content p {
  margin: 0 !important;
  font: var(--body-2x-small);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
  /* width: var(--spacing-32); */
  flex-shrink: 0;
  white-space: nowrap;
  text-align: center;
}

.MessageVoiceMessage-content-voice {
  position: relative;
}

.line-container1 {
  height: 20px;
}

.MessageVoiceMessage-content-voice canvas {
  width: 100% !important;
}


/* .dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: red;
  position: absolute;
}

.vertical-line {
  border-left: thick solid #990000;
  height: 90%;
  top: 5%;
  color: red;
  background: red;
  position: relative;
  right: 5%;
  z-index: 999;
}

.line-container {
  height: 90%;
  top: 5%;
  background: red;
  position: absolute;
  right: 5%;
  z-index: 9999;
} */