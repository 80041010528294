.cstm-btm-at {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}
.z-full {
  z-index: 9999;
}
.cstm-srd {
  display: flex;
}
.sort-by-mn-bl {
  margin-left: 70px; 
}
.cstm-admin-access-rules {
  margin-bottom: 10px;
}

.cstm-tabs-menu-only {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: inherit !important;
}

.cstm-tabs-menu-only::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

.cstm-tabs-menu-only::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ffffff;
}

.cstm-tabs-menu-only::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}

#guestTickets .uic-title-rt-only {
  margin-top: inherit;
}

#addons .uic-title-rt-only {
  margin-top: inherit;
}

.edit-user-details-popup .modal-content {
  height: 100%;
}

.edit-user-details-popup .modal-body {
  height: inherit !important;
}

/* End 12-3-2024 */

.insert-loading {
  position: fixed;
  z-index: 99999;
  background: #ffffffcc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insert-loading img {
  width: 80px;
}

.cstmMainImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-document-main-body-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  width: 100%;
}

.notification-btm-btn {
  margin-top: 30px;
  margin-bottom: 50px;
}

.notification-btm-btn .btn2 {
  vertical-align: top;
  margin-right: 24px;
}

.error-extra-seats {
  color: var(--red);
  font-size: 14px;
  line-height: 18px;
  display: block;
  width: 100%;
}

.error-saubcategor-name {
  color: var(--red);
  display: block;
  font-size: 12px;
  position: absolute;
  bottom: -19px;
}

.doc-subcat-subcontainer {
  position: relative;
  padding-bottom: 24px !important;
  display: flex;
  gap: 12px;
}

.doc-addsubcat-container-main {
  display: flex;
}

.doc-subcat-edit {
  padding: 0px 16px !important;
}

.doc-subcat-delete {
  padding: 0px 16px !important;
}

.error-doc-addedsucat {
  color: var(--red);
  display: block;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
}

h4.prevention-copy-sub-title {
  margin-bottom: 35px;
  font-weight: 400;
}

#editActivity,
#AddActivity {
  overflow: hidden;
}

.notification-modal-btns {
  display: inline-flex;
  column-gap: 10px;
}

.doc-subcat-subcontainer-icon {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.doc-subcat-subcontainer-icon img {
  width: 24px;
  cursor: pointer;
}

.cstm-loadind-add {
  text-align: center;
  background: #ffffffdb;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cstm-subcategory-loader {
  position: relative;
}

.cstm-loadind-add img {
  position: absolute;
  width: 32px;
}

.doc-subcat-subcontainer {
  position: relative;
  padding-bottom: 24px !important;
  display: flex;
  gap: 12px;
}

.doc-addsubcat-container-main {
  display: flex;
}

.doc-subcat-edit {
  padding: 0px 16px !important;
}

.doc-subcat-delete {
  padding: 0px 16px !important;
}

.error-doc-addedsucat {
  color: var(--red);
  display: block;
  font-size: 12px;
  position: absolute;
  bottom: 5px;
}

h4.prevention-copy-sub-title {
  margin-bottom: 35px;
  font-weight: 400;
}

#editActivity,
#AddActivity {
  overflow: hidden;
}

.notification-modal-btns {
  display: inline-flex;
  column-gap: 10px;
}

.cstm-uic-doc-title {
  display: block !important;
}

.notification-user-list-popup .desk-globle-model-box-inner {
  max-width: 800px;
}

.notification-user-list-popup .cstm-cstm-viewlist {
  height: auto;
  margin-top: 20px;
}

.cstm-title-note-msg {
  font-size: 12px;
  color: #747474;
  position: absolute;
  right: 12px;
  margin-top: -2px;
}

.notification-status-ad-only {
  margin-bottom: 24px !important;
}

.custom-select-atlest {
  margin-bottom: 30px !important;
}

.select-atleast-one-rule-from {
  margin: 5px 0px 0px;
}

.notification-user-list {
  cursor: pointer;
}

.notification-user-list:hover {
  text-decoration: underline;
}

.cstm-datepicker-field .react-datepicker__calendar-icon {
  fill: #091f48;
  right: 13px;
  top: 9px;
}

.cstm-datepicker-field .react-datepicker-wrapper {
  width: 150px !important;
}

.acces-error-only {
  bottom: 10px;
}

.message-report-video {
  position: relative;
  width: 130px;
}

.report-play-video {
  position: absolute;
  background: #01010154;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-play-video img {
  height: 40px;
  width: 40px;
}

.report-message-thumbnail {
  height: 90px;
  width: 130px;
  object-fit: cover;
}

.message-report-media-popup .modal-dialog {
  max-width: 800px;
}

.message-report-media-popup button.close {
  line-height: 18px;
  position: absolute;
  right: -10px;
  top: -10px;
  color: #000;
  z-index: 99;
  background: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
}

.cstm-all-admin a {
  color: #201e29;
}

.cstm-all-admin a:hover {
  text-decoration: underline;
}

.upload-document-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-24, 24px);
  flex: 1 0;
  max-width: 768px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.upload-document-main-title {
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-8, 8px);
  align-self: stretch;
}

.upload-document-main-title h4 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
  letter-spacing: -0.24px;
  margin: 0px;
}

.upload-document-main-title h5 {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-large);
  margin: 0px;
}

.upload-document-main-body {
  border-radius: var(--radius-medium, 12px);
  border: 2px dashed var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  width: 100%;
}

.upload-document-error {
  color: var(--red);
  display: block;
  position: absolute;
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  bottom: -20px;
}

.file-uploader-body {
  display: flex;
  padding: var(--spacing-24, 24px);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-24, 24px);
}

.file-uploader-body-drag {
  display: flex;
  align-items: center;
  gap: var(--spacing-16, 16px);
  flex: 1 0 0;
}

.file-uploader-body-drag-text h5 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-x-small);
}

.file-uploader-body-drag-text h6 {
  margin: 0px;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
}

.file-uploader-body-drag-icon {
  min-width: 32px;
}

.cstm-progress-bar {
  display: flex;
  padding-bottom: var(--spacing-2, 2px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-8, 8px);
  flex: 1 0 0;
  width: 100%;
}

.cstm-progress-bar-info {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  justify-content: space-between;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-x-small);
}

.cstm-progress-bar-track {
  background: var(--color-bg-neutral-secondary, #eaedf1);
  height: 4px;
  width: 100%;
  border-radius: var(--radius-x-small, 4px);
  position: relative;
}

.cstm-progress-bar-track-color {
  position: absolute;
  height: 4px;
  border-radius: var(--radius-x-small, 4px);
  background: var(--color-bg-brand-strong, #0b42cf);
}

.cstm-delete-popup .modal-dialog {
  max-width: 648px;
  width: 100%;
}

.cstm-delete-popup .modal-content {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
}

.cstm-delete-badge {
  display: flex;
  padding: var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-24, 24px);
  flex: 1 0 0;
}

.cstm-delete-badge-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.popup-title-h3 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
  letter-spacing: -0.24px;
}

.popup-cont-p {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--regular);
  margin: 0px;
}

.popup-cont-p b {
  font-weight: 700;
}

.cstm-popup-footer {
  display: flex;
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.btn-cancel-popup {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-space-small, 11px) var(--spacing-20, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  background-color: var(--color-bg-static-white);
}

.btn-cancel-popup:hover {
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.btn-cancel-popup:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

body button.btn-cancel-popup:focus {
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d) !important;
  outline-offset: 2px;
}

.btn-delete-popup {
  display: flex;
  padding: var(--spacing-space-small, 11px) var(--spacing-20, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-danger-strong, #f1343d);
  color: var(--color-text-danger-onstrong, #fff);
  text-align: center;
  font: var(--body-large-bold);
  border: none;
}

.btn-delete-popup:hover {
  background: var(--color-bg-danger-strong-hovered, #db1d26);
}

.btn-delete-popup:active {
  background: var(--color-bg-danger-strong-pressed, #c90007);
}

body button.btn-delete-popup:focus {
  background: var(--color-bg-danger-strong, #f1343d);
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d) !important;
  outline-offset: 2px;
}

.select-a-new {
  display: flex;
  width: 296px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-8, 8px);
}

.select-a-new-h5 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-2x-small);
}

body select.select-popup-field {
  border-radius: var(--radius-8, 8px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px) var(--spacing-8, 8px)
    var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font: var(--body-medium);
  height: 42px;
  background-image: url(../Images/icn_m_chevron_down.svg);
  background-position: 95% center;
}

.btn-blue-popup {
  display: flex;
  padding: var(--spacing-space-small, 11px) var(--spacing-20, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-danger-onstrong, #fff);
  text-align: center;
  font: var(--body-large-bold);
  border: none;
}

.btn-blue-popup:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}

.btn-blue-popup:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
}

body button.btn-blue-popup:focus {
  background: var(--color-bg-brand-strong, #0b42cf);
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d) !important;
  outline-offset: 2px;
}

button.upload-media-btn:disabled {
  pointer-events: none !important;
  background: var(--color-bg-disabled-strong, #dadde0) !important;
  color: var(--color-text-disabled-onstrong, #84898f) !important;
}

.select-a-new .error {
  position: inherit;
  font-size: 12px;
  font-weight: 500;
}

.selected-active {
  background: #f3f5f7 !important;
}

.drop-box-only {
  margin-top: -20px !important;
}

.suggestion-box {
  position: absolute;
  padding: 12px 0 !important;
  z-index: 2;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  margin-top: 4px;
  max-height: 350px;
  overflow: auto;
  width: 305px;
}

.suggestion-box ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.suggestion-box ul li {
  padding: 10px 16px;
  cursor: pointer;
}

.suggestion-box ul li b {
  color: #000;
  font-weight: 700;
}

.suggestion-box ul li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.cstm-clone-title {
  font-size: 28px;
  line-height: 29px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 15px;
}

#rbgcp-wrapper > div:nth-child(4) > div:nth-child(2),
#rbgcp-wrapper > div:nth-child(4) > div:nth-child(3),
#rbgcp-wrapper > div:nth-child(4) > div:nth-child(4) {
  display: none;
}

.group-info-category-img img {
  height: 50px;
  width: 90px;
  object-fit: cover;
}

.group-info-category-img {
  margin-right: 12px;
}

.cstm-plan-member-main .cstm-cstm-viewlist {
  margin-top: 20px;
}

.mrgn-rgt {
  margin-right: 20px;
}

.cstm-url-category-photo {
  object-fit: contain;
  height: 50px;
  width: 90px;
  border: 1px solid #ebebeb;
  margin-right: 10px;
  background: #f3f5f7;
  cursor: pointer;
}

.label-eup-main {
  padding: 16px 16px 8px;
  border-radius: 1px;
  background: transparent;
  border: 1px solid #d2d2d4;
}

.label-eup-main-in {
  align-items: center;
  background: #351bce;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
  position: relative;
}

.label-eup-main-in a {
  color: #fff;
}

.cstm-reason-info select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cstm-add-related-video {
  display: flex;
}

.sort-by-mn {
  margin-bottom: 20px;
}

.sort-by-mn-select {
  display: inline-block;
  width: 106px;
}

.sort-by-mn-title {
  display: inline-block;
  margin-right: 12px;
}

.no-data-fp {
  grid-column: 12/1;
  height: calc(100vh - 300px) !important;
}

.searchPartner h1 img {
  padding-left: 8px;
  width: 36px;
}

.searchPartner h1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px !important;
}

.cstm-admin-table-badge-color {
  border-radius: 1px;
  height: 20px;
  width: 30px;
}

.cstm-badgecolor-container {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgb(32 30 41 / 70%);
  height: 48px;
  padding: 10px 20px;
  width: 100%;
}

input.cstm-admin-badge-color {
  padding: 0px;
  border: none;
  cursor: pointer;
}

h1.clone-title {
  text-align: initial;
}

.featuredPartner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

span.max-select {
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
}

.cstm-badgecolor-container {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgb(32 30 41 / 70%);
  height: 48px;
  padding: 10px 20px;
  width: 100%;
}

input.cstm-admin-badge-color {
  padding: 0px;
  border: none;
  cursor: pointer;
}

.cstm-clone-msg-container {
  border: 1px solid #d2d2d4;
  border-radius: 8px;
  background: #fff;
  padding: 14px 10px 12px 10px;
  position: fixed;
  left: 24px;
  bottom: 24px;
  width: 100%;
  max-width: 451px;
  transition: 0.5s;
  -webkit-animation-name: example;
  -webkit-animation-duration: 4s;
  animation-name: example;
  animation-duration: 4s;
  z-index: 3;
}

.searchPartner {
  display: flex;
  flex-direction: column;
}

input.editfeatured {
  margin-bottom: 5px;
  width: 500px !important;

  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  color: #091f48 !important;
  border: none !important;
  padding: 0px !important;
  height: auto !important;
}

input.editfeatured2 {
  width: 500px !important;

  line-height: 20px !important;
  border: none !important;
  padding: 0px !important;
  height: auto !important;
}

.cstm-clone-msg-container p {
  margin: 0px;
}

.cstm-clone-msg-container h3 {
  font-weight: 600;

  font-size: 18px;
  color: #091f48;
}

.cstm-select-ap-drop {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
}

.cstm-select-ap-drop .react-datepicker__tab-loop {
  display: inline-block;
  vertical-align: top;
}

.cstm-select-ap-drop .react-datepicker-wrapper {
  width: 130px;
  vertical-align: top;
  display: inline-block;
}

.cstm-select-ap-drop img.cstm-arrow-left-right {
  margin: 17px 10px;
}

.cstm-select-ap-drop .react-datepicker__triangle:before,
.cstm-select-ap-drop .react-datepicker__triangle:after {
  display: none !important;
}

.cstm-select-ap {
  display: inline-block;
}

.lifetime-only {
  margin-left: 20px;
  position: relative;
  z-index: 1;
}

.partner-status-select {
  width: 95px;
  display: flex;
  align-content: center;
  align-items: center;
}

a.cstm-view-users-count-only:hover {
  color: #3114c9 !important;
  text-decoration: underline !important;
}

.cstm-view-users-count {
  display: flex;
  align-items: center;
}

.cstm-lg-ttl {
  display: flex;
  align-items: center;
}

.cstm-url-link {
  object-fit: contain;
  height: 50px;
  width: 50px;
  border: 1px solid #ebebeb;
  border-radius: 50px;
  margin-right: 10px;
  background: #f3f5f7;
  cursor: pointer;
}

img.partner-sort-logo {
  object-fit: contain;
}

img.banner-img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border: 1px solid #ebebeb;
  border-radius: 50px;
  margin-right: 10px;
  background: #f3f5f7;
}

.status-select-only {
  padding: 0px 20px 0px 0px !important;
  width: auto !important;
  margin-left: 10px !important;
  height: auto !important;
  border: none !important;
  background-image: url(../Images/arrowselect.png) !important;
}

.cstm-ls-checkbox svg {
  position: absolute !important;
}

.cstm-sec-button {
  display: flex;
  gap: 2px 24px;
  align-items: center;
}

.cstm-sec-button .uic-fl-links .btn.disable {
  color: #091f48 !important;
  opacity: 0.3;
  pointer-events: none;
}

.css-1q1u3t4-MuiTableRow-root.MuiTableRow-hover:hover {
  /* background-color: rgba(0, 0, 0, 0.04); */
}

.cstm-sec-button .uic-fl-links .btn {
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #351bce !important;
  height: auto;
  padding: 0px 24px;
  margin: 0px;
  box-shadow: none;
  text-decoration: underline;
  width: auto;
}

.cstm-sec-button .uic-fl-links .btn:hover {
  background: transparent;
  border: none;
  color: #091f48 !important;
}

.cstm-uplaod-img-link span.text-limitaion {
  position: absolute;
  bottom: 0;
}

.select-all-checkbox {
  display: inline-block;
  vertical-align: top;
  margin-right: 21px;
  padding-top: 13px;
  font-size: 16px;
}

.select-all-checkbox input {
  vertical-align: -3px;
}

.select-all-checkbox label {
  margin: 0px;
  cursor: pointer;
}

.cstm-eventimage-wrapper {
  position: absolute;
  margin: 10px;
  z-index: 1;
}

.cstm-eventimage-wrapper input {
  width: 20px !important;
  width: 20px !important;
}

.cstm-partner-preview {
  background: #fff;
  border: 1px solid #091f48;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  color: #091f48;
  height: 48px;
  padding: 0px 40px;
  cursor: pointer;
}

.cstm-partner-preview:hover {
  background: #201e29;
  border: 1px solid #201e29;
  color: #fff;
}

.cstm-profile-upload-btn .edit-pro-text2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0b42cf;
  cursor: pointer;
  text-decoration: underline;
}

.msg-select-category {
  bottom: 1px;
}

.cstm-edit-attendees-word {
  height: 72px;
  width: 72px;
  display: flex;
  object-fit: cover;
  background-repeat: no-repeat;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.13));
  border-radius: 144px;
  background: #201e28;
  color: #fff;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin: auto;
}

.event-locimage-loader {
  /* position: relative; */
  opacity: 0.5;
  display: inline-block;
}

.event-locimage-loader span.loader-btn {
  /* position: absolute; */
  left: 0;
  z-index: 2;
  background: #ffffffd6;
  /* width: 163px; */
  text-align: center;
  /* line-height: 50px; */
}

.show-icon i {
  transform: rotate(180deg);
}

span.error.cstm-smg {
  margin-top: -28px;
}

.cstm-cl-search-sug-history-remove {
  color: var(--color-text-brand-strong, #0b42cf);
  float: right;
  align-items: center;
  display: flex;
}

.cancel-search-videolist {
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
  padding: 9px;
  margin: -8px;
}

.cstm-cl-search-sug-container {
  position: absolute;
  padding: 12px 0 !important;
  width: 700px !important;
  z-index: 2;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  margin-top: 4px;
}

.cstm-cl-search-sug-container {
  max-height: 350px;
  overflow: auto;
}

.cstm-cl-search-sug-container::-webkit-scrollbar {
  width: 6px;
}

.cstm-cl-search-sug-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary-hovered);
}

.cstm-cl-search-sug-container::-webkit-scrollbar-thumb {
  background: var(--color-border-neutral-secondary);
  border-radius: 10px;
}

.cstm-cl-search-sug-container ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.cstm-cl-search-sug-container ul li {
  padding: 10px 16px;
  cursor: pointer;
}

.cstm-cl-search-sug-container ul li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.cstm-cl-search-sug-history-container {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.cstm-cl-search-sug-history-remove {
  color: var(--color-text-brand-strong, #0b42cf);
  float: right;
  align-items: center;
  display: flex;
  padding: 0px;
  border: none;
  background: transparent;
  font: var(--body-medium);
  line-height: 20px;
}

.cstm-cl-search-sug-history-remove:hover {
  color: #000 !important;
}

.cstm-cl-search-sug-history-title {
  overflow-wrap: anywhere;
  color: var(--color-text-neutral-strong, #060d14);
  width: 100%;
  padding-right: 50px;
  font: var(--body-medium);
  line-height: 20px;
}

.cstm-cl-search-sug-history-title b {
  font-weight: 700;
}

.event-type-drop-only {
  padding-bottom: 0px !important;
}

.event-type-drop-only .css-b62m3t-container {
  width: 100%;
}

.event-type-drop-only .css-t3ipsp-control {
  border: 1px solid #d2d2d4 !important;
  box-shadow: none !important;
}

.event-type-drop-only .css-1xc3v61-indicatorContainer,
.event-type-drop-only .css-15lsz6c-indicatorContainer {
  color: #545454 !important;
  margin-right: 5px !important;
}

.event-type-drop-only .css-1u9des2-indicatorSeparator {
  display: none !important;
}

.event-type-drop-only .css-t3ipsp-control:hover {
  border: 1px solid #d2d2d4 !important;
  box-shadow: none !important;
}

.event-type-drop-only .css-13cymwt-control {
  border-radius: 0px;
  border: 1px solid #d2d2d4 !important;
}

.event-type-drop-only .css-1fdsijx-ValueContainer {
  padding-left: 15px !important;
}

.event-type-drop-only .css-1nmdiq5-menu {
  z-index: 5 !important;
}

.event-type-drop-only .css-tr4s17-option {
  background-color: #351bce !important;
}

.event-type-drop-only .css-d7l1ni-option {
  background-color: #ebe8fa !important;
  cursor: pointer;
}

.event-type-drop-only input {
  height: 34px !important;
}

.cstm-replace-pp {
  padding: 20px;
  width: 100%;
}

.cstm-rpp-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #201e29;
  margin-bottom: 10px;
}

.cstm-banner-img .desk-globle-model-box-inner {
  max-width: 800px;
}

.cstm-banner-img .zoomImgContainer img {
  width: 100%;
  object-fit: contain;
  max-height: 500px;
}

.no-data-found-pl {
  display: table-caption;
  font-size: 16px;
  color: red;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

.export-error {
  text-align: right;
  margin-top: -10px;
  margin-bottom: 5px;
  font-size: 12px;
  color: red;
}

.cstm-export-link {
  text-align: right;
  padding-bottom: 15px;
}

.cstm-export-link a {
  color: #351bce !important;
  font-weight: 500;
  text-decoration: underline !important;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 7px;
}

.cstm-export-link a:hover {
  color: #000 !important;
  text-decoration: inherit !important;
}

.cstm-cstm-viewlist .MuiTableContainer-root {
  /* overflow-y: hidden; */
  min-height: 488px;
  border-bottom: 1px solid #d2d2d4;
  position: relative;
  /* margin-bottom: 10px; */
}

.cstm-cstm-viewlist {
  max-height: calc(100vh - 120px);
  overflow: auto;
  height: 570px;
  margin-bottom: -10px;
}

.cstm-cstm-viewlist::-webkit-scrollbar {
  width: 6px;
}

.cstm-cstm-viewlist::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.cstm-cstm-viewlist::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 10px;
}

.partner-liasting-top-title {
  padding: 20px;
  font-size: 18px;
  color: #060d14;
  margin-bottom: 0px;
}

.cstm-pl-close {
  right: 15px;
  top: 17px;
  position: absolute;
  color: #aeaeae;
  font-size: 35px;
  font-weight: 400;
  cursor: pointer;
}

.cstm-pl-close:hover {
  color: #351bce;
}

.partner-listing-popup-only .desk-globle-model-box-inner {
  max-width: 700px;
  border-radius: 0px;
  overflow: hidden;
}

.cstm-view-users-count:hover {
  color: #000 !important;
  text-decoration: underline #351bce !important;
}

.cstm-do-you-want {
  display: flex;
}

.form-check-input:checked {
  background-color: #351bce;
  border-color: #351bce;
}

.cstm-pm-list {
  display: flex;
  align-items: center;
}

.status-undo:hover img {
  filter: invert(0.2) sepia(10) saturate(350) hue-rotate(-115deg);
}

.partner-icon-rf-only {
  line-height: 13px !important;
  display: flex;
  align-items: center;
  padding-bottom: 3px;
}

a.status-undo {
  margin-left: 5px;
}

.tox .tox-number-input input {
  height: 25px !important;
}

.select-maximum {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.uic-title img {
  padding-right: 8px;
  padding-left: 8px;
  width: 30px;
}

body img.cstm-vdo {
  max-height: 50px !important;
  width: auto !important;
}

.partner-logo-780-500 {
  height: 143px !important;
  width: 222px !important;
}

.partner-logo-780-500 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-2256-760 {
  height: 76px !important;
  width: 222px !important;
}

.partner-logo-2256-760 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-1128-380 {
  height: 113px !important;
  width: 222px !important;
}

.partner-logo-1128-380 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-375-144 {
  height: 87px !important;
  width: 222px !important;
}

.partner-logo-375-144 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-624-336 {
  height: 104px !important;
  width: 222px !important;
}

.partner-logo-624-336 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-720-522 {
  height: 161px !important;
  width: 222px !important;
}

.partner-logo-720-522 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-2256-576 {
  width: 222px !important;
  height: 58px !important;
}

.partner-logo-2256-576 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-logo-780-552 {
  width: 222px !important;
  height: 158px !important;
}

.partner-logo-780-552 img {
  max-height: 100% !important;
  object-fit: cover;
  width: 100%;
}

.partner-list-action {
  margin-right: 10px;
  padding: 0px;
  border: none;
  background: transparent;
}

.cstm-data-edit-load2 {
  text-align: center;
  position: absolute;
  box-sizing: border-box;
  width: 78%;
  height: calc(100vh - 90px);
  left: 21%;
}

.cstm-data-edit-load2 img {
  width: 60px;
  position: relative;
  top: 45%;
}

.tox .tox-number-input input {
  padding: 0px !important;
  height: 25px !important;
}

.cstm-editor-tool-ds .tox-tinymce {
  border: none !important;
}

.cstm-data-edit-load img {
  width: 60px;
}

.cstm-data-edit-load {
  margin-top: 17%;
  text-align: center;
}

.expired-only {
  background: #fbc3c3 !important;
}

input#react-select-2-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tox-tinymce {
  border: none !important;
}

.tox .tox-toolbar__primary {
  background-image: unset !important;
}

.tox .tox-number-input {
  border-radius: 3px;
  display: flex;
  margin: 6px 1px 5px 0;
  padding: 0 4px;
  width: auto;
  align-items: center;
}

.cstm-editor-tool-ds {
  border: 1px solid #d2d2d4;
}

.save-draft-loader {
  margin-left: 20px;
}

.cstm-company-logo .desk-globle-model-box-inner {
  max-width: 800px;
}

.cstm-company-logo .zoomImgContainer {
  text-align: center;
  margin: 20px 0px 0px;
}

.cstm-company-logo .zoomImgContainer img {
  width: 100%;
  object-fit: contain;
  max-height: 500px;
}

.cstm-rpp-close-btn {
  color: #aeaeae;
  font-size: 35px;
  font-weight: 300;
  line-height: 18px;
  float: right;
  cursor: pointer;
}

.cstm-rpp-close-btn:hover {
  color: var(--navyblue);
}

.pending-only {
  background: #f1eeff !important;
}

.review-count {
  background: #351bce;
  margin-left: 5px;
  color: #fff;
  font-size: 10px;
  border-radius: 15px;
  font-weight: 700;
  min-width: 21px;
  height: 21px;
  display: inline-block;
  line-height: 18px;
  font-family: "Inter", sans-serif;
  vertical-align: 2px;
  text-align: center;
  border: 1px solid white;
}

.cstm-reason-info label {
  text-align: left;
  display: block;
  color: #000;
  font-size: 16px;
}

.cstm-reason-info {
  margin-bottom: 24px;
}

.ays-cancel-btn-main {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.close-draft {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 35px;
  cursor: pointer;
  color: #aeaeae;
  font-weight: 400;
}

.close-draft:hover {
}

a.create-speaker-link {
  text-decoration: underline !important;
  color: #2a11aa !important;
  cursor: pointer;
}

.cstm-uplaod-img-link {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.cstm-uplaod-img-link .form-group {
  text-align: center;
}

span.value-dollar {
  position: absolute;
  margin: 14px 0px 0px 10px;
}

.cstm-url-field {
  width: 400px !important;
  margin-right: 20px;
}

.cstm-select-mm .highlightOption {
  background: unset;
  color: unset;
}

.cstm-select-mm .multiSelectContainer li:hover {
  background: #351bce;
}

.cstm-select-mm input {
  border: none !important;
  padding: 8px 15px !important;
  height: inherit !important;
}

.cstm-select-mm .chip {
  margin-top: 6px;
}

.cstm-applyurl-container {
  display: flex;
  float: left;
}

.cstm-apply-btn-container {
  margin-right: 20px;
}

body .filter select {
  width: auto;
}

.filter {
  margin-left: 20px;
}

.delete-modal-with-loader {
  z-index: 9999;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.select-attendees-box {
  max-height: calc(100vh - 250px);
  overflow: auto;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 10px;
  padding-right: 20px;
}

.select-attendees-box::-webkit-scrollbar {
  width: 6px;
}

.select-attendees-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.select-attendees-box::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.select-attendees-box::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

.existing-attendee-search {
  margin-bottom: 12px;
}

.uic-title-rt-only button.btn,
.uic-title-rt-only .import.text {
  padding: 0px 15px;
}

.delete-modal-with-loader {
  background: #000000c7;
}

.delete-modal-with-loader .modal-content {
  max-width: 500px;
  width: 100%;
}

span.response-text {
  font-size: 20px;
  color: #000;
  line-height: 24px;
  margin: 25px 0px;
}

span.message-modal-addtional-div {
  margin-bottom: 25px;
  font-size: 18px;
  color: #000;
}

span.text-limitaion {
  float: right;
  font-size: 12px;
  color: #747474;
}

.cstm-profile-selector-btn {
  text-align: right;
}

.cstm-profile-selector-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cstm-profile-selector-all {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(11%, 1fr));
  overflow: auto;
  margin: 30px 0px;
  max-height: calc(100vh - 280px);
}

.cstm-profile-selector-all::-webkit-scrollbar {
  width: 6px;
}

.cstm-profile-selector-all::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.cstm-profile-selector-all::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.cstm-profile-selector-all::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

.profile-selector-modal .modal-content {
  padding: 30px;
}

.profile-selector-modal button.close {
  position: absolute;
  right: 4px;
  top: 3px;
  font-size: 25px;
  font-weight: 400;
  color: #afafaf;
}

.cstm-profile-selector-top h1 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #091f48;
}

.cstm-profile-selector input {
  position: absolute !important;
  left: 8px;
  top: 8px;
}

button.delete-modal-with-loader-close-icon {
  text-align: center;
  position: absolute;
  width: 22px;
  right: 8px;
  top: 5px;
  height: 22px;
  background: #000;
  padding: 0px 2px;
  border: 1px solid #b3b3b3;
  border-radius: 50px;
  color: #ffff;
  font-size: 18px;
  line-height: 18px;
  display: grid;
}

.cstm-profile-selector {
  position: relative;
  cursor: pointer;
  margin: 0px;
}

.cstm-profile-selector img {
  height: 144px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #c1c1c1 !important;
}

.profile-selector-modal {
  background: #000000bd;
}

.profile-selector-modal .modal-dialog {
  max-width: 1250px;
}

.select-participants-box {
  padding-right: 20px;
}
.select-participants-searchbar {
  margin-bottom: 17px;
}

body .cstm-date-search {
  padding-right: 12px !important;
}

.select-participants-box-in-name {
  display: flex;
  gap: 10px;
  padding-left: 30px;
}

.select-participants-box-in-checkbox {
  position: absolute;
  top: 10px;
}

.cstm-apl-upload-close {
  position: absolute;
  right: -7px;
  background: #351bce;
  color: #fff;
  font-size: 19px;
  top: -9px;
  height: 19px;
  width: 19px;
  line-height: 18px;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.cstm-apl-upload-img {
  margin-top: 20px;
}

.cstm-apl-upload-img-box {
  margin: 0px 20px 20px 0px;
  position: relative;
  float: left;
}

.cstm-apl-upload-img-box img {
  object-fit: cover;
  height: 90px;
  width: 90px;
}

.cstm-apl-main {
  border: 1px dashed #a2a2a2;
  text-align: center;
  padding: 16px;
  font-size: 16px;
  color: #351bce;
}

.cstm-chk-preregistration-container {
  display: flex;
  align-items: center;
}

.users-selection-only h2 {
  margin-bottom: 10px !important;
}

.select-participants-box-in-img {
  min-width: 48px;
}

.select-pt-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  color: #091f48;
  word-break: break-all;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.select-participants-box-in-checkbox input {
  vertical-align: middle;
}

.select-pt-email {
  margin: 0px;
  font-size: 15px;
  line-height: 21px;
}

.select-participants-box-in-img img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0px;
  background: #eaeaea;
  max-width: 48px;
  min-height: 48px;
}

.select-participants-box-in {
  width: 100%;
  border: 1px solid #dadada;
  box-sizing: border-box;
  padding: 10px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.select-participants-box {
  max-height: 586px;
  overflow: auto;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 10px;
  padding-right: 20px;
}

.cstm-channel-type-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 10px;
}

.cstm-channel-type-title-error {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: red;
  margin-bottom: 10px;
}

.cstm-channel-type-title-non-select {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: blue;
  margin-bottom: 10px;
}

.cstm-channel-type-title-registeredUser {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: green;
  margin-bottom: 10px;
}

.cstm-channel-type-title-alreadyexist {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: purple;
  margin-bottom: 10px;
}

.select-participants-box::-webkit-scrollbar {
  width: 6px;
}

.select-participants-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.select-participants-box::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.select-participants-box::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

a.edit-content-link:hover {
  color: var(--blue);
  text-decoration: underline;
}

a.edit-content-link {
  color: #007bff;
  text-decoration: underline;
}

.banner-drag-drop {
  font-size: 19px;
  color: #606060;
}

img.banner-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border: 1px solid #ebebeb;
}

img.partner-sort-logo {
  object-fit: contain;
}

input.form-control.tel-class {
  padding-left: 55px !important;
}

input.form-control.tel-class:focus {
  box-shadow: none;
  border-color: #d2d2d4;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: inherit !important;
}

.cstm-video-upload-img img:not(.channel-img) {
  /* max-height: 154px; */
  aspect-ratio: 16 / 9;
  width: 100%;
  /* max-width: 161px; */
}
.cstm-video-upload-img .empty-img {
  max-height: 154px;
  width: auto;
  aspect-ratio: 16 / 9;
}

.cstm-notification-upload-img img {
  /* max-height: 161px !important; */
  aspect-ratio: 16 / 9;
}

.cstm-up-pp-delete .create-group-loader {
  margin: -1%;
  width: 105%;
  height: 105%;
}

.cstm-up-pp-delete {
  z-index: 9999;
}

.upload-photo-icon {
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
}

.cstm-up-icon {
  cursor: pointer;
  margin-left: 24px;
}

.upload-photo-container img {
  max-height: 100%;
  object-fit: cover;
}

.upload-photo-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cstm-view-photo .modal-content {
  width: 100%;
  text-align: center;
  background: transparent;
  height: 100%;
}

.cstm-view-photo .modal-dialog {
  max-width: 100%;
  margin: 20px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(54.5px);
  backdrop-filter: blur(54.5px);
  border-radius: 20px;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-photo-button .loader-btn {
  position: absolute;
  top: 0;
  background: #ffffffeb;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 48px;
}

.cstm-upload-photo-event-loader img {
  height: 70px;
}

.cstm-upload-photo-event-loader {
  text-align: center;
  margin-top: 250px;
}

.upload-photo-button .loader-btn img {
  width: 42px;
}

.upload-photo-button {
  position: relative;
}

.cstm-upload-photo-event-in img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cstm-upload-photo-event-in {
  cursor: pointer;
  aspect-ratio: 1/1;
}

.cstm-upload-photo-event {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 24px;
}

.group-lce-box {
  overflow: auto;
  max-height: 410px;
}

.group-lce-box::-webkit-scrollbar {
  width: 6px;
}

.group-lce-box::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.group-lce-box::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.group-lce-box::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

.no-data-found {
  display: table-caption;
  font-size: 16px;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -54px;
}

.cstm-data-load {
  display: table-caption;
  font-size: 16px;
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
}

.cstm-data-load img {
  height: 60px;
  display: block;
  margin: auto;
}
.section-loader-box {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-loader-box .loader-img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}

.uic-title-rt-only {
  position: absolute;
  right: 24px;
}

.faq-container-only .questions-accordian-edt-delete {
  right: 0;
}

body .rdw-link-modal {
  height: auto;
}

.cstm-code-addt .rdw-link-modal {
  border: 1px solid #d2d2d4;
  box-shadow: none;
  height: auto;
}

label.rdw-link-modal-target-option input {
  vertical-align: -4px;
}

.topic-label-in {
  background: #eae6ff;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: inherit;
  color: #091f48;
  padding: 7px 10px;
  margin-right: 16px;
  margin-bottom: 16px !important;
  display: inline-block;
}

.topic-label-in b {
  font-weight: 500;
}

.topic-label-in button {
  padding: 0px;
  border: none;
  background: transparent;
  margin-left: 10px;
  font-size: 20px;
  vertical-align: -2px;
}

.video-col-padding {
  display: grid;
  grid-gap: 22px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.hobbies-lbl input[type="radio"] {
  vertical-align: middle;
}

.cyp-btm-btn {
  margin-top: 30px;
  margin-bottom: 50px;
}

.cstm-editor-tool .rdw-editor-toolbar.toolbarClassName {
  box-sizing: border-box;
  width: 97.9%;
}

.cstm-chk-preregistration-container label {
  cursor: pointer;
  margin-bottom: 0px;
}

.img-event-list {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 1px solid #e5e5e5;
}

.cstm-group-ls {
  margin-bottom: 20px;
}

.group-list-create-event label {
  display: flex;
  align-items: center;
  margin: 12px 0px 0px;
  cursor: pointer;
  justify-content: flex-start;
}

.group-list-create-event label input {
  margin-right: 10px !important;
}

.cstm-code-addt .editorClassName.rdw-editor-main {
  padding-top: 82px;
}

.speakers-only input {
  padding: 0px 13px !important;
  height: auto !important;
  border: none !important;
  width: 135px !important;
  display: inline-flex;
}

.speakers-only .searchWrapper {
  padding: 10px 8px 4px 8px;
  border-radius: 0px;
  min-height: 48px;
}

.speakers-only .optionListContainer {
  z-index: 4;
}

.speakers-only .multiSelectContainer ul::-webkit-scrollbar {
  width: 6px;
}

.speakers-only .multiSelectContainer ul::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.speakers-only .multiSelectContainer ul::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.speakers-only .multiSelectContainer ul::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

.speakers-only .highlightOption {
  background: #f3f5f7 !important;
  color: #201e29 !important;
  font-weight: 500 !important;
}

.speakers-only .multiSelectContainer li:hover {
  background: #ebe8fa;
  color: #201e29;
}

.speakers-only .multiSelectContainer li {
  padding: 4px 20px;
  font-size: 12px;
}

.cstm-upload-box {
  padding: 12px;
  border: 1px dashed #b1b1b1;
  position: relative;
}

.cstm-upload-box .error {
  bottom: -20px;
  left: 0;
}

html body.modal-open {
  overflow: hidden;
}

.btn7 {
  background: rgba(147, 145, 151, 0.3);
  border-radius: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #201e29;
  height: 48px;
  padding: 12px 32px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

.btn7:hover {
  background: var(--yellow);
  color: #ffffff;
}

.location-suggestions {
  border: 1px solid #d2d2d4;
  margin-top: -1px;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 4;
}

.location-suggestions-loading img {
  width: 50px;
  padding: 30px 0px;
}

.location-suggestions-in {
  padding: 5px 12px;
  cursor: pointer;
}

.location-suggestions-loading {
  text-align: center;
}

.edit-modal-loader {
  text-align: center;
  position: relative;
  top: 47%;
}

.edit-modal-loader img {
  height: 60px;
  width: 60px;
}

#cropImgDis .cropper-bg {
  background-image: inherit !important;
}

/* cropper ImageLoader */
.img-cropper-container {
  width: 280px;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-cropper-container img{
  width: 48px;
  height: 48px;
}


/* add styles for image cropping */
.checksboxes-visible #cropImgDis .cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC") !important;
  width: 100% !important;
  height: 500px !important;
}

.checksboxes-visible .desk-globle-model-box-inner {
  width: 1024px !important;
  max-width: 100% !important;
}

.checksboxes-visible #cropImgDis.cropImgDis {
  width: 100% !important;
  max-width: 100% !important;
  height: 500px !important;
  max-height: 100% !important;
}

.checksboxes-visible .cstm-replace-pp {
  min-height: 640px;
}

.cstmCropperImg {
  width: 80px;
}

/* spinner */
.spinner-wrapper {
  display: flex;
  flex-direction: column;
}

.spinner-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* end styles for cropping image */
span.error2 {
  color: red;
}

img.delete-cmt-loader {
  height: 20px;
}

.rdw-editor-toolbar.toolbarClassName {
  position: absolute;
  z-index: 2;
  width: 98.6%;
}

.editorClassName.rdw-editor-main {
  padding-top: 45px;
}

label.subcat-addvideo {
  margin: 0px 30px 10px 0px;
  display: flex;
  /* text-transform: capitalize; */
  font-weight: 600;
  cursor: pointer;
  align-items: center;
}

.dis-loader-cmt {
  width: 16px;
  vertical-align: -2px;
}

.newsfeed-write-your-comments-tx .dis-loader-cmt {
  width: 21px;
  vertical-align: -2px;
  filter: invert(1000%) sepia(1000%) saturate(100%) hue-rotate(378deg)
    brightness(1000%) contrast(100%);
}

.cstm-vd {
  resize: vertical;
  overflow: auto;
  border: 1px solid #d2d2d4;
  min-height: 300px;
  max-height: 700px;
  width: 100%;
}

.cstm-vd .public-DraftStyleDefault-ltr {
  padding: 0px 15px;
}

.cstm-vd .notranslate.public-DraftEditor-content > div {
  min-height: 210px;
}

.cstm-lb-only span.loader-btn {
  width: 100% !important;
}

img.cstm-vl-img {
  height: 170px;
  object-fit: cover;
  width: 100%;
}

.um-logo img {
  height: 40px;
}

.header-bk-left img {
  height: 40px;
}

.vjs-poster {
  pointer-events: none;
  background-color: white !important;
}

.cstm-rt-only img.btn-partner2 {
  right: 20px;
  left: inherit;
  cursor: pointer;
}

.cstm-close-icon {
  height: 30px;
  font-size: 20px;
}

.cstm-likes-only {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding-bottom: 12px;
}

.cstm-likes-only-user {
  position: absolute;
}

.cstm-likes-only-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  padding-left: 50px;
  cursor: pointer;
}

.cstm-likes-only-user img {
  border-radius: 30px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #d5d5d5 !important;
}

.loadind-post.cstm-cn-pg-loader {
  height: auto !important;
}

.cstm-vd-box-in-main h4 {
  text-overflow: ellipsis;
  width: 100%;
  /* height: 1.2em; */
  margin-top: 10px;
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
}

span.error.err-url {
  position: relative;
}

.cstm-cn-video-prtn h4 {
  padding: 0px;
  margin-top: 12px;
  font-size: 15px;
  margin-bottom: 6px;
  height: 20px;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

.cstm-cn-video-prtn a {
  font-size: 12px;
  line-height: 16px;
  height: 34px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}

img.btn-partner2 {
  position: absolute;
  left: 10px;
  top: 6px;
  background: #e8e8e8;
  border-radius: 50px;
}

.cstm-cn-video-prtn .cbs-support .cstm-partner-pic {
  height: 150px;
  width: 100%;
  padding: 0px;
  object-fit: cover;
}

.cstm-cn-video-prtn .btn-partner {
  position: absolute;
  right: 0;
  top: -12px;
  background: #fff;
  border: 1px solid #a4a4a4;
  border-radius: 30px;
  font-size: 14px;
  line-height: 22px;
}

.cstm-cn-video-prtn {
  margin-top: 30px;
}

.video-edit-box {
  left: -127px !important;
}

.users-info-content-main.cstm-video-detail-width {
  width: 100%;
  margin: auto;
  max-width: 1280px;
}

.form-group.cstm-edit-profile-img {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.cstm-quest-sec .card-link {
  background: #eaf5ff;
}

.cstm-quest-sec .card-link.collapsed {
  background: #fff;
}

.form-group.cstm-q-a {
  margin-bottom: 16px;
}

.cstm-admin-access-grp .searchWrapper {
  /* padding: 16px 16px 8px; */
  padding: 14px 20px 10px;
  border-radius: 0;
}

.cstm-admin-access-grp .searchWrapper input#search_input {
  padding-left: 0px !important;
  width: inherit !important;
}

.cstm-edit-profile-heading {
  color: #63626a;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.form-group.cstm-edit-profile-img .form-group-ct {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100px;
}

.form-group.cstm-edit-profile-img .form-group-ct img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-group.admin-error-msg {
  margin: 0px;
}

.error-msg {
  margin-bottom: 4px;
  font-size: 14px;
  color: #ed143d;
}

.form-group.form-group-button {
  margin-top: 40px;
}

.users-info-content-main .MuiTableContainer-root {
  overflow-y: hidden;
  min-height: 500px;
  border-bottom: 1px solid #d2d2d4;
  position: relative;
}

.admin-no-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.admin-no-found p {
  color: #939393;
}

.edit-user-details-popup .videodetail.adminVideodetail .video-js {
  aspect-ratio: 16/9;
}

.videodetail.adminVideodetail .video-js .vjs-big-play-button {
  font-size: 40px;
  line-height: 80px;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 50%;
  padding: 0;
  border-radius: 100px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;
  transform: translate(-50%, -50%);
}

.cstm-vd-cnt-right button {
  font-weight: 700;
}

.cstm-vd-cnt-right .like-btn svg path,
.cstm-vd-cnt-right .dislike-btn svg path {
  fill: #fff;
}

.cstm-vd-cnt-markbtn {
  background: #f4f4f4 !important;
  color: rgba(32, 30, 41, 0.7) !important;
}

.cstm-vd-cnt-markbtn:hover {
  background: rgba(32, 30, 41, 0.7) !important;
  color: #ffffff !important;
}

.cstm-vd-cnt-sdf {
  margin-bottom: 16px;
}

.cstm-admin-cate-tag,
.cstm-admin-grup-tag {
  margin-bottom: 16px;
}

.cstm-admin-cate-tag,
.cstm-admin-grup-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.admin-profile-details-all-nav ul.nav.profiledt-menu.cstm-tab-add-video {
  border-bottom: 1px solid #d2d2d4;
  border-top: 1px solid #d2d2d4;
  margin-top: 16px;
}

.cstm-admin-cate-tag .musicvideobtn button,
.cstm-admin-grup-tag .musicvideobtn button {
  margin: 0px;
}

.cstm-admin-cate-tag span,
.cstm-admin-grup-tag span {
  width: 100%;
  margin-bottom: 0px !important;
}

.questions-box-incont input {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  height: 48px;
  padding: 10px 20px;
  width: 100%;
}

.users-info-content-main hr {
  border-color: #d7d7d9 !important;
}

.clif-add-btn {
  margin-top: 20px !important;
}

.searchWrapper span.chip {
  z-index: 1;
  position: relative;
  background: #351bce;
}

.highlightOption {
  background: #351bce;
}

.cstm-bdr-cb .hobbies-lbl {
  padding-top: 16px;
}

.cstm-bdr-cb {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  height: auto;
  padding: 0px 80px 0px 20px;
  width: 100%;
}

.cstm-bdr-cb label.form-check-label {
  margin-bottom: 12px;
}

.cstm-row-pdg {
  padding: 0px 20px;
}

.cstm-add-bdr-box .cstm-edit-delete-main {
  right: 1px !important;
  top: 12px !important;
  z-index: 1;
}

.cstm-add-bdr {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  height: 48px;
  padding: 10px 20px;
  width: 100%;
  display: block;
}

.um-rgt-btn-loading {
  position: relative;
  float: right;
}

/* .um-rgt-btn-loading2 {
     position: relative;
     float: left;
}
 */
.loading-btn-in2 {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 32px;
}

.loading-btn-in2 img {
  width: 36px;
}

.react-datepicker__header select {
  background-color: #fff;
  padding: 0px 5px;
  height: 30px;
  border-radius: 5 px;
  border: 1px solid #c1c1c1;
  font-size: 12px;
  margin: 0px 5px;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  background-image: inherit;
}

.react-datepicker__header button {
  padding: 0px;
  border: none;
  font-size: 18px;
}

/* .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: -190px !important;
} */

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--navyblue) !important;
}

.sidebar-menu .__react_component_tooltip {
  position: absolute !important;
  left: 0px !important;
  width: 103px !important;
  text-align: center;
  top: inherit !important;
  bottom: 5px !important;
  font-size: 12px !important;
  margin: 0px !important;
  background: transparent !important;
  padding: 0px !important;
  line-height: 16px !important;
}

.sidebar-menu .__react_component_tooltip:after,
.sidebar-menu .__react_component_tooltip:before {
  display: none !important;
}

.social-checked:before {
  position: absolute;
  content: "\f058";
  font-family: FontAwesome;
  left: 29px;
  font-size: 26px;
  margin-top: 2px;
}

.mpd-list-box-left {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
}

.mpd-list-box-right {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #201e29;
}

.mpd-list {
  padding-bottom: 20px;
}

.mpd-list-box {
  display: flex;
  max-width: 650px;
  justify-content: space-between;
  margin-bottom: 22px;
}

.cstm-plan-price-only:before {
  content: "$";
  position: absolute;
  font-size: 24px;
  font-weight: 700;
  color: #091f48;
  margin-left: 22px;
  margin-top: 14px;
}

.cstm-plan-price-only input {
  padding-left: 48px !important;
}

.plan-reso-bd-sub {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  margin: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.plan-reso-bd-sub span {
  padding-left: 8px;
}

.plan-reso-bd-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.plan-resources-main-body {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.plan-resources-main {
  margin-bottom: 30px;
}

.select-all-lbl {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #351bce;
  margin: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.select-all-lbl span {
  padding-left: 8px;
}

.uic-table-th .uic-th-td {
  position: relative;
}

span.custom-sorting {
  position: absolute;
  right: 12px;
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}

span.custom-sorting i {
  line-height: 11px;
}

.plan-resources-main-left {
  width: 45%;
}

.plan-resources-main-right {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

.resources-na-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  opacity: 0.7;
}

.plan-resources-main-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d9;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.auto-rene-lbl {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
}

.auto-rene-label input {
  margin-right: 12px !important;
  accent-color: #2a72d1 !important;
}

.grup-main-cover-img-sec {
  position: relative;
  min-height: 250px;
  max-height: 450px;
  border: 1px dashed #351bce;
  overflow: hidden;
}

.ct-lbl-main.cstm-tg-tg .form-group {
  margin-bottom: 10px;
}

.grup-main-cover-img-sec .cover-image-dpdn {
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  align-items: center;
  margin: 0;
  height: 100%;
  transition: 0.3s all;
}

.group-info-detials-cont.cstm-group-info-detials-cont {
  min-height: 250px;
  max-height: 450px;
  overflow: hidden;
}

.group-info-detials-cont.cstm-group-info-detials-cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 52/19;
}

.grup-main-cover-img-sec .cover-image-dpdn:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.grup-main-cover-img-sec .dropzone {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.grup-main-cover-img-sec .cover-image-dpdn.cstm-group-cover-img img {
  width: 100%;
  height: 10%;
  object-fit: cover;
  aspect-ratio: 52/19;
}

.auto-rene-label {
  margin-right: 24px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  float: left;
  cursor: pointer;
}

.automatic-renewal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 10px;
  display: block;
}

.create-topic-info .uic-title-crt-plan h1 {
  margin: 10px 0px 0px;
}

.form-group-ct.cstm-pln-editor-ss {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgb(32 30 41 / 100%);
  min-height: 120px;
  width: 100%;
  padding-top: 14px;
}

.form-group-ct.cstm-pln-editor-ss .rdw-editor-toolbar {
  border: none;
  gap: 12px;
}

.form-group-ct.cstm-pln-editor-ss .rdw-editor-main {
  padding: 0px 16px 16px;
}

.cstm-plan-editor-padding.form-group-ct.cstm-pln-editor-ss .rdw-editor-main {
  padding: 35px 16px 16px !important;
}

.cover-image-dpdn-img {
  margin-top: 20px;
  width: 200px;
}

.or-connet-title span {
  background: #f3f5f7;
  padding: 0px 10px;
  position: relative;
}

.or-connet-title {
  position: relative;
}

.or-connet-title:before {
  height: 1px;
  width: 100%;
  position: absolute;
  background: #cecfd3;
  content: "";
  left: 0;
  top: 14px;
}

.um-right-in .btn {
  margin: 0px;
}

.or-connet-main {
  margin-bottom: 20px;
}

.btn-facebook i {
  font-size: 24px;
  margin-right: 8px;
  vertical-align: bottom;
}

.btn-facebook {
  background-color: #4267b2;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 54px;
  width: 100%;
  border: none;
}

.btn-facebook:hover {
  background: var(--yellow);
}

.btn-linkedin i {
  font-size: 24px;
  margin-right: 8px;
  vertical-align: bottom;
}

.btn-linkedin {
  background-color: #0072b1;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 54px;
  width: 100%;
  border: none;
  text-transform: capitalize;
}

.btn-linkedin:hover {
  background: var(--yellow);
}

.or-connet-title {
  text-align: center;
  margin-bottom: 25px;
}

.or-connet-main {
  display: flex;
}

.hobbies-lbl input[type="checkbox"] {
  vertical-align: -5px;
}

.hobbies-lbl label.form-check-label {
  margin-right: 25px;
  display: flex;
  align-items: center;
}

.hobbies-lbl {
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.cstm-tg-tg .form-group {
  margin-bottom: 10px;
}

.cstm-drag-drop {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 5px;
}

.cstm-jpeg-png {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.cover-image-dpdn {
  background: #ffffff;
  border: 1px dashed #351bce;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  align-items: center;
  margin-top: -14px;
}

.cover-image-dpdn-in-left {
  margin-right: 16px;
}

.cover-image-dpdn-in-left img {
  height: 66px;
}

.cover-image-dpdn-in-right {
  display: flex;
  flex-direction: column;
}

.cover-image-dpdn {
  background: #ffffff;
  border: 1px dashed #351bce;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  align-items: center;
}

.create-yuor-topic-main-box {
  display: flex;
  background: #fff;
}

.adminVideodetail-view {
  aspect-ratio: 16/9;
}

.create-yuor-topic {
  width: 25%;
  padding: 72px 63px;
  height: calc(100vh - 83px);
  position: sticky;
  top: 83px;
}

.icon-box-last {
  margin-top: 12px;
}

.create-yuor-topic:after {
  content: "";
  height: 240px;
  width: 240px;
  position: absolute;
  bottom: 0;
  left: 63px;
  background-image: url(../Images/hashtag.png);
}

.cyfg-mn:after {
  background-image: url(../Images/hashtag2.png);
  bottom: 40px;
}

.cymp-main:after {
  background-image: url(../Images/premium.png);
  bottom: 5px;
}

.create-topic-info h1 {
  margin: 20px 0px;
}

.create-topic-info {
  width: 75%;
  /* height: calc(100vh - 83px);
     */
  float: left;
  padding: 20px;
  border-left: 1px solid #d5d5d5;
}

.posts-filter-dp {
  left: -44px !important;
}

.posts-filter-dp li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  padding: 6px 16px;
  cursor: pointer;
}

.posts-filter-dp li:hover {
  color: #2a72d1;
}

.make-hide-ck-in {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091f48;
}

.make-hide-ck-in input {
  margin-right: 8px !important;
  vertical-align: -4px;
}

.make-hide-ck {
  display: flex;
  align-items: center;
  gap: 24px;
}

.admin-create-post .add-poll-main-box input {
  background: transparent !important;
  padding-right: 85px !important;
  border: none !important;
}

.admin-create-post .add-poll-main-box2 input {
  background: transparent !important;
  padding-right: 44px !important;
  border: none !important;
}

.admin-create-post .create-pp-post-cont-text textarea {
  border: none;
}

.gi-group-posts-edit .what-post-right-input input[type="text"] {
  border-radius: 15px;
  background: rgb(32 30 41 / 6%);
  padding: 10px 20px;
  border: none;
}

.cstm-all-admin h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #091f48;
  margin: 0px;
}

.cstm-all-admin h2 {
  font-weight: 700;
  font-size: 16px;
  color: #091f48;
  margin: 0px;
}

.cstm-all-admin a:hover {
  text-decoration: inherit;
}

.cstm-all-admin button,
.cstm-all-admin select {
  text-transform: none;
  text-decoration: initial !important;
}

.cstm-all-admin button:focus {
  outline: inherit !important;
  box-shadow: inherit !important;
}

b.cstm-all-admin utton:hover {
  outline: inherit;
}

.cstm-all-admin input[type="radio"] {
  width: 20px;
  height: 12px;
  margin: 0px;
  accent-color: #351bce;
  cursor: pointer;
  position: inherit;
  margin-right: 0px;
}

.edit-user-details-popup .modal-dialog .group-info-user.cstm-upload-box {
  height: auto;
}

.cstm-all-admin input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin: 0px;
  accent-color: #351bce;
  cursor: pointer;
  position: inherit;
  margin-right: 5px;
}

.questions-accordian-edt-delete {
  position: absolute;
  top: 0;
  right: 39px;
  height: 48px;
  background: #e8f1fb;
  display: none;
  align-items: center;
  padding: 0px 6px;
}

.questions-accordian-edt-delete a {
  padding: 0px 8px;
}

.questions-accordian .card:hover .questions-accordian-edt-delete {
  display: flex;
}

.rfg-radio-yesno span.um-ck-ac {
  margin-left: 8px;
  cursor: pointer;
}

.rfg-radio-yesno .form-check-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  float: left;
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.questions-accordian {
  margin-bottom: 40px;
}

.questions-accordian .card {
  margin-bottom: 15px;
  box-shadow: none;
  border-radius: 0px;
}

.questions-accordian-header a:before {
  font-family: "FontAwesome";
  font-size: 18px;
  margin-right: 12px;
}

.questions-accordian-header a.collapsed.card-link:before {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 18px;
  margin-right: 12px;
}

.questions-accordian-header a.card-link:before {
  content: "\f106";
}

.rfg-radio-yesno {
  padding: 0px 0px 14px 52px;
  display: flex;
  width: 100%;
}

.questions-accordian-header .card-link {
  display: block;
  font-weight: 700;
  line-height: 24px;
  color: #201e29;
  padding: 12px 20px 12px 58px;
}

.faq-drag-drop {
  position: absolute;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #7d8a9f;
  cursor: pointer;
}

.required-bx {
  border: 1px solid #d2d2d4;
  border-radius: 1px;
  padding: 20px 20px 0px 20px;
  margin-bottom: 18px;
}

.clt-checkbox {
  margin-bottom: 15px !important;
}

.clt-checkbox input.form-check-input {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 10px;
}

.clt-checkbox .form-check-input:focus {
  box-shadow: none;
}

.choose-layout-type-main-box-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 9px;
  align-items: center;
}

.admin-group-post-tab .cp-btn button {
  max-width: 100%;
  width: 100%;
}

.admin-group-post-tab {
  max-width: 840px;
  width: 100%;
}

.admin-group-post-tab .cp-btn {
  width: 20%;
}

.admin-group-post-tab .cp-btn.cstm-cp-dp {
  width: 12%;
}

.admin-group-post-tab .gi-group-posts-post {
  border: none;
}

.admin-group-post-tab .gi-group-posts-post .newsfeed-post {
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
  border-radius: 0px;
}

.admin-group-post-tab .gi-group-posts-post .newsfeed-post .newsfeed-post-right {
  padding-left: 0px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-header {
  padding-right: 110px;
  padding-top: 23px;
  margin-bottom: 16px;
  padding-left: 90px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-header-icon {
  right: 24px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-text {
  padding: 0px 16px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-topik {
  padding: 0px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.admin-edit-img-cstm-btn .edit-img-cstm-btn-left {
  gap: 10px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-cmt {
  padding: 0px 16px 16px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .comment-main-box-right-icon {
  margin-bottom: 0px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .comment-main-box:last-child {
  border-bottom: 0px;
}

.cstm-newsfeed-post .comment-main-box:last-child {
  border-bottom: 0px;
}

.admin-group-post-tab
  .gi-group-posts-post
  .newsfeed-post
  .newsfeed-post-right-write {
  position: relative;
  border-top: 1px solid #d7d7d7;
  padding: 16px 16px 0px;
  margin-top: 0;
}

span.clm-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.choose-layout-type-main {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
}

.choose-layout-type-main-box {
  background: #d0e3f6;
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
}

.choose-layout-type-main-box-img img {
  width: 100%;
}

.cstm-all-admin .uic-sort-by-title {
  font-size: 14px;
  font-weight: 600;
}

.choose-layout-type-main-box-header input.form-check-input {
  height: 20px;
}

.green {
  color: #25a931;
}

.uic-select-sort-by {
  width: 140px !important;
  margin-left: 15px;
}

.uic-sort-by {
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
  display: inline-block;
  vertical-align: top;
}

.cstm-all-admin .btn.btn-red {
  background: #ff4852 !important;
  border: 1px solid #ff4852 !important;
}

span.msg-content {
  color: #848fa4;
  margin-bottom: 30px;
}

.msg-popup h1 {
  margin-bottom: 25px;
  line-height: 32px;
}

.msg-icon {
  margin-bottom: 25px;
}

.msg-popup .close {
  right: 20px;
  top: 20px;
  position: absolute;
  color: #aeaeae;
  background-color: transparent;
  border: 0;
  font: var(--title-large);
  font-weight: 400;
  line-height: 20px;
}

.msg-popup .modal-content {
  text-align: center;
  padding: 40px;
}

.modal-content {
  border-radius: 0;
  border: 1px solid rgba(32, 30, 41, 0.1);
}

/* --- */
.modal-content.cropper-content {
  height: calc(100vh - 110px);
}

.header-bk {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d5d5d5;
  justify-content: space-between;
  padding: 20px 15px;
  align-items: center;
  position: fixed;
  z-index: 10;
  background: #fff;
}

.header-bk .dropdown-menu a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.edit-img-cstm-btn-left {
  display: flex;
  gap: 10px;
}

.user-icon {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 7px;
}

.header-bk-right-user {
  background: #f4f4f4;
  border-radius: 30px;
  padding: 5px;
}

.header-bk-right-user .dropdown-toggle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.header-bk-right-user .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  margin-right: 8px;
  vertical-align: 1px;
  content: "";
  border-top: 6px solid;
  border-right: 5px solid transparent;
  font-weight: 100;
  border-left: 5px solid transparent;
}

.header-bk-right-user .dropdown-toggle:hover {
  color: #351bce;
}

.header-bk-right-user .dropdown-menu {
  margin-top: 15px;
  padding: 10px;
  min-width: 145px;
}

.sidebar-menu {
  background: #f3f5f7;
  width: 110px;
  position: fixed;
  border-right: 1px solid #d5d5d5;
  overflow: auto;
  height: calc(100vh - 83px);
  margin-top: 83px;
  transition: all 0.5s ease;
}

.sidebar-menu ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-menu ul li {
  display: flex;
  width: 100%;
  position: relative;
}

.sidebar-menu ul li a {
  padding: 16px 0px;
  width: 100%;
  text-align: center;
}

.sidebar-menu ul li a .admin-left-icons {
  fill: #091f48;
  opacity: 0.5;
  transition: 0.3s all;
}

.sidebar-menu ul li a span.admin-left-title {
  margin-top: 4px;
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600 !important;
  color: #7b899e;
  transition: 0.3s all;
  padding: 0px 8px;
}

.sidebar-menu ul li a:hover .admin-left-icons {
  opacity: 1;
  fill: #351bce;
}

.sidebar-menu ul li a:hover span.admin-left-title {
  opacity: 1;
  color: #351bce;
}

.sidebar-menu ul li a.menu-active {
  background: #351bce;
}

.sidebar-menu ul li a.menu-active .admin-left-icons {
  fill: #fff;
  opacity: 1;
}

.sidebar-menu ul li a.menu-active span.admin-left-title {
  color: #fff;
  opacity: 1;
}

.menu-active .partners-dot-hl {
  background: #fff;
}

.partners-dot-hl {
  background: #351bce;
  height: 6px;
  width: 6px;
  display: inline-block;
  margin: 0px 0px 6px 1px;
  border-radius: 50px;
  position: relative;
}

.menu-active .pending-req-dot-hl {
  background: red;
}

.pending-req-dot-hl {
  background: red;
  height: 12px;
  width: 12px;
  display: inline-block;
  margin: 0px 0px 6px 1px;
  border-radius: 50px;
  position: relative;
}

.request-count {
  background: red;
  margin-left: 5px;
  color: #fff;
  font-size: 10px;
  border-radius: 15px;
  font-weight: 700;
  min-width: 21px;
  height: 21px;
  display: inline-block;
  line-height: 18px;
  font-family: "Inter", sans-serif;
  vertical-align: 2px;
  text-align: center;
  border: 1px solid white;
}

.content-main {
  margin-left: 110px;
  padding-top: 83px;
}

.users-info {
  display: block;
  min-height: calc(100vh - 83px);
}

.users-info-content {
  padding-left: 300px;
  transition: all 0.5s ease;
}

.users-info-sidebar {
  width: 300px;
  background: #f6f6f6;
  position: fixed;
  overflow: auto;
  height: calc(100vh - 83px);
  border-right: 1px solid #e6e6e6;
  transition: all 0.5s ease;
}

.users-info-sidebar .card-header {
  padding: 0px;
  margin-bottom: 0 !important;
}

.users-info-sidebar .card {
  border-width: 0px;
  box-shadow: none;
}

.users-info-sidebar .card-header button {
  width: 100%;
  border: none;
  padding: 10px 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #848fa4;
  text-decoration: inherit;
}

.users-info-sidebar .card-header a {
  width: 100%;
  border: none;
  padding: 10px 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #848fa4;
  text-decoration: inherit;
  display: block;
}

.users-info-sidebar .card-header a:hover {
  background: #ebe8fa;
  text-decoration: inherit;
}

.users-info-sidebar .card-header button i {
  float: right;
  font-size: 23px;
}

.users-info-sidebar .card-header button:hover {
  background: #ebe8fa;
  text-decoration: inherit;
}

.cstm-ac-in-menu ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-ac-in-menu ul li {
  border-bottom: 1px solid #d5d5d5;
}

.cstm-ac-in-menu ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #848fa4;
  padding: 14px 36px;
  width: 100%;
  display: inline-block;
}

.cstm-ac-in-menu ul li a:hover {
  background: #e7f1fa;
  text-decoration: inherit;
  color: #091f48;
}

.users-info-sidebar .card {
  border-radius: 0px;
}

.sd-active2 {
  color: #091f48 !important;
  font-weight: 600 !important;
  background: #fff !important;
}

.sd-active {
  color: #fff !important;
  background: #351bce !important;
}

.users-info-content-main {
  padding: 24px;
  background-color: #fff;
}

.uic-title {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.cstm-all-admin input:focus,
.cstm-all-admin select:focus,
.cstm-all-admin textarea:focus {
  outline: none;
}

.cstm-all-admin input[type="tel"],
.cstm-all-admin input[type="text"],
.cstm-all-admin input[type="email"],
.cstm-all-admin input[type="password"],
.cstm-all-admin input[type="number"],
.cstm-all-admin input[type="date"],
.cstm-all-admin input[type="datetime-local"],
.cstm-all-admin input[type="file"],
.cstm-all-admin input[type="time"],
.cstm-all-admin input[type="url"] {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgb(32 30 41 / 70%);
  height: 48px;
  padding: 10px 20px;
  width: 100%;
}

.cstm-all-admin input[type="text"]::placeholder,
.cstm-all-admin input[type="email"]::placeholder,
.cstm-all-admin input[type="password"]::placeholder,
.cstm-all-admin input[type="number"]::placeholder,
.cstm-all-admin input[type="date"]::placeholder,
.cstm-all-admin input[type="datetime-local"]::placeholder,
.cstm-all-admin input[type="file"]::placeholder,
.cstm-all-admin input[type="time"]::placeholder,
.cstm-all-admin textarea::placeholder {
  font-weight: 400;
  color: #606060;
}

.cstm-all-admin select {
  border-radius: 1px;
  background: transparent;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(32, 30, 41, 1);
  height: 48px;
  padding: 10px 38px 10px 20px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../Images/BackendImages/arrow-down.png);
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}
.cstm-all-admin select:disabled {
  background-color: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}

.select-min-field {
  min-width: 180px;
}

.cstm-all-admin textarea {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  vertical-align: middle;
}

.cstm-all-admin textarea:focus {
  font-weight: 700;
}

.cstm-all-admin .btn {
  background: #351bce;
  border: 1px solid #351bce;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 48px;
  padding: 0px 40px;
  margin: 0px;
  box-shadow: none;
  width: auto;
}

.cstm-all-admin button.btn.admin-add-option-btn {
  width: 100%;
  background: #fff;
  border: 1px dashed #351bce;
  color: #351bce !important;
}

.cstm-all-admin button.btn.admin-add-option-btn:hover {
  background: #eaf5ff;
}

.cstm-all-admin .btn:hover {
  background: #201e29;
  border: 1px solid #201e29;
}

button.admin-add-op {
  position: absolute;
  top: 12px;
  right: 1px;
  height: 46px;
  width: 76px;
  border: navajowhite;
  background: #eaf5ff;
  color: #351bce;
  font-size: 14px;
  font-weight: 700;
}

button.admin-add-op:hover {
  background: #d7ecff;
}

.admin-added-select-options-inner {
  margin-bottom: 16px;
}

.cstm-all-admin .btn2 {
  background: #fff;
  border: 1px solid #091f48;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #091f48 !important;
  height: 48px;
  padding: 0px 40px;
  cursor: pointer;
}

.cstm-all-admin .btn2:hover {
  background: #201e29;
  border: 1px solid #201e29;
  color: #fff !important;
}

.cstm-all-admin .clt-checkbox .form-check-label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  cursor: pointer;
}

.uic-fl-search {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}

.uic-fl-search i {
  position: absolute;
  right: 18px;
  top: 15px;
  color: #091f48;
  cursor: pointer;
  font-size: 18px;
}
.search-input {
  max-width: 300px;
}
.search-input input {
  border-radius: 1px;
  background: #fff;
  border: 1px solid #d2d2d4;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgb(32 30 41 / 70%);
  padding: 10px 20px;
  padding-right: 38px !important;
}

.search-input i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.uic-fl-btn {
  display: inline-block;
  vertical-align: top;
}

.search-user-input {
  width: 305px !important;
  padding-right: 45px !important;
}

.uic-table {
  min-width: 1200px;
}

.uic-table-th {
  display: flex;
  justify-content: space-between;
}

.uic-table-td {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d2d2d4;
  font-size: 14px;
}

.uic-table-th {
  display: flex;
  background: #f3f5f7;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: rgba(9, 31, 72, 1);
}

.uic-table-th .uic-th-td {
  padding: 12px;
  padding-right: 30px;
}

.uic-table-td .uic-th-td {
  color: #201e29;
  padding: 12px;
  /* word-break: break-all; */
  word-break: break-word;
  font-size: 14px;
}
/* empty cell spacing */
.uic-table-td .uic-th-td:empty {
  height: 45px;
}
.css-rqglhn-MuiTable-root tr.uic-th-td {
  border-right: none;
}

.uic-table-main {
  margin-bottom: 20px;
  border: 1px solid #d2d2d4;
  box-sizing: border-box;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* overflow: inherit !important;
     */
  border-bottom: none;
}

.uic-table-icon a {
  margin-right: 10px !important;
}

.uic-th-td {
  border-right: 1px solid #d2d2d4;
}

.uic-th-td:last-child {
  border-right: none;
}

.uic-tb-mn {
  box-shadow: inherit !important;
}

.uic-table-th th {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.uic-table thead th {
  background: #f3f5f7;
}

.min-w-auto {
  min-width: auto;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-19 {
  width: 19%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-22 {
  width: 22%;
}

.w-23 {
  width: 23%;
}

.w-24 {
  width: 24%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-27 {
  width: 27%;
}

.w-28 {
  width: 28%;
}

.w-29 {
  width: 29%;
}

.w-30 {
  width: 30%;
}

.w-31 {
  width: 31%;
}

.w-32 {
  width: 32%;
}

.w-32 {
  width: 32%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-35 {
  width: 35%;
}

.w-36 {
  width: 36%;
}

.w-38 {
  width: 38%;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.w-41 {
  width: 41%;
}

.w-42 {
  width: 42%;
}

.w-43 {
  width: 43%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-47 {
  width: 47%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.edit-user-details-popup {
  padding-right: 0px !important;
}

.edit-user-details-popup .modal-dialog {
  height: 100vh;
  background: #fff;
  margin: 0px;
  max-width: 700px;
  margin-left: auto;
}

.edit-user-details-popup .modal-content {
  border: 0px;
}

.edit-user-details-popup .modal-body {
  flex-grow: 1;
  padding: 24px 24px 0px 24px;
  background: #fff;
  overflow: auto;
  height: calc(100vh - 134px);
  min-height: 500px;
}

.edit-user-details-header {
  border-bottom: 1px solid #d2d2d4;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.edit-main-user-details-popup .edit-user-details-header {
  justify-content: end;
}

.close {
  color: #aeaeae;
  opacity: 1;
  background-color: transparent;
  border: 0;
  font: var(--title-large);
  font-weight: 400;
  line-height: 20px;
  opacity: 1;
  border: none;
  padding: 0px;
  background: transparent;
}

.close:hover {
  color: #351bce !important;
  opacity: 1 !important;
}

.edit-user-details-footer {
  background: #fff;
  padding-bottom: 16px;
  padding-top: 16px;
}

.edit-user-details-popup.edit-main-user-details-popup
  .edit-user-details-footer
  button {
  margin-left: 24px;
}

.form-group-label {
  color: #091f48;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-left: 20px;
  position: relative;
  margin-bottom: -9px;
  background: #fff;
  display: table-caption;
  padding: 0px 8px;
  width: max-content;
  z-index: 3;
}

.cstm-all-add {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 165px);
  max-width: 450px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cstm-all-add-icon {
  margin-bottom: 24px;
}

.cstm-all-add h1 {
  margin-bottom: 16px;
}

.cstm-all-add p {
  margin-bottom: 32px;
  color: #848fa4;
}

.um-fm-tl i {
  color: #979797;
}

.cstm-edit-delete {
  position: relative;
}

.cstm-edit-delete-main {
  position: absolute;
  top: 1px;
  right: 1px;
  height: 46px;
  background: #e8f1fb;
  display: none;
  align-items: center;
  padding: 0px 0px;
}

.cstm-edit-delete-main.cstm-edit-delete-label {
  position: absolute;
  top: -9px;
  right: -6px;
  width: 46px;
  height: 46px;
  background: #e8f1fb;
  display: none;
  align-items: center;
  padding: 0px 0px;
}

.cstm-edit-delete:hover .cstm-edit-delete-main {
  display: flex;
}

.cstm-edit-delete-main a {
  margin: 0px 7px;
  width: 100%;
  text-align: center;
}

.cstm-edit-delete input {
  padding-right: 100px;
}

.cstm-aad-field {
  margin-top: 20px;
}

.cstm-aad-field a {
  background: #ffffff;
  border: 1px dashed #351bce;
  border-radius: 1px;
  width: 100%;
  display: block;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #351bce;
  padding: 15px;
}

.cstm-aad-field a:hover {
  color: #201e29;
  border: 1px dashed #201e29;
}

.cstm-update-form-btn {
  text-align: right;
  margin-top: 50px;
}

.cstm-all-admin {
  background: #fff;
}

.MuiTablePagination-actions button {
  border-radius: 0px;
}

p.MuiTablePagination-displayedRows.css-1chpzqh {
  margin: 0px;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin: 0px;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  border: 1px solid #e1e1e1;
  border-radius: 0px;
  margin-left: 10px;
}

.form-group-ck-mn {
  border: 1px solid #d2d2d4;
  padding: 20px 20px 2px 20px;
  display: flex;
  flex-wrap: wrap;
}

.form-group-ck {
  width: 33%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  cursor: pointer;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.form-group-ck input {
  margin-right: 8px !important;
}

.questions-box-incont {
  padding: 10px 20px 15px;
  display: flex;
}

.questions-box-incont .form-check-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  float: left;
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.qsn-lbl {
  /* margin-left: 8px; */
  cursor: pointer;
}

.cover-image-upload-ttl img {
  margin-right: 8px;
  width: 24px;
}

.cover-image-upload {
  margin-top: -15px;
  margin-bottom: 35px;
}

.cover-image-upload-size {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.cover-image-upload-ttl {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.upload-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.upload-image-wrapper .cover-image-upload-ttl {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.upload-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.upload-image-wrapper .cover-image-upload-ttl {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.upload-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.upload-image-wrapper .cover-image-upload-ttl {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.cover-image-upload {
  margin-top: -15px;
}

.cover-image-upload-img {
  margin-bottom: 20px;
}

.group-listing-menu {
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 24px;
}

.nav.group-listing-menu li {
  position: relative;
}

.nav.group-listing-menu li a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091f48;
  padding: 6px 15px;
  display: inline-block;
}

.nav.group-listing-menu li a.active {
  color: #351bce;
}

.nav.group-listing-menu li a.active:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #351bce;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.gl-title-sub {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 24px;
}

.group-mem-pos-in {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 1px;
  padding: 16px;
}

.group-mem-pos-in-img {
  margin-bottom: 16px;
}

.group-mem-pos-in-cont {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  color: #091f48;
  margin-bottom: 8px;
}

.group-mem-pos-in-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
}

hr.gi-line {
  margin: 32px 0px;
  border-color: #d5d5d5;
}

.group-update-user-photo {
  height: 64px;
  width: 64px;
  object-fit: cover;
  border-radius: 50px;
}

.group-update-user-other {
  border: 1px solid #fff;
  position: absolute;
  bottom: 0px;
  right: 0;
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50px;
  box-shadow: 0px 0px 3px #00000096;
}

.group-update-main-in {
  min-height: 64px;
  margin-bottom: 16px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-update-main-in-img {
  position: absolute;
}

.group-update-main-in-cont {
  padding-left: 80px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
}

.group-update-dtl {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 4px;
}

.group-update-main-in-new {
  background: #ffcc00;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  padding: 3px 8px;
}

.group-update-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #351bce;
}

.gi-group-posts-edit {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 1px;
  margin-bottom: 24px;
}

.gi-group-posts-edit .what-is-happening-main {
  margin-bottom: 10px;
}

.gi-group-posts-post {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 1px;
  margin-bottom: 24px;
}

.gi-btn-save {
  margin-top: 20px;
}

.gi-btn-save button {
  vertical-align: middle;
}

.group-info-user {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  height: 100%;
}

.group-info-user-img.cstm-group-info-user-img img {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  object-fit: cover;
}

.group-info-user-img img {
  height: 63px;
  width: 63px;
  object-fit: cover;
}

.group-info-user-img.cstm-group-img img {
  width: 50px;
  height: 50px;
}

.group-info-user-img {
  margin-right: 12px;
}

.group-info-user-link button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline !important;
  color: #091f48;
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
}

.group-info-user-link button:hover {
  color: #351bce;
}

.group-info-user-link {
  margin-right: 24px;
}

.group-info-detials {
  margin-bottom: 28px;
}

.group-info-detials-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
}

.group-info-detials-cont {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
}

.group-info-detials-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 6px;
}

.uic-fl-cstm {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(32, 30, 41, 0.2);
  margin-bottom: 20px;
}

.cstm-video-drop.show {
  left: -127px !important;
}

.cstm-video-drop a span:hover {
  color: var(--yellow);
}

.icon-box.icon-box2 {
  margin-top: 20px;
}

.icon-1 {
  padding-right: 19px;
}

.video-edit-box {
  position: absolute;
  background-color: #fff;
  padding: 10px;
  width: 153px;
  border: 1px solid rgba(32, 30, 41, 0.3);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  top: 0px;
  left: 0px;
  transform: translate3d(130px, 61px, 0px);
}

.video-edit-box {
  font-size: 12px;
}

.video-edit-box img {
  padding-right: 5px;
}

.videodottedimg {
  position: absolute;
  right: 30px;
  top: 18px;
  background-color: #fff;
  padding: 3px 4px;
}

.admin-content-video {
  position: relative;
}

.admin-content-video .cstm-videoPlayericons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all;
}

.admin-content-video:hover {
  cursor: pointer;
}

.admin-content-video:hover .cstm-videoPlayericons {
  opacity: 1;
  visibility: visible;
}

.gallery-main {
  margin-bottom: 28px;
}

.videotime p {
  position: absolute;
  background: #000;
  color: #fff;
  right: 34px;
  top: 152px;
  padding: 0 6px;
  font-size: 10px;
}

.uic-fl-link {
  align-self: center;
}

.edit-link,
.restorevideolink {
  padding-right: 25px;
  text-decoration: underline;
  color: #091f48 !important;
  font-size: 16px;
  font-weight: 700;
}

.delete-link,
.deletevideolink {
  text-decoration: underline;
  color: #ff4852 !important;
  font-size: 16px;
  font-weight: 700;
}

.edit-link:hover,
.restorevideolink:hover {
  color: #000 !important;
  text-decoration: underline !important;
}

.delete-link:hover,
.deletevideolink:hover {
  color: #ff4852;
  text-decoration: underline !important;
}

.deletevideolink {
  margin-right: 20px;
}

.uic-title h3 {
  color: #091f48;
  font-size: 16px;
  cursor: pointer;
}

.videodetail img {
  width: 100%;
}

.videodetail {
  margin-bottom: 24px;
}

.video-content h4 {
  color: #091f48;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 12px;
}

.video-content p {
  color: rgba(32, 30, 41, 0.7);
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 28px;
}

.musicvideobtn button,
.mixedvideobtn button {
  padding: 3px 16px;
  font-size: 14px;
  background-color: #f4f3f4;
  border: 1px solid #dedede;
  border-radius: 40px;
  color: #091f48;
  font-weight: 600;
  margin-right: 8px;
}

.like-btn button {
  background-color: #351bce;
  border: none;
  border-radius: 10px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 8px;
}

.like-btn button:hover {
  background: #201e29;
}

.like-btn button:before {
  background-image: url("../Images/white-like.png");
  content: "";
  position: relative;
  width: 18px;
  height: 16px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: -3px;
  margin-right: 5px;
}

.mark-btn button {
  background-color: #f4f4f4;
  border: none;
  border-radius: 10px;
  width: 100%;
  color: #201e29;
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 8px;
  font-weight: 700;
}

.mark-btn button:hover {
  background: #201e29;
  color: #fff;
}

.mark-btn button:before {
  background-image: url("../Images/mark-view-btn.png");
  content: "";
  position: relative;
  width: 18px;
  height: 15px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: -3px;
  margin-right: 5px;
}

.mark-btn button:hover:before {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.icon-align {
  padding-right: 6px;
}

.border-bottom {
  border-bottom: 1px solid #201e29;
  margin: 16px 0;
}

.patnerdetails h3 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #091f48;
}

.cbs-support {
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 8px;
  height: 100%;
  position: relative;
}

.cbs-support img {
  padding: 7px;
}

.cbs-support h4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #091f48;
  margin-bottom: 0;
  padding-left: 7px;
}

.read-more {
  color: #091f48;
  font-weight: 700;
}

.plan-reso-bd-custm {
  margin-bottom: 24px;
}

.form-group-label-cstm {
  color: rgba(32, 30, 41, 1);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-left: 56px;
  position: relative;
  margin-bottom: -8px;
  background: #fff;
  display: table-caption;
  padding: 0px 18px;
  width: max-content;
  z-index: 1;
  top: 30px;
}

.delete-video-content {
  color: #351bce;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.from-group-cstm {
  margin-bottom: 35px !important;
}

.page-not-found {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTablePagination-selectLabel {
  margin-bottom: 0;
}

.card.dragging {
  cursor: move;
}

.card-link.dropArea {
  color: white !important;
  background-color: white !important;
  position: relative;
}

.movableicon {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 48px;
  background: #e8f1fb;
  display: block;
  align-items: center;
  padding: 8px 10px;
}

.movableicon i {
  font-size: 19px;
  line-height: 32px;
}

.question-footer-aa {
  padding: 16px 24px;
}

hr {
  opacity: 1;
}

.modal-content.modal-content-cstm,
.modal-content.modal-content-cstm3 {
  padding: 30px;
}

button.btn.cstm-updatebutton,
button.btn.cstm-updatebutton3 {
  margin-left: 30px;
  margin-right: 10px;
}

.loader-button1 button {
  background: #351bce;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  height: 48px;
  padding: 12px 32px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

span.loader-btn img {
  width: 30px;
}

.loader-button1 {
  position: relative;
  display: inline;
}

.loader-button1 span.loader-btn {
  position: absolute;
  left: 0;
  z-index: 2;
  background: #ffffffd6;
  width: 163px;
  text-align: center;
  line-height: 50px;
}

.video-detail-main {
  display: flex;
  justify-content: space-between;
}

.cstm-comment-share-btn .videodetailicon {
  margin-left: 9px;
}

label.plan-reso-bd-title span {
  margin-left: 6px;
}

.dropdown-menu.cstm-droupdown.show a {
  clear: both;
}

.dropdown-menu.cstm-droupdown.show img {
  float: right;
  margin-top: 4px;
}

.btn-partner {
  background: transparent;
  border: none;
  cursor: pointer;
}

.cstm-video-mn-in .video-js {
  width: 100%;
}

.cstm-video-mn-in button.vjs-big-play-button {
  display: none;
}

.cstm-video-upload {
  margin-right: 20px;
}

.channel-img-container {
  /* width: 161px !important; */
}

.cstm-video-upload-img {
  width: 270px;
  height: auto;
  aspect-ratio: 16 / 9;
  background: #ffffff;
  border: 1px dashed #351bce;
  border-radius: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cstm-video-upload-img.square {
  aspect-ratio: 1/1;
}
.aspect-ratio-unset {
  aspect-ratio: unset !important;
}
.cstm-video-upload-video {
  width: 250px;
}

.cstm-video-upload-video video {
  width: 100%;
}

.cstm-add-video-title-sub {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 10px;
}

.fvideo-description-edit-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #091f48;
  margin-bottom: 12px;
}

.video-description-edit-box {
  border: 1px solid #dbdbdb;
  border-radius: 1px;
}

.cstm-add-video-title-sub2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  margin-bottom: 15px;
}

.cstm-av-line {
  border-color: #d2d2d4 !important;
}

.cstm-av-line {
  border-color: #d2d2d4 !important;
  margin-bottom: 30px;
}

.cstm-add-video-tabs {
  margin-bottom: 30px;
  border-bottom: 1px solid lightgrey;
  display: flex;
}

.cstm-add-video-tabs ul li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #848fa4;
  padding: 0px 25px 10px;
  display: inline-block;
  position: relative;
}

.cstm-add-video-tabs ul li a:hover {
  color: #351bce;
}

.cstm-add-video-tabs ul li a.active {
  color: #351bce;
}

.cstm-add-video-tabs ul li a.active:before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #351bce;
}

.cstm-relevant-partners-upload {
  margin-bottom: 20px;
  padding-top: 3px;
  display: flex;
  align-items: center;
}

.cstm-relevant-partners-upload-in {
  background: #ffffff;
  border: 1px dashed #351bce;
  border-radius: 1px;
  margin-right: 18px;
  padding: 15px 17px;
}

.cstm-relevant-partners-upload-in img {
  width: 63px;
}

.cstm-partner-main {
  padding-right: 130px;
  position: relative;
}

.cstm-partner-btn {
  position: absolute;
  right: 0;
  top: 10px;
}

.video-description-edit-box .editorClassName.rdw-editor-main {
  padding: 0px 10px;
  height: 20vh;
}

.add-btn-align {
  align-self: center;
  margin-top: 10px;
}

.cstm-clif-notes {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 1px solid #2297ff;
  padding: 10px 16px;
  margin-top: 10px;
  background: #e7f1fa;
}

.cstm-clif-notes-inner {
  flex-grow: 1;
}

.cstm-clif-notes-inner p {
  margin-bottom: 0;
  color: #091f48;
}

.cstm-group-info-user {
  margin-top: 1rem;
}

.cstm-col-cbs-support {
  margin-bottom: 18px;
}

.btn-partner-main {
  display: inline-flex;
  width: 100%;
  justify-content: end;
  position: absolute;
}

.group-info-user.cstm-group-info {
  margin: 0;
}

.cstm-add-field {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cstm-add-field .error-msg {
  margin-bottom: 0;
  font-size: 14px;
  padding: 4px 15px;
  color: #ed143d;
}

.add-cstm-field-main-sec .cstm-add-field {
  flex-grow: 1;
  overflow: hidden;
}

.cstm-edit-delete-field {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 134px);
  min-height: 500px;
  padding: 16px 0px;
  overflow-x: hidden;
}

.add-cstm-field-main-sec .cstm-edit-delete-field {
  height: 100%;
}

.add-cstm-field-main-sec .edit-di-gbns {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  padding: 0px 16px !important;
}

.add-cstm-field-main-sec .cstm-right-panel {
  overflow: auto;
  height: 100%;
  padding-top: 20px;
  border-top: 1px solid #d2d2d4;
}

.add-cstm-field-main-sec .cstm-right-panel::-webkit-scrollbar {
  width: 0px;
}

.add-cstm-field-main-sec .cstm-right-panel::-webkit-scrollbar-track {
  border-radius: 0px;
  background: #f3f5f7;
}

.add-cstm-field-main-sec .cstm-right-panel::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 0px;
}

.add-cstm-field-main-sec .cstm-right-panel::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

.cstm-model-content {
  height: 100%;
}

.edit-user-details-footer button.btn,
.cstm-update-cancle-btn button.btn {
  margin-right: 10px;
}

.edit-user-details-footer.cstm-add-button {
  /* margin-left: 24px; */
  padding-left: 24px;
}

.modal-content.edit-user-model-content {
  box-shadow: inherit;
  height: 100%;
}

.cstm-update-cancle-btn {
  padding: 20px 0 18px 20px;
  background: #fff;
  box-shadow: 1px -4px 5px #ededed;
}

.cstm-right-panel {
  overflow: auto;
  height: calc(100vh - 170px);
}

button.btn.cncle-btn {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #091f48 !important;
  transition: 0.3s all;
}

.form-group-ct.cstm-edit-delete-added-info {
  position: absolute;
  top: 11px;
  width: auto;
  border: none;
  left: 16px;
  pointer-events: none;
}

.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="text"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="email"],
.cstm-all-admin
  .form-group-ct.cstm-edit-delete-added-info
  input[type="password"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="number"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="date"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="file"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="time"],
.cstm-all-admin .form-group-ct.cstm-edit-delete-added-info input[type="url"] {
  border-radius: 0px;
  background: transparent;
  border: none;
  font-style: normal;
}

button.btn.cncle-btn:hover {
  color: #fff !important;
  background: rgba(32, 30, 41, 0.3);
  border: 1px solid rgba(32, 30, 41, 0.3);
}

.form-group-label.cstm-label-here {
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;
}

.admin-event-details-heading-text {
  min-height: 40px;
}

.group-listing-main {
  margin-top: 20px;
}

.plan-resources {
  height: 36vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid #c1c1c1;
}

.plan-resources::-webkit-scrollbar,
.video-description-edit-box .editorClassName.rdw-editor-main::-webkit-scrollbar,
.sidebar-menu::-webkit-scrollbar,
.cstm-right-panel::-webkit-scrollbar,
.uic-table-main::-webkit-scrollbar,
.edit-user-details-popup .modal-body::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.plan-resources::-webkit-scrollbar-track,
.video-description-edit-box
  .editorClassName.rdw-editor-main::-webkit-scrollbar-track,
.sidebar-menu::-webkit-scrollbar-track,
.cstm-right-panel::-webkit-scrollbar-track,
.uic-table-main::-webkit-scrollbar-track,
.edit-user-details-popup .modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}

.plan-resources::-webkit-scrollbar-thumb,
.video-description-edit-box
  .editorClassName.rdw-editor-main::-webkit-scrollbar-thumb,
.sidebar-menu::-webkit-scrollbar-thumb,
.cstm-right-panel::-webkit-scrollbar-thumb,
.uic-table-main::-webkit-scrollbar-thumb,
.edit-user-details-popup .modal-body::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}

.plan-resources::-webkit-scrollbar-thumb:hover,
.video-description-edit-box
  .editorClassName.rdw-editor-main::-webkit-scrollbar-thumb:hover,
.sidebar-menu::-webkit-scrollbar-thumb:hover,
.cstm-right-panel::-webkit-scrollbar-thumb:hover,
.uic-table-main::-webkit-scrollbar-thumb:hover,
.edit-user-details-popup .modal-body::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}

/* .public-DraftStyleDefault-block {
     margin: 0 !important;
} */
.plan-resources-checkbox {
  display: flex;
  justify-content: end;
  gap: 26px;
}

.cstm-plan-reso-bd-title {
  padding-right: 20px;
}

.plan-resourses-header h1 {
  margin-bottom: 18px;
  margin-top: 20px;
}

.error.cstm-error44 {
  margin-top: -27px;
}

span.error.cstm-error1 {
  margin: 0px 0;
}

span.error.cstm-error2 {
  margin: -78px 0;
}

span.error.cstm-error3 {
  margin: -14px 0;
}

.plan-resources-main-head.cstm-plan-resourse {
  margin-bottom: 0;
}

.cyp-btm-btn.cstm-edit-plan {
  margin-top: 0;
}

.modal-dialog .modal-content {
  box-shadow: none;
}

.form-group-ct.cstm-value-input {
  padding: 0 20px;
}

.group-info-user.ctsm-fram-img img {
  width: 50px;
  height: 50px;
}

.cstm-group-info-user-img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  overflow: hidden;
  border: 1px dashed #351bce;
  padding: 4px;
}

.group-info-user.ctsm-fram-img .cstm-group-info-user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-group-ct.cstm-edit-delete {
  padding-bottom: 0;
}

.what-post-right-input.cstm-post-input input {
  width: 100% !important;
  border: 0 !important;
  cursor: pointer;
}

.form-group-ctst {
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.form-group-ct {
  padding-bottom: 4px;
}

.error.cstm-error4 {
  margin-top: -2px;
}

.error-video {
  margin-top: -18px;
}

span.error.error-video {
  margin-top: -20px;
}

.plan-resources-main.cstm-plan-resourse-main {
  margin-top: 35px;
}

.profile-detilas-all-nav.cstm-profile-details-all-nav {
  padding: 0 0 20px 0;
}

.profiledt-menu li .cstm-profile-link {
  border-radius: 0;
  border-right: 1px solid #d2d2d4;
}

ul.nav.profiledt-menu.cstm-tab-add-video {
  border-bottom: 1px solid #d2d2d4;
}

.edit-user-details-footer.cstm-create-button {
  padding-left: 24px;
}

.cover-image-dpdn.cstm-group-cover-img img {
  width: 200px;
  height: 200px;
}

.cstm-videodes ul {
  padding-left: 18px;
}

.cstm-vd-cnt {
  display: flex;
  justify-content: space-between;
}

.cstm-vd-cnt-left ul {
  padding-left: 18px;
}

button.cstm-vd-box-close {
  background: #fff;
  position: absolute;
  right: -10px;
  top: -10px;
  border: 1px solid #cdcdcd;
  border-radius: 50px;
  line-height: 21px;
  font-size: 13px;
}

.cstm-vd-box-in {
  display: table;
  width: 100%;
}

.message-modal-addtional-div {
  margin-bottom: 13px;
  font-size: 18px;
  color: #000;
}

.cstm-are-you {
  max-width: 440px !important;
  padding: 35px 35px !important;
  position: relative;
  border-radius: 0px;
}

p.save-draft-text {
  font-size: 16px;
}

.btn-text-draft .btn,
.btn-text-draft .btn2 {
  padding-left: 15px;
  padding-right: 15px;
}

.um-right-in input {
  border-radius: 8px;
  border: 1px solid rgba(13, 13, 13, 0.3);
  display: flex;
  padding: 8px 12px;
  width: 100%;
  color: #060d14;
  text-overflow: ellipsis;
  font: var(--body-medium);
  box-sizing: border-box;
  background-color: var(--bg-screen-strong);
}

.admin-table-short-desc {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #201e29;
}

a.cstm-view-doc-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #201e29;
}

a.cstm-view-doc-title:hover {
  text-decoration: underline;
}

.admin-doc-icon-list {
  display: flex;
  gap: 10px;
  align-items: center;
}

.document-status-select {
  display: flex;
  align-content: center;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .group-listing-tabs .tab-pane .uic-fl {
    margin-top: 90px;
  }

  .uic-title-rt-only {
    margin-top: -70px;
  }
}

@media screen and (max-width: 1420px) {
  .nav.group-listing-menu li a {
    font-size: 13px;
    padding: 6px 8px;
  }
}

/* date range picker */
.cstm-date-range-group
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  padding: 6px 10px 5px 25px !important;
  text-align: center;
}

.cstm-date-range-group
  .react-datepicker-wrapper
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.cstm-date-range-group .react-datepicker-wrapper {
  width: 130px !important;
}

/* Modal body css */
.ctsm-modal-body {
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

button .btn-loader-img-cover {
  width: 60px;
  height: 20px;
  object-fit: cover;
}

.text-box-wrapper {
  background-color: #e2edfd;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  font: var(--body-small-bold);
  max-width: 230px;
  width: 100%;
}

.error-rule {
  position: relative !important;
  display: block;
}

/* Recuring CSS */
.cstm-radio-btn input {
  display: none;
}

.cstm-radio-btn label {
  width: 40px;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  margin-right: 15px;
  transition: all 0.5s ease;
}

.cstm-radio-btn input:checked + label {
  background-color: #351bce !important;
  border-color: #351bce;
  color: #fff;
}

.cstm-ending-wrapper .enddate-col {
  display: flex;
  margin-right: 30px;
  width: 23%;
}

.cstm-ending-wrapper .afterdate-event-col .subcat-addvideo,
.cstm-ending-wrapper .enddate-col .subcat-addvideo {
  margin-bottom: 0;
  margin-right: 15px;
}

.cstm-ending-wrapper .enddate-col .form-group {
  width: 100%;
  margin-bottom: 0;
}

.cstm-ending-wrapper .afterdate-event-col {
  display: flex;
  margin-right: 30px;
  align-items: center;
}

.upload-ticket-image {
  margin-bottom: 20px;
}

/* TextAreaComponent CSS */
.editable-textArea {
  min-height: 150px !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 1px;
  font-size: 16px;
  line-height: 1.5;
  overflow-y: auto;
  margin-bottom: 15px;
  outline: none;
  overflow: hidden;
}
.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Toast CSS */
.toast-wrapper {
  position: fixed;
  z-index: 999;
}
.toast-wrapper.top {
  top: 20px;
}
.toast-wrapper.right {
  right: 20px;
}
.toast-wrapper.left {
  left: 20px;
}
.toast-wrapper.bottom {
  bottom: 20px;
}

/* Table CSS */

/* table thead th {
    white-space: nowrap;
    padding-right: 30px !important;
}
table .uic-th-td:last-child .uic-table-icon div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

table .uic-th-td:last-child .uic-table-icon div a {
    flex-shrink: 0;
}



table .uic-th-td:last-child .uic-table-icon div a:last-child {margin-right: 0 !important;} */
/* TextAreaComponent CSS */
.editable-textArea {
  min-height: 150px !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 1px;
  font-size: 16px;
  line-height: 1.5;
  overflow-y: auto;
  margin-bottom: 15px;
  outline: none;
  overflow: hidden;
}
.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cstm-modal-xl .modal-content,
.cstm-modal-xl .modal-dialog {
  max-width: 900px;
}
.button-stepper .button.medium.icon-only {
  padding: var(--spacing-8);
}

.button-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 112px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.button-stepper p {
  font: var(--body-x-large-bold);
  color: var(--color-text-static-darkgray);
}
.edit-stepper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.edit-stepper .button-stepper {
  position: relative;
  bottom: 0;
  right: 0;
  width: fit-content;
  gap: 10px;
}
.edit-stepper .error-extra-seats {
  width: 100%;
  display: block;
}

.cstm-modal-xl .modal-content,
.cstm-modal-xl .modal-dialog {
  max-width: 900px;
}
.button-stepper .button.medium.icon-only {
  padding: var(--spacing-8);
}

.button-stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 112px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.button-stepper p {
  font: var(--body-x-large-bold);
  color: var(--color-text-static-darkgray);
}
.edit-stepper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.edit-stepper .button-stepper {
  position: relative;
  bottom: 0;
  right: 0;
  width: fit-content;
  gap: 10px;
}
.edit-stepper .error-extra-seats {
  width: 100%;
  display: block;
}
.fileUpload {
  background: #351bce;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 1px;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin: 1.25em auto;
  overflow: hidden;
  padding: 0.875em;
  position: relative;
  text-align: center;
  width: 156px;
  cursor: pointer;
}
.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #292929;
  cursor: pointer;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 148px;
  height: 46px;
  cursor: pointer;
}

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.users-info-content-main .desk-globle-model-box-inner {
  max-width: 700px;
  border-radius: 0;
}
.users-info-content-main .desk-globle-model-box-inner .MuiTableContainer-root {
  min-height: 488px;
}
.partner-logo {
  min-height: 102px;
}
.partner-thumb {
  min-height: 120px;
  width: 222px;
}
.partner-banner {
  min-height: 44px;
  width: 222px;
}
.partner-mobile-banner {
  min-height: 58px;
  width: 222px;
}
.partner-mobile-banner img.image-empty-thumb,
.partner-banner img.image-empty-thumb {
  width: 30px;
  height: auto;
}

/* cropper bd */
.checksboxes-visible.cropper-bd .desk-globle-model-box-inner {
  width: auto !important;
  max-width: 1024px !important;
  /* padding: 0 40px; */
}

.cropper-bd .modal-content {
  border: none;
}

.cropper-bd .cropper-content .crop-media-body {
  padding: 20px;
  background-color: #454545;
}

.cropper-bd .modal-content.cropper-content {
  max-height: calc(100vh - 110px) !important;
  height: auto !important;
}

.cropper-bd .ReactCrop__child-wrapper img {
  max-height: calc(100vh - 300px) !important;
  height: auto;
  /* width: fit-content;
  width: -webkit-fit-content; */
}

.MuiAutocomplete-popper  {
  box-shadow: rgba(0, 0, 0, 0.314) 0px 0px 13px 0px;
  border-radius: 8px;
}
/* admin-sidebar */
@media screen and (max-width: 991px) {
  .cstm-all-admin .custom-overlay,
  .sidebar-menu,
  .users-info-sidebar,
  .header-bk {
    z-index: 111;
  }
  .users-info-sidebar {
    width: 260px;
    transform: translateX(-100%);
  }
  .sidebar-menu {
    transform: translateX(-100%);
    z-index: 112;
  }
  .users-info-sidebar.show {
    transform: translateX(109px);
  }
  .sidebar-menu.show {
    transform: translateX(0%);
  }
  .content-main {
    margin-left: 0;
  }
  .users-info-content {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .content-main {
    padding-top: 61px;
  }
  .sidebar-menu {
    margin-top: 61px;
    height: calc(100vh - 61px);
    width: 90px;
  }
  .sidebar-menu ul li a span.admin-left-title {
    font-size: 12px;
    line-height: 1.4;
  }
  .users-info-sidebar.show {
    transform: translateX(90px);
    width: 220px;
  }
  .users-info-sidebar .card-header button,
  .cstm-ac-in-menu ul li a,
  .users-info-sidebar .card-header a {
    font-size: 14px;
    line-height: 1.4;
  }
  .header-bk {
    padding: 10px 15px;
  }
  .header-bk-left img {
    height: 30px;
  }
  .users-info-sidebar {
    height: calc(100vh - 61px);
  }
  .header-bk-right-user .dropdown-toggle {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .header-bk-right-user .dropdown-toggle .user-icon {
    width: 25px;
    height: 25px;
  }
  .import.text,
  .cstm-all-admin button.btn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    height: auto !important;
    padding: 10px 17px !important;
  }
  #Photos .uic-fl-mn .btn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    padding: 0;
  }
  .search-input {
    max-width: 100%;
  }
  .checkin-modal {
    padding: 0;
  }
  .checkin-modal .model-popup {
    max-width: 100%;
    border-radius: 0;
    height: 100%;
  }
  .checkin-modal .model-popup .cstm-data-load {
    height: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .cstm-upload-photo-event {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .group-info-user {
    flex-wrap: wrap;
    gap: 10px;
  }
}
