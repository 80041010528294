.event-thumb-img {
  display: flex;
  width: 100%;
  height: auto;
}
.event-thumb-img img {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center;
}
.thumb-img-type-medium {
  max-width: 480px;
  min-width: 225px;
}
.thumb-img-type-medium .thumb-image {
  border-radius: 12px;
}
.thumb-img-type-small {
  min-width: 121px;
  max-width: 224px;
}
.thumb-img-type-small .thumb-image {
  border-radius: 6px;
}
.thumb-img-type-x-small {
  min-width: 96px;
  max-width: 120px;
}
.thumb-img-type-x-small .thumb-image {
  border-radius: 4px;
}
