.dropdown-active {
  background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5)!important;
}
.dropdown-list {
  display: flex;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-small, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  position: absolute;
  margin: 4px 0px;
  max-height: 420px;
  z-index: 2;
}


.dropdown-list li {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-12, 12px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  font: var(--body-medium);
  box-sizing: border-box;
  word-break: break-all;
}

.dropdown-list li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.dropdown-list li:focus {
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
}

.dropdown-list .active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.dropdown-label {
  display: flex;
  align-items: center;
  gap: var(--spacing-8, 8px);
  border-radius: var(--radius-small, 4px);
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium);
  cursor: pointer;
  position: relative;
  padding: 0px 26px 0px 0px;
  width: -moz-fit-content;
  width: fit-content;
  background: var(--color-bg-elevated, #fff);
  border: none;
}

.dropdown-label:focus {
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d);
  outline-offset: 2px;
}

.dropdown-label:after {
  background-image: url(../../Images/icn_m_chevron_down.svg);
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-size: cover;
  top: 2px;
  right: 0px;
}

.dropdown-up-arrow::after {
  rotate: 180deg !important;
}

.dropdown-label b {
  font-weight: 700;
}


.dropdown-list.large {
  min-width: 400px;
}

.dropdown-list.medium {
  min-width: 296px;
}

.dropdown-list.small {
  min-width: 160px;
}


.doc-suggestion-dropdown-list {
  display: flex;
  padding-bottom: var(--spacing-2, 2px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.doc-suggestion-dropdown-list h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  word-break: break-all;
}


.doc-suggestion-dropdown-list h6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  word-break: break-all;
}


.error-item-li {
  color: var(--color-text-danger-strong);
}