.checkbox-field {
    position: relative;
    width: fit-content;
}

.checkbox-field label {
    margin: 0px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    color: var(--color-text-neutral-strong, #060d14);
    font: var(--body-medium);
}

.checkbox-field input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
}

.checkbox-field label>span {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--color-border-neutral-strong);
    margin-right: 0px;
    border-radius: var(--radius-4);
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 1;
}

.checkbox-field input:checked+label>span {
    background-color: var(--color-bg-brand-strong);
    opacity: 1;
    border: 1p solid transparent;
}

.checkbox-field input:checked+label>span:hover {
    background-color: var(--color-bg-brand-strong-hovered);
}

.checkbox-field input:checked+label>span:active {
    background-color: var(--color-bg-brand-strong-pressed);
}

.checkbox-field input+label>span .checkedIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
}

.checkbox-field input:checked+label>span .checkedIcon {
    opacity: 1;
}

.checkbox-field input+label>span.indeterminate .checkmark-icon {
    display: none;
}

.indeterminate-icon {
    display: none;
}

.checkbox-field input+label>span.indeterminate .indeterminate-icon {
    display: block;
}

.checkbox-field label>span:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered);
}

.checkbox-field label>span:active {
    border: 1px solid var(--color-border-neutral-strong-pressed);
}

.checkbox-field label>span:focus {
    outline-offset: 2px;
    border: 2px solid var(--color-border-inverse-neutral);
}

.checkbox-label {
    font: var(--body-medium);
    color: var(--color-text-neutral-strong);
    margin: 0;
}