.chat-cell {
    display: flex;
    width: 100%;
    padding: var(--spacing-8, 8px);
    /* align-items: center; */
    gap: var(--spacing-16, 16px);
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    box-sizing: border-box;
}

.chat-cell:hover {
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
    cursor: pointer;
}

.chat-cell:active {
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
    cursor: pointer;
}

.chat-cell.selected,
.chat-cell.selected:hover .chat-cell.selected:active {
    background: var(--color-bg-neutral-secondary);
}

.chat-cell-avatar {
    width: var(--AvatarSizeLarge, 48px);
    height: var(--AvatarSizeLarge, 48px);
    min-width: 48px;
    flex-shrink: 0;
    position: relative;
}

.chat-cell-container {
    display: flex;
    padding-right: var(--spacing-4, 4px);
    align-items: flex-start;
    gap: var(--spacing-12, 12px);
    flex: 1 0 0;
}

.chat-cell-container-text {
    display: flex;
    padding-right: var(--spacing-12, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-2, 2px);
    flex: 1 0;
    height: 100%;
    justify-content: center;
}

.chat-cell-container-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--spacing-4, 4px);
}

.chat-cell-container-time-in {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-align: right;
    text-overflow: ellipsis;
    font: var(--body-x-small);
    margin: 0px;
}

.chat-cell-container-cont {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-cell-container-cont .chat-badge-mention {
    padding: 0.5px;
}

.chat-active {
    background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}