.chat-message-reply-sec{
    padding: var(--spacing-4) 0;
}

.chat-messagereply.outgoing .chat-message-reply-sec .reply-line{
    background: var(--light-blue-400);
}

.chat-messagereply.outgoing .chat-message-reply-sec .avtarName,
.chat-messagereply.outgoing .chat-message-reply-sec .replyText{
    color: var(--color-text-brand-onstrong);
}
