.IncomingMessageTime-sec {
  padding: var(--spacing-2) var(--spacing-6);
  width: fit-content;
  gap: var(--spacing-4);
  border-radius: var(--radius-full);
  font: var(--body-2x-small);
  color: var(--color-text-neutral-tertiary);
}

.IncomingMessageTime-sec.hasBackdrop {
  color: var(--color-text-static-white);
  background: var(--black-600);
}
