.reaction-sec {
    width: -moz-fit-content;
    width: fit-content;
    padding-right: var(--spacing-4);
    margin-left: auto;
}

.reaction-sec.isOutgoing {
    padding-right: var(--spacing-12);
}


.reaction-bg-screen {
    width: fit-content;
    border-radius: var(--radius-16);
    background: var(--bg-screen-strong);

}

.reaction-wrapper {
    width: fit-content;
    padding: 0 var(--spacing-8);
    border-radius: var(--radius-16);
    background: var(--color-bg-neutral-secondary);
    border: 2px solid var(--color-border-inverse-screen);
}

.reaction-wrapper span {
    padding-left: var(--spacing-4);
    font: var(--body-x-small-bold);
    color: var(--color-text-neutral-strong);
}