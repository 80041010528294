.chat-empty-sec,
.chat-nomessage-sec {
  padding: var(--spacing-16);
  gap: var(--spacing-4);
}

.chat-slot-sec {
  padding: var(--spacing-16) 0;
  gap: var(--spacing-4);
}

.chat-sec-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.chat-sec-inner .chat-slot-sec {
  width: 100%;
}

/* Chat Scroll  */

/* .chat-center-sec .chat-message-wrapper .chat-sec-inner .chat-slot-sec {
  margin: 0 -14px;
  width: calc(100% + 28px);
}

.chat-center-sec .chat-message-wrapper .outgoing .chat-message-container {
  margin-right: 8px;
} */
/* For the chat Invert */

.chat-slots-invert,
.chat-slots-invert .chat-slot-sec,
.chat-slots-invert .chat-nomessage-sec,
.chat-slots-invert .chat-nomessage-sec .ChatDataContainer,
.chat-slots-invert .ChatDataContainer {
  flex-direction: column-reverse;
}

/*  */
.chat-action-menu .dropdown-list {
  width: 190px;
}

.chat-action-menu {
  position: absolute;
  top: calc(50% + 18px);
  z-index: 5;
}

.outgoing .chat-action-menu {
  left: -180px;
}

.incoming .chat-action-menu {
  right: -180px;
}

.top-drop-down {
  top: inherit;
  bottom: calc(50% + 18px);
}

.chat-action-menu .dropdown-list {
  position: relative;
}