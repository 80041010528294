.loadind-post-colla {
    text-align: center;
    height: 100vh;
}

.loadind-post-colla img {
    width: 80px;
    position: relative;
    top: 50%;
    margin-top: -25px;
}

.header-collaborator {
    margin: 18px 12px;
}

.collaborator-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: calc(100vh - 100px); */
    max-width: 580px;
    width: 100%;
    margin: 120px auto;
    gap: 48px;
}

.collaborator-h2 {
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--title-large);
    margin: 0px;
    letter-spacing: -0.36px;
}

.collaborator-h4 {
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--title-small);
    letter-spacing: -0.24px;
    margin: 0px;
}

.collaborator-h6 {
    color: var(--color-text-neutral-secondary, #394046);
    text-align: center;
    font: var(--body-large);
    margin: 0px;
    display: flex;
    padding: 0px var(--spacing-space-2-x-large, 32px);
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.collaborator-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-medium, 16px);
    align-self: stretch;
}

.collaborator-email {
    display: flex;
    padding: var(--spacing-space-2-x-small, 8px) var(--spacing-space-x-large, 24px);
    align-items: center;
    gap: var(--spacing-space-2-x-small, 8px);
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-brand-secondary, #DFEDFF);
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-large-bold);
}

.collaborator-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-space-medium, 16px);
}

.collaborator-field {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.collaborator-field input {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--radius-large, 16px);
    border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.20));
    color: var(--color-text-neutral-strong);
    text-align: center;
    font: var(--title-large);
    letter-spacing: -0.36px;
    background-color: var(--color-bg-elevated);
}

.collaborator-field input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    /* font: var(--title-medium); */
    color: var(--color-text-neutral-tertiary);
}

.collaborator-field input::-moz-placeholder {
    /* Firefox 19+ */
    /* font: var(--title-medium); */
    color: var(--color-text-neutral-tertiary);
}

.collaborator-field input:-ms-input-placeholder {
    /* IE 10+ */
    /* font: var(--title-medium); */
    color: var(--color-text-neutral-tertiary);
}

.collaborator-field input:-moz-placeholder {
    /* Firefox 18- */
    /* font: var(--title-medium); */
    color: var(--color-text-neutral-tertiary);
}

.collaborator-field input:focus {
    border: 2px solid var(--color-border-inverse-neutral);
}

.collaborator-fill-field {
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30)) !important;
}

.collaborator-error-field {
    border: 1px solid var(--color-border-danger-strong, #DB1D26) !important;
}

.collaborator-field-error {
    color: var(--color-text-danger-strong);
    text-align: center;
    font: var(--body-x-small);
}

.collaborator-field-sucessfully {
    color: var(--color-text-success-strong);
    text-align: center;
    font: var(--body-x-small);
}

.collaborator-field-msg {
    display: none;
}

.collaborator-send {
    color: var(--color-text-neutral-secondary);
    text-align: center;
    font: var(--body-medium);
    position: relative;
    display: flex;
    padding: var(--spacing-space-2-x-small, 8px) var(--spacing-space-2-x-large, 32px);
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.collaborator-send-loader-img img {
    width: 26px;
}

.collaborator-send-loader-img {
    width: 100%;
    background-color: #ffffffdb;
    position: absolute;
}

.collaborator-send-in {
    position: relative;
}

.collaborator-link {
    color: var(--color-text-brand-strong, #0B42CF);
    font: var(--body-large-bold);
    cursor: pointer;
}

.collaborator-emailicon {
    height: 120px;
    width: 100%;
    text-align: center;
}

.collaborator-info-registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-large, 32px);
    align-self: stretch;
}

.collaborator-registration-info {
    display: flex;
    padding: 0px var(--spacing-space-2-x-large, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-medium, 16px);
    align-self: stretch;
}

.collaborator-registration-agree {
    padding: var(--spacing-space-2-x-small, 8px) var(--spacing-space-2-x-large, 40px);
    color: var(--color-text-neutral-secondary, #394046);
    text-align: center;
    font: var(--body-medium);
}

.collaborator-registration-agree a {
    color: var(--color-text-neutral-secondary, #394046);
    text-align: center;
    font: var(--body-medium);
    text-decoration-line: underline;
}

.collaborator-registration-agree a:hover {
    color: var(--color-bg-brand-strong);
    text-decoration-line: inherit;
}

.collaborator-registration-btn {
    width: 100%;
}

.btn-connect {
    display: flex;
    padding: var(--spacing-space-medium, 16px) var(--spacing-space-x-large, 24px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-small, 8px);
    align-self: stretch;
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--body-large-bold);
    width: 100%;
    background-color: var(--color-bg-elevated);
}

.btn-connect:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
}

.btn-connect:active {
    border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}

.collaborator-registration-btn-label {
    display: flex;
    padding: 0px var(--spacing-space-4-x-small, 4px);
    align-items: flex-start;
}

.collaborator-succesfully {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-large, 32px);
    align-self: stretch;
}

.btn-log {
    color: var(--color-text-brand-onstrong, #FFF);
    text-align: center;
    font: var(--body-large-bold);
    display: flex;
    padding: var(--spacing-space-medium, 16px) var(--spacing-space-x-large, 28px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-small, 8px);
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-brand-strong, #0B42CF);
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-brand-strong, #0B42CF);
}

.btn-log:hover {
    background: var(--color-bg-brand-strong-hovered);
    color: var(--color-text-static-white);
}

.collaborator-revoke-popup-main {
    max-width: 420px;
    position: relative;
    border-radius: var(--radius-medium, 12px);
    background: var(--color-bg-elevated, #FFF);
    box-shadow: var(--shadow-small);
    overflow: inherit;
}

/* New Sign-Up page */
.new-signup-page.collaborator-main {
    height: auto;
    margin: auto;
    max-width: 360px;
    width : 100%;
    padding: 80px 0;
}
.new-login-page .btn-facebook:hover {
    background: var(--color-bg-brand-strong-hovered, #0735AB);
}
button.apple-btn.new-login-page-btn:hover {
    background-color: #000000;
}
.new-login-page .apple-btn i,
.new-login-page .btn-facebook i,
.new-signup-page .apple-btn i,
.new-signup-page .btn-facebook i {
    font-size: 20px;
}
.new-signup-page .btn-facebook {
    background-color: #0866FF;
}
.new-signup-page .collaborator-registration-info {
    padding: 0;
    gap: 12px;
}
.new-signup-page .collaborator-registration-agree {
    color: var(--color-text-neutral-tertiary, #84898f);
    font-size: 13px;
    line-height: 1.3;
    padding-top: 0;
    padding-bottom: 0;
}
.new-signup-page .collaborator-registration-agree a {
    color: var(--color-bg-brand-strong, #0B42CF);
    text-decoration: none;
    font-size: inherit;
    line-height: inherit;
}
.header-spacing {
    padding: 18px 24px;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background-color: #fff;
}
.new-email-verify {
    height: auto;
    margin: auto;
    max-width: 360px;
    width : 100%;
    padding: 80px 0;
}
.new-email-verify .collaborator-h6 {
    padding: 0;
}
.new-email-verify .collaborator-info {
    gap: 24px;
}
.new-email-verify .text-field-main {
    max-width: 100%;
}
.new-email-verify .button {
    width: 100%;
    display: block;
}
.new-email-verify  a {
    color: var(--color-text-brand-strong, #0B42CF);
    font-weight: 700;
}
.new-check-email .collaborator-info {
    margin: auto;
    max-width: 360px;
    width : 100%;
    padding: 80px 0;
    gap: 0;
}
.new-check-email .collaborator-info .collaborator-h2 {
    margin-top: 32px;
}
.new-check-email .collaborator-info .collaborator-h6 {
    padding: 8px 0 24px;
}
.new-contact-support .collaborator-info {
    margin: auto;
    max-width: 360px;
    width : 100%;
    padding: 80px 0;
    gap: 32px;
}
.new-contact-support .collaborator-info .collaborator-h6 {
    padding: 0;
}
.new-contact-support .collaborator-info .button {
    width: 100%;
    display: block;
}
button .btn-loader-img-cover {
    width: 60px;
    height: 20px;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    .collaborator-field {
        gap: 8px;
    }

    .collaborator-field input {
        font-size: 18px !important;
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 8px;
    }

    .collaborator-h2 {
        font-size: 24px;
        line-height: 28px;
        padding: 0px 10px;
    }

    .collaborator-emailicon {
        height: 80px;
    }

    .collaborator-emailicon img {
        height: 80px;
    }

    .collaborator-main {
        margin: 50px auto;
        gap: 18px;
    }

    .collaborator-h6 {
        font-size: 14px;
        line-height: 21px;
    }

    .collaborator-email {
        padding: 5px 10px;
    }

    .collaborator-email {
        font-size: 14px;
        line-height: 21px;
    }

    .collaborator-send {
        font-size: 13px;
    }
}