
.messagebubble-sec-container {
  max-width: 432px;
  width: 100%;
}

.messagebubble-wrapper {
  position: relative;
  width: fit-content;
  gap: var(--spacing-4);
  max-width: 432px;
}

.massage-insider {
  border-radius: var(--radius-12);
  overflow: hidden;
  border: 1px solid var(--color-border-neutral-strong);
}

.messagebubble-wrapper img {
  display: block;
  max-height: 426px;
  max-width: 432px;
}

.msg-time-sec {
  position: absolute;
  right: var(--spacing-6);
  bottom: var(--spacing-6);
  z-index: 2;
}

/* Img 2 */
.messagebubble-wrapper.imgtwo .massage-insider {
  max-height: 214px;
  max-width: 214px;
  width: 214px;
  height: 214px;
}

.messagebubble-wrapper.imgtwo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Img 3 */
.messagebubble-wrapper.imgthree .massage-insider {
  max-height: 140px;
  max-width: 140px;
  width: 140px;
  height: 140px;
}

.messagebubble-wrapper.imgthree img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Img more */
.messagebubble-wrapper.imgmore .massage-insider {
  max-height: 140px;
  max-width: 140px;
  width: 140px;
  height: 140px;
}

.messagebubble-wrapper.imgmore img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-counter-sec {
  display: none;
}

.massage-insider.counterImg {
  position: relative;
}

.massage-insider.counterImg .img-counter-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: var(--color-bg-blackout-strong);
  z-index: 1;
}

.massage-insider.counterImg .img-counter-sec p {
  font: var(--title-2);
  letter-spacing: -0.3px;
  color: var(--color-text-static-white);
}

.imgone .massage-insider.Horizontal {
  min-width: 432px;
  max-width: 432px;
  min-height: 242px;
  max-height: 242px;
  aspect-ratio: 16/9;
}

.imgone .massage-insider.Horizontal img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.imgone .massage-insider.Vertical {
  min-width: 242px;
  max-width: 242px;
  min-height: 432px;
  max-height: 432px;
  aspect-ratio: 9/16;
}

.imgone .massage-insider.Vertical img {
  width: 100%;
  height: 100%;
  aspect-ratio: 9/16;
  object-fit: cover;
  max-height: max-content;
}

.imgone .massage-insider.Squre {
  min-width: 242px;
  max-width: 242px;
  min-height: 242px;
  max-height: 242px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.imgone .massage-insider.Squre img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-height: max-content;
}


/* Video Bubble */



.messagebubblevideo .massage-insider.Horizontal {
  min-width: 432px;
  max-width: 432px;
  min-height: 242px;
  max-height: 242px;
  aspect-ratio: 16/9;
}

.messagebubblevideo .massage-insider.Horizontal img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.messagebubblevideo .massage-insider.Vertical {
  min-width: 242px;
  max-width: 242px;
  min-height: 432px;
  max-height: 432px;
  aspect-ratio: 9/16;
}

.messagebubblevideo .massage-insider.Vertical img {
  width: 100%;
  height: 100%;
  aspect-ratio: 9/16;
  object-fit: cover;
  max-height: max-content;
}

.messagebubblevideo .massage-insider.Squre {
  min-width: 242px;
  max-width: 242px;
  min-height: 242px;
  max-height: 242px;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.messagebubblevideo .massage-insider.Squre img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-height: max-content;
}


.msg-video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-blackout-strong);
  border-radius: var(--radius-full);
}