.notifications {
    display: flex;
    max-width: 480px;
    width: 100%;
    flex-direction: column;
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-elevated, #FFF);
    box-shadow: var(--shadow-small);
    height: calc(100vh - 76px);
    position: fixed;
    right: 24px;
    top: 52px;
}
.notifications-heading {
    display: flex;
    padding: 8px 12px 8px var(--spacing-20, 20px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    position: relative;
}
.notifications-heading .dropdown-list {
    right: 12px; 
    top: 50px;  
}
.notifications-in-body {
    display: flex;
    padding: var(--spacing-12, 12px) 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: 100%; 
}

.notifications hr {
    z-index: 1;
}
.notifications-in-emptystate {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
}

.notification-card-body-list-loading {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px;
}
.notification-card-body-list-in-loading {
    height: 42px;
    width: 100%;
    border-radius: 8px;
}
/* .notification-card-body-container-text-sub h6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}   */