.video-medium-card-wrapper {
    min-width: 280px;
    max-width: 440px;
    width: 100%;
}
.video-medium-wrapper .video-dot-btn {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: all .5s ease;
}
.video-medium-wrapper:hover .video-dot-btn {
    opacity: 1;
    visibility: visible;
}
.video-medium-card a:focus-visible::before {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    width: calc(100%);
    height: calc(100%);
    border: 2px solid #000;
    border-radius: var(--radius-12);
    z-index: 1;
}

.video-medium-card a:focus-visible {
    position: relative;
    outline: none;
}

.video-medium-card {
    display: flex;
    flex-direction: column;
}

.video-medium-card .video-medium-thumb-img.video-img-type-medium {
    flex-shrink: 0;
    display: flex;
    width: 100%;
    height: auto;
    border-radius: var(--radius-12);
    overflow: hidden;
}

.video-medium-card .video-medium-thumb-img img {
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--radius-12);
}

.video-medium-card .video-medium-details-box {
    position: relative;
    padding: var(--spacing-12) var(--spacing-40) var(--spacing-12) 0;
}
.video-medium-card .video-medium-details-wrapper .video-medium-card-title {
    font: var(--body-large);
    font-weight: 600;
    color: var(--color-text-neutral-strong);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: var(--spacing-4);
}

.video-medium-card .video-medium-details-wrapper .video-details-view-date {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-2);
}

.video-medium-card .video-medium-details-wrapper .video-card-title {
    font: var(--title-2x-medium);
    color: var(--color-text-neutral-strong);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: var(--spacing-4);
}

.video-medium-card .video-medium-details-wrapper .video-type-text,
.video-medium-card .video-medium-details-wrapper .video-details-view-date span {
    font: var(--body-small);
    color: #394046;
}

.video-medium-card .video-medium-details-wrapper .video-details-view-date span:not(:first-child) {
    display: flex;
    align-items: center;
}

.video-medium-card .video-medium-details-wrapper .video-details-view-date span:not(:first-child)::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    margin: 0 6px 0px;
}
.video-medium-card .video-medium-details-wrapper .video-medium-type-text {
    font: var(--body-small);
    color: var(--color-text-neutral-secondary);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: var(--spacing-4);
}
.video-medium-card .video-medium-thumb-img .video-duration {
    position: absolute;
    bottom: 7.5px;
    right: 8px;
    color: var(--color-text-static-white);
    background: var(--color-bg-blackout-strong);
    padding: 4px 8px;
    border-radius: var(--radius-8);
    font: var(--body-small);
    font-weight: 600;
  }
.video-medium-card-wrapper.has-dotmenu .video-dot-btn {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    position: absolute;
    top: var(--spacing-12);
    right: 0;
    z-index: 1;
}
.video-medium-card-wrapper.has-dotmenu:focus-visible .video-dot-btn,
.video-medium-card-wrapper.has-dotmenu:hover .video-dot-btn {
    opacity: 1;
    visibility: visible;
}
.video-dropdown-wrapper {
    position: absolute;
    top: 55px;
    right: 0;
}
.video-dropdown-wrapper.top {
    top: -60px;
}
.video-medium-card-wrapper .video-medium-thumb-img .status-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
}