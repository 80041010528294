.chat-data-cell {
    display: flex;
    width: 100%;
    padding: var(--spacing-8, 8px) var(--spacing-20, 20px);
    align-items: center;
    gap: var(--spacing-16, 16px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    box-sizing: border-box;
}

.chat-data-cell:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
    cursor: pointer;
}

.chat-data-cell:active {
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
    cursor: pointer;
}

.chat-data-cell-text {
    display: flex;
    padding-right: var(--spacing-12, 12px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    box-sizing: border-box;
}

.chat-data-cell-text h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    word-break: break-all;
    font: var(--body-small);
    font-weight: 600;
}

.chat-data-cell-text p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    word-break: break-all;
    font: var(--body-small);   
    color: var(--color-text-neutral-secondary);
}

.chat-data-cell-icon {
    min-width: 24px;
    height: 24px;
    flex-shrink: 0;
}