.event-medium-cards {
  grid-gap: var(--spacing-24) var(--spacing-16);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding: var(--spacing-24);
}

.empty-state.event-empty-state {
  grid-column: -1 / 1;
  min-height: calc(100vh - 244px);
  justify-content: center;
}
