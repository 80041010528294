.iframe-loader {
    height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.map_tbl_ttl {
    text-decoration: underline;
    font-size: 15px;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.cstm-profile-upload-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cstm-profile-upload-img a {
    text-align: center;
    font: var(--body-small);
    font-weight: 600;
    text-decoration: underline!important;
}

.cstm-map-upload-avar {
    height: 72px;
    width: 72px;
    object-fit: cover;
    border: 2px solid #ffffff !important;
    /* box-shadow: 0px 3px 15px #e3e3e3; */
    /* filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.13)); */
    border-radius: 50px;
    background: #201e29;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 36px;
}

.cstm-map-ld-user-img {
    height: 72px;
    width: 72px;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #ffffff !important;
    /* filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.13)); */
    border-radius: 144px;
}

.user_map_left {
    font-weight: 700;
    color: #000;
    padding-right: 3px;
}

.map-data-loading {
    text-align: center;
    padding-top: 40px;
}
.map-data-loading img {
    width: 80px;
    margin: 0 auto;
}

.user-map-lable {
    line-height: 18px;
    color: #000;
}

.member-banner-map {
    /* height: 381px; */
    height: 40vh;
    background-image: url(../../Images/page-headers_summit-2.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
}


.member-banner-map-in {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}


.member-banner-h2 {
    color: var(--color-text-inverse);
    font: var(--title-large);
    letter-spacing: -0.36px;
    margin: 0px;
    text-align: center;
    padding-top: 40px;


}


.member-banner-menu {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}


.member-banner-menu li {
    margin: 0px;
}


.member-banner-menu li a {
    color: var(--color-text-inverse);
    text-decoration: inherit;
    padding: 10px;
    font: var(--body-large);
}


.member-banner-menu li a:hover {
    color: var(--color-bg-accent-yellow);
}


.member-active {
    color: var(--color-bg-accent-yellow) !important;
}

.member-text-main {
    max-width: 1240px;
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
    padding: 100px 20px;
}

.member-text-main p {
    margin: 0px 0px 20px;
    line-height: 36px;
    font-size: 24px;
    color: #222;
}

.member-text-main p:last-child {
    margin-bottom: 0px;
}


.member-map-only {
    max-width: 1240px;
    margin: 0px auto;
    width: 100%; 
    padding: 0px 20px 70px;
}


.mds-chapters {
    margin-top: 30px;
}

.courtney-mail-link {
    font-weight: 600;
    color: #000;
    text-decoration: underline;
}

.courtney-mail-link:hover {
    text-decoration: inherit;
    color: #000;
}


.we-have-launched {
    background: var(--color-bg-neutral-secondary);
    width: 100%;
    padding: 100px 20px;
    box-sizing: border-box;
}


.we-have-launched-in {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}


.we-have-launched-in-logo {
    display: flex;
    flex-wrap: wrap;
    gap: 45px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}


.we-have-launched-in-logo-in {
    width: calc(33.33% - 30px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.we-have-launched-in-logo-in img {
    height: 100px;
}


.chapter-map {
    background: #04133e;
    width: 100%;
    padding: 100px 20px;
    box-sizing: border-box;
}


.chapter-map-in {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}


.chapter-map-title {
    text-align: center;
    margin: 0px 0px 30px;
    font-size: 32px;
    font-weight: 700;
    color: #FFF;
}

.we-have-launched-title {
    color: #091f48;
    text-align: center;
    margin: 0px 0px 54px;
    font-size: 30px;
    font-weight: 700;
}

.menber-chapter-page-only {
    max-width: 622px;
}

.member-text-main2 {
    max-width: 1240px;
    margin: 0px auto;
    width: 100%;
    box-sizing: border-box;
    padding: 55px 20px 20px 20px;
    font-size: 16px;
    color: #222;
    line-height: 24px;
}
.member-text-main2 p {
    margin-bottom: 16px;
} 

.gm-style-iw.gm-style-iw-c {
    width: 100%;
}