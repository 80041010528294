.chat-link-thumbnail-img{
	display: flex;
	width: var(--AvatarSizeLarge, 48px);
	height: var(--AvatarSizeLarge, 48px);
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: var(--radius-8, 8px);  
}
.chat-link-thumbnail-img img {
	width: var(--AvatarSizeLarge, 48px);
	height: var(--AvatarSizeLarge, 48px);
	border-radius: var(--radius-8, 8px);   
	background-size: cover;  
	object-fit: cover;
}
.chat-link-thumbnail-icn {
    display: flex;
    width: var(--AvatarSizeLarge, 48px);
    height: var(--AvatarSizeLarge, 48px);
    padding: 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-secondary, #EAEDF1);
    box-sizing: border-box; 
}