.event-ticket-purchase-heading-wrapper .back-to-text-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-text-neutral-secondary);
    font: var(--body-small);
    margin-bottom: var(--spacing-16);
}
.event-ticket-purchase-heading-wrapper .back-to-text-link svg {
    flex-shrink: 0;
    margin-right: 8px;
}
.event-ticket-purchase-heading-wrapper .back-to-text-link svg path {
    fill: var(--color-text-neutral-secondary);
}
.event-ticket-purchase-heading-wrapper .event-ticket-purchase-heading-text {
    font: var(--title-large);
    color: var(--color-text-neutral-strong);
    margin: 0;
}
.event-ticket-purchase-heading-wrapper .event-ticket-purchase-heading-description {
    font: var(--body-medium);
    color: var(--color-text-neutral-secondary);
    margin-top: 16px;
}