* {
  box-sizing: border-box;
}

/* Colors */

:root {
  /* BG colors */
  --bg-screen-strong: #ffffff;
  --color-bg-elevated: #fff;
  --color-bg-static-white: #fff;
  --color-bg-static-white-hovered: #fff;
  --color-bg-static-white-pressed: #e8eaed;
  --color-bg-static-black: #0d0d0d;
  --color-bg-inverse-neutral: #041527;
  --color-bg-inverse-screen: #fff;
  --color-bg-neutral-strong: #041527;
  --color-bg-neutral-strong-hovered: #152232;
  --color-bg-neutral-strong-pressed: #253244;
  --color-bg-neutral-secondary: #eaedf1;
  --color-bg-neutral-secondary-hovered: #dce0e5;
  --color-bg-neutral-secondary-pressed: #ced3d9;
  --color-bg-neutral-tertiary: rgba(255, 255, 25, 0);
  --color-bg-neutral-tertiary-hovered: #eaedf1;
  --color-bg-neutral-tertiary-pressed: #dce0e5;
  --color-bg-brand-strong: #0B42CF;
  --color-bg-brand-strong-hovered: #0735ab;
  --color-bg-brand-strong-pressed: #052d93;
  --color-bg-brand-secondary: #dfedff;
  --color-bg-brand-secondary-hovered: #c7dfff;
  --color-bg-brand-secondary-pressed: #9ec6ff;
  --color-bg-accent-pink: #f73558;
  --color-bg-accent-yellow: #ffd71d;
  --color-bg-info-strong: #0d99ff;
  --color-bg-info-strong: #0d99ff;
  --color-bg-info-strong-pressed: #0768cf;
  --color-bg-info-secondary: #dbeefc;
  --color-bg-info-secondary-hovered: #bde3ff;
  --color-bg-info-secondary-pressed: #80caff;
  --color-bg-success-strong: #14ae5c;
  --color-bg-success-strong-hovered: #009951;
  --color-bg-success-strong-pressed: #008043;
  --color-bg-success-secondary: #cff7da;
  --color-bg-success-secondary-hovered: #aff3c6;
  --color-bg-success-secondary-pressed: #84e0a3;
  --color-bg-warning-strong: #FFAE3D;
  --color-bg-warning-strong-hovered: #F2941A;
  --color-bg-warning-strong-pressed: #DE7B12;
  --color-bg-warning-secondary: #FFE0C2;
  --color-bg-warning-secondary-hovered: #FCD19C;
  --color-bg-warning-secondary-pressed: #FFC36F;
  --color-bg-danger-strong: #F1343D;
  --color-bg-danger-strong-hovered: #DB1D26;
  --color-bg-danger-strong-pressed: #C90007;
  --color-bg-danger-secondary: #FCDDDF;
  --color-bg-danger-secondary-hovered: #FFC9CC;
  --color-bg-danger-secondary-pressed: #FF9EA3;
  --color-bg-disabled-strong: #dadde0;
  --color-bg-disabled-strong: #dadde0;
  --color-bg-blackout-strong: rgba(13, 13, 3, 0.8);
  --color-bg-blackout-secondary: rgba(13, 13, 3, 0.5);
  --color-bg-blackout-tertiary: rgba(13, 13, 3, 0.1);
  --color-bg-lightening-secondary: rgba(255, 255, 25, 0.2);
  --color-bg-lightening-secondary-hovered: rgba(255, 255, 255, 0.3);
  --color-bg-lightening-secondary-pressed: rgba(255, 255, 255, 0.4);
  --color-bg-chat-incoming: #eaedf1;
  --color-bg-chat-outgoing: #3870E0;

  /* Text */
  --color-text-static-white: #fff;
  --color-text-static-darkgray: #060d14;
  --color-text-inverse: #fff;
  --color-text-neutral-strong: #060d14;
  --color-text-neutral-strong-hovered: #060d14;
  --color-text-neutral-strong-pressed: #060d14;
  --color-text-neutral-secondary: #394046;
  --color-text-neutral-tertiary: #84898f;
  /* Color Added on 29-05-2024 */ /* Start */
  --color-text-neutral-onstrong-strong : #FFF;
  --color-text-neutral-onstrong-strong-hovered : #FFF;
  --color-text-neutral-onstrong-strong-pressed : #FFF;
  --color-text-neutral-onstrong-secondary : rgba(255, 255, 255, 0.80);
  --color-text-neutral-onstrong-tertiary : rgba(255, 255, 255, 0.70);
  --color-text-neutral-onsecondary-strong: #060D14;
  --color-text-neutral-onsecondary-strong-hovered: #060D14;
  --color-text-neutral-onsecondary-strong-pressed: #060D14;
  --color-text-neutral-onsecondary-secondary: #394046;
  --color-text-neutral-onsecondary-tertiary: #394046;
  /* Color Added on 29-05-2024 */ /* End */
  --color-text-brand-strong: #0B42CF;
  --color-text-brand-strong-pressed: #052d93;
  --color-text-brand-onstrong: #fff;
  --color-text-brand-onstrong-hovered: #fff;
  --color-text-brand-onstrong-pressed: #fff;
  --color-text-brand-onsecondary: rgba(255, 255, 255, 0.8);
  --color-text-brand-onsecondary-hovered: rgba(255, 255, 255, 0.8);
  --color-text-brand-onsecondary-pressed: rgba(255, 255, 255, 0.8);
  /* Color changed on 21-05-2024 */
  --color-text-info-strong: #007BE5;
  --color-text-info-strong-hovered: #007BE5;
  --color-text-info-strong-pressed: #007BE5;
  --color-text-info-onstrong: #fff;
  --color-text-info-onstrong-hovered: #fff;
  --color-text-info-onstrong-pressed: #fff;
  --color-text-info-onsecondary: #0768CF;
  --color-text-info-onsecondary-hovered: #0768CF;
  --color-text-info-onsecondary-pressed: #0768CF;
  --color-text-success-strong: #008043;
  --color-text-success-strong-hovered: #01562e;
  --color-text-success-strong-pressed: #00381e;
  --color-text-success-onstrong: #fff;
  --color-text-success-onstrong-hovered: #fff;
  --color-text-success-onstrong-pressed: #fff;
  --color-text-success-onsecondary: #060D14;
  --color-text-success-onsecondary-hovered: #01562E;
  --color-text-success-onsecondary-pressed: #01562E;
  --color-text-warning-strong: #de8212;
  --color-text-warning-strong-hovered: #984f06;
  --color-text-warning-strong-pressed: #6d3200;
  --color-text-warning-onstrong: #0d0d0d;
  --color-text-warning-onstrong-hovered: #0d0d0d;
  --color-text-warning-onstrong-pressed: #0d0d0d;
  --color-text-warning-onsecondary: #6D3200;
  --color-text-warning-onsecondary-hovered: #6D3200;
  --color-text-warning-onsecondary-pressed: #6D3200;
  --color-text-danger-strong: #C90007;
  --color-text-danger-strong-hovered: #c90007;
  --color-text-danger-strong-pressed: #C90007;
  --color-text-danger-onstrong: #fff;
  --color-text-danger-onstrong-hovered: #fff;
  --color-text-danger-onstrong-pressed: #fff;
  --color-text-danger-onsecondary: #810B0B;
  --color-text-danger-onsecondary-hovered: #810B0B;
  --color-text-danger-onsecondary-pressed: #810B0B;
  --color-text-disabled-strong: #84898f;
  --color-text-disabled-onstrong: #84898f;
  /* Added variant 09-08-2024 start */
  --color-text-link-strong: #0B42CF;
  /* Added variant 09-08-2024 end */
  /* Icons */
  --color-icon-static-white: #fff;
  --color-icon-static-darkgray: #060d14;
  --color-icon-inverse-tertiary: #fff;
  --color-icon-inverse-screen: #fff;
  --color-icon-neutral-strong: #060d14;
  --color-icon-neutral-secondary: #394046;
  --color-icon-neutral-tertiary: #84898f;
  --color-icon-brand-strong: #0b42cf;
  --color-icon-brand-strong-hovered: #0735ab;
  --color-icon-brand-strong-pressed: #052d93;
  --color-icon-brand-onstrong: #fff;
  --color-icon-brand-onstrong-hovered: #fff;
  --color-icon-brand-onstrong-pressed: #fff;
  --color-icon-brand-onsecondary: rgba(255, 255, 255, 0.8);
  --color-icon-brand-onsecondary-hovered: rgba(255, 255, 255, 0.8);
  --color-icon-brand-onsecondary-pressed: rgba(255, 255, 255, 0.8);
  --color-icon-accent-pink: #f73558;
  --color-icon-accent-yellow: #ffd71d;
  --color-icon-info-strong: #007be5;
  --color-icon-info-strong-hovered: #0768cf;
  --color-icon-info-strong-pressed: #0a4589;
  --color-icon-info-onstrong: #fff;
  --color-icon-info-onstrong-hovered: #fff;
  --color-icon-info-onstrong-pressed: #fff;
  --color-icon-info-onsecondary: rgba(255, 255, 255, 0.8);
  --color-icon-info-onsecondary-hovered: rgba(255, 255, 255, 0.8);
  --color-icon-info-strong-pressed: #0a4589;
  --color-icon-success-strong: #008043;
  --color-icon-success-strong-hovered: #01562e;
  --color-icon-success-strong-pressed: #00381e;
  --color-icon-onsuccess-strong: #fff;
  --color-icon-onsuccess-strong-hovered: #fff;
  --color-icon-onsuccess-strong-pressed: #fff;
  --color-icon-success-onsecondary: rgba(255, 255, 255, 0.8);
  --color-icon-success-onsecondary-hovered: rgba(255, 255, 255, 0.8);
  --color-icon-success-onsecondary-pressed: rgba(255, 255, 255, 0.8);
  --color-icon-warning-strong: #de8212;
  --color-icon-warning-strong-hovered: #984f06;
  --color-icon-warning-strong-pressed: #6d3200;
  --color-icon-warning-onstrong: #0d0d0d;
  --color-icon-warning-onstrong-hovered: #0d0d0d;
  --color-icon-warning-onstrong-pressed: #0d0d0d;
  --color-icon-warning-onsecondary: rgba(13, 13, 13, 0.8);
  --color-icon-warning-onsecondary-hovered: rgba(13, 13, 13, 0.8);
  --color-icon-warning-onsecondary-pressed: rgba(13, 13, 13, 0.8);
  --color-icon-danger-strong: #db1d26;
  --color-icon-danger-strong: #db1d26;
  --color-icon-danger-strong-pressed: #810b0b;
  --color-icon-danger-onstrong: #fff;
  --color-icon-danger-onstrong-hovered: #fff;
  --color-icon-danger-onstrong-pressed: #fff;
  --color-icon-danger-onsecondary: rgba(255, 255, 255, 0.8);
  --color-icon-danger-onsecondary-hovered: rgba(255, 255, 255, 0.8);
  --color-icon-danger-onsecondary-pressed: rgba(255, 255, 255, 0.8);
  --color-icon-disabled-strong: #84898f;
  --color-icon-disabled-onstrong: #84898f;

  /* Border */
  --color-border-static-white: #fff;
  --color-border-inverse-neutral: #0d0d0d;
  --color-border-inverse-screen: #fff;
  --color-border-neutral-strong: rgba(13, 13, 13, 0.3);
  --color-border-neutral-strong-hovered: rgba(13, 13, 13, 0.5);
  --color-border-neutral-strong-pressed: rgba(13, 13, 13, 0.7);
  --color-border-neutral-secondary: rgba(13, 13, 13, 0.2);
  --color-border-neutral-secondary-hovered: rgba(13, 13, 13, 0.5);
  --color-border-neutral-secondary-pressed: rgba(13, 13, 13, 0.7);
  --color-border-neutral-tertiary: rgba(13, 13, 13, 0.1);
  --color-border-neutral-tertiary-hovered: rgba(13, 13, 13, 0.3);
  --color-border-neutral-tertiary-pressed: rgba(13, 13, 13, 0.5);
  --color-border-danger-strong: #db1d26;
  --color-border-danger-strong-hovered: #c90007;
  --color-border-danger-strong-pressed: #810b0b;
  --color-border-disabled-strong: rgba(13, 13, 13, 0.1);

  /* Extra colors */
  --light-blue-900: #112748;
  --light-blue-300: #bde3ff;
  --light-blue-400: #80caff;
  --light-blue-500: #0d99ff;
  --slate-1000: #041527;
  --black-600: rgba(13, 13, 13, 0.6);


  /* ====================================== */

  /* spacing New - 10-01-2024 */
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-10: 10px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;

  /* ====================================== */

  /* Radius New - 10-01-2024 */
  --radius-4: 4px;
  --radius-6: 6px;
  --radius-8: 8px;
  --radius-10: 10px;
  --radius-12: 12px;
  --radius-14: 14px;
  --radius-16: 16px;
  --radius-full: 9999px;

  /* ====================================== */

  /* Title */
  --title-x-large: normal 700 48px/48px Figtree, sans-serif;
  --title-large: normal 700 36px/40px Figtree, sans-serif;
  /* Updated on 08-02-2024 */
  --title-medium: normal 700 26px/32px Figtree, sans-serif;
  /* Updated on 24-05-2024 */
  --title-small: normal 700 20px/28px Figtree, sans-serif;
  /* Updated on 08-02-2024 */
  --title-x-small: normal 700 16px/24px Figtree, sans-serif;
  /* Updated on 08-02-2024 */
  --title-2x-small: normal 700 14px/20px Figtree, sans-serif;
  --title-2: normal 700 22px/28px Figtree, sans-serif;
  /* Added 19-01-2024 */
  --title-title1: normal 700 24px/28px Figtree, sans-serif;

  /* Body */
  --body-2x-large: normal 400 20px/30px Inter, sans-serif;
  --body-2x-large-bold: normal 700 20px/30px Inter, sans-serif;
  --body-x-large: normal 400 18px/28px Inter, sans-serif;
  --body-x-large-bold: normal 700 18px/28px Inter, sans-serif;
  --body-large: normal 400 16px/24px Inter, sans-serif;
  --body-large-bold: normal 700 16px/24px Inter, sans-serif;
  --body-medium: normal 400 15px/24px Inter, sans-serif;
  --body-medium-bold: normal 700 15px/24px Inter, sans-serif;
  --body-small: normal 400 14px/20px Inter, sans-serif;
  --body-small-bold: normal 700 14px/20px Inter, sans-serif;
  --body-x-small: normal 400 13px/18px Inter, sans-serif;
  --body-x-small-bold: normal 700 13px/18px Inter, sans-serif;
  --body-x-small-bold-second: normal 700 12px/16px Inter, sans-serif;
  --body-2x-small: normal 400 12px/16px Inter, sans-serif;
  --body-2x-small-bold: normal 700 12px/16px Inter, sans-serif;

  /* Link */
  --link-2x-large: normal 700 20px/30px Inter, sans-serif;
  --link-x-large: normal 700 18px/28px Inter, sans-serif;
  --link-large: normal 700 16px/24px Inter, sans-serif;
  --link-medium: normal 700 15px/24px Inter, sans-serif;
  --link-small: normal 700 14px/20px Inter, sans-serif;
  --link-x-small: normal 700 13px/18px Inter, sans-serif;

  /* Controls */
  --controls-large: normal 400 16px/24px Figtree, sans-serif;
  --controls-large-bold: normal 700 16px/24px Figtree, sans-serif;
  --controls-medium: normal 400 15px/24px Figtree, sans-serif;
  --controls-medium-bold: normal 700 15px/24px Figtree, sans-serif;
  --controls-small: normal 400 14px/20px Figtree, sans-serif;
  --controls-small-bold: normal 700 14px/20px Figtree, sans-serif;
  --controls-x-small: normal 400 13px/16px Figtree, sans-serif;
  --controls-x-small-bold: normal 700 13px/16px Figtree, sans-serif;
  --controls-large-emphasized: normal 700 12px/16px Figtree, sans-serif;
  --controls-actionLabel: normal 500 11px/16px Figtree, sans-serif;

  /* Chats */
  --chat-reglular: normal 400 15px/20px Inter, sans-serif;
  --chat-emphasized: normal 700 15px/20px Inter, sans-serif;

  /* Shadow */
  --shadow-x-small: 0px 1px 2px 0px rgba(4, 21, 39, 0.3),
    0px 0px 1px 0px rgba(4, 21, 39, 0.4);
  --shadow-small: 0px 2px 6px 0px rgba(4, 21, 39, 0.2);
  --shadow-medium: 0px 2px 10px 0px rgba(4, 21, 39, 0.04),
    0px 4px 25px 0px rgba(4, 21, 39, 0.1);
  --shadow-large: 0px 10px 60px 0px rgba(4, 21, 39, 0.1);
}

.title-x-large {
  font: var(--title-x-large);
}

.body-medium {
  font: var(--body-medium);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.cursor-pointer {
  cursor: pointer;
}

.rounded-full {
  border-radius: var(--radius-full);
}
/* Start Scrollbar  */
.scrollbar {
  overflow: auto;
  /* this css for the making scrollbar in absolute view */
  /* scrollbar-gutter: stable both-edges;   */
}

.scrollbar::-webkit-scrollbar {
  width: 14px;
} 

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3);
  border-radius: var(--radius-x-small, 8px);
  border: 4px solid var(--color-border-inverse-screen);
  min-height: 64px; 
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(4, 21, 39, 0.3);
  cursor: pointer;
}
.scrollbar.no-border-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.scrollbar.no-border-scrollbar::-webkit-scrollbar-thumb {
  border: 0;
  background: var(--color-border-neutral-secondary);
  border-radius: 10px;
}
.scrollbar.no-border-scrollbar::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.chat-sec-inner.scrolling::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3) !important;
}
.chat-sec-inner::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.0) !important;
}


/* End Scrollbar  */