.chat-emojies.incoming .chat-message {
  background: transparent;
  /* padding: 0 0 0 var(--spacing-12); */
  padding: 0 0 0 var(--spacing-0);
  border-radius: 0;
}

.chat-emojies .chat-message-text-sec {
  padding: 0;
  align-items: flex-end;
}

.chat-emojies.incoming .chat-message .chat-message-text,
.chat-emojies.outgoing .chat-message .chat-message-text {
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.other-os-emoji {
  letter-spacing: -4px;
}

.mac-os-emoji {
  letter-spacing: 0px;
}

.chat-emojies.outgoing .chat-message {
  background: transparent;
  padding: 0 var(--spacing-0) 0 0;
  /* padding: 0 var(--spacing-12) 0 0; */
  border-radius: 0;
}

.chat-emojies .chat-reaction {
  margin-top: 0;
}

.chat-emojies .chat-message-text.one {
  font-size: 56px !important;
  line-height: 64px !important;
}

.chat-emojies .chat-message-text.mac-os-emoji.one {
  font-size: 56px !important;
  line-height: 64px !important;
}

.chat-emojies .chat-message-text.two {
  font-size: 50px !important;
  line-height: 56px !important;
}

.chat-emojies .chat-message-text.mac-os-emoji.two {
  font-size: 50px !important;
  line-height: 56px !important;
}

.chat-emojies .chat-message-text.three {
  font-size: 44px !important;
  line-height: 50px !important;
}

.chat-emojies .chat-message-text.mac-os-emoji.three {
  font-size: 44px !important;
  line-height: 50px !important;
}

.chat-emojies .chat-message-text.four {
  font-size: 38px !important;
  line-height: 44px !important;
}

.chat-emojies .chat-message-text.mac-os-emoji.four {
  font-size: 38px !important;
  line-height: 44px !important;
}

.outgoing .chat-message-container .chat-avtar-message {
  display: flex;
  justify-content: flex-end;
}

.outgoing .chat-message-container .chat-avtar-message .chat-body {
  width: auto;
}

.incoming.chat-emojies .chat-message-text-sec {
  align-items: flex-start;
}

.incoming .chat-message-container .chat-avtar-message {
  display: flex;
  justify-content: flex-start;
}

.chat-emojies .chat-body {
  width: auto;
}

.chat-emojies.outgoing .reaction-sec {
  padding-right: var(--spacing-4);
}