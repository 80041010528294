.event-medium-card-box {
  min-width: 280px;
  max-width: 440px;
  width: 100%;
}

.event-medium-card-box .event-medium-card-img {
  position: relative;
  display: flex;
}

.event-medium-card-box .event-medium-card-img .status-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}

.event-medium-card-box .event-medium-card-texts {
  padding: var(--spacing-12, 12px) var(--spacing-40, 40px)
    var(--spacing-12, 12px) 0px;
}

.event-medium-card-box .event-medium-card-texts p {
  margin: 0 0 4px;
}

.event-medium-card-box .event-medium-card-texts p:last-child {
  margin: 0;
}

.event-medium-card-box .event-medium-card-texts .event-medium-card-event-price,
.event-medium-card-box .event-medium-card-texts .event-medium-card-title {
  font: var(--body-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
}

.event-medium-card-box .event-medium-card-texts .event-medium-card-event-type,
.event-medium-card-box .event-medium-card-texts .event-medium-card-date {
  font: var(--body-small);
  color: var(--color-text-neutral-secondary);
}
.event-medium-card-box
  .event-medium-card-texts
  p.event-medium-card-event-price {
  margin-top: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
.event-medium-card-box
  .event-medium-card-texts
  p.event-medium-card-event-price
  .from-text {
  font-weight: 400;
  color: var(--color-text-neutral-secondary);
}
.event-medium-card-box .event-medium-card-texts .event-medium-card-dot {
  position: absolute;
  right: 2px;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-6);
  border-radius: var(--radius-full);
  background: var(--color-bg-neutral-tertiary-hovered);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.event-medium-card-box:hover .event-medium-card-texts .event-medium-card-dot {
  opacity: 1;
  visibility: visible;
}

.event-image-cursor {
  cursor: pointer;
}

.event-medium-card-image {
  /* border: 1px solid var(--color-bg-static-white-pressed); */
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);
}
  
.event-medium-card-image img {
  border-radius: 12px;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s;
  width: 100%;
}
