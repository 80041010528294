.chat-message-bubble-sec {
  width: 100%;
}

.chat-message-bubble-inner {
  gap: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-48) var(--spacing-2) var(--spacing-16);
  position: relative;
}

.chat-message-bubble-inner .highlight {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: var(--light-blue-500);
  opacity: 0.1;
  z-index: 1;
}

.chat-message-container {
  width: fit-content;
  display: flex;
  position: relative;
}

.chat-message-container.reaction-on {
  flex-direction: column;
  /* align-items: end; */
}

.chat-avtar-message {
  gap: var(--spacing-4);
  width: 100%;
}

.chat-reaction {
  width: -moz-fit-content;
  width: 100%;
  position: relative;
  margin-top: -6px;
  /* margin-left: 34px; */
  text-align: end;
  display: block;
  /* padding-left: var(--spacing-12); */
  padding-left: var(--spacing-32);
}

.incoming.chat-channel-group .chat-reaction {
  padding-left: 64px;
  /* padding-left: 18px */
}

.chat-message {
  max-width: 440px;
  position: relative;
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--radius-12) var(--radius-12) var(--radius-12) 0;
  width: -webkit-fill-available;
}

.chat-message.stacked {
  border-radius: var(--radius-12);
}

.chat-message-text-sec {
  padding: var(--spacing-2) 0;
  position: relative;
  gap: var(--spacing-6);
}

.chat-avtar-name {
  margin: 0;
  color: var(--color-text-neutral-strong);
  font: var(--body-x-small-bold);
}

.chat-message-text {
  margin: 0;
  color: var(--color-text-neutral-strong);
  font: var(--chat-reglular);
  word-wrap: break-word;
  white-space: pre-wrap;
  /* white-space: normal; */
}

.chat-body {
  width: 100%;
}

.extra-time {
  opacity: 0;
  float: right;
}

.chat-message-time {
  position: absolute;
  bottom: var(--spacing-8);
  right: var(--spacing-6);
}

.chat-actions {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.chatmessage-advanced {
  gap: var(--spacing-16);
  padding: var(--spacing-2) var(--spacing-4);
  width: 100%;
  z-index: 1;
}

/* Incoming message */
.incoming .chat-message {
  background: var(--color-bg-chat-incoming);
}

.incoming .chat-actions {
  right: -112px;
}

.stacked-item .chat-tail,
.stacked-item .chat-avtar {
  opacity: 0;
}

/* OutGoing */
.outgoing .chat-message {
  background: var(--color-bg-chat-outgoing);
}

.outgoing .chat-actions {
  left: -112px;
}

.outgoing .chat-message-text {
  color: var(--color-text-brand-onstrong);
}

.outgoing .chat-message-container {
  margin-left: auto;
}

.outgoing .chat-message {
  border-radius: var(--radius-12) var(--radius-12) 0 var(--radius-12);
}

.outgoing .chat-message.stacked {
  border-radius: var(--radius-12);
}

.outgoing .chat-tail {
  order: 2;
}

.chat-message-bubble-inner.outgoing {
  padding: var(--spacing-2) var(--spacing-8) var(--spacing-2) 100px;
}

/* Message Dropdown */
.chat-reaction .dropdown-list {
  width: 220px;
}

/* Emojies Reaction container  */
.chat-reaction-bubble {
  width: max-content;
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--radius-8);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-small);
  z-index: 2;
  top: calc(100% + 4px);
  left: 50%;
  gap: var(--spacing-4);
  transform: translate(-50%, 10%);
    z-index: 3;
}

.chat-reaction-bubble span {
  cursor: pointer;
  font-size: 28px;
  line-height: 40px;
  display: flex;
}

.mention-users {
  font: var(--chat-emphasized);
  color: var(--color-text-info-strong);
}

.outgoing .mention-users {
  color: var(--color-text-brand-onstrong);
}

/* Style for the "See more" and "See less" buttons */
.custom-show-more .show-more-anchor {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

/* Style for "See more and "See less" buttons on hover */
.custom-show-more .show-more-anchor:hover {
  /* color: blue;  */
  text-decoration: underline;
}