.sidebar-main-in {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cstn-cl-arrow {
  position: absolute;
  right: 12px;
}

.cstn-cl-arrow .mds-icons {
  display: block !important;
}

.sidebar-main {
  width: 248px;
  position: fixed;
  /* overflow: auto; */
  top: inherit;
  height: calc(100vh - 56px);
  background: var(--color-bg-static-white);
  z-index: 4;
  display: flex;
  /* height: 100%; */
  justify-content: space-between;
  flex-direction: column;
}

.sidebar-footer hr {
  margin: 0px !important;
}

#wrapper {
  overflow: hidden;
  transition: height 0.5s;
}

.sidebar-main::-webkit-scrollbar {
  width: 6px;
}

.sidebar-main::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary);
}

.sidebar-main::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary);
  border-radius: 10px;
}

.sidebar-main:hover::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.sidebar-main-ul {
  margin: 0px;
  padding: 12px;
  list-style: none;
}

.sidebar-link {
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px) var(--spacing-space-small, 12px);
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  position: relative;
  font: var(--body-medium);
  background: var(--color-bg-neutral-tertiary);
  color: var(--color-text-neutral-strong) !important;
  transition: 0.1s all;
}

.sidebar-link:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
  transition: all 0.1s ease-in-out;
}

.hovered {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.sidebar-link:active {
  background: var(--color-bg-neutral-tertiary-pressed);
}

/* Navbar is active */
.sidebar-link.active {
  background: var(--color-bg-neutral-secondary);
  transition: 0.3s all;
}

.sidebar-link.active:hover {
  background: var(--color-bg-neutral-secondary-hovered);
}

.sidebar-link.active:active {
  background: var(--color-bg-neutral-secondary-pressed);
}

.sidebar-link .mds-icons svg {
  fill: var(--color-icon-neutral-strong);
}

.sidebar-link.active .mds-icons {
  display: none;
}

.sidebar-link .mds-icons.filled {
  display: none;
}

.sidebar-link.active .mds-icons.filled {
  display: block;
}

.sidebar-label-name {
  margin-right: 4px;
}

.sidebar-link.active .sidebar-label-name {
  font: var(--body-medium-bold);
}

.sidebar-icon-active {
  display: none;
}

.sidebar-icon-img {
  margin-right: 24px;
  position: relative;
}
.sidebar-icon-img span.badge-notification-point {
  position: absolute;
  right: -3px;
} 
.sidebar-line {
  margin: 0px 24px;
  background: var(--color-border-neutral-tertiary);
  height: 1px;
  border: 0px;
  width: auto;
}

.sidebar-title {
  color: var(--color-text-neutral-strong, #060D14);
  font: var(--title-x-small);
  margin: 4px 12px 8px;
}

.sidebar-on-off {
  width: 6px;
  height: 6px;
  display: block;
  background: var(--color-bg-accent-pink);
  border-radius: 50px;
  position: absolute;
  right: 12px;
  top: 18px;
}

.sidebar-footer {
  margin: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sidebar-footer-link {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sidebar-footer-link li a {
  color: var(--color-text-neutral-secondary);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}

.sidebar-footer-link li a:hover {
  color: var(--color-text-brand-strong, #0B42CF);
}

.sidebar-copy {
  color: var(--color-text-neutral-tertiary, #84898F);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.category-slider-menu {
  position: fixed;
  background: var(--color-bg-blackout-secondary);
  width: 100%;
  top: 0;
  z-index: 9;
  left: 248px;
}

.category-slider-menu-in {
  background: var(--bg-screen-strong);
  width: 248px;
  border-left: 1px solid var(--color-border-neutral-tertiary);
}

.category-slider-close {
  height: 72px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.category-slider-close img {
  cursor: pointer;
}

.category-slider-menu-all {
  height: calc(100vh - 72px);
  overflow: auto;
}

.category-slider-menu-all::-webkit-scrollbar {
  width: 6px;
}

.category-slider-menu-all::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary);
}

.category-slider-menu-all::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary);
  border-radius: 10px;
}

.category-slider-menu-all:hover::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.cstm-menu-pp-top {
  align-items: center;
  display: flex;
  height: 56px;
  padding-left: 14px;
}

.stm-menu-pp-top-view {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  margin-right: 16px;
}

.stm-menu-pp-top-logo img {
  height: 28px;
  vertical-align: text-top;
}

.sidebar-menu-popup-main {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: menupp;
  animation-name: menupp;
  background: #0000 !important;
}

.sidebar-menu-popup-main.hide-menu {
  opacity: 0;
  visibility: hidden;
  transition: all .5s ease;
}

.sidebar-menu-popup-main.show-menu {
  opacity: 1;
  visibility: visible;
  transition: all .3s ease;
}

.sidebar-menu-popup {
  background: var(--color-bg-static-white);
  height: 100vh;
  width: 248px;
}

.sidebar-main-ul-sub {
  display: flex;
  width: 300px;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-elevated, #FFF);
  box-shadow: var(--shadow-small);
  list-style: none;
  /* display: none; */
  /* max-height: calc(100vh - 95px); */
  /* max-height: 600px; */
}

.sidebar-main-ul li {
  position: relative;
  width: 100%;
}

/* .sidebar-main-ul li:hover .sidebar-main-ul-sub {
  display: block; 
} */
.sidebar-main-ul-sub li a {
  display: flex;
  color: var(--color-text-neutral-strong, #060D14);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
  font: var(--body-medium);
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  gap: 4px;
  align-items: center;

  /* min-width: 120px; */
  /* gap: var(--spacing-12, 12px); */
  /* align-self: stretch; */
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 1; */
  /* flex: 1 0 0; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.sidebar-main-ul-sub li a:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.sidebar-main-ul-sub li a:active {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.sidebar-main-ul-sub li .active {
  background: var(--color-bg-neutral-secondary) !important;
}

.sidebar-main-all {
  position: absolute;
  left: 224px;
  top: -20px;
  padding: 20px 20px 20px 0px;
}
.sidebar-main-ul-sub{
  margin-top: 0px;
} 

/* .sidebar-main-all {
  position: absolute;
  left: 42px;
  top: 41px;
  padding-right: 20px;
  z-index: 1;
} */
.sidebar-main-all-loading {
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
}

.sidebar-main-all-loading-in {
  width: 100%;
  height: 20px;
  border-radius: 5px;
}

@media only screen and (max-width: 999px) {

  .sidebar-main-all {
    left: 0;
    z-index: 1;
    position: initial !important;
  }

  .sidebar-main-ul-sub {
    width: 218px;
    position: initial !important;
  }

  .sub-menu-open .cstn-cl-arrow {
    rotate: 90deg;
  }
}

@media only screen and (max-height : 600px){
  .sidebar-main-ul-sub{
    top: 70px!important;
    max-height: calc(100vh - 80px)!important;
  } 
}



/* Chat Icons */

.nav-beta-bedge-chat {
  background: #FFF;
  padding: 1px 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #351bce;
  color: #201e29;
  line-height: 1.5;
  margin-left: auto;
}