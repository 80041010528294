.badges-neutral.medium,
.badges-neutral.medium,
.badges-neutral.medium,
.badges-danger.medium {
  display: inline-block;
  max-height: 20px;
  min-width: 20px;
  padding: 2px 6px;
  border-radius: var(--AvatarSizeMedium, 50px);
  text-align: center;
  font: var(--body-x-small-bold);
  line-height: 16px;
}

.badges-danger.medium {
  background: var(--color-bg-accent-pink);
  color: var(--color-text-inverse, #fff);
}

.badges-neutral.medium {
  background: var(--color-bg-inverse-neutral);
  color: var(--color-text-inverse, #fff);
}

.badges-neutral.large,
.badges-danger.large {
  min-width: 24px;
  max-height: 24px;
  padding: 2px 8px;
  display: inline-block;
  border-radius: var(--AvatarSizeMedium, 40px);
  text-align: center;
  font: var(--body-x-small-bold);
  line-height: 20px;
}

.badges-neutral.large {
  background: var(--color-bg-inverse-neutral);
  color: var(--color-text-inverse, #fff);
}

.badges-danger.large {
  background: var(--color-bg-accent-pink);
  color: var(--color-text-inverse, #fff);
}

.filter-button .badges-danger,
.filter-button .badges-neutral {
  position: absolute;
  right: -2px;
  top: -2px;
}

/* Start badge css */
.status-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: var(--link-small);
  text-transform: capitalize;
}

.status-badge:hover {
  box-shadow: 0px 1px 2px 0px rgba(4, 21, 39, 0.3),
    0px 0px 1px 0px rgba(4, 21, 39, 0.4);
  cursor: pointer;
}

.status-medium {
  padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
}

.status-small {
  padding: 0px var(--spacing-4, 4px);
  border-radius: var(--radius-x-small, 4px) !important;
}

.status-radius-small {
  border-radius: var(--radius-small, 8px);
}

.status-radius-full {
  border-radius: var(--radius-full, 120px);
}

.status-badge.accent-yellow {
  background: var(--color-bg-accent-yellow, #ffd71d);
  color: var(--color-text-static-darkgray, #060d14);
}

.status-badge.danger {
  background: var(--color-bg-danger-strong, #f1343d);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.warning {
  background: var(--color-bg-warning-strong, #fac505);
  color: var(--color-text-static-darkgray, #060d14);
}

.status-badge.success {
  background: var(--color-bg-success-strong, #14ae5c);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.success {
  background: var(--color-bg-success-strong, #14ae5c);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.accent-pink {
  background: var(--color-bg-accent-pink, #f73558);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.brand {
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.neutral {
  background: var(--color-bg-neutral-strong);
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.blackout {
  background: var(--black-600, rgba(13, 13, 13, 0.6));
  color: var(--color-text-danger-onstrong, #fff);
}

.status-badge.white {
  background: var(--color-bg-static-white, #fff);
  color: var(--color-text-static-darkgray, #060d14);
}

/* End badge css */

.online-status {
  border-radius: 50px;
}

.online {
  background-color: var(--color-bg-success-strong, #14ae5c);
}

.offline {
  background-color: #dadde0;
}

.online-status.small {
  width: 10px;
  height: 10px;
  border: 2px solid var(--color-bg-static-white, #fff);
}

.online-status.medium {
  width: 12px;
  height: 12px;
  border: 2px solid var(--color-bg-static-white, #fff);
}

.online-status.large {
  width: 18px;
  height: 18px;
  border: 3px solid var(--color-bg-static-white, #fff);
}

.badge-notification-point {
  background: var(--color-bg-accent-pink, #f73558);
  width: 12px;
  height: 12px;
  border-radius: 50px;
  display: flex;
  border: 2px solid var(--color-bg-static-white);
}
