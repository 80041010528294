.messagebar-sec {
    padding: 0 var(--spacing-16) var(--spacing-16) var(--spacing-16);
}

.messagebar-container {

    border-radius: var(--radius-12);
    box-shadow: var(--shadow-x-small);
}

.messagebar-typing-container {
    padding: var(--spacing-12) 0 0 var(--spacing-16);
}

.messagebar-actions-container {
    padding: 0 var(--spacing-10) var(--spacing-8);
}

.messagebar-reply-container {
    padding: var(--spacing-16) var(--spacing-10) var(--spacing-16) var(--spacing-16);
    border-bottom: 1px solid var(--color-border-neutral-tertiary);
}

.messagebar-attachment-container {
    padding: var(--spacing-16) var(--spacing-10) 0 var(--spacing-16);
}

.messagebar-voice-inner {
    padding: var(--spacing-8) var(--spacing-10);
}

.messagebar-voice-inner .MessageVoiceMessage-content {
    width: 100%;
}

.ql-mention-list-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: var(--shadow-small);
    width: 304px;
    max-height: 264px;
    overflow: auto;
    z-index: 2;
}

.ql-mention-list-container::-webkit-scrollbar {
    width: 14px;
}

.ql-mention-list-container::-webkit-scrollbar-thumb {
    background: rgba(4, 21, 39, 0.3);
    border-radius: var(--radius-x-small, 8px);
    border: 4px solid var(--color-border-inverse-screen);
    min-height: 64px;
}

.ql-mention-list-container::-webkit-scrollbar-thumb:hover {
    background: rgba(4, 21, 39, 0.3);
    cursor: pointer;
}

.ql-mention-list-container .ql-mention-list {
    list-style: none;
    padding: 8px 0;
    margin: 0;
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item {
    padding: 8px 16px;
    background-color: transparent;
    transition: all .5s ease;
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item:hover {
    background-color: #EAEDF1;
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item .mention-user {
    display: flex;
    align-items: center;
    word-break: break-word;
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item .mention-user .profile-image,
.ql-mention-list-container .ql-mention-list .ql-mention-list-item .mention-user .rounded-user-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    background-color: #1e1e1e;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item.selected {
    background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.ql-mention-list-container .ql-mention-list .ql-mention-list-item .mention-user .profile-image {
    object-fit: cover;
    object-position: center;
}