.empty-state {
    display: flex;
    width: 100%;
    max-width: 648px;
    padding: var(--spacing-48, 48px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-24, 24px);
    margin: auto;
}
.empty-state-icon{
    height: 120px;
    width: 100%;
    text-align: center;
}
.empty-state-icon img{
    height: 120px;
    width: 120px;
    object-fit:cover; 
}
.empty-state-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.empty-state-info-title{
    max-width: 480px;
    width: 100%;
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--title-small);
    margin: 0px;
}
.empty-state-info-p{
    max-width: 480px;
    width: 100%;
    color: var(--color-text-neutral-secondary, #394046);  
    text-align: center;
    margin: 0px;
    font:var(--body-small);  
}
.empty-emoji-state .empty-state-info .empty-state-info-title:empty {
    display: none;
}
.empty-emoji-state .empty-state-icon {
    height: auto;
}
.empty-emoji-state .empty-state-icon .emoji-img {
    width: 96px;
    height: 96px;
}