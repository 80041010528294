.model-header-popup {
  display: flex;
  width: 100%;
  padding: var(--spacing-12, 12px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
  justify-content: space-between;
  border-radius: 12px 12px 0px 0px;
}

.model-header-content {
  padding: 0px var(--spacing-12);
}

.model-header-popup h4 {
  color: var(--color-text-static-darkgray);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.model-counts {
  gap: var(--spacing-2);
}

.model-header-popup span {
  font: var(--body-x-small);
  color: var(--color-text-neutral-tertiary);
}
