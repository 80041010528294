.event-detail-page {
  padding-top: 64px;
}
.event-container {
  margin: auto;
  max-width: 1120px;
  width: 100%;
  padding: 0 15px;
}
.event-detail-head {
  background: radial-gradient(
      1361.06% 50% at 50% 50%,
      rgba(13, 13, 13, 0) 7%,
      rgb(13 13 13 / 60%) 100%
    )
    #0d0d0dcc;
  background-color: #1e1e1f;
  padding: var(--spacing-48) 0;
}
.event-detail-head .event-detail-head-wrapper {
  display: flex;
  align-items: flex-start;
}
.event-detail-head .event-detail-head-wrapper .event-thumb-img {
  flex-shrink: 0;
  margin-right: 40px;
  max-width: 288px;
}
.event-detail-head .event-detail-head-wrapper h1 {
  font: var(--title-large);
  color: var(--color-bg-static-white);
  margin-bottom: 4px;
}
.event-detail-head .event-detail-head-wrapper p {
  font: var(--body-large);
  color: var(--color-bg-static-white);
}
.event-detail-head .event-detail-head-wrapper .btn-wrapper {
  margin-top: var(--spacing-32);
  display: flex;
  gap: 12px;
}
.event-detail-head .event-detail-head-wrapper .btn-wrapper .tonal {
  background-color: var(--color-bg-lightening-secondary);
}
.event-detail-head .event-detail-head-wrapper .btn-wrapper .tonal:hover {
  background-color: var(--color-bg-lightening-secondary-hovered);
}
.event-detail-head .event-detail-head-wrapper .btn-wrapper .tonal svg path {
  fill: var(--color-bg-static-white);
}
.event-details-wrapper .event-details .event-organizer-heading {
  display: flex;
  align-items: center;
  padding: var(--spacing-40) 0;
}
.event-details-wrapper .event-details .event-organizer-heading svg {
  flex-shrink: 0;
  margin-right: var(--spacing-16);
}
.event-details-wrapper .event-details .event-organizer-heading h3 {
  font: var(--body-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
}
.event-details-wrapper .event-details .text-wrapper {
  padding-top: var(--spacing-48);
}
.event-details-wrapper .event-details .text-wrapper h2 {
  margin-bottom: var(--spacing-24);
  font: var(--title-medium);
  color: var(--color-text-neutral-strong);
}
.event-details-wrapper .event-details .text-wrapper p {
  font: var(--body-medium);
}
.event-details-wrapper .event-details .text-wrapper .topic-text {
  padding: var(--spacing-20) 0;
  margin-top: var(--spacing-20);
}
.event-details-wrapper .event-details .text-wrapper .faq-wrapper h3 {
  font: var(--title-small);
  padding: var(--spacing-20) 0;
  color: var(--color-text-neutral-strong);
}
.event-details-wrapper
  .event-details
  .text-wrapper
  .faq-wrapper
  .faq-que-ans-item {
  padding-bottom: var(--spacing-20);
}
.event-details-wrapper
  .event-details
  .text-wrapper
  .faq-wrapper
  .faq-que-ans-item
  .faq-que {
  font: var(--body-medium-bold);
  color: var(--color-text-neutral-strong);
}
.list-number {
  list-style: decimal;
  margin-bottom: var(--spacing-32);
  padding-left: 20px;
}

.event-details-flexbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 100px;
}
.event-sticky-card-wrapper {
  flex-shrink: 0;
  max-width: 392px;
  width: 100%;
  padding: var(--spacing-48) 0;
  height: 100%;
  position: sticky;
  top: 20px;
}
.event-sticky-card-wrapper .event-sticky-card {
  border-radius: var(--radius-12, 12px);
  background: var(--color-bg-elevated, #fff);
  /* shadow-large */
  box-shadow: var(--shadow-large);
}
.event-sticky-card-wrapper .event-sticky-card .btn-wrapper,
.event-sticky-card-wrapper .event-sticky-card .event-details {
  padding: var(--spacing-24);
}

.event-details {
  width: 100%;
  max-width: 648px;
}

.event-sticky-card-wrapper .event-sticky-card h3 {
  font: var(--body-x-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
  padding-bottom: var(--spacing-4);
}
.event-sticky-card-wrapper .event-sticky-card .event-type-text {
  font: var(--body-small);
  color: var(--color-text-neutral-secondary);
}

.cstm-event-page-banner-loading {
  height: 380px;
  width: 100%;
  border-radius: 12px;
  margin: auto;
  display: block;
}

.mds-logo-loading {
  height: 50px;
  width: 100%;
}

.event-details-wrapper-loader {
  margin: 20px 80px;
}

.event-mds-logo-loader {
  margin: 20px;
  height: 80px;
  width: 50%;
  margin-left: 250px;
}

.event-details-description-loader {
  margin: -4px;
  height: 500px;
  width: 50%;
  margin-left: 250px;
}

.price-tag-loader {
  margin-top: -75px;
  height: 200px;
  width: 25%;
  margin-left: 54px;
  margin-right: -28px;
  position: absolute;
  border-radius: 12px;
}
