.chat-floating-button-primary {
    display: inline-flex;
    padding: var(--spacing-16, 16px);
    align-items: flex-start;
    border-radius: var(--radius-full, 999px);
    background: var(--color-bg-brand-strong, #0B42CF);
    box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04), 0px 4px 25px 0px rgba(4, 21, 39, 0.10);
    cursor: pointer;
    border: none;
}
.chat-floating-button-primary:hover {
    background: var(--color-bg-brand-strong-hovered, #0735AB);
}
.chat-floating-button-primary:active {
    background: var(--color-bg-brand-strong-pressed, #052D93);
    box-shadow: none;
}