.chat-message-type {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    align-content: center;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-small);
}

.chat-message-type-text,
.chat-message-type-file {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-small);
    width: 100%;
    word-break: break-all;
}

.chat-message-type-file svg {
    margin-right: var(--spacing-4);
    margin-bottom: var(--spacing-2);
}

/* .chat-message-type-file {
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
} */

/* 
.chat-message-type-file{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;    
    text-overflow: ellipsis;
    word-break: break-all;
} */
.chat-message-type-receivername {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-small);
    word-break: break-all;
}