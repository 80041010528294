.event-summary-ticket-item {
    display: flex;
    align-items: center;
}
.event-summary-ticket-item .event-summary-ticket-image {
    margin-right: var(--spacing-16, 16px);
    max-width: 96px;
}
.event-summary-ticket-item .event-summary-ticket-text-wrapper {
    width: 100%;
}
.event-summary-ticket-item .event-summary-ticket-text-wrapper .event-summary-ticket-title-wrapper {
    justify-content: space-between;
    margin: 0 0 4px;
}
.event-summary-ticket-item .event-summary-ticket-text-wrapper .event-summary-ticket-title-wrapper .event-summary-ticket-title {
    font: var(--body-small);
    font-weight: 600;
    color: var(--color-text-neutral-strong);
}
.event-summary-ticket-item .event-summary-ticket-text-wrapper .event-summary-ticket-title-wrapper .remove-btn {
    font: var(--body-medium);
    color: var(--color-text-neutral-strong);
    text-decoration-line: underline;
    text-underline-offset: 3px;
    margin-left: var(--spacing-8);
}
.event-summary-ticket-item .event-summary-ticket-text-wrapper .event-summary-ticket-amount {
    font: var(--body-small);
    color: var(--color-text-neutral-secondary);
    margin: 0;
}