.header-ft {
  display: flex;
  padding: 0px var(--spacing-24, 24px);
  /* padding: 0px var(--spacing-24, 24px) 0px var(--spacing-16, 16px); */
  padding: 0px var(--spacing-24, 24px);
  /* padding: 0px var(--spacing-24, 24px) 0px var(--spacing-16, 16px); */
  justify-content: space-between;
  background: var(--color-bg-screen);
  align-items: center;
  align-self: stretch;
  /* height: 56px; */
  position: fixed;
  width: 100%;
  z-index: 5;
  background: var(--color-bg-static-white);
}

.header-ft-logo {
  display: flex;
  padding: var(--spacing-8) 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.header-ft-element-sec {
  display: flex;
  padding: var(--spacing-12, 12px) 0px;
  align-items: center;
  gap: var(--spacing-8, 8px);
}
 
.cstm-menu-mobile {
  margin-left: -8px;
}

.header-ft-vn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-ft-vn:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-hovered);
}

.header-ft-vn:active {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-pressed);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-ft-vn:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-hovered);
}

.header-ft-vn:active {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-pressed);
}

.header-ft-view-nav {
  cursor: pointer;
  border-radius: 50px;
  display: flex;
}
.header-ft-icon {
  position: relative;
}
.header-ft-icon .badge-notification-point {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
}


.red-on {
  background: var(--color-bg-accent-pink);
  height: 12px;
  width: 12px;
  display: inherit;
  position: absolute;
  border-radius: 50px;
  top: 2px;
  right: 2px;
  border: 2px solid var(--color-bg-static-white);
}



 
.header-ft-menu-icon {
  cursor: pointer;
  position: relative;
}

.chat-photo-mn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chat-photo-mn:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-hovered);
}

.chat-photo-mn:active {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-pressed);
}

.chat-photo-mn:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-hovered);
}

.chat-photo-mn:active {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-pressed);
}

.header-ft-menu-user {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: var(--spacing-8);
}

.header-ft-photo-user {
  position: relative;
  display: flex;
}

.header-ft-photo-user {
  position: relative;
  display: flex;
}

.header-ft-photo-user img {
  height: 32px;
  width: 32px;
  min-width: 32px;
  object-fit: cover;
  border-radius: 50px;
}

.header-ft-menu-user-main {
  order: 1;
}

.chat-on-off {
  border-radius: 50px;
  border: 2px solid var(--color-bg-static-white);
  border: 2px solid var(--color-bg-static-white);
  height: 10px;
  width: 10px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  bottom: -1px;
  right: -1px;
}

.dropdown-menu.header-menu-dp {
  order: 2;
  margin-left: -200px;
}

.header-ft-menu-view.show .mn-close {
  display: block;
}

.header-ft-menu-view.show .mn-open {
  display: none;
}

.header-menu-dp {
  background: #FFFFFF;
  border: 1px solid rgba(32, 30, 41, 0.1);
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 240px;
  padding: 0px;
  margin-top: 8px;
}

.header-menu-dp ul {
  margin: 0px;
  padding: 0px 0px 6px 0px;
  list-style: none;
}

.header-menu-dp ul li a {
  display: flex;
  padding: 8px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #201E29;
  margin: 0px 8px;
  border-radius: 10px;
}

.header-menu-dp.footer-menu ul li a {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #091F48;
  padding: 12px 16px;
}

.header-menu-dp ul li a:hover {
  background-color: #F3F5F7;
}

.header-menu-dp ul li a img {
  margin-right: 17px;
}

.header-menu-dp ul li a svg {
  margin-right: 17px;
}
.header-menu-dp ul li:last-child {
  border-bottom: none;
}

.header-ft-search-search-down {
  top: 50px;
  position: absolute;
  background: #FFFFFF;
  border: 1px solid rgba(32, 30, 41, 0.2);
  box-shadow: 0px 4px 16px rgb(154 154 154 / 25%);
  border-radius: 15px;
  max-width: 476px;
  width: 100%;
  overflow: hidden;
}

.header-ft-search-search-down ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.header-ft-search-search-down ul li {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091F48;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.header-ft-search-search-down ul li img {
  margin-right: 8px;
}

.header-ft-search-search-down ul li:hover {
  background: #F3F5F7;
  cursor: pointer;
}

.header-menu-icon {
  height: 40px;
  width: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-ft-name-user:after {
  background-image: url(../Images/arrowdown.svg);
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  top: 6px;
  right: 12px;
}

.header-ft-name-user {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201E29;
  position: relative;
  padding-right: 35px;
}

.header-ft-menu {
  display: flex;
  align-items: center;
}

.cstm-profile-info {
  padding: 20px 20px 16px 20px;
  border-bottom: 1px solid rgba(32, 30, 41, 0.1);
  text-align: center;
  margin-bottom: 6px;
}

.cstm-profile-info-user {
  margin-bottom: 16px;
}

.cstm-profile-info-user img {
  width: 72px;
  height: 72px;
  border: 2px solid #FFFFFF !important;
  object-fit: cover;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.1));
  border-radius: 400px;
}

.cstm-profile-info-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #201E29;
  word-break: break-word;
}

.cstm-hd-as {
  display: none;
}

.cstm-pt-link {
  border-top: 1px solid rgba(32, 30, 41, 0.1);
  text-align: center;
  margin-top: 7px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.cstm-pt-link a {
  margin: 0px !important;
}


.chat-desk {
  position: relative;
}

.header-cont-lib .header-inner-ft .header-ft-element-sec {
  justify-content: end;
}




.header-ft-search {
  background-color: #f4f4f4;
  height: 48px;
  max-width: 476px;
  display: flex;
  width: 100%;
  border-radius: 15px;
  align-items: center;
  padding: 12px 0px;
  position: relative;
}


.cstm-menu-responsive {
  display: none !important;
}

body.menuview .header-ft-view-nav {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.header-search{
  width: 480px;
  height: 40px;
}

.header-search .text-field-search-main {
max-width: 100%;
}

.header-search .text-field-search{
  width: 480px;
}

.header-search .dropdown-list{
  width: 480px;
  max-height: 350px;
}

.header-ft.video-header-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.header-ft.video-header-grid .header-ft-logo {
  justify-content: start;
}

.header-ft.video-header-grid .header-ft-element-sec {
  justify-content: end;
}
  
.header-ft.video-header-grid .header-search {
  margin: auto;
}

@media only screen and (min-width: 999px) {
  .header-ft-view-nav {
    display: none;
  }

}

@media (max-width:767.98px) {
  .header-ft {
    height: 54px;
  }
}