.preview-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-search-suggestion {
  max-width: 400px;
  width: 100%;
}

.doc-download-progress {
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 9999;
}

/* Start Document page css */
.doc-management-main {
  width: 100%;
  /* max-width: 1192px; */
  /* padding: 0px 24px 12px; */
  /* padding: 12px 32px; */
  margin: auto;
}

.doc-management-main-banner {
  display: flex;
  padding: 32px 40px;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 120px;
  align-self: stretch;
  border-radius: var(--radius-medium, 12px);
  background: url(../../../Images/docs-banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin: var(--spacing-12) var(--spacing-24) var(--spacing-24);
}

.doc-management-main-banner-detials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.doc-management-main-banner-detials h4 {
  color: var(--color-text-static-white, #fff);
}

.wereon-sub-title {
  margin: 0px;
  overflow: hidden;
  color: var(--color-text-static-white, #fff);
  text-overflow: ellipsis;
  font: var(--body-small);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
}

.doc-management-header-main {
  display: flex;
  width: 100%;
  /* padding-top: 24px; */
  padding: var(--spacing-12) var(--spacing-24) 0;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  position: sticky;
  top: 55px;
  background: var(--color-text-brand-onstrong, #fff);
  z-index: 2;
}

.doc-management-header-main-top {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
}

.doc-management-header-main-top h2 {
  color: var(--color-text-neutral-strong, #060d14);
}

.doc-management-header-main-bottom {
  display: flex;
  padding: var(--spacing-8, 8px) 0px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

.doc-management-header-main-bottom-right {
  display: flex;
  align-items: center;
  gap: var(--spacing-24, 24px);
}

.doc-management-header-main-bottom-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-24, 24px);
}

.doc-management-chips {
  display: flex;
  align-items: center;
  gap: 8px;
}

.more-results {
  display: flex;
  padding-top: var(--spacing-32, 32px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.doc-table-tr-loading {
  width: 100%;
  height: 72px;
  margin-bottom: 12px;
  border-radius: 8px;
}

/* End Document page css */

.doc-modal-only-right {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-text-static-white, #fff);
}

.doc-modal-tabs {
  padding: 8px 20px 0px 20px;
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.doc-modal-tabs .docs-menu-section li {
  width: 100%;
}

.doc-modal-tabs .docs-menu-section li a {
  width: 100%;
  text-align: center;
}

.doc-modal-tabs1 {
  width: 100%;
  height: 100%;
}

.doc-modal-tabs2 {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.doc-modal-tabs2::-webkit-scrollbar {
  width: 5px;
}

.doc-modal-tabs2::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-secondary);
}

.doc-modal-tabs2::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-bg-neutral-secondary-pressed);
}

.clear-all-filters-btn {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong);
  padding: 12px 24px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.clear-all-filters-btn:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
  color: var(--color-text-neutral-strong, #060d14);
}

.doc-modal-info-cont-main {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
  height: calc(100vh - 307px);
}

.doc-modal-info-cont-main::-webkit-scrollbar {
  width: 5px;
}

.doc-modal-info-cont-main::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-secondary);
}

.doc-modal-info-cont-main::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-bg-neutral-secondary-pressed);
}

.doc-modal-info-cont {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
}

.doc-modal-info-cont-photo img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
}

.doc-modal-info-cont-detail-head {
  display: flex;
  align-items: center;
  gap: var(--spacing-8, 8px);
  align-self: stretch;
}

.doc-modal-info-cont-detail-head h5 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-x-small);
  margin: 0px;
}

.doc-modal-info-cont-detail-head h6 {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-x-small);
  margin: 0px;
}

.doc-modal-info-cont-detail-text {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-medium);
}

.doc-modal-info-cont-detail-text a {
  color: var(--color-text-info-strong, #007be5);
  font: var(--body-medium);
}

/* Start Custom date range */
.custom-date-range {
  display: flex;
  width: 100%;
  padding: var(--spacing-20, 20px);
  gap: var(--spacing-16, 16px);
}

.custom-date-range input {
  border-radius: var(--radius-small, 8px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--body-medium);
  box-sizing: border-box;
  color: var(--color-text-neutral-tertiary, #84898f);
}

.custom-date-range .react-datepicker__day--selected {
  background-color: var(--color-bg-brand-strong) !important;
}

.custom-date-range .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--color-bg-brand-strong) !important;
  color: var(--color-text-static-white);
}

/* End Custom date range */

/* Start Filter Sidebar Partners */
.filter-sidebar-partners {
  max-width: 400px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* End Filter Sidebar Partners */

/* Start Filter Sidebar Header */
.filter-sidebar-header {
  display: flex;
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  padding: var(--spacing-12, 12px) var(--spacing-8, 8px)
    var(--spacing-12, 12px) var(--spacing-20, 20px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
}

.filter-sidebar-header button.button {
  padding: var(--spacing-space-2-x-small, 8px);
}

.filter-sidebar-header-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  /* font: var(--title-medium); */
  letter-spacing: -0.24px;
  word-wrap: break-word;
  margin: 0px;
}

.filter-sidebar-header-second {
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  padding: var(--spacing-12, 12px) var(--spacing-8, 8px);
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-16, 16px);
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
}

.filter-sidebar-header-second button.button {
  padding: var(--spacing-space-2-x-small, 8px);
}

.filter-sidebar-header-title-center {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  text-overflow: ellipsis;
  margin: 0px;
}

.filter-sidebar-search {
  display: flex;
  padding: 24px 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-20, 20px);
  align-self: stretch;
  background: var(--color-bg-elevated, #fff);
}

/* End Filter Sidebar Header */

/*  start Filter Sidebar Navigation */
.filter-sidebar-body {
  background: var(--color-bg-elevated, #fff);
  flex-grow: 1;
}

.filter-sidebar-navigation {
  display: flex;
  width: 100%;
  max-width: 400px;
  height: 56px;
  padding: var(--spacing-16, 16px) var(--spacing-16, 16px)
    var(--spacing-16, 16px) var(--spacing-20, 20px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  flex-shrink: 0;
  border: none;
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  cursor: pointer;
}

.filter-sidebar-navigation:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.filter-sidebar-navigation:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.filter-sidebar-navigation-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-12, 12px);
  flex: 1 0 0;
  font: var(--body-small);
  font-weight: 600;
}

.filter-icon-svg {
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
}

.filter-icon-svg svg {
  margin-left: 5px;
}

.fs-font-title {
  font-weight: 400 !important;
}

/*  End Filter Sidebar Navigation */

/*  Start Filter Sidebar Footer*/
.filter-sidebar-footer {
  background: var(--color-bg-elevated, #fff);
  padding: var(--spacing-16, 16px) var(--spacing-20, 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
}

/*  End Filter Sidebar Footer*/

/* Start Media Preview Sidebar Comment */

.media-preview-sidebar-comments {
  display: flex;
  max-width: 400px;
  width: 100%;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
}

.mps-comments-post {
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-20, 20px);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
}

.mps-comments-post-in {
  display: flex;
  padding-right: var(--spacing-24, 24px);
  align-items: flex-start;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  box-sizing: border-box;
  position: relative;
}

.mps-comments-post-in-avatar {
  display: flex;
  width: var(--AvatarSizeMedium, 40px);
  height: var(--AvatarSizeMedium, 40px);
  justify-content: center;
  align-items: center;
}

.comments-dots-button {
  position: absolute;
  right: -12px;
  top: -6px;
}

.mps-comments-post-name-time {
  display: flex;
  align-items: center;
  gap: var(--spacing-8, 8px);
  align-self: stretch;
}

.mps-comments-post-name-time h5 {
  display: -webkit-box;
  max-width: 112px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font: var(--title-x-small);
  margin: 0px;
}

.mps-comments-post-name-time h6 {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-x-small);
  margin: 0px;
}

.mps-comments-post-text {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
}

.mps-comments-post-actions {
  display: flex;
  height: 44px;
  align-items: center;
  gap: 16px;
}

.mps-comments-post-actions-in {
  display: flex;
  align-items: center;
  gap: var(--spacing-4, 4px);
  margin-left: -12px;
}

.collapse-replays-button {
  display: inline-flex;
  height: 36px;
  padding: var(--spacing-6, 6px) var(--spacing-12, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-2, 2px);
  flex-shrink: 0;
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  border: none;
  color: var(--color-text-info-strong, #007be5);
  font: var(--body-medium-bold);
  cursor: pointer;
}

.collapse-replays-button:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.collapse-replays-button:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.collapse-replays-button:focus {
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d);
  outline-offset: 2px;
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
}

.mps-comments-post-reply {
  padding-left: 76px;
}

/* End Media Preview Sidebar Comment */

/* Start Docs Cover Card*/

.card-cover {
  background: var(--gray-800, #394046);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.card-cover .status-badge {
  position: absolute;
  right: 12px;
  top: 12px;
}

.card-cover .card-cover-icon {
  position: absolute;
  left: 8px;
  top: 12px;
}

.card-cover .card-cover-icon {
  position: absolute;
  left: 8px;
  top: 12px;
}

.card-cover-img {
  max-height: 100%;
  max-width: 100%;
}

.card-cover.medium {
  max-width: 480px;
  min-width: 225px;
  height: 270px;
  border-radius: var(--radius-medium, 12px);
}

.card-cover.small {
  max-width: 224px;
  min-width: 121px;
  height: 126px;
  border-radius: var(--radius-small, 8px);
}

.card-cover.xsmall {
  max-width: 120px;
  min-width: 94px;
  height: 68px;
  border-radius: var(--radius-x-small, 4px);
}

.card-cover.small .status-badge {
  display: none;
}

.card-cover.xsmall .status-badge {
  display: none;
}

.card-cover.small .card-cover-icon {
  display: none;
}

.card-cover.xsmall .card-cover-icon {
  display: none;
}

/* End Docs Cover Card*/

/* Starr Document Collection*/

.document-collection-files {
  position: absolute;
  bottom: 0px;
  background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.8));
  display: flex;
  width: 100%;
  padding: 12px 16px 12px 12px;
  box-sizing: border-box;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.document-collection-files-text {
  margin: 0px;
  color: var(--color-text-static-white, #fff);
  font: var(--body-small);
}

.card-cover.small .document-collection-files {
  display: none;
}

.card-cover.xsmall .document-collection-files {
  display: none;
}

/* End Document Collection*/

/* Share popup */

.documet-share-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 21px;
  padding: 48px;
  width: 100%;
  position: relative;
}
.documet-share-field {
  width: 100%;
  position: relative;
}
.documet-share-field .text-field-main {
  max-width: 100%;
}
.documet-share-field-copy {
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
  z-index: 1;
  color: var(--color-bg-brand-strong);
}
.documet-share-field-copy {
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
  z-index: 1;
  color: var(--color-bg-brand-strong);
}
.documet-share-field-copy:hover {
  color: var(--color-bg-static-black);
}

.documet-share-popup button {
  position: absolute;
  top: 12px;
  right: 12px;
}

/* Start Preview not available */
.preview-not-available-main {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}

.preview-not-available {
  display: flex;
  width: 400px;
  padding: var(--spacing-48, 48px) var(--spacing-32, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-24, 24px);
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04),
    0px 4px 25px 0px rgba(4, 21, 39, 0.1);
  margin: -20px auto 0px;
}

.preview-not-available-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.preview-block-btns-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.preview-not-available-footer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.preview-not-available-body h4 {
  color: var(--color-text-neutral-strong, #060d14);
}

.you-can-downlod-text {
  color: var(--color-text-neutral-strong, #060d14);
  margin: 0px;
  font: var(--body-x-small);
}

/* End Preview not available */

/* document tooltip  */

.document-name-tooltip {
  position: relative;
}
/* .document-name-tooltip  .mds-tooltip {
    position: absolute;
    margin: 39px 0px 0px 50px;
    z-index: 1;
} */
.document-people-tooltip .mds-tooltip,
.document-name-tooltip .mds-tooltip {
  position: absolute;
  top: -17px;
  z-index: 1;
  left: 60px;
  color: #ffffff !important;
  margin: 0 !important;
}
.document-people-tooltip {
  position: relative;
}
.document-people-tooltip .mds-tooltip {
  position: absolute;
  margin: 44px 0px 0px 50px;
  z-index: 1;
  color: var(--color-bg-brand-strong);
}
.documet-share-field-copy {
  position: absolute;
  right: 14px;
  top: 9px;
  cursor: pointer;
  z-index: 1;
  color: var(--color-bg-brand-strong);
}
.documet-share-field-copy:hover {
  color: var(--color-bg-static-black);
}

.documet-share-popup button {
  position: absolute;
  top: 12px;
  right: 12px;
}

.filter-sidebar-navigation-item {
  position: relative;
}

.filter-sidebar-navigation-item .close-icon {
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.filter-sidebar-navigation-item .filter-sidebar-navigation-title {
  margin-right: 26px;
}
.filter-sidebar-navigation-item {
    position: relative;
}
.filter-sidebar-navigation-item .close-icon {
    position: absolute;
    right: 48px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}
.filter-sidebar-navigation-item .filter-sidebar-navigation-title {
    margin-right: 26px;
}