.notification-card {
    display: flex;
    width: 100%;
    padding: var(--spacing-16, 16px) var(--spacing-20, 20px);
    flex-direction: column;
    align-items: flex-start;
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
}
.notification-card-body-container-text-sub h6 {
    color: #060d14!important;
    font: var(--body-small);
}

.notification-card:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}

.notification-card:active {
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
}

.notification-card-badge {
    top: 8px;
    left: 12px;
    position: absolute;
}

.notification-card-body {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-8, 8px);
    align-self: stretch;
}

.notification-card-body-container {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-16, 16px);
    flex: 1 0 0;
}

.notification-card-body-thumbnail {
    display: flex;
    width: 96px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: var(--radius-4, 4px);
}

.notification-card-body-thumbnail img {
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: cover;
    border-radius: var(--radius-4, 4px);
}

.notification-card-body-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    opacity: 0;
}
/* .notification-card-body-btn button { 
    display: none;
}
.notification-card:hover .notification-card-body-btn button {
    display: block;
} */

.notification-card:hover .notification-card-body-btn {
    opacity: 1;
}

.notification-card-body-container-avtar {
    display: flex;
    width: var(--AvatarSizeSmall, 32px);
    height: var(--AvatarSizeSmall, 32px);
    justify-content: center;
    align-items: center;
}

.notification-card-body-container-text {
    display: flex;
    padding-right: var(--spacing-12, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.notification-card-body-container-text-sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-2, 2px);
    align-self: stretch;
}

.notification-card-body-container-text-sub h6 {
    font: var(--body-small);
    color: #060D14 !important;
}

.notification-card-body-container-text-subtitle {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
}

.notification-card-body-container-text-date {
    display: flex;
    /* padding: var(--spacing-4, 4px) 0px; */
    padding-top: var(--spacing-4, 4px);
    justify-content: center;
    align-items: flex-start;
    color: var(--color-text-neutral-tertiary, #84898F);
    font: var(--body-x-small);
}

.notification-card-body-btn .dropdown-list {
    right: 24px;
    top: 56px;
}
