.attachment-bar-sec {
  gap: var(--radius-16);
  flex-wrap: wrap;
}
.attachment-items {
  width: var(--spacing-56);
  height: var(--spacing-56);
}

.attachment-items img,
.attachment-items video {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-8);
  overflow: hidden;
  object-fit: cover;
  border: 1px solid var(--color-border-neutral-strong);
}

.attachment-close {
  top: -8px;
  right: -8px;
}

.attachment-play {
  bottom: 4px;
  left: 4px;
}

/* Files */

.attachment-files,
.attachment-voice {
  gap: var(--spacing-16);
  padding: 0 var(--spacing-16);
  border-radius: var(--radius-8);
  background-color: var(--color-bg-neutral-secondary);
  max-width: 248px;
  width: 100%;
}

.attachment-content {
  padding: var(--spacing-6) 0 var(--spacing-8);
  flex: 1 1 auto;
  width: calc(100% - 56px);
}
.attachment-content .file-title {
  color: var(--color-text-neutral-strong);
  font: var(--body-medium);
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.attachment-content .file-size {
  color: var(--color-text-neutral-tertiary);
  font: var(--body-x-small);
  margin: 0;
}

.attachment-voice {
  padding: var(--spacing-16);
}

.attachment-voice .voice-content {
    gap: var(--spacing-4);
    width: calc(100% - 56px);
    flex: 1 1 auto;
}
.attachment-voice .voice-content p {
  /* width: var(--spacing-32); */
  flex-shrink: 0;
  white-space: nowrap;
  color: var(--color-text-neutral-strong);
  font: var(--body-2x-small);
  font-weight: 600;
  text-align: right;
  display: block;
  margin: 0px !important;
}

.attachment-voice .voice-content img{
    width: 100%;
    height: 18px;
    max-width: 140px;
    object-fit: cover;
}
.attachment-voice{
  height: 56px;
}

/* Messagebar Attachment Voice */
.attachment-voice #wavesurfer-container {
  width: 100%;
}

