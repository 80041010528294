.chat-sidebar {
    /* max-width: 360px; */
    /* width: 360px; */
    max-width: 360px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.chat-sidebar-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.chat-sidebar-head-search {
    display: flex;
    padding: var(--spacing-20, 20px) var(--spacing-20, 20px) 0px var(--spacing-20, 20px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
}

.chat-sidebar-head-tabs {
    display: flex;
    padding: var(--spacing-8, 8px) var(--spacing-20, 20px) 0px var(--spacing-20, 20px);
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
}

.chat-sidebar-head-tabs .tabs {
    width: 100%;
}

.chat-sidebar-empty-state {
    display: flex;
    flex: 1 1 auto;
    /* height:calc(100vh - 119px); */
}

.chat-sidebar-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 1 auto;
    align-self: stretch;
    box-sizing: border-box;
    height: 100%;
    /* height: calc(100vh - 119px); */
}

.chat-sidebar-body-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.chat-sidebar-chat-btn {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.chat-sidebar-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: var(--spacing-12);
}

.chat-sidebar-loading {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}