@media only screen and (max-width: 1500px) {
  body .newsmanagement-main {
    padding: 0px 24px;
  }
}

@media (max-width: 1400px) {
  .partners-meet-slider {
    margin: 0px -24px -20px -24px !important;
  }
}

@media (max-width: 1200px) {
  .cstm-cl-top {
    flex-direction: column;
    gap: 12px;
    align-items: start !important;
  }

  .mds-partners-head {
    align-items: flex-start !important;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 0px !important;
  }

  .mds-partners-head-left {
    gap: 12px !important;
  }

  body .cstm-search-part-input {
    width: 320px !important;
  }

  .mds-partners-head-right-sort ul.cstm-drop-list {
    left: 0;
  }

  .partners-details-post-card {
    margin-right: 24px !important;
    padding: 12px 12px 48px !important;
    max-width: 35% !important;
  }
}

@media (max-width: 1100px) {
  .fresh-updates-slider button.slick-arrow {
    top: 29% !important;
  }

  .submit-your-document-btn {
    padding: 8px 15px !important;
  }

  .btn-unlock-section .partners-details-btn-main {
    padding: 12px 20px !important;
  }

  .news-details-main-list {
    margin-bottom: 40px !important;
  }

  .news-details-test-edit {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .news-details-pg-title {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  body .partners-meet-slider button.slick-arrow {
    top: 27% !important;
  }

  .partner-explore-offer-content {
    padding: 32px !important;
    min-height: auto !important;
  }

  .partner-explore-offer-content h1 {
    max-width: 450px !important;
    font-size: 32px;
    line-height: 32px;
  }

  .partner-explore-offer-content p {
    margin-bottom: 24px !important;
    max-width: 400px !important;
  }

  .partner-explore-offers-btn {
    padding: 8px 15px !important;
  }

  .partnes-banner-title {
    font-size: 36px !important;
    line-height: 42px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .mds-about-partner-title {
    font-size: 30px !important;
    line-height: 38px !important;
  }

  .get-details-btn {
    padding: 8px 24px !important;
  }

  .partners-details-btn-main {
    padding: 8px 15px !important;
  }

  .partners-details-related-posts li {
    padding: 16px 60px 16px 16px !important;
  }
}

@media (max-width: 999px) {

  .see-offers-btn {
    padding: 4px 12px;
    font-size: 14px;
  }

  .fresh-updates-slider {
    margin: 0px -12px !important;
  }

  .all-content-main-section-in {
    padding-left: 0;
  }

  .doc-management-main {
    padding: 12px 16px !important;
  }

  .cstm-conten-listing-pg-only .cstm-cv-video {
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-top: 16px !important;
  }

  body .newsmanagement-main {
    padding: 16px;
  }

  .partners-details-main {
    padding: 12px 16px 48px !important;
  }

  .mds-partners {
    padding: 16px !important;
  }

  .category-slider-menu-in {
    height: 100vh;
  }

  .category-slider-menu {
    height: 100vh;
  }

  .any-category-could-slider {
    margin: 0px !important;
  }

  .partners-meet-slider {
    margin: 0px -12px -20px -12px !important;
  }

  .header-ft.cstm-header-bar .header-inner-ft {
    padding: 0px 16px 0px 6px !important;
  }

  .all-content-main-center {
    padding-left: 0px !important;
  }

  .sidebar-main {
    left: -100%;
    transition: 0.8s all;
  }

  .menuview .sidebar-main {
    left: 0;
  }

  .partners-news-main {
    padding: 16px !important;
  }

  .mds-partners-title {
    font-size: 30px !important;
    line-height: 42px !important;
  }
}

@media (max-width: 767.98px) {
  .mds-partners-head-right-sort ul.cstm-drop-list {
    width: 160px;
  }

  .docs-menu-section li {
    width: 100%;
  }

  .docs-menu-section li a {
    width: 100%;
  }

  .docs-menu-section {
    flex-direction: column !important;
  }

  .all-docs-main-section-search-right {
    width: 100% !important;
  }

  .all-docs-main-section-filters button {
    font-size: 12px !important;
    padding: 3px 12px !important;
  }

  .all-docs-main-section {
    padding: 12px 0px !important;
  }

  .all-docs-main-section-search-left {
    width: 100% !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: 12px !important;
  }

  .all-docs-main-section-search {
    flex-direction: column !important;
    gap: 12px !important;
  }

  .table-fn-pagination-cont li a {
    line-height: 14px !important;
    font-size: 12px !important;
  }

  .pagination-right-arrow {
    min-width: 30px !important;
    width: 30px !important;
    padding: 5px !important;
  }

  .fresh-updates-slider {
    margin: 0px -24px !important;
  }

  .fresh-updates-slider .slick-slide {
    padding: 12px 12px !important;
  }

  .doc-management-main-banner-loading {
    margin-bottom: 12px !important;
  }

  h2.fresh-updates-title {
    font-size: 24px !important;
    line-height: 28px !important;
    padding: 12px 0px !important;
  }

  a.submit-your-document-btn {
    padding: 5px 14px !important;
    font-size: 14px !important;
  }

  .doc-management-main-banner {
    flex-direction: column !important;
    gap: 16px !important;
    align-items: flex-start !important;
    padding: 16px !important;
    margin-bottom: 12px !important;
  }

  .table-fn-pagination-cont li {
    height: 22px !important;
    width: 22px !important;
  }

  .table-fn-pagination {
    flex-direction: column;
    gap: 15px;
  }

  input.cstm-cl-search-input {
    width: 100% !important;
    height: 36px !important;
  }

  img.search-icon-field {
    right: 32px !important;
    top: 24px !important;
  }

  .select-partners-pp-search-img {
    padding: 0px 16px 16px 16px !important;
  }

  .select-partners-pp-search-field {
    padding: 16px !important;
  }

  .btn-footer-pp-only {
    padding: 16px !important;
  }

  .select-partners-pp-only {
    padding: 16px !important;
  }

  .leave-review-offer {
    padding: 16px !important;
  }

  .leave-review-offer-left {
    min-width: 125px !important;
  }

  .select-partners-pp-search-img {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr)) !important;
  }

  .partners-details-popup-your-review {
    padding: 24px 16px !important;
  }

  .btn-unlock-section span {
    min-width: auto !important;
  }

  .cstm-cl-only-sk {
    padding: 8px 16px 0px 16px !important;
  }

  .news-details-main-newsroom {
    padding: 16px !important;
  }

  .newsroom-text {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .news-details-pg-date {
    font-size: 12px !important;
  }

  .news-details-main-list-info h4 {
    font-size: 14px !important;
    line-height: 18px !important;
    margin-bottom: 5px !important;
  }

  .news-details-main-list-img img {
    min-width: 100px !important;
    height: 80px !important;
  }

  .news-details-main-list-img {
    min-width: 120px !important;
    height: 80px !important;
  }

  .news-details-main-list ul li {
    gap: 12px !important;
  }

  .news-details-pg-h3 {
    margin-bottom: 24px !important;
  }

  .news-details-pg-line {
    margin: 20px 0px !important;
  }

  .news-details-pg-title {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .cstm-cl-top {
    flex-direction: column;
  }

  .trending-posts-main-box {
    padding: 16px 48px 16px 40px !important;
  }

  .trending-posts-main {
    padding: 12px 0px 24px 0px !important;
  }

  .trending-posts-main-all {
    padding: 12px 0px !important;
  }

  .cstm-news-banner {
    margin-bottom: 12px !important;
  }

  .trending-posts-date {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .trending-posts-title {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .cstm-news-banner-loading {
    height: 150px !important;
  }

  body .cstm-news-banner .slick-slider button.slick-prev {
    right: 60px !important;
  }

  body .cstm-news-banner .slick-slider button.slick-arrow {
    height: 30px !important;
    width: 30px !important;
    bottom: 24px !important;
    background-size: 18px !important;
  }

  body .cstm-news-banner .slick-slider button.slick-next {

    right: 16px !important;
  }

  .cstm-news-banner .slick-slide img {
    height: 150px;
  }

  .reviews-list-p {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  button.btn-cancel {
    padding: 7px 24px !important;
    font-size: 14px !important;
  }

  button.btn-review {
    padding: 8px 24px !important;
    font-size: 14px !important;
  }

  .partners-details-popup-main-footer {
    padding: 12px 16px !important;
  }

  .partners-details-popup-leave-a-review {
    padding: 16px !important;
  }

  .partners-details-post-card-preferred {
    padding: 6px !important;
  }

  .get-details-btn {
    padding: 5px 24px !important;
    font-size: 14px !important;
  }

  .get-details-btn {
    padding: 3px 14px !important;
    font-size: 14px !important;
  }

  .partners-details-btn-main {
    padding: 3px 14px !important;
    font-size: 14px !important;
  }

  .partners-details-popup-main-cont {
    padding: 12px 16px 0px !important;
  }

  .partners-details-popup-offer-details-cont h3 {
    font-size: 18px !important;
    line-height: 24px !important;
    margin-bottom: 6px !important;
  }

  .partners-details-popup-main-head {
    padding: 12px 16px !important;
  }

  .partners-details-popup-offer-details img {
    height: 100px !important;
  }

  .partnes-popup-offer-banner-title {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .partners-details-popup-offer-details-cont {
    padding: 16px !important;
  }

  .partnes-banner-title {
    font-size: 24px !important;
    line-height: 38px !important;
  }

  .partners-details-banner-img {
    height: 220px !important;
  }

  .partners-details-banner-img img {
    height: 220px !important;
  }

  .partners-details-reviews-list {
    margin: 16px 0px !important;
  }

  .partners-details-h3 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .partners-details-related-videos-cont h4 {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .partners-details-related-videos-cont h6 {
    font-size: 11px !important;
    line-height: 15px !important;
  }

  .related-videos-year-view {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .partners-details-related-videos-img {
    width: 100px !important;
    height: 80px !important;
  }

  ul.partners-details-related-videos li {
    gap: 12px !important;
  }

  .partners-details-about-cont hr {
    margin: 20px 0px !important;
  }

  .mds-other-offers-only {
    grid-gap: 20px 12px !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  }

  .partners-details-related-posts {
    grid-template-columns: initial !important;
  }

  .partners-details-main-left-right {
    margin-top: 30px !important;
  }

  .partners-details-about-cont {
    max-width: 100% !important;
    margin: 0px !important;
  }

  .mds-about-partner-title {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  .partners-details-post-card {
    margin-right: 0 !important;
    padding: 12px 24px 36px !important;
    max-width: 100% !important;
    margin-bottom: 60px !important;
    position: relative !important;
    top: initial !important;
  }

  .partners-details-reviews {
    margin-bottom: 24px !important;
  }

  .partners-details-post-card-logo {
    padding: 6px 0px 24px 0px !important;
  }

  .partners-details-main-left-right {
    flex-direction: column-reverse !important;
  }

  .mds-partners-title {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .mds-partners-head {
    gap: 12px !important;
  }

  .cstm-search-part {
    width: 100%;
  }

  body .cstm-search-part-input {
    width: 100% !important;
  }

  .mds-partners-head-left {
    gap: 12px !important;
    align-items: flex-start !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .see-offers-btn {
    padding: 2px 12px !important;
    font-size: 13px !important;
  }

  .category-slider-close {
    height: 54px !important;
  }

  .partners-browse-all-box {
    gap: 8px !important;
  }

  .partners-browse-all-box h3 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .any-categoty-title h3 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .partners-explore-offers-banner-loading {
    height: 250px;
    width: 100%;
  }

  .partners-browse-all-box {
    min-height: 250px !important;
  }

  .partners-explore-offers-banner {
    margin-bottom: 12px !important;
  }

  body .partners-meet-slider .slick-slide {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .partners-meet-slider {
    margin: 0px -15px 0px -15px !important;
    padding: 0px 15px !important;
  }

  .mds-partners-cont {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  }

  .any-categoryh4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .any-categoty-title {
    padding: 12px 0px !important;
  }

  .any-category-could-slider {
    margin: 0px !important;
    padding: 12px 0px !important;
  }

  .partners-have-an-awesome {
    padding: 24px !important;
    margin: 20px 0px !important;
    gap: 16px !important;
  }

  .partners-have-an-awesome h2 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  .mds-partners-cont {
    grid-gap: 20px 12px !important;
    padding: 12px 0px !important;
  }

  .partners-title-sub {
    padding: 12px 0px !important;
    gap: 6px !important;
  }

  .partners-title-sub h2 {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .partners-title-sub p {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .parners-link-all {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .partner-explore-offers-btn {
    padding: 5px 14px !important;
    font-size: 14px !important;
  }

  .partner-with-us-btn {
    padding: 5px 14px !important;
    font-size: 14px !important;
  }

  .partner-explore-offer-content p {
    margin-bottom: 16px !important;
  }

  .partner-explore-offer-content h1 {
    margin-bottom: 8px !important;
  }

  .title-x-large {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  .partner-explore-offer-content {
    min-height: auto !important;
  }

  .partner-explore-offer-content {
    padding: 16px !important;
  }

  body .all-content-main {
    padding: 54px 0 0px 0 !important;
  }

  .sidebar-main {
    height: calc(100vh - 54px) !important;
  }

  .header-ft-element-sec {
    justify-content: flex-end !important;
  }

  .header-ft-search {
    display: none !important;
  }

  hr.cstm-ssv-line {
    display: none;
  }

  .cstm-saved-single-video-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    margin-bottom: 10px;
  }

  .cstm-saved-single-video-lbl {
    display: none;
  }

  .cstm-ssv-description {

    display: none;
  }

  .cstm-saved-single-video-desc {
    display: none;
  }

  .cstm-saved-single-video-in {
    height: inherit;
    margin-bottom: 12px;
  }

  .cstm-saved-single-video-gallery-img {
    display: none;
  }

  .cstm-my-videos-history-list-main-box-info {
    padding-right: 12px;
  }

  .cstm-all-content-library-main-only .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: auto;
  }

  .cstm-all-content-library-main-only .card-header button.btn-link {
    padding: 4px 8px !important;
    font-size: 13px !important;
    width: max-content;
  }

  .cstm-all-content-library-main-only .card-header button img {
    display: none !important;
  }

  body.menuview .all-content-main-center {
    padding-left: 0px !important;
  }

  .dropdown-menu.header-menu-dp {
    margin-left: 0;
  }

  .cstm-sg-ttl-main {
    width: 195px;
  }

  .newsfeed-sort-all.show ul li {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 12px;
  }

  .newsfeed-sort-all.show .dropdown-menu.newsfeed-select-in.show {
    left: 0px !important;
    border: transparent;
  }

  .loadind-post {
    height: 50px;
  }

  .shard-post-icons {
    padding-right: 0px;
    padding-top: 12px;
  }

  .post-shared-date-info {
    padding-top: 4px;
  }

  .shared-post-user-details {
    padding: 12px 8px;
  }

  .um-main.cstm-um-main.login-um-main .um-left {
    height: 100vh;
    min-height: 450px;
    /* height: var(--doc-height); */
    height: calc(var(--vh, 1vh) * 100);
  }

  /* Header */
  .header-ft-element-sec {
    justify-content: flex-end;
  }

  .header-ft-search {
    height: 40px;
    width: 40px;
    padding: 0px;
    max-width: 40px;
  }

  .header-ft-search-search {
    position: relative;
    width: 100%;
    padding-left: 0px;
  }

  .cstm-edit-delete-poll-inner {
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }

  .cstm-edit-delete-poll ul li a,
  .cstm-save-dash-btn button,
  .cstm-link-dash-btn button {
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 12px;
  }

  .cstm-save-dash-btn button img,
  .cstm-link-dash-btn button img {
    margin-right: 0;
  }

  .dropdown-menu.show {
    opacity: 1;
  }

  .all-content-main.cstm-all-content-main {
    grid-template-columns: minmax(0, 0fr) minmax(250px, 1fr) minmax(0px, 0fr);
  }

  .all-content-main.cstm-all-content-main {
    column-gap: 0px;
  }

  .what-is-happening-main,
  .newsfeed-main {
    width: calc(100% - 16px);
    margin: 0px auto 16px;
  }

  .what-post {
    align-items: center;
  }

  .what-post-right-input {
    margin-bottom: 0;
  }

  .what-post-btn-all.what-post-btn-all-desktop {
    display: none;
  }

  .what-post-btn-all.what-post-btn-all-mobile {
    display: flex !important;
    padding-top: 12px;
    margin-top: 8px;
    border-top: 1px solid #e5e5e5;
  }

  .what-post-btn-all-mobile .cp-btn button span {
    display: block;
    margin-top: -2px;
  }

  .what-post-btn-all-mobile .cp-btn {
    display: flex;
    width: 40%;
    align-items: end;
  }

  .what-post-btn-all-mobile .cp-btn button {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px !important;
    justify-content: center;
    max-width: inherit;
    margin-right: 0px !important;
    height: 36px;
  }

  .what-post-btn-all-mobile .cp-btn button span {
    display: block;
    margin-top: 0;
  }

  .what-post-btn-all-mobile .cp-btn.cstm-cp-dp {
    width: 20%;
  }

  .what-post-btn-all-mobile .cp-btn.topics {
    display: none;
  }

  .newsfeed-post-left img {
    height: 48px;
    width: 48px;
  }

  .cstm-newsfeed-post .newsfeed-post-right-header {
    padding: 12px 48px 8px 74px;
    margin-bottom: 8px;
  }

  .newsfeed-post-right-header-name {
    padding-left: 0px;
  }

  .user-name-title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 3px;
  }

  .user-name-date {
    font-size: 10px;
    line-height: 14px;
  }

  .cstm-newsfeed-post .newsfeed-post-right-header-icon {
    right: 14px;
  }

  .newfeed-dp {
    margin-left: 12px;
  }

  .newfeed-dp-save {
    order: 3;
  }

  .newfeed-dp-link {
    order: 2;
  }

  .cstm-dtl-poll {
    order: 1;
  }

  .create-pp-post-btn span.cp-btn {
    width: 20% !important;
  }

  .cstm-edit-delete-poll ul li img {
    margin-right: 6px;
    width: 20px;
  }

  .newsfeed-agree-main {
    height: auto;
    padding: 6px 16px;
    min-height: 48px;
  }

  .newsfeed-agree-main .newsfeed-agree-main-title {
    z-index: 1;
    text-align: left;
  }

  .newsfeed-agree-main .newsfeed-agree-main-color {
    height: 100%;
  }

  .cstm-img-peview-model.modal-dialog.modal-dialog-centered {
    width: 100%;
    height: 100vh;
    margin: 0;
  }

  .cstm-img-peview-model.modal-dialog.modal-dialog-centered .list-group-item-success {
    color: #0f5132;
    background: #2f2f2f;
  }

  .create-popup-post .cstm-img-peview-model .modal-content {
    height: 100%;
    max-height: 100%;
    background: #2f2f2f;
    border-radius: 0px;
  }

  .cstm-img-peview-model.modal-dialog.modal-dialog-centered .awssld {
    max-height: 100%;
    height: 100%;
  }

  .create-popup-post .cstm-img-peview-model .awssld__wrapper {
    max-height: 100%;
  }

  .cstm-img-peview-model .awssld__bullets {
    display: flex;
    position: absolute;
    bottom: 12px;
    z-index: 10;
  }

  .cstm-img-peview-model .awssld__bullets button {
    width: 8px;
    height: 8px;
  }

  .cstm-img-peview-model .item.cstm-cl-items {
    height: 100%;
    max-height: calc(100vh - 140px);
    padding-bottom: 0px;
  }

  .cstm-img-peview-model .awssld__content {
    background-color: #2f2f2f;
  }

  .cstmcloseBnt {
    display: block;
    width: 48px;
    height: 48px;
    font-size: 30px;
    line-height: 35px;
    margin: 8px 4px 0 auto;
    background: transparent;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .cp-btn button img {
    margin-right: 0;
    margin-bottom: -2px;
  }

  .bijausernoclass {
    display: block;
  }

  .bijausernoclass .cstm-edit-dash-btn,
  .bijausernoclass .cstm-delete-dash-btn,
  .newfeed-dp.newfeed-dp-link,
  .newfeed-dp.newfeed-dp-save {
    display: none;
  }

  .userpoteclass .cstm-link-dash-btn,
  .userpoteclass .cstm-save-dash-btn {
    display: block;
  }

  .newsfeed-post-left {
    top: 12px;
  }

  /* create post model box */
  .modal.create-popup-post .modal-dialog {
    transform: none;
    top: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }

  .create-popup-post .modal-content {
    border-radius: 0;
    max-height: 100%;
    height: 100%;
  }

  .create-popup-post .create-post-content-inner-sub {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .create-pp-post.csm-main-pp-post {
    flex-grow: 1;
    height: 100%;
    max-height: 100%;
  }

  .create-pp-post.csm-main-pp-post .create-pp-post-right {
    max-height: 100%;
    flex-grow: 1;
    height: 100%;
  }

  .create-pp-post.csm-main-pp-post .create-pp-post-cont {
    /* height: calc(100vh - 387px); */
    height: calc((var(--vh, 1vh) * 100) - 387px);
    border-radius: 5px;
    /* min-height : 150px; */
    transition: height 1s ease;
  }

  .create-pp-post.csm-main-pp-post .create-pp-post-cont::-webkit-scrollbar,
  .csstm-add-topic-listing .topics-search-ovr::-webkit-scrollbar,
  .csstm-add-topic-listing .topics-search-label::-webkit-scrollbar,
  .edit-post-content-inner-main .edit-img-inner-main::-webkit-scrollbar {
    width: 0px;
  }

  .responsive-cr-heading-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .responsive-cr-heading-in {
    display: flex;
    align-items: center;
    gap: 8;
  }

  .responsive-cr-heading-in .cr-pp-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #091f48;
  }

  .responsive-cr-heading-sec .close {
    font-weight: 200;
    font-size: 21px;
  }

  .res-create-ps-title .close,
  .res-csm-main-pp-post .create-pp-post-left {
    display: none;
  }

  .create-ps-title-title.ccstm-creatr-post-main {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: start;
  }

  .create-ps-title {
    align-items: start;
    margin-bottom: 4px;
  }

  .bx-close {
    font-size: 10px;
    height: 20px;
    width: 20px;
  }

  .create-popup-post .create-pp-post-img3,
  .create-popup-post .create-pp-post-img2 {
    gap: 8px;
    flex-direction: column;
  }

  .create-popup-post .create-pp-post-img3-left,
  .create-popup-post .create-pp-post-img3-right,
  .create-popup-post .create-pp-post-img2-in {
    width: 100%;
    overflow: hidden;
  }

  .create-popup-post .create-pp-post-img3-right {
    gap: 8px;
  }

  .create-popup-post .create-pp-post-img3-right-in,
  .create-popup-post .create-pp-post-img2-in img,
  .create-popup-post .create-pp-post-img2-in video {
    max-height: 320px;
  }

  .create-pp-cunt {
    font-size: 24px;
  }

  .modal.create-popup-post .create-pp-cunt {
    font-size: 48px;
  }

  .create-ps-title-title.ccstm-creatr-post-main h4 {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .create-ps-title-title.ccstm-creatr-post-main select,
  .create-ps-title-title.ccstm-creatr-post-main input[type="text"] {
    max-width: 100%;
    width: 100%;
    height: 40px;
    margin: 0 0 12px 0px;
  }

  .create-pp-post-cont-text textarea {
    margin-bottom: 16px;
  }

  .topics-search-label {
    margin-bottom: 8px;
  }

  .add-poll-main {
    margin-bottom: 16px;
  }

  .create-pp-post-btn {
    gap: 12px;
  }

  .create-pp-post-public-btn {
    margin-left: 0px;
    width: 100%;
  }

  .create-pp-post-public-btn button {
    width: 100%;
    max-width: 100%;
  }

  .add-poll-main-box input[type="text"] {
    padding: 10px 83px 10px 15px;
  }

  .add-poll-main-box2 input[type="text"] {
    padding-right: 54px;
  }

  .edit-img-cstm-btn-right button {
    margin: 0;
  }

  .csstm-add-topic-listing {
    max-height: calc(100vh - 80px);
    flex-grow: 1;
  }

  .modal-content.cstm-topic-model-box .create-ps-title {
    margin-bottom: 16px;
  }

  .csstm-add-topic-listing .topics-search-ovr {
    /* overflow: auto; */
    flex-grow: 1;
    margin-top: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .csstm-add-topic-listing .topics-search-label {
    overflow: auto;
    flex-grow: 0;
    max-height: 140px;
    background: #f4f4f4;
    border-radius: 8px;
    padding: 10px 10px 5px;
    margin-bottom: 20px;
  }

  .tag-a-friend {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #d5d5d5;
  }

  .csstm-add-topic-listing .edit-img-cstm-btn-right {
    margin-top: 20px;
  }

  .csstm-add-topic-listing .topics-search-ovr-in {
    padding: 12px 16px;
  }

  img.crt-post-dott,
  .cstm-cp-dp:hover .cstm-cp {
    display: none;
  }

  .crt-post-poll {
    display: block;
  }

  .msg-popup.create-popup-post .edit-img-modal-dialog .modal-content {
    padding: 16px;
  }

  .edit-post-content-inner-sub {
    height: auto;
    max-height: calc(100vh - 34px);
  }

  .editimgcount1,
  .editimgcount2,
  .editimgcount3,
  .editimgcount4,
  .editimgcount5,
  .editimgcount0 {
    width: 100%;
  }

  .editimgcount0 .edit-post-content-inner-sub {
    width: 100%;
  }

  .edit-post-content-inner-main {
    height: 100%;
    display: flex;
    height: auto;
  }

  .editimgcount4 .edit-img-inner-main .list-group-item,
  .editimgcount4 .edit-img-inner-main .edit-cstm-img-inner-sec,
  .editimgcount3 .edit-img-inner-main .list-group-item,
  .editimgcount3 .edit-img-inner-main .edit-cstm-img-inner-sec,
  .editimgcount5 .edit-img-inner-main .list-group-item,
  .editimgcount5 .edit-img-inner-main .edit-cstm-img-inner-sec {
    width: 100%;
  }

  .edit-cstm-img-inner-bg {
    background-position: center !important;
    background-size: cover !important;
  }

  .edit-post-content-inner-main.editimgcount2 {
    display: flex;
    height: auto;
    align-items: center;
  }

  .edit-img-cstm-btn-left {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  .edit-img-cstm-btn-left .cp-btn {
    width: auto;
  }

  .edit-img-cstm-btn-left .cp-btn button img {
    margin-right: 0;
    margin-bottom: -3px;
    padding: 0;
  }

  .post-uploading-in-progress {
    width: calc(100% - 16px);
    margin: auto auto 16px;
  }

  .post-msg-all {
    padding: 10px;
    display: flex;
    font-size: 12px;
    line-height: 20px;
    position: fixed;
    width: 95%;
    left: 2.5%;
    right: 2.5%;
    top: 10%;
    z-index: 99;
    height: 40px;
    transform: translateX(0%);
  }

  .post-close-msg {
    padding: 0;
    font-size: 16px;
  }

  .newsfeed-post-right-cmt {
    margin-top: 10px;
  }

  .cmt-other-cunt,
  .cstm-ppl-comments {
    display: none;
  }

  .newsfeed-post-right-cmt-right .newfeed-img-name,
  .newsfeed-post-right-cmt-right .newfeed-img-name {
    margin-left: 0px;
  }

  .newsfeed-post-right-cmt-right {
    display: flex;
    gap: 12px;
  }

  /* Post Comment */
  .comment-main-box-right-box-name-name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
  }

  .comment-main-box {
    margin-left: 6px;
  }

  .comment-main-box-left img {
    height: 32px;
    width: 32px;
  }

  .comment-main-box-right {
    padding-left: 40px;
  }

  .comment-main-box-right-icon-left .newfeed-img-name {
    margin-right: 8px;
  }

  span.comment-time-st.res-comment-time-st {
    display: flex;
    gap: 2px;
    align-items: center;
  }

  .cstm-all-content-center.all-content-main-center {
    max-width: 100%;
  }

  .res-comment-time-st-inner {
    width: 50px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .cstm-delete-modelbox {
    justify-content: center;
  }

  .cstm-delete-modelbox h1 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .cstm-delete-modelbox .msg-icon {
    margin-bottom: 16px;
  }

  .cstm-delete-modelbox .msg-btn.cancle button.btn {
    background: transparent;
    color: #919191 !important;
    border: 1px solid #919191;
    box-shadow: none;
  }


  .globle-model-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: #ffffff;
    overflow: hidden;
    padding: 76px 0px;
    z-index: 10;
  }

  .globle-model-box .globle-back-btn {
    background: none;
    border: none;
    padding: 16px 8px 10px 16px;

  }

  .globle-model-box .card {
    box-shadow: none;
    border-radius: 0px;
    margin-bottom: 16px;
  }

  .globle-model-box .card-header {
    padding: 0px;
  }

  .globle-model-box .card button {
    box-shadow: none;
    padding: 12px 16px;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #091F48 !important;
    text-decoration: none;
    background-color: #FFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .globle-model-box .cstm-starred-group.glob-cstm-starred-group ul {
    height: calc((var(--vh, 1vh) * 100) - 270px);
    overflow: auto;
  }

  .globle-model-box .collapse .cstm-starred-group.cstm-starred-group-sad {
    border-bottom: none;
    padding: 8px 0px 16px;
  }

  .globle-model-box .card button i {
    float: right;
    flex-grow: 1;
    font-size: 23px;
    text-align: right;
  }

  /* group post */
  .all-content-main.cstm-all-content-main.grup-cstm-all-content-main {
    padding-top: 70px;
  }

  .grup-cstm-all-content {
    grid-area: newsfeed;
  }

  .profile-detilas-all {
    margin: -46px 0px 24px;
  }

  .pro-group-members {
    flex-wrap: wrap;
  }

  .search-members-input {
    width: 100% !important;
  }

  .pro-map {
    margin-bottom: 16px;
    min-height: 130px;
  }

  .pro-map img {
    height: 100%;
    min-height: 240px;
  }

  .cstm-grup-admin .pro-title {
    margin-bottom: 16px;
    margin-top: 24px;
  }

  .pro-post-list-main-in {
    padding: 8px 8px;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .pro-post-list-main-in-user {
    flex-grow: 1;
    margin-bottom: 0px;
  }

  .pro-post-list-main-in-link {
    align-items: end;
    flex-direction: column;
    gap: 5px;
  }

  .pro-post-list-main-in-link-view button {
    font-weight: 600;
  }

  .pro-post-list-main-in-link-unfollow {
    font-weight: bold;
  }

  .upcoming-group-events-box-cont-title {
    font-size: 14px;
    line-height: 20px;
    padding-right: 54px;
  }

  .upcoming-group-events-box-cont-sub {
    font-size: 12px;
    line-height: 18px;
    padding-right: 48px;
  }

  .upcoming-group-events-box-cont-brn {
    gap: 6px;
    padding-right: 16px;
  }

  .upcoming-group-events-box-cont-brn button {
    font-size: 12px;
    line-height: 14px;
  }

  .upcoming-group-events-box {
    padding: 16px 0px 16px 88px;
  }

  .pro-events-top {
    flex-wrap: wrap;
    padding: 0px 16px;
  }

  .pro-events-top .pro-uge-title {
    margin-bottom: 8px;
  }

  .pro-uge-view {
    width: 100%;
    justify-content: space-between;
  }

  .pro-events-top .pro-uge-view-check {
    margin-right: 0px;
    width: 50%;
  }

  .upcoming-group-events-box-cale {
    left: 12px;
  }

  .upcoming-group-star {
    top: 16px;
  }

  .upcoming-group-star img {
    width: 24px;
    height: 24px;
    border-radius: 50px;
  }

  .profile-details-tabs .tab-pane {
    margin-bottom: 50px;
  }

  .group-media-slider img,
  .group-media-slider video {
    border-radius: 5px;
  }

  .files-post-tab-inner .pro-group-members-list-left-img img {
    height: 40px;
    width: 40px;
  }

  .files-post-tab-inner .pro-group-members-list-left-title {
    font-size: 14px;
    line-height: 20px;
  }

  .files-post-tab-inner .pro-group-members-list-left-img {
    margin-left: 0;
    left: 0px;
  }

  .files-post-tab-inner .pro-group-members-list-left {
    padding-left: 48px;
    flex-grow: 1;
    width: 70%;
  }

  .files-post-tab-inner .btn-uf {
    font-size: 12px;
    line-height: 19px;
  }

  .files-post-tab-inner .pro-group-members-list-right {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex-wrap: wrap;
    width: 30%;
    gap: 10px;
  }

  .files-post-tab-inner .pro-group-members-list-right-date {
    margin-right: 0;
  }

  /*  Content Library */
  .all-content-main.cstm-all-content-main.cstm-all-library-content-main {
    column-gap: 0;
    grid-template-columns: minmax(0, 0fr) minmax(0, 12fr) !important;
  }

  .cstm-content-archive-main-top {
    flex-wrap: wrap;
  }

  .cstm-content-archive-main-top h3 {
    margin-bottom: 10px;
  }

  .cstm-content-archive-main-top .uic-fl-search {
    margin-right: 0px;
    width: 100%;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec {
    position: relative;
    width: 100%;
  }

  .cstm-content-sorting {
    display: none;
  }

  .cstm-back-video-detail-cl .back-link {
    padding: 0px 0px 16px 16px;
  }

  .cstm-content-sorting select.cstm-content-archive-sort-select {
    width: 90px;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec .search-user-input {
    width: 100% !important;
    padding-right: 45px !important;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec .uic-fl-search i {
    top: 12px;
  }

  .cstm-content-archive-main-top {
    padding: 16px;
  }

  .cstm-gallery-img {
    border-radius: 5px !important;
  }

  .cstm-cv-video-in video {
    object-fit: cover;
  }

  .cstm-cl-items-list {
    font-size: 12px;
    line-height: 16px;
  }

  /* content library video */

  .video-js.vjs-fullscreen .vjs-tech {
    object-fit: contain;
  }

  /* .cstm-back-video-detail-cl .cstm-content-details-video {
          position: sticky;
          top: 76px;
          z-index: 20;
          width: 100%;
      } */
  .cstm-all-library-video-details .all-content-main-right {
    display: block;
  }

  .all-content-main.cstm-all-content-main.cstm-all-library-video-details {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .cstm-vd-cnt.csmt-front-vd-cunt {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 0;
    margin-bottom: 0px;
  }

  .cstm-vd-cnt.csmt-front-vd-cunt .cstm-vd-cnt-left {
    width: 100%;
  }

  .cstm-vd-cnt.csmt-front-vd-cunt .cstm-vd-cnt-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-between;
    border-top: 1px solid #cccccc;
    margin-top: 24px;
    padding-top: 16px;
  }

  .cstm-vd-cnt-left h4 {
    font-size: 17px;
    padding-bottom: 8px;
  }

  .cstm-vd-cnt.csmt-front-vd-cunt .cstm-video-innr-text-tag {
    margin-top: 12px;
  }

  .cstm-vd-cnt-right button,
  .cstm-vd-cnt-right .mark-btn-watched {
    width: 49%;
  }

  .cstm-vd-cnt-right .mark-btn-watched button {
    width: 100%;
  }

  .videodetailicon-sec {
    margin-top: 16px;
    flex-direction: row;
    width: 100%;
  }

  .videodetailicon {
    display: block;
    width: 33.33%;
    text-align: center;
    font-size: 13px;
  }

  .videodetailicon-i {
    width: 24px;
    height: 24px;
    display: block;
    margin: auto auto 4px;
  }

  .video-details-tabing .profile-details-tabs .tab-pane {
    margin-bottom: 4px;
    margin-top: 20px;
  }

  .cl-v-comment-sec .newsfeed-post-right-write,
  .cl-v-comment-sec .comment-main,
  .cl-v-comment-sec .view-more-comments-link {
    display: none;
  }

  .cl-v-comment-sec.res-v-comment .newsfeed-post-right-write,
  .cl-v-comment-sec.res-v-comment .comment-main,
  .cl-v-comment-sec.res-v-comment .view-more-comments-link {
    display: block;
  }

  .cstm-vd-tabs {
    padding-top: 24px;
    margin-bottom: 9px;
  }

  .cl-v-comment-sec .comment-main-title {
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .cl-v-comment-sec.res-v-comment .comment-main-title {
    padding-bottom: 0px;
  }

  .cl-v-comment-sec .cmt-arrow-down {
    transform: rotate(180deg);
    display: block;
  }

  .cl-v-comment-sec.res-v-comment .cmt-arrow-down {
    transform: rotate(0deg);
  }

  .react-input-emoji--button {
    display: none !important;
  }

  .cstm-all-library-video-details .all-content-main-center,
  .cstm-back-video-detail-cl {
    margin-bottom: 0px;
  }

  .related-videos-main {
    margin: 0px 0px 0px;
  }

  .cstm-back-video-detail-cl .newsfeed-post-right-write {
    border-top: 0px;
    margin-top: 0px;
    padding-bottom: 4px;
  }

  .cstm-all-library-video-details .comment-main {
    margin-top: 16px !important;
    padding: 0 0px 0px 8px;
  }

  .cstm-all-library-video-details .react-input-emoji--placeholder {
    font-size: 13px;
  }

  .related-videos-contant-in {
    grid-template-columns: minmax(0, 136px) minmax(0, 1fr);
    padding: 0px 6px 12px 10px;
  }

  .related-videos-contant-in-right {
    padding: 0px;
    justify-content: start;
    gap: 6px;
  }

  .rvc-views-comment {
    display: flex;
    align-items: center;
  }

  .cstm-views-lk {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
  }

  .rvc-title {
    margin-bottom: 4px;
  }

  .related-videos-contant {
    margin-bottom: 60px;
  }

  .loading-video-loader-heading-sec {
    flex-direction: column;
  }

  .loading-video-loader-heading {
    width: 100%;
  }

  .loading-video-loader-left {
    width: 100%;
  }

  .all-content-main.cstm-all-content-main.cstm-all-library-video-details .all-content-main-right.cstm-all-content-main-right {
    margin-top: 16px;
  }

  .cl-v-comment-sec.res-v-comment .view-more-comments-link {
    padding: 0px 0px 16px;
  }

  .res-profile-detilas-all .profile-detilas-all-username-left2 {
    padding-top: 5px;
    flex-grow: 1;
    padding-bottom: 0px;
  }

  .res-profile-detilas-all .profile-detilas-user-photo {
    position: absolute;
    left: 16px;
    top: 15px;
  }

  .res-profile-detilas-all .profile-detilas-user-title {
    font-size: 16px;
    padding-right: 32px;
    position: relative;
  }

  .res-profile-detilas-all .profile-detilas-comm .btn2 {
    display: none;
  }

  .res-profile-detilas-all .res-edit-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: transparent;
  }

  .rs-joined-group {
    display: block;
  }

  .rs-group-media-inner-ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    width: 100%;
    gap: 12px;
    border-radius: 5px;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }

  .rs-group-media-inner-ul li {
    padding: 8px 8px 12px;
    border: 1px solid #cecece;
    width: 48%;
    border-radius: 5px;
  }

  .rs-group-media-inner-li-div {
    width: 100%;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 4px;
  }

  .rs-group-media-inner-li-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .rs-group-media-inner-details {
    height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .rs-group-media-inner-li-img-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #091F48;
    margin-bottom: 10px;
  }

  /* Edit profile */
  .all-content-main-edit-profile {
    grid-area: newsfeed;
  }

  .all-content-main.cstm-all-content-main.edit-profile {
    column-gap: 0;
    grid-template-columns: minmax(0, 0fr) minmax(0, 12fr) !important;
    display: block;
  }

  .all-content-main-edit-profile-header {
    padding: 16px;
  }

  .cstm-edit-profile {
    font-size: 16px;
    line-height: 24px;
  }

  .back-btn img {
    vertical-align: -2.5px;
  }

  .all-content-main-edit-profile-main {
    padding: 15px;
    gap: 15px;
  }

  .all-content-main-edit-profile-upload {
    width: 100%;
  }

  .cstm-profile-upload-cont {
    width: 100%;
    margin-bottom: 30px;
  }

  .cstm-profile-upload-con .form-group {
    margin-bottom: 16px;
  }

  .um-comm-prefe .col-sm-4:nth-child(2) {
    margin: 12px 0px;
  }

  .um-cp-check .form-check-label {
    padding: 6px 12px;
  }

  .cstm-profile-upload-cont .btn {
    margin: 0;
  }

  .globle-model-box.globle-search-model-box {
    padding: 16px 16px 0;
    display: block;
  }

  .globle-search-heading-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .globle-search-heading-sec h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #091F48;
    margin-bottom: 0;
  }

  .globle-model-box.globle-search-heading-sec .globle-back-btn {
    border: none;
    padding: 8px;
  }

  .globle-search-model-box .header-ft-search {
    height: 48px;
    width: 100%;
    padding: 0px;
    max-width: 100%;
    flex-wrap: wrap;
    background-color: #f4f4f4;
  }

  .globle-search-model-box .header-ft-search-search {
    width: 100%;
  }

  .globle-search-model-box .header-ft-search-select {
    display: block;
  }

  .globle-search-model-box .header-ft-search-search input {
    display: block;
    height: 48px;
  }

  .globle-search-model-box .header-ft-search-search-down {
    top: 0;
    position: relative;
    background: #FFFFFF;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    overflow: auto;
    margin-top: 8px;
    flex-grow: 1;
    height: 100%;
  }

  .globle-search-model-box .header-ft-search-search-down ul {
    height: calc(100% - 120px);
    overflow: scroll;
  }

  .globle-search-model-box .header-ft-search-search-down ul li {
    padding: 10px 16px 10px 16px;
    border-radius: 15px;
  }

  .cstm-change-password {
    display: block;
  }

  /* Billing info */
  .um-billing-info.cstm-um-billing-info {
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 0;
  }

  .cstm-um-billing-info .um-billing-info-details {
    flex-direction: column;
  }

  .cstm-um-billing-info .um-billing-info-left,
  .cstm-um-billing-info .um-billing-info-right {
    width: 100%;
  }

  .cstm-um-billing-info .um-billing-info-left {
    display: flex;
    justify-content: space-between;
  }

  .cstm-um-billing-info .um-billing-info-price {
    margin-bottom: 10px;
  }

  .cstm-um-billing-info .um-billing-info-lable {
    font-size: 12px;
    line-height: 16px;
  }

  .cstm-um-billing-info .um-billing-info-right-upgrade {
    margin-top: 16px;
  }

  .cstm-um-billing-info .cancle-subscription-link {
    margin-left: 16px;
  }

  .um-payment-info {
    border-radius: 0px;
    padding: 16px;
  }

  .um-billing-information {
    border-radius: 0px;
  }

  .um-billing-informatio-title {
    padding: 16px;
  }

  .um-billing-information-table {
    padding: 12px 16px 24px;
  }

  /* Upgrade Plan Model box */
  .globle-model-box {
    padding: 0px;
    z-index: 30;
  }

  .desk-globle-model-box-inner {
    max-height: 100%;
    overflow-y: hidden;
  }

  .desk-globle-model-box .um-member-step.um-meme-det-only {
    padding: 0;
  }

  .desk-globle-model-box .um-member-step.um-meme-det-only h2.globle-hsk {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 40px;
  }

  .desk-globle-model-box .um-membership-details {
    flex-grow: 1;
    justify-content: start;
    overflow-y: auto;
  }

  .um-membership-details-in {
    padding: 20px 16px 20px 16px;
  }

  .desk-globle-model-box .um-membership-details-in {
    background-color: #E8F1FB;
    width: 100%;
  }

  .desk-globle-model-box .um-membership-details-in-price {
    margin-top: 10px;
    margin-bottom: 0;
    border-bottom: 0;
  }

  .desk-globle-model-box .um-membership-details-in.selectedplan span.um-md-price {
    font-size: 32px;
    line-height: 42px;
  }

  .desk-globle-model-box .um-membership-details-in p {
    margin-bottom: 8px;
  }

  .up-sbmit-btn {
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .selectedplan:before {
    top: 16px;
    right: 16px;
  }

  .desk-globle-model-box-inner .globle-back-btn {
    padding: 0;
    margin: 5px 0 0 auto;
  }
}

@media (max-width: 575.98px) {

  .trending-posts-sub-title {
    font-size: 16px !important;
    line-height: 21px !important;
  }

  .news-updates-details-box-title {
    padding: 16px 16px 0px !important;
    height: auto !important;
  }

  .news-updates-top-img img {
    height: 250px !important;
  }

  .news-updates-details-box {
    width: 100% !important;
    margin-bottom: 24px !important;
  }

  .news-updates-details {
    flex-direction: column !important;
  }

  .news-updates-details-box-date {
    padding: 0px 16px 16px !important;
  }

  .news-updates-top-title {
    padding: 16px 16px 0px !important;
  }

  .news-updates-top-date {
    padding: 0px 16px 16px !important;
  }

  .what-post-btn-all-mobile .cp-btn.feeling {
    display: none;
  }

  .rvc-views-comment {
    align-items: flex-start;
    flex-direction: column;
    gap: 3px;
  }

  .rvc-dave {
    margin-bottom: 0;
  }

  .category-slider-menu {
    left: 0px !important;
  }

}



@media (min-width : 576px)and (max-width: 767.98px) {

  .editimgcount3,
  .editimgcount4,
  .editimgcount5 {
    width: 90%;
    margin: auto;
  }

  .editimgcount1,
  .editimgcount2,
  .editimgcount0 {
    width: 70%;
    margin: auto;
  }

  .edit-img-cstm-btn-left {
    display: flex;
    gap: 10px;
  }

  .editimgcount4 .edit-img-inner-main .list-group-item,
  .editimgcount4 .edit-img-inner-main .edit-cstm-img-inner-sec,
  .editimgcount3 .edit-img-inner-main .list-group-item,
  .editimgcount3 .edit-img-inner-main .edit-cstm-img-inner-sec,
  .editimgcount4 .edit-img-inner-main .list-group-item,
  .editimgcount4 .edit-img-inner-main .edit-cstm-img-inner-sec,
  .editimgcount3 .edit-img-inner-main .list-group-item,
  .editimgcount3 .edit-img-inner-main .edit-cstm-img-inner-sec {
    width: 48%;
  }

  .cstm-all-content-main-right .related-videos-contant {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(242px, 1fr)) !important;
    list-style: none;
    transition: 0.5s all;
    grid-area: auto;
    gap: 0;
  }

  .related-videos-contant-in {
    grid-area: auto;
  }

  .related-videos-contant-in {
    display: block;
  }

  .related-videos-contant-in-left {
    height: 150px;
    aspect-ratio: 16/9;
    width: 100%;
  }

  .related-videos-contant-in-right {
    margin-top: 10px;
    margin-bottom: 12px;
  }


}

@media (min-width: 768px) and (max-width: 1023.98px) {

  .header-ft-search {
    height: 42px;
    max-width: 360px;
    background-color: #f4f4f4;
    padding: 0px;
  }

  .header-ft-search-search input,
  .header-ft-search-select {
    display: block;
  }

  .header-menu-dp {
    margin-top: 15px;
  }

  .header-ft-menu-icon {
    margin-right: 20px;
  }

  /* Dashboard */
  .what-post-right .cp-btn-dotted img {
    width: 16px;
    height: 16px;
  }

  .all-content-main {
    padding: 86px 0px 0px 16px;
  }

  .all-content-main.cstm-all-content-main {
    column-gap: 1rem;
    grid-template-columns: minmax(0, 6fr) minmax(460px, 12fr) minmax(0px, 0fr);
  }

  .all-content-main-left.cstm-all-content-main-left {
    display: block;
  }

  .all-content-main-left.cstm-all-content-main-left {
    top: 86px;
    max-height: calc(100vh - 86px);
  }

  /* Footer main */
  .webfooter {
    width: auto;
    padding: 0;
    border-radius: 50px;
    right: 16px;
    bottom: 20px;
    border: 1px solid #242424;
    box-shadow: 1px 7px 10px 5px #2424242e;
  }

  .webfooter .footer-icon {
    display: none;
  }

  .webfooter .header-ft-menu-view .footer-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  /* newsfeed section post */
  .what-post {
    gap: 12px;
  }

  .what-is-happening-main,
  .newsfeed-main {
    margin: 0px 0px 20px;
  }

  .newsfeed-post-right-header-name {
    padding-left: 0px;
  }

  .newsfeed-post-left img {
    height: 56px;
    width: 56px;
  }

  .cstm-newsfeed-post .newsfeed-post-right-header {
    padding-top: 20px;
    padding-left: 84px;
    padding-right: 130px;
  }

  .user-name-title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }

  .cstm-newsfeed-post .newsfeed-post-right-header-icon {
    right: 16px;
    top: 20px;
  }

  .comment-main-box-right-box-name-name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 2px;
  }

  .newsfeed-write-your-comments-tx .react-input-emoji--button {
    top: 4px;
  }

  .cstm-img-peview-model .awssld__controls button {
    transform: translateY(-50%) scale(0.7);
    transform-origin: top center;
  }

  .cstm-img-peview-model .awssld__controls button.awssld__prev {
    margin-left: 10px;
  }

  .cstm-img-peview-model .awssld__controls button.awssld__next {
    margin-right: 10px;
  }

  .cstm-edit-delete-poll {
    left: -30px !important;
  }

  .create-popup-post button.cp-btn-photos img,
  .create-popup-post button.cp-btn-videos img {
    padding-right: 4px;
  }

  .create-popup-post .cp-btn button {
    font-size: 13px !important;
    padding: 8px !important;
    margin-right: 10px !important;
  }

  .newsfeed-post-right-header-icon {
    flex-direction: row;
  }

  .editimgcount3,
  .editimgcount4,
  .editimgcount5 {
    width: 90%;
    margin: auto;
  }

  .editimgcount1,
  .editimgcount2,
  .editimgcount0 {
    width: 70%;
    margin: auto;
  }

  .edit-img-cstm-btn-left {
    display: flex;
    gap: 10px;
  }

  .editimgcount5 .edit-img-inner-main .list-group-item,
  .editimgcount5 .edit-img-inner-main .edit-cstm-img-inner-sec {
    width: 48%;
  }

  .create-pp-post-cont-text textarea {
    margin-bottom: 10px;
  }

  .add-poll-main {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .what-post button img,
  button img {
    margin-right: 0px;
    margin-top: 0px;
  }

  /* video details */
  .all-content-main.cstm-all-content-main.cstm-all-library-video-details {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-right: 16px;
  }

  .cstm-all-library-video-details .all-content-main-right {
    display: block;
  }

  .cstm-all-content-main-right .related-videos-contant {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(242px, 1fr)) !important;
    list-style: none;
    transition: 0.5s all;
    grid-area: auto;
    gap: 0;
  }

  .related-videos-contant-in {
    grid-area: auto;
  }

  .related-videos-contant-in {
    display: block;
  }

  .related-videos-contant-in-left {
    height: 150px;
    aspect-ratio: 16/9;
    width: 100%;
  }

  .related-videos-contant-in-right {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .all-content-main-center {
    margin-bottom: 0;
  }

  .related-videos-main {
    height: 100%;
    margin: 0px 0px 0px;
  }

  .related-videos-contant-in {
    padding: 0px 8px 12px 8px;
  }

  .desk-globle-model-box .um-member-step.um-meme-det-only {
    padding: 0px 23px;
  }

  .um-membership-details-in p {
    font-size: 14px;
    line-height: 17px;
  }

  .um-member-step .globle-hsk {
    font-size: 24px;
    line-height: 46px;
  }

  .desk-globle-model-box .um-membership-details {
    padding-top: 10px;
  }

  .um-membership-details-in-lable {
    font-size: 12px;
    line-height: 16px;
  }

  .desk-globle-model-box .um-membership-details-in {
    width: 200px;
    padding-top: 20px;
  }

  .desk-globle-model-box .um-membership-details-in-price {
    margin-top: 14px;
    margin-bottom: 4px;
    padding-bottom: 10px;
    border-bottom: none;
    /* width: 200px; */
  }

  body .desk-globle-model-box .um-meme-det-only {
    min-height: auto;
  }

  .up-sbmit-btn {
    margin-bottom: 30px;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 1023.98px) {
  .tab-content.header-noti-cont-tabs-main {
    overflow: auto;
    max-height: calc(100vh - 140px);
  }

  /* anouncement model */
  .an-globle-model-box,
  .for-globle-model-box,
  .noti-globle-model-box {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #2f2f2fbd;
    z-index: 30;
    padding: 0px;
  }

  .an-globle-model-box-inner {
    width: 100%;
    height: 100%;
    max-width: 400px;
    background: #fff;
    margin-left: auto;
  }

  .an-globle-back {
    padding: 0 8px 0 0;
    border: none;
    background: none;
  }

  .an-globle-model-heading {
    padding: 16px 8px 16px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d2d2d4;
    position: sticky;
    top: 0;
  }

  .an-globle-model-heading h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
    position: relative;
    color: #091F48;
  }

  .an-globle-model-heading-sec {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .an-globle-model-heading-sec span {
    height: auto;
    background: #FF4852;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 14px;
    border-radius: 50px;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }

  .an-globle-model-box ul,
  .for-globle-model-box ul {
    padding: 0px 16px 16px;
    list-style-type: none;
    overflow: auto;
    height: 100%;
  }

  .an-globle-model-box ul li,
  .for-globle-model-box ul li {
    padding: 12px 0px;
    list-style-type: none;
    border-bottom: 1px solid #d2d2d4;
    position: relative;
  }

  .for-globle-model-box ul li {
    display: flex;
  }

  .for-globle-model-box ul li.cstm-sg-ttl-main {
    width: 100%;
    white-space: normal;
    display: block;
  }

  .an-globle-model-box ul li:last-child,
  .for-globle-model-box ul li:last-child {
    border-bottom: none;
  }

  .announcements-list-image img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .other-user .res-profile-detilas-all .res-edit-icon {
    display: none;
  }

  .other-user .profile-detilas-all-username-right {
    padding-top: 24px;
  }

  .other-user .profile-detilas-comm {
    order: 2;
  }

  .other-user .profile-detilas-cmn.pd-joind {
    order: 1;
  }

  .other-user .profile-detilas-cmn.pd-dottod {
    order: 3;
  }

  .for-globle-model-box .cstm-sg-list {
    margin-right: 12px;
  }

  .noti-globle-model-box .header-noti-box-in {
    position: relative;
    display: block;
    left: 0px;
    height: 100%;
    border: none;
  }

  .noti-globle-model-box .header-noti-box-cont {
    border: none;
    box-shadow: none;
    width: 100%;
  }

  .noti-globle-model-box .header-noti-box-cont-title {
    display: none;
  }

  .noti-globle-model-box img.noti-usr-photo {
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }

  .noti-globle-model-box .head-noti-user {
    left: 0;
  }

  .noti-globle-model-box .header-noti-box-cont-tabs-cont ul li {
    padding-left: 65px;
    margin-bottom: 12px;
  }

  .head-noti-new {
    font-size: 10px;
    line-height: 13px;
  }
}


@media (min-width: 768px) and (max-width: 1199.98px) {

  /* left side bar */
  .all-content-main-left-sidebar .card-header button {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .cstm-sg-ttl-main {
    max-width: 118px;
    width: 100%;
  }

  .cstm-starred-group ul li {
    padding: 6px 46px 6px 12px;
    gap: 12px;
  }

  .cstm-sg-list {
    margin-right: 0px;
  }

  img.sctm-pt-mn {
    border-radius: 12px;
    width: 32px;
    height: 32px;
  }

  img.sctm-pt-star {
    width: 16px;
    height: 16px;
  }

  li.cstm-opend-group {
    margin-top: 0px;
    border-radius: 4px 4px 0px 0px;
  }

  li.cstm-starred-group-sad-li.cstm-opend-group {
    padding-top: 10px !important;
  }

  .cstm-opend-group-topis {
    padding: 2px 8px 8px;
  }

  .cstm-opened-topic-list .group-topics-ls {
    padding: 8px;
  }

  .cstm-opened-topic-list .sort-by-main {
    padding: 8px 4px 0px 0px;
    align-items: center;
  }

  .sort-by-main img {
    margin-left: 4px;
  }

  .search-topics-box input[type="text"] {
    height: 32px;
    padding: 10px 34px 10px 16px;
  }

  .search-topics-box:after {
    height: 16px;
    width: 16px;
    top: 18px;
  }

  .cstm-opened-topic-list .cstm-starred-group .cstm-sg-dotted {
    right: 12px;
  }

  .cstm-opened-topic-list .cstm-starred-group ul li {
    padding: 6px 32px 6px 12px;
  }

  .cstm-opened-topic-list .cstm-starred-group ul li .cstm-sg-dotted {
    right: 8px;
  }

  .cstm-opened-topic-list .cstm-starred-group {
    padding-bottom: 4px;
  }

  .cstm-opened-topic-list .cstm-starred-group .cstm-sg-ttl-main {
    max-width: 146px;
  }

  .cstmcloseBnt {
    display: block;
    width: 48px;
    height: 48px;
    font-size: 30px;
    line-height: 35px;
    margin: 0;
    background: transparent;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
  }

  /* Group */
  .profile-detilas-all-nav {
    padding: 6px 12px 0px;
  }

  .profile-detilas-all {
    margin: -96px 8px 16px;
  }

  .pro-post-list-main-in {
    padding: 12px 8px;
    position: relative;
  }

  .pro-post-list-main-in-link {
    margin-top: -2px;
  }

  .pro-post-list-main-in-user-left img {
    height: 56px;
    width: 56px;
  }

  .group-media-slider img,
  .group-media-slider video {
    border-radius: 5px;
  }

  .pro-group-members-list-left-img img {
    height: 48px;
    width: 48px;
  }

  .pro-group-members-list-left-title {
    font-size: 14px;
    line-height: 20px;
  }

  .pro-group-members-list-left-img {
    margin-left: 0;
    top: 0;
    left: 0;
  }

  .pro-group-members-list-left {
    padding-left: 60px;
    width: 70%;
    flex-flow: 1;
  }

  .pro-group-members-list-right {
    width: 30%;
    justify-content: end;
  }

  .all-content-main.cstm-all-content-main.cstm-all-library-content-main.mn-cnt3-wd {
    grid-template-columns: minmax(0, 6fr) minmax(460px, 12fr) minmax(0px, 0fr);
  }

  .cstm-cv-video {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .gallery-content1 {
    font-size: 14px;
    line-height: 20px;
  }

  .cstm-gallery-img {
    border-radius: 5px !important;
  }

  a.gallery-main-icon.icon-1 {
    padding: 0;
  }

  .cstm-cv-video {
    padding: 0px 8px;
  }

  .cstm-content-archive-main-top {
    padding: 10px 14px;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .cstm-content-archive-main-top h3 {
    margin-bottom: 12px;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec .search-user-input,
  .cstm-content-archive-sort.cstm-content-video-short-sec .uic-fl-search {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec .uic-fl-search i {
    top: 11px;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec .search-user-input {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    border-radius: 10px !important;
  }

  .cstm-content-archive-sort.cstm-content-video-short-sec {
    position: relative;
    width: 100%;
  }

  .cstm-content-sorting {
    position: absolute;
    right: 0;
    top: -37px;
  }

  .cstm-cv-video-in video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .res-profile-detilas-all .profile-detilas-all-username-left2 {
    padding-top: 5px;
    flex-grow: 1;
    padding-bottom: 0px;
    width: 100%;
  }

  .res-profile-detilas-all .profile-detilas-user-photo {
    position: absolute;
    left: 16px;
    top: 15px;
  }

  .user-pt-main {
    font-size: 14px;
  }

  .res-profile-detilas-all .profile-detilas-user-title {
    font-size: 16px;
    margin-bottom: 0px;
    padding-right: 32px;
    position: relative;
  }

  .cstm-cgyj-main ul li {
    align-items: start;
    gap: 9px;
  }

  .res-profile-detilas-all .profile-detilas-comm .btn2 {
    display: none;
  }

  .res-profile-detilas-all .res-edit-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: transparent;
  }


  .all-content-main.cstm-all-content-main.edit-profile {
    grid-template-columns: minmax(0, 6fr) minmax(460px, 12fr) minmax(0px, 0fr);
  }

  .all-content-main-edit-profile-header {
    padding: 16px;
  }

  .all-content-main-edit-profile-header .um-back {
    margin-bottom: 8px;
  }

  .cstm-edit-profile {
    font-size: 20px;
    line-height: 24px;
  }

  .all-content-main-edit-profile-main {
    padding: 16px;
    gap: 16px;
  }

  .all-content-main-edit-profile-upload,
  .cstm-profile-upload-cont {
    width: 100%;
  }

  .cstm-profile-upload-cont .form-group {
    margin-bottom: 16px;
  }

  /* Billing info */
  .um-billing-info.cstm-um-billing-info {
    padding: 16px;
    margin-bottom: 16px;
  }

  .cstm-um-billing-info .um-billing-info-details {
    flex-direction: column;
  }

  .cstm-um-billing-info .um-billing-info-left,
  .cstm-um-billing-info .um-billing-info-right {
    width: 100%;
  }

  .cstm-um-billing-info .um-billing-info-left {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .cstm-um-billing-info .um-billing-info-price {
    margin-bottom: 10px;
  }

  .cancle-subscription-link {
    margin-left: 16px;
  }
}

@media(min-width:1024px) and (max-width : 1199.98px) {

  .header-ft-search {
    max-width: 380px;
  }

  .all-content-main {
    padding: 96px 16px 0px 16px;
  }

  .all-content-main-left.cstm-all-content-main-left,
  .all-content-main-right.cstm-all-content-main-right {
    top: 96px;
    max-height: calc(100vh - 104px);
  }

  /* Right side bar */
  .all-content-main-right-sidebar .card-header button {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .cstm-events-list ul li {
    padding: 8px 8px 8px 12px;
  }

  .all-content-main-right .cstm-starred-group {
    padding: 4px 0px 6px 0px;
  }

  .all-content-main-right .cstm-starred-group ul li:last-child {
    padding-bottom: 6px;
  }

  .all-content-main-right .cstm-starred-group ul li {
    align-items: top !important;
    gap: 8px !important;
  }

  .cstm-sg-date {
    bottom: 6px;
  }


  /* dashboard post */
  .what-post .cp-btn button img {
    margin-right: 0px;
    margin-top: 0;
  }

  .what-is-happening-main {
    margin-bottom: 16px;
  }

  .cstm-newsfeed-post .newsfeed-post-right-header-icon {
    right: 16px;
    top: 32px;
  }

  .editimgcount0,
  .editimgcount1,
  .editimgcount2 {
    width: 50%;
  }

  .editimgcount5 .edit-img-inner-main .list-group-item,
  .editimgcount5 .edit-img-inner-main .edit-cstm-img-inner-sec {
    width: 31.33%;
  }

  .announcements-list-image img {
    height: auto;
    border-radius: 5px;
  }

  .new-lbl {
    right: 4px;
    font-size: 10px;
    line-height: 14px;
  }

  .announcements-list-image {
    margin-bottom: 8px;
  }

  .cstm-vd-cnt.csmt-front-vd-cunt {
    margin-bottom: 0px;
  }

  .related-videos-contant-in {
    display: flex;
    flex-direction: column;
    grid-area: auto;
  }

  .related-videos-contant-in-left {
    height: 150px;
    width: 100%;
  }

  .rvc-title {
    margin-bottom: 8px;
  }

  .desk-globle-model-box .um-member-step.um-meme-det-only {
    padding: 0px 30px;
  }
}

@media(min-width:1200px) and (max-width : 1439.98px) {
  .cstm-sg-ttl-main {
    width: 150px;
  }

  .related-videos-contant-in {
    display: flex;
    flex-direction: column;
    grid-area: auto;
  }

  .related-videos-contant-in-left {
    height: 150px;
    width: 100%;
  }

  .res-profile-detilas-all .profile-detilas-all-username {
    flex-direction: row;
    width: 100%;
    align-items: center;
  }

  .res-profile-detilas-all .profile-detilas-all-username-left2 {
    padding-left: 76px;
    flex-grow: 1;
    width: 100%;
  }

  .res-profile-detilas-all .profile-detilas-all-username-right {
    width: 176px;
  }

  .res-profile-detilas-all .profile-detilas-all-username-right .btn2 {
    width: 100%;
  }

  .res-profile-detilas-all .profile-detilas-user-title {
    font-weight: 700;
    font-size: 20px;
    color: #091F48;
    margin-bottom: -4px;
    padding-top: 4px;
  }
}

@media (max-width:350px) {
  .btn-unlock-section {
    align-items: center !important;
    flex-direction: column;
  }
}