h1 {
  font: var(--title-x-large);
  letter-spacing: -0.48px;
  margin: 0px;
  word-break: break-word;
}
h2 {
  font: var(--title-large);
  letter-spacing: -0.36px;
  margin: 0px;
  word-break: break-word;
}
h3 {
  font: var(--title-medium);
  /* line height changed on 21-05-2024 */
  letter-spacing: -0.26px;
  margin: 0px;
  word-break: break-word;
}
h4 {
  font: var(--title-small);
  margin: 0px;
  word-break: break-word;
}
h5 {
  font: var(--title-x-small);
  margin: 0px;
  word-break: break-word;
}

h6 {
  font: var(--title-2x-small);
  margin: 0px;
  word-break: break-word;
}

p {
  font: var(--body-large);
  margin: 0px;
  word-break: break-word;
}
