.dropdown-label {
    display: flex;
    align-items: center;
    gap: var(--spacing-8, 8px);
    border-radius: var(--radius-small, 4px);
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-medium);
    cursor: pointer;
    position: relative;
    padding: 0px 26px 0px 0px;
    width: -moz-fit-content;
    width: fit-content;
    background: var(--color-bg-elevated, #FFF);
    border: none;
}
.dropdown-label:focus {
    outline: 2px solid var(--color-border-inverse-neutral, #0D0D0D);
    outline-offset: 2px;
}
.dropdown-label:after {
    background-image: url(../../Images/icn_m_chevron_down.svg);
    content: '';
    position: absolute; 
    height: 20px; 
    width: 20px;
    background-size: cover;
    top: 2px;
    right: 0px;
  }
.dropdown-up-arrow::after {
    rotate: 180deg!important;
}
.dropdown-label b {
    font-weight: 700;
}

.select-field{
    position: relative;
    max-width: 296px;
}
.select-field-label {
    padding: var(--spacing-8, 8px) var(--spacing-52, 52px) var(--spacing-8, 8px) var(--spacing-16, 16px);
    gap: var(--spacing-16, 16px);
    border-radius: var(--radius-small, 8px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    display: flex;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    font: var(--body-medium);
    background: var(--color-bg-elevated, #FFF);
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer; 
}
.select-field-label::placeholder {
        color: var(--color-text-neutral-tertiary, #84898F)!important;
        opacity: 1; 
      }
.select-field-label::-ms-input-placeholder { 
        color: var(--color-text-neutral-tertiary, #84898F)!important;
      }
.select-field-label:hover{ 
    border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
    }
.select-field-label:active{ 
    border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
    }
.select-field-label:after {
        background-image: url(../../Images/icn_m_chevron_down.svg);
        content: '';
        position: absolute;
        height: 24px;
        width: 24px;
        background-size: cover;
        top: 9px;
        right: 12px;
    }
.select-field-arrow:after{
    rotate: 180deg!important; 
}    