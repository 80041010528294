.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full);
  border: none;
  cursor: pointer;
  font: var(--body-medium);
  font-weight: 600;
}

.button-text {
  padding: 0 var(--spacing-4);
}

.button:focus-visible::before {
  content: '';
  display: block;
  position: absolute;
  inset: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  outline: 2px solid #000;
  border-radius: 555px;
}

.button:focus-visible {
  position: relative;
}

/* =========== Size ================= */
/* Large Button */
.button.large {
  padding: var(--spacing-16) var(--spacing-24);
  gap: var(--spacing-8);
}

.button.large.icon-only {
  padding: var(--spacing-16);
}

/* medium Button */
.button.medium {
  padding: var(--spacing-12) var(--spacing-16);
  gap: var(--spacing-6);
}

.button.medium.icon-only {
  padding: var(--spacing-12);
}

/* small */
.button.small {
  font: var(--body-small);
  font-weight: 600;
  padding: var(--spacing-8) var(--spacing-12);
  gap: var(--spacing-4);
  line-height: 24px;
}

.button.small.icon-only {
  padding: var(--spacing-8);
}

/* extrasmall */
.button.extrasmall {
  padding: var(--spacing-6) var(--spacing-12);
  gap: var(--spacing-4);
}

.button.extrasmall.icon-only {
  padding: var(--spacing-6);
}

/* ================= Varient ================= */

/* Primary */
.button.primary {
  background: var(--color-bg-brand-strong);
  color: var(--color-text-brand-onstrong);
}

.button.primary svg path {
  fill: var(--color-icon-brand-onstrong);
}

.button.primary:hover {
  background: var(--color-bg-brand-strong-hovered);
}

.button.primary:active {
  background: var(--color-bg-brand-strong-pressed);
}

.button.primary:disabled {
  background: var(--color-bg-disabled-strong) !important;
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.primary:disabled svg path {
  fill: var(--color-icon-disabled-onstrong);
}

/* secondary */
.button.secondary {
  color: var(--color-text-neutral-strong);
  border: 1px solid var(--color-border-neutral-strong);
  background: var(--color-text-brand-onstrong);
}

.button.secondary svg path {
  fill: var(--color-icon-neutral-strong);
}

.button.secondary:hover {
  color: var(--color-text-neutral-strong);
  border: 1px solid var(--color-border-neutral-strong-hovered);
}

.button.secondary:active {
  color: var(--color-text-neutral-strong);
  border: 1px solid var(--color-border-neutral-strong-pressed);
}

.button.secondary:disabled {
  pointer-events: none;
  border: 1px solid var(--color-border-disabled-strong);
  color: var(--color-text-disabled-onstrong);
}

.button.secondary:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}

/* tertiary */
.button.ghost {
  background: var(--color-bg-neutral-tertiary);
  color: var(--color-text-neutral-strong);
}

/* .button.ghost svg path {
  fill: var(--color-icon-neutral-strong);
} */

.button.ghost:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.button.ghost:active {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.button.ghost:disabled {
  background: var(--color-bg-disabled-strong) !important;
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.ghost:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}

/* tonal */
.button.tonal {
  background: var(--color-bg-neutral-secondary);
  color: var(--color-text-neutral-strong);
}

.button.tonal svg path {
  fill: var(--color-icon-neutral-strong);
}

.button.tonal:hover {
  background: var(--color-bg-neutral-secondary-hovered);
}

.button.tonal:active {
  background: var(--color-bg-neutral-secondary-pressed);
}

.button.tonal:disabled {
  background: var(--color-bg-disabled-strong) !important;
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.tonal:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}

/* static-white */
.button.static-white {
  background: var(--color-bg-static-white);
  color: var(--color-text-static-darkgray);
}

.button.static-white svg path {
  fill: var(--color-icon-static-darkgray);
}

.button.static-white:hover {
  background: var(--color-bg-static-white-hovered);
}

.button.static-white:active {
  background: var(--color-bg-static-white-pressed);
}

.button.static-white:disabled {
  background: var(--color-bg-disabled-strong) !important;
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.static-white:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}

/* soft-white */

.button.ghost-white {
  background: var(--color-bg-lightening-tertiary);
  color: var(--color-text-static-white);
}

.button.ghost-white svg path {
  fill: var(--color-icon-static-white);
}

.button.ghost-white:hover {
  background: var(--color-bg-lightening-tertiary-hovered);
}

.button.ghost-white:active {
  background: var(--color-bg-lightening-tertiary-pressed);
}

.button.ghost-white:disabled {
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.ghost-white:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}

/* Danger */
.button.danger {
  background: var(--color-bg-danger-strong);
  color: var(--color-text-brand-onstrong);
}

.button.danger:disabled svg path {
  fill: var(--color-icon-brand-onstrong);
}

.button.danger:hover {
  background: var(--color-bg-danger-strong-hovered);
}

.button.danger:active {
  background: var(--color-bg-danger-strong-pressed);
}

.button.danger:disabled {
  background: var(--color-bg-disabled-strong) !important;
  pointer-events: none;
  color: var(--color-text-disabled-onstrong) !important;
}

.button.danger:disabled svg path {
  fill: var(--color-text-disabled-onstrong);
}


/* Button Tooltip(New) */

button[data-title]:hover::before {
  opacity: 0;
}

button.ghost[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  width: max-content;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px);
  flex-direction: column;
  align-items: center;
  border-radius: var(--radius-small, 8px);
  background: var(--gray-800, #394046);
  color: var(--color-text-static-white, #FFF);
  text-align: center;
  font: var(--body-x-small);
  width: max-content;
  box-sizing: border-box;
  opacity: 1;
  z-index: 10;
}

button.ghost.tool-tip-botom[data-title]:hover::before {
  top: 100%;
  margin-top: 4px;
}

button.ghost.tool-tip-top[data-title]:hover::before {
  bottom: 100%;
  margin-bottom: 4px;
}

/* Button Carousel */
.button-carousel {
  width: 38px;
  height: 38px;
  background-color: var(--color-bg-static-white);
  border-radius: var(--radius-full);
  border: 1px solid var(--color-border-neutral-tertiary);
  box-shadow: var(--shadow-medium);
  padding: var(--spacing-8);
}
.button-carousel:hover {
  border-color: var(--color-border-neutral-tertiary-hovered);
  background-color: var(--color-bg-static-white-hovered);
}
.button-carousel:active {
  border-color: var(--color-border-neutral-tertiary-pressed);
  background-color: var(--color-bg-static-white-pressed);
}
.button-carousel:focus-visible {
  border-color: transparent;
  background-color: var(--color-bg-static-white);
}