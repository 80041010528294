/* .carousel-cell {
  width: 100%; 
  height: 200px;
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel.is-fullscreen .carousel-cell {
  height: 100%;
}

.carousel-cell img {
  display: block;
  max-height: 100%;
} */

.choose-media-attachment-details-right {
  height: 715px;
}

.madl-body-images-videos label {
  aspect-ratio: 1/1;
  margin: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.li-disabled {
  pointer-events: none;
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
  opacity: 0.3;
}

#imageuploadurl {
  width: 300px;
  /* Set your preferred width */
  height: 300px;
  /* Set your preferred height */
}

.media-attachment-details .modal-content {
  display: initial !important;
}

.share-btn-details {
  border-radius: 50px;
  opacity: 1;
  font-size: 14px;
  padding: 4px 8px;
}

.savedata-loader-img {
  background: #ffffffe6;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.savedata-loader-img img {
  width: 24px;
}

.empty-attament-media {
  height: 630px;
  max-height: calc(100vh - 302px);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.attachement-media-data-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-height: calc(100vh - 302px);
  height: 630px;
}

.attachement-media-data-loading img {
  width: 70px;
}

.admin-content-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-24, 24px);
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 24px;
}

.admin-content-header h2 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-large);
  letter-spacing: -0.36px;
}

.upload-media-btn {
  position: relative;
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-brand-onstrong, #fff);
  text-align: center;
  font: var(--body-large-bold);
  border: none;
}

.upload-media-btn:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}

.upload-media-btn:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
}

.upload-media-tabs {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.upload-media-tabs button {
  display: flex;
  padding: var(--spacing-12, 12px);
  align-items: center;
  gap: var(--spacing-2, 2px);
  border: none;
  background-color: var(--bg-screen-strong);
  color: var(--color-text-neutral-strong-pressed, #060d14);
  font: var(--body-large-bold);
  cursor: pointer;
  position: relative;
  text-align: center;
  justify-content: center;
}

.upload-media-tabs button:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.upload-media-tabs button:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.upload-media-tabs button.active:after {
  height: 4px;
  width: 100%;
  border-radius: var(--radius-x-small, 4px)
    var(--radius-x-small, 4px) 0px 0px;
  background: var(--color-bg-brand-strong, #0b42cf);
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.upload-media-fillers {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.upload-media-chips {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4, 4px);
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.2));
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-medium);
  cursor: pointer;
  background-color: var(--color-text-static-white);
  padding: var(--spacing-8, 8px) var(--spacing-20, 20px);
}

.upload-media-chips:hover {
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
}

.upload-media-chips:active {
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
}

.upload-media-chips-main {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.upload-media-chips.tab-active {
  background: var(--color-bg-inverse-neutral, #041527);
  color: var(--color-text-inverse, #fff);
}

.upload-media-filler-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.upload-media-filler-main-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-24, 24px);
}

.filter-list-grid {
  display: flex;
  padding: var(--spacing-2, 2px);
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #e7ebf1;
}

.filter-list-grid button {
  border: none;
  display: flex;
  padding: var(--spacing-6, 6px) var(--spacing-8, 8px);
  align-items: center;
  align-self: stretch;
}

button.filter-list-grid-active {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px #04152705;
}

.upload-media-filler-search {
  position: relative;
  min-width: 440px;
}

.upload-media-filler-search-input {
  border-radius: var(--radius-full, 120px) !important;
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3)) !important;
  color: var(--color-text-neutral-tertiary, #84898f) !important;
  font: var(--body-medium) !important;
  height: auto !important;
  padding: 8px 56px 8px 16px !important;
}

.upload-media-filler-search-input::placeholder {
  color: var(--color-text-neutral-tertiary, #84898f) !important;
  opacity: 1;
}

.upload-media-filler-search-input::-ms-input-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f) !important;
}

.upload-media-filler-search-input:hover {
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5)) !important;
}

.upload-media-filler-search-input:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7)) !important;
}

.upload-media-filler-search-icon {
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
}

.upload-media-label {
  display: flex;
  align-items: center;
  gap: var(--spacing-8, 8px);
  border-radius: var(--radius-small, 4px);
  color: var(--color-text-neutral-strong, #060d14);
  cursor: pointer;
  position: relative;
  padding: 0px 26px 0px 0px;
  width: -moz-fit-content;
  width: fit-content;
  background: var(--color-bg-elevated, #fff);
  border: none;
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize !important;
}

.upload-media-label:after {
  background-image: url(../../Images/icn_m_chevron_down.svg);
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-size: cover;
  top: 2px;
  right: 0px;
}

.dropdown-up-arrow::after {
  rotate: 180deg !important;
}

.upload-media-dropdown-list {
  display: flex;
  width: 200px;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-small, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  position: absolute;
  margin: 4px 0px;
  max-height: 280px;
  overflow: auto;
  z-index: 3;
}

.upload-media-dropdown-list::-webkit-scrollbar {
  width: 14px;
}

.upload-media-dropdown-list::-webkit-scrollbar-thumb {
  background: #b2b7bd;
  border-radius: var(--radius-x-small, 8px);
  border: 4px solid #fff;
}

.upload-media-dropdown-list::-webkit-scrollbar-thumb:hover {
  background: #808992;
}

.upload-media-dropdown-list li {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-12, 12px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  font: var(--body-medium);
  box-sizing: border-box;
}

.upload-media-dropdown-list li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.upload-media-dropdown-list li:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.sort-by-label {
  font-weight: 400 !important;
}

.upload-media-filler-main-right .upload-media-dropdown-list {
  right: 24px;
}

.file-uploader-medium {
  /* max-width: 648px; */
  width: 100%;
  display: flex;
  padding: var(--spacing-48, 48px) var(--spacing-32, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-24, 24px);
  align-self: stretch;
  border-radius: var(--radius-medium, 12px);
  border: 2px dashed var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  box-sizing: border-box;
  cursor: pointer;
}

.file-uploader-medium:hover {
  border: 2px dashed
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.file-uploader-medium:active {
  border: 2px dashed
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.7));
}

.file-uploader-medium:focus {
  border: 2px dashed var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  outline: 2px solid var(--color-border-inverse-neutral, #0d0d0d);
  outline-offset: 2px;
}

.file-uploader-medium-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
}

.file-uploader-medium-body-icon {
  text-align: center;
  height: 32px;
  width: 100%;
}

.file-uploader-medium-body-icon img {
  vertical-align: middle;
}

.file-uploader-medium-body-title-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.file-uploader-medium-body-title-text h5 {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
  margin: 0px;
}

.file-uploader-medium-body-title-text h6 {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-x-small);
  margin: 0px;
}

.upload-media-drop {
  padding: var(--spacing-24, 24px) 0px;
}

/* .upload-media-drop .file-uploader-medium {
  max-width: 100%;
  height: 300px;
} */

.file-uploader-medium-button button {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-space-small, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  background-color: transparent;
}

.file-uploader-medium-button button:hover {
  color: var(--color-text-neutral-strong, #060d14);
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.file-uploader-medium-button button:active {
  color: var(--color-text-neutral-strong, #060d14);
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

.file-uploader-error-border {
  border: 2px dashed var(--color-border-danger-strong, #db1d26) !important;
}

.file-uploader-error {
  color: var(--color-text-danger-strong, #db1d26);
  font: var(--body-x-small);
  margin-top: 8px;
}

.media-images-videos {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.media-active-select {
  border-radius: 12px;
  outline: 2px solid var(--blue-500, #0b42cf);
  outline-offset: 4px;
  position: relative;
}

.media-active-select:before {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  right: 4px;
  top: 4px;
  background-image: url(../../Images/icn_s_checkmark_filled.svg);
}

.media-images-videos-column {
  aspect-ratio: 1/1;
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background: var(--color-bg-static-white-pressed); */
  background-image: url(../../Images/WF-Image-Placeholder.svg);
  width: calc(14.28% - 21px);
}

.media-upload-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  background: #f3f5f7;
}

.media-upload-videos {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.compression-loader-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-text-brand-onsecondary);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  z-index: 2;
}

.compression-loader-img img {
  height: 60px;
  width: 60px;
}

.media-upload-images-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-text-brand-onsecondary);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  border: 1px solid #dedede;
  border-radius: 12px;
}

.media-upload-images-loader-in {
  display: flex;
  width: 94px;
  height: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
}

.media-reupload-img {
  font-size: 42px !important;
  color: #0b42cf;
}

.media-upload-images-loader-fill {
  border-radius: 12px;
  background: var(--color-bg-brand-strong, #0b42cf);
  height: 8px;
}

.media-upload-videos-loader {
  background: var(--color-bg-blackout-secondary, rgba(13, 13, 13, 0.5));
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
}

.media-upload-videos-loader img {
  cursor: pointer;
  width: 21%;
}

.media-no-data-found {
  text-align: center;
  grid-column: 12/1;
  margin-top: 100px;
  color: #d40606;
  font-size: 16px;
}

.empty-state {
  display: flex;
  width: 100%;
  max-width: 648px;
  padding: var(--spacing-48, 48px) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24, 24px);
  margin: auto;
  grid-column: 1/-1;
}

.empty-state-icon {
  height: 120px;
  width: 100%;
  text-align: center;
}

.empty-state-icon img {
  height: 120px;
  width: 120px;
  object-fit: cover;
}

.empty-state-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.empty-state-info-title {
  max-width: 480px;
  width: 100%;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
  margin: 0px;
}

.empty-state-info-p {
  max-width: 480px;
  width: 100%;
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  margin: 0px;
  font: var(--body-small);
}

.empty-state-buttun {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-space-small, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  background-color: transparent;
}

.empty-state-buttun:hover {
  color: var(--color-text-neutral-strong, #060d14);
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.empty-state-buttun:active {
  color: var(--color-text-neutral-strong, #060d14);
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

.media-attachment-details .modal-dialog {
  max-width: 1344px;
  width: 90%;
  display: flex;
  align-items: center;
  min-height: 100%;
  margin: 0px auto;
}

.media-attachment-details .modal-content {
  border-radius: 12px;
  margin: 24px 0px;
}

.media-attachment-details-header {
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  /* background: var(--color-bg-elevated, #FFF); */
  display: flex;
  width: 100%;
  padding: var(--spacing-12, 12px) var(--spacing-12, 12px)
    var(--spacing-12, 12px) var(--spacing-24, 24px);
  align-items: center;
  gap: 10px;
}

.madh-h4 {
  flex: 1 0 0;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-small);
  margin: 0px;
}

.madh-close {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px);
  justify-content: center;
  align-items: center;
  border: none;
}

.madh-close:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.madh-close:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.media-attachment-details-body {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.media-delete-message-alert {
  position: absolute;
  width: 100%;
  background: #c4dec4c7;
  z-index: 4;
  font-size: 16px;
  padding: 24px;
  color: #496e49;
}

.alert-for-media-processing {
  width: 100%;
  background: #c4dec4c7;
  z-index: 4;
  font-size: 16px;
  padding: 24px;
  color: #496e49;
  margin-bottom: 16px;
  border-radius: 8px;
}

.media-attachment-details-right {
  border-left: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  width: 304px;
  flex-shrink: 0;
  max-height: calc(100vh - 120px);
  overflow: auto;
  height: 760px;
}

.media-attachment-details-left {
  width: calc(100% - 304px);
  width: -moz-calc(100% - 304px);
  width: -webkit-calc(100% - 304px);
  width: -o-calc(100% - 304px);
  position: relative;
}

.media-attachment-details-left-main {
  max-height: calc(100vh - 120px);
  height: 760px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px 24px 24px;
}

.mad-slider-img {
  border-radius: 12px;
  max-height: 100%;
}

.mad-image-box {
  display: flex;
  padding: 16px;
  gap: 12px;
}

.mad-image-box-left {
  background: var(--color-bg-neutral-secondary, #eaedf1);
  border-radius: 12px;
  width: 120px;
  height: 97px;
  position: relative;
}

.media-details-video {
  min-width: 120px;
  width: 120px;
  height: 97px;
  border-radius: 12px;
  object-fit: cover;
}

.media-popup-video {
  align-items: center;
  background: var(--color-bg-blackout-secondary, #0d0d0d80);
  border-radius: 12px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.mad-image-box-right h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font: var(--link-x-small);
  margin: 0px;
  word-break: break-all;
}

.mad-image-box-right h6 {
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font: var(--body-x-small);
  margin: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
}

.mad-image-box-right {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.mad-hr {
  border-top-color: var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  margin: 0px;
}

.mad-field-box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
}

.mad-field-box-main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8, 8px);
}

.mad-field-box-main h6 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-2x-small);
}

.mad-text-field {
  border-radius: var(--radius-small, 8px) !important;
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3)) !important;
  display: flex !important;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px)
    var(--spacing-8, 8px) var(--spacing-16, 16px) !important;
  align-items: center !important;
  gap: var(--spacing-16, 16px) !important;
  align-self: stretch !important;
  font: var(--body-medium) !important;
  height: auto !important;
}

.mad-text-field:hover {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.5)) !important;
}

.mad-text-field:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7)) !important;
}

.mad-text-textarea {
  border-radius: var(--radius-small, 8px) !important;
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3)) !important;
  display: flex !important;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px)
    var(--spacing-8, 8px) var(--spacing-16, 16px) !important;
  align-items: center !important;
  gap: var(--spacing-16, 16px) !important;
  align-self: stretch !important;
  font: var(--body-medium) !important;
  height: 80px !important;
}

.mad-text-textarea:hover {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.5)) !important;
}

.mad-text-textarea:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7)) !important;
}

.mad-text-error-border {
  border: 1px solid var(--color-border-danger-strong, #db1d26) !important;
}

.mad-copy-url {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  padding: 12px 16px;
  width: 100%;
  flex-direction: column;
}

.mad-copy-url h5 {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.mad-copy-url-list-icon {
  opacity: 0.6;
  display: none;
}

.mad-copy-url-list-icon {
  height: 20px;
  width: 20px;
}

.mad-copy-url-list {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  gap: 0px;
}

.mad-copy-url-list li {
  border-radius: 6px;
  padding: 5px 12px;
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  cursor: pointer;
}

.mad-copy-url-list li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.mad-copy-url-list li:hover .mad-copy-url-list-icon {
  display: block;
}

.mad-copy-url-list li:active {
  background: #bdbdbd;
}

.mad-copy-url-list-text {
  position: relative;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.mad-copy-url-list-text:before {
  height: 5px;
  width: 5px;
  background: var(--color-bg-inverse-neutral, #041527);
  position: absolute;
  content: "";
  left: 0;
  border-radius: 50px;
}

.mad-copy-url-list .active {
  border: 1px solid var(--color-border-neutral-strong, #0d0d0d4d);
}

.mad-field-box-left h5 {
  color: #060d14;
  font-family: Muller;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px;
}

.mad-field-box-left h6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #060d14;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  word-break: break-all;
  cursor: pointer;
  margin: 0px;
}

.mad-field-box-left h6:hover {
  color: var(--color-text-brand-strong, #0b42cf);
}

.mad-copy-url {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 6px;
}

.copy-url-icon-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px);
  justify-content: center;
  align-items: center;
  border: none;
}

.copy-url-icon-btn:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.copy-url-icon-btn:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.mad-field-box-right {
  min-width: 44px;
  width: 44px;
}

.mad-delete-download {
  display: flex;
}

.mad-delete-download {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.mad-delete-link {
  color: var(--red-500, #f1343d);
  font: var(--title-2x-small);
  cursor: pointer;
  position: relative;
}

.mad-delete-link:hover {
  color: var(--color-text-brand-strong, #0b42cf);
}

.mad-delete-loader-img {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffdb;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.mad-delete-loader-img img {
  width: 24px;
}

.mad-download-link {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-2x-small);
  cursor: pointer;
}

.mad-download-link:hover {
  color: var(--color-text-brand-strong, #0b42cf);
}

.mad-categories-badge {
  display: inline-flex;
  padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  border-radius: var(--radius-small, 8px);
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-brand-onstrong, #fff);
  font: var(--body-x-small);
}

.mad-categories-badge img {
  width: 16px;
}

.mad-save-btn {
  position: relative;
  display: flex;
  padding: var(--spacing-6, 6px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-brand-onstrong, #fff);
  text-align: center;
  font: var(--body-large-bold);
  border: none;
}

.mad-save-btn:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}

.mad-save-btn:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
}

.mad-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.categories-select-box {
  position: relative;
}

.categories-select-label {
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px)
    var(--spacing-8, 8px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  border-radius: var(--radius-small, 8px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  cursor: pointer;
  color: var(--color-text-neutral-strong, #060d14);
}

.categories-select-label:hover {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.5));
}

.categories-select-label:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

.categories-select-label:after {
  background-image: url(../../Images/icn_nav_chevron_bottom.svg);
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background-size: cover;
  top: 8px;
  right: 12px;
}

.categories-up-arrow::after {
  rotate: 180deg !important;
}

.categories-select-list {
  display: flex;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-small, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  position: absolute;
  margin: 4px 0px;
  max-height: 200px;
  width: 100%;
  z-index: 1;
}

.categories-select-list li {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-12, 12px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  font: var(--body-medium);
  box-sizing: border-box;
}

.categories-select-list li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.categories-select-list .active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.media-attachment-details-footer {
  display: flex;
  width: 100%;
  padding: var(--spacing-24, 24px);
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.madl-header {
  display: flex;
  width: 100%;
  padding: var(--spacing-24, 24px);
  align-items: center;
  gap: var(--spacing-24, 24px);
  justify-content: space-between;
}

.madl-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.madl-body {
  max-height: calc(100vh - 302px);
  height: 630px;
  /* min-height: 622px; */
  padding: 0px var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 6px;
  margin-top: -6px;
}

.madl-body-main {
  /* display: grid; */
  /* grid-gap: 24px; */
  /* grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)); */
  padding-bottom: 24px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.madl-body-images-videos {
  position: relative;
  cursor: pointer;
  background: var(--color-bg-static-white-pressed);
  border-radius: 12px;
  width: calc(16.66% - 20px);
}

.choose-upload-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.choose-upload-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.choose-media-attachment-details-right {
  border-left: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  width: 304px;
  flex-shrink: 0;
  max-height: calc(100vh - 210px);
  overflow: auto;
  height: 715px;
}

.choose-media-attachment-details-left {
  width: 100%;
  position: relative;
}

.upload-media-data-loading {
  text-align: center;
  margin-top: 200px;
}

.upload-media-data-loading img {
  width: 60px;
}

.choose-media-multipie-item {
  display: flex;
  padding: 16px;
  gap: 12px;
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.choose-media-multipie-item-left img {
  min-width: 120px;
  width: 120px;
  height: 97px;
  border-radius: 12px;
  object-fit: cover;
}

.choose-media-multipie-item-right h5 {
  font: var(--body-x-small-bold);
  color: var(--color-text-neutral-secondary, #394046);
  margin: 0px;
}

.choose-media-multipie {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  min-width: 304px;
  border-left: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.choose-media-multipie-link {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
}

.media-drag-main {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #000000bf;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  flex-direction: column;
}

.media-drag-main h3 {
  color: var(--color-text-inverse, #fff);
  font-size: 30px;
  font-weight: 600px;
  margin-bottom: 24px;
}

.media-drag-main h4 {
  color: var(--color-text-inverse, #fff);
  font-size: 16px;
  margin: 0px;
}

.crop-media-footer {
  display: flex;
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.change-image-btn {
  display: flex;
  padding: var(--spacing-space-small, 11px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--link-large);
  background-color: var(--color-bg-static-white);
}

.change-image-btn:hover {
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.change-image-btn:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

/* .crop-media-body {
  height: 736px;
  max-height: calc(100vh - 210px);
  display: flex;
  justify-content: center;
} */

.cropper-content .crop-media-body {
  flex-grow: 1;
  text-align: center;
  /* height: 100%; */
  overflow: auto;
  display: flex;
  position: relative;
}

.cropper-content .crop-media-body .ReactCrop {
/*   height: 100%; */
  width: max-content;
  margin: auto;
}

.cropper-content.horizontal .crop-media-body .ReactCrop {
/*   height: auto; */
}

.input-checkbox-popup-media {
  position: absolute !important;
  left: 10px;
  top: 10px;
  z-index: 2;
  display: none;
}

/* .crop-media-body .ReactCrop__child-wrapper {
  height: 100%;
} */

.crop-media-image {
  height: 100%;
  /* width: 100%; */
  margin: auto;
}

.media-attachment-slider {
  height: 100%;
  width: 100%;
}

.media-attachment-slider .slick-slider {
  height: 100%;
  width: 100%;
}

.media-attachment-slider .slick-list {
  height: 100%;
  width: 100%;
}

.media-attachment-slider .slick-track {
  height: 100%;
  width: 100%;
}

.media-attachment-slider .slick-slide div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-slider-img-only {
  width: auto !important;
  max-height: 100% !important;
}

.media-slider-video-only {
  max-height: 100% !important;
  background-color: #000;
}

.media-attachment-slider .slick-prev:before {
  content: url("../../Images/icn_m_chevron_left.svg");
}

.media-attachment-slider .slick-next:before {
  content: url("../../Images/icn_m_chevron_right.svg");
}

.media-attachment-slider button.slick-arrow {
  position: absolute;
  top: 50%;
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-static-white, #fff);
  box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04),
    0px 4px 25px 0px rgba(4, 21, 39, 0.1);
  display: inline-flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  z-index: 1;
}

.media-attachment-slider button.slick-arrow:hover {
  border-radius: var(--radius-full, 120px);
  border: 1px solid
    var(--color-border-neutral-tertiary-hovered, rgba(13, 13, 13, 0.3));
  background: var(--color-bg-static-white-hovered, #fff);
  box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04),
    0px 4px 25px 0px rgba(4, 21, 39, 0.1);
}

.media-attachment-slider button.slick-arrow:active {
  border-radius: var(--radius-full, 120px);
  border: 1px solid
    var(--color-border-neutral-tertiary-pressed, rgba(13, 13, 13, 0.5));
  background: var(--color-bg-static-white-pressed, #e8eaed);
  box-shadow: var(--shadow-small);
}

.media-attachment-slider .slick-prev {
  left: 24px;
}

.media-attachment-slider .slick-next {
  right: 24px;
}

.media-attachment-slider button.slick-arrow.slick-disabled {
  display: none !important;
}

.media-images-videos-colum-loading {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  vertical-align: middle;
}

.madl-body-images-videos-loading {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  vertical-align: middle;
  aspect-ratio: 1/1;
}
