.emoji-messages .massages-ebox-right {
  padding: 0px;
}
.emoji-messages {
  background: none !important;
}
.emoji-messages-fontsize {
  font-size: 56px !important;
  line-height: normal!important;
}
.emoji-messages-small-fontsize {
  font-size: 28px !important;
  line-height: normal!important;
}
/* .connecting-message-sec{
  position: absolute;
    left: 0;
    right: 0;
    margin-top: 12px;
    z-index: 2;
}
.connecting-message {  
  display: flex;
  width: max-content;
  border: 1px solid #dddddd;
  text-align: center;
  margin: auto;  
  background: #ffffffe6;
  border-radius: 30px;
  padding: 4px 14px; 
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 13px;
  margin-bottom: 12px;
  
}
.connecting-message img {
 width: 15px;
 height: 15px;
}
.connecting-message:before {
  content: '';
  height: 16px;
  width: 16px;
  background-image: url(../Images/connectionloader.gif);
  background-size: cover;
  background-repeat: no-repeat;
} */
.unread-messages-label {
  border-bottom: 1px solid #fbbebe;
  text-transform: uppercase;
  color: #f66b6b;
  text-align: right;
  margin-bottom: 10px;
  padding-bottom: 4px;
  font-size: 12px;
}
.cstm-mention-list-user-photo {
  position: relative;
}
.cstm-chat-user-name {
  color: #201E29;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-right: 4px;
}
.pfl-tn-photo{
  object-fit: cover;
  border-radius: 50px;
  height: 40px;
  width: 40px;
}
.cstm-moel-photo {
  margin-right: 8px;
  display: grid; 
  position: relative;
  margin-bottom: -25px;
}

.messages-other-ebox-left .cstm-mention-light {
  background: #c0c0c0;
  font-weight: 600;
  border-radius: 4px;
  padding: 2px 4px;
}
.messages-other-ebox-right .cstm-mention-light {
  background: #8479e7;
  font-weight: 600;
  border-radius: 4px;
  padding: 2px 4px;
}
.cstm-mention-list {
  position: absolute;
  bottom: 60px;
  border-radius: 8px;
  border: 1px solid var(--light-border-primary, #E6E6E6);
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  overflow: auto;
  max-height: 296px;
  min-width: 356px;
  left: 0px;
  padding: 8px;
}
.cstm-mention-list::-webkit-scrollbar {
  width: 5px;
}
.cstm-mention-list::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cstm-mention-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.cstm-mention-list:hover::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.cstm-mention-list-box {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 16px;
}
.cstm-mention-list-box-user {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #201E29;
}
.cstm-mention-list-box:hover {
  border-radius: 12px;
  background: var(--light-extra-grey, #F3F5F7);
  cursor: pointer;
}
.cstm-avat-mention {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}
img.cstm-mention-pt {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.chat-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-image img {
  height: 60px!important;
  width: 60px!important;
}
.chatuserlistingloader-main-right {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  justify-content: center;
}
.chatuserlistingloader-main-right1.skeleton-box { 
  height: 15px;
}
.chatuserlistingloader-main-right2.skeleton-box {
  height: 10px;
}
.chatuserlistingloader-main {
  display: flex;
  margin-bottom: 15px;
  gap: 12px;
  justify-content: flex-start;
}
.chatuserlistingloader {
  padding: 0px 24px;
}
.chatuserlistingloader-main-left {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  min-width: 48px;
}
.chatuserlistingloader-main {
  display: flex;
  margin-bottom: 15px;
  gap: 12px;
  justify-content: flex-start;
}
.chatuserlistingloader-main-left{
  border-radius: 50px;
  width: 48px;
  height: 48px;
}
.messages-other-ebox-left .qutted-chat span.url-msg {
  text-decoration-line: underline;
  color: #351bce;
}
.mini-chat-type-write-send {
  display: flex;
  align-items: center;
}
.mini-chat-type-write-text {
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
}
.mini-chat-type-write-send-btn {
  width: 36px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer; 
  justify-content: center;
}
.mini-chat-delete-attached {
  position: absolute;
  top: -6px; 
  right: -6px;
  z-index: 1;
}
.mini-chat-delete-close {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #201e29;
  color: #fff;
  border: 1px solid #ffffff;
  padding: 0px;
}
.mini-chat-type-filearea-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(48px, 1fr));
  overflow-y: auto;
  gap: 12px;
  padding: 15px 12px 12px;
  border-top: 1px solid #e6e6e6;
}
.mini-chat-type-filearea-box-item {
  width: 48px;
  height: 48px;
  position: relative;
  -webkit-aspet-ratio: 1;
  -o-aspet-ratio: 1;
  aspect-ratio: 1;
}
.mini-chat-type-filearea-box-item-innner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.mini-chat-type-write {
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid #e6e6e6;
}
.mini-chat-header-left-photo-name a {
  font-style: normal; 
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #201e29 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
  display: block;
}
.mini-chat-header-left-photo {
  position: relative; 
}
.mini-chat-header-left-photo img {
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 50px;
  object-fit: cover;
  position: relative;
}
.mini-chat-header {
  padding: 12px;
  border-bottom: 1px solid #e8e8e9;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.mini-chat-header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.mini-chat-header-right {
  display: flex;
}
.chat-box-no-data-message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9B9A9F;
}
.chat-box-no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 48px;
}
.chat-box-no-data-icon {
  padding-bottom: 16px;
}
.chat-data-fine-size-links{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #32303A;
}
.chat-data-fine-link a {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-decoration-line: underline;
  color: var(--navyblue);
}
.chat-data-fine-link a:hover {
 color:var(--yellow);
}
.only-chat-files-use .chat-search-list-cont {
  padding-right: 50px;
}
.chat-video-play-icon {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.chat-search-list-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  min-height: 48px;
} 
.chath4 {
  margin: 12px 8px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-search-list-box-main {
  padding: 0px 16px 8px 16px;
  overflow: auto;
  height: calc(100vh - 212px);
}
.chat-search-list-box-main::-webkit-scrollbar {
  width: 5px;
}
.chat-search-list-box-main::-webkit-scrollbar-track {
  border-radius: 10px;
}
.chat-search-list-box-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.chat-search-list-box-main:hover::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.chat-data-list li {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  position: relative;
}
.chat-data-list li:hover { 
  background: #F3F5F7;
  border-radius: 10px;  
  cursor: pointer; 
}
.chat-data-list {
  margin: 0px;
  padding: 0px; 
  list-style: none;
}
.chat-data-list li:hover .chat-search-list-icon {
  display: block;
}
.chat-search-list-icon {
  float: right;
  position: absolute;
  right: 8px;
  margin-top: 11px; 
  display: none;
}
.chat-data-fine-name {
  word-break: break-all;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201E29;
}
.chat-data-fine-size {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-search-file-type  {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}
.only-chat-files-use .chat-search-list-img {
  display: block;
}

.chat-search-list-img {
  position: relative;
  display: contents;
  min-width: 48px;
}
.chat-search-list-img img {
  height: 48px;
  width: 48px;
  object-fit: cover;
  min-width: 48px;
  border-radius: 10px;
}
.chat-search-recent-box-search {
  position: relative;
}
.chat-search-recent-box-search input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #201E29;
  padding: 0px 12px 0px 37px;
  width: 358px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 8px;
  box-sizing: border-box;
}
.chat-search-recent-box-search input::-webkit-input-placeholder {
  font: weight 400;
  color: #606060;   
}
.cstm-user-uplaod-input input:-ms-input-placeholder {
  font: weight 400;
  color: #606060;   
}
.cstm-user-uplaod-input input::placeholder {
  font: weight 400;
  color: #606060;    
} 
.chat-search-recent-box-search:after {
  background-image: url(../Images/chat-search-icon.svg);
  background-repeat: no-repeat;
  content: "";
  height: 16px;
  width: 16px;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 12px;
}
.chat-search-ntd {
  margin-top: -8px;
  right: 5px;
}
.chat-search-ntd button {
  padding: 6px 10px;
}
.chat-search-recent-box-recent {
  position: relative;
} 
.chat-recent-dropbox {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201E29;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
}
.chat-recent-dropbox img {
  margin-left: 8px;
}
.chat-search-recent-box {
  position: relative;
  padding: 12px 14px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-sg-msg2 {
  margin-left: 4px;
}
.chat-on-hover:hover {
  background: transparent!important;
} 
.massages-name-time img {
  vertical-align: -5px;
}
.chat-video-play-icon img {
  max-width: 24px!important;
  width: 24px!important;
  height: 24px!important;
  object-fit: contain!important;
}
.header-chat-new-search {
  position: relative;
}
.header-chat-new-search input {
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 10px 16px 10px 46px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
}
.header-chat-new-search:after {
  background-image: url(../Images/SearchSidebar.svg);
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1; 
  top: 11px;
  left: 18px;
}
.header-chat-cont-link {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  border-radius: 0px 0px 10px 10px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e6e6e6;
  width: 100%;
  background: #FFF;
}
.view-in-chat-box-link {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  width: 100%;
  color: #201E29!important;
  font-family: 'Inter', sans-serif;
}
.view-in-chat-box-link:hover{
  color: #351bce!important;
}
.searchbaradd img.chat-user-phto {
  height: 40px!important;
  width: 40px!important;
}
.searchbaradd .cstm-avat-box {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.searchbaradd .header-chat-cont-list-cont {
  padding: 0px 90px 0px 56px;
}
.searchbaradd ul.chat-list-user-box li {
  padding: 8px!important;
  height: 56px!important;
}
.group-info-main{
  transition: 0.4s;
  -webkit-animation-name: groupbar;
  -webkit-animation-duration: 4s;
  animation-name: groupbar;
  animation-duration: 4s;
}
@-webkit-keyframes groupbar {
  0% {
     right: -600px;
 }
  25% {
     right: 0px;
 }
}
@keyframes groupbar {
  0% {
      right: -600px;
 }
  25% {
     right: 0px;
 }
}
.header-chat-cont-list-cont {
 width: 100%;
}
.chat-module-left .searchbaradd{
 height: calc(100vh - 196px);
}
.cstm-upload-cgc {
  width: 112px;
  margin: auto;
}
.avatar-view-info {
  border-radius: 50px;
  width: 72px;
  height: 72px;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.cstm-avat-box-left-sidechat-small {
  border-radius: 50px;
  height: 40px;
  width: 40px;
  display: flex;
  color: #FFF;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  text-transform: uppercase;
}
.cstm-avat-box-left-sidechat {
  height: 72px;
  width: 72px;
  border-radius: 50px;
  object-fit: cover;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #FFF;
  display: flex;
  align-items: center;
}
.messages-groups-can-have {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  max-width: 320px;
  width: 100%;
  background: #F6F6F6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201E29;
  align-content: center;
  margin: 16px auto;
} 
.messages-groups-can-have b {
  font-weight:600; 
}
.messages-obhl-photo {
  position: relative;
} 
.new-message-empty-state {
  margin-top: 16px;
}
.cstm-add-members-back-button {
  margin: -8px;
  padding: 8px 24px 8px 8px;
  cursor: pointer;
} 
.create-group-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: #ffffffe0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-group-loader img {
  height: 25px;
}
.cstm-cgl {
  position: relative;
}
.viewinfo-transparent {
  background: transparent!important;
}
.chat-page-loader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -32px;
}
.chat-page-loader img { 
  width: 64px;
}
.chat-header-tp {
  display: flex;
  align-items: center;
}
.chat-header-tp-left {
  width: 48px;
  height: 48px;
  background-color: #ececec;
  border-radius: 50px;
}
.chat-header-tp-right {
  width: 150px;
  height: 18px;
  background-color: #ececec;
  margin-left: 12px;
}
.chat-list-select-hover {
  background: #F3F5F7;
  border-radius: 12px;
}
.brn-arrow-remove {
  padding-left: 0px!important;
}
.brn-arrow-remove:before {
  display:none!important;
}
.brn-arrow-remove:hover {
  color: #201e29!important;
}
.discard-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201E29;
  padding: 10px 20px;
  border: none;
  background: transparent;
}
.discard-btn {
  color: #351BCE;
}
.edit-group-info-box-btn {
  gap: 12px;
  display: flex;
  align-items: center;
}
.online-trading-field {
  border: 1px solid #9B9A9F!important;
  border-radius: 10px !important;
  background: #FFF !important;
}
.egi-img {
  height: 72px;
  width: 72px;
  min-width: 72px;
  object-fit: cover;
  border-radius: 50px;
}
.edit-group-info-mn-change-img-photo {
  margin-right: 28px;
}
.edit-group-info-info-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #414049;
  margin-bottom: 8px;
}
.edit-group-info-info{
  margin-bottom: 24px;
}
.edit-group-info-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201E29;
  padding: 0px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
}
.edit-group-info-btn:hover {
  color: #351bce;
}
.edit-group-info-mn {
  padding: 24px;
}
.edit-group-info-mn-change-img {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.edit-group-info-title {
  border-bottom: 1px solid #E6E6E6;
  padding: 24px;
  height: 72px;
  display: flex;
  align-items: center;
}
span.chat-popup-close {
  position: absolute;
  left: -60px;
  top: 16px;
  cursor: pointer;
}
.cstn-vgi-main-lf-photo {
  position: relative;
}
.group-ingo-add-members {
  overflow: auto;
  height: calc(100vh - 202px);
  padding: 0px 16px 16px 16px;
} 
.group-ingo-add-members::-webkit-scrollbar {
  width: 5px;
}
.group-ingo-add-members::-webkit-scrollbar-track {
  border-radius: 10px;
}
.group-ingo-add-members::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.group-ingo-add-members:hover::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.group-info-meme-only {
  right: -4px!important;
  top: 23px!important;
  border: 1px solid #E6E6E6!important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08)!important;
  border-radius: 6px!important;
}
.cstn-vgi-main-rt {
  position: relative;
}
.cstm-vgi-dtd {
  padding: 0px;
  border: none;
  background: transparent; 
  width: 24px;
}
.cstn-vgi-main-lf-avat {
  position: relative;  
}
.cstn-vgi-main-lf-avat-text {
  background: #201E29;
  min-height: 48px;
  width: 48px;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFF;
  border-radius: 50px;
}
.cstn-vgi-main-lf {
  display: flex;
  align-items: center;
}
.cstn-vgi-main-lf-title {
  margin-left: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #201E29;
}
.cstn-vgi-main-lf-photo img {
  height: 48px;
  width: 48px;
  min-width: 48px;
  object-fit: cover;
  border-radius: 50px;
}
.group-ingo-add-members ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.group-ingo-add-members ul li:hover {
  background: #f3f5f7;
  border-radius: 12px;
  cursor: pointer;
}
.group-ingo-add-members ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.add-members-link-bt {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  color: #351BCE!important; 
  padding: 0px;
  border: none;
  background: transparent;
}
.add-members-link-bt:hover {
  color: #000!important;
}
.group-info-main {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08);
  width: 540px;
  height: 100vh;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 5; 
}
.group-info-main-top-left-img img {
  height: 72px;
  width: 72px;
  object-fit: cover;
  border-radius: 50px;
}
.group-info-main-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 14px 24px;
}
.group-info-main-top-left {
  display: flex;
  align-items: center;
}
.group-info-main-top-left-info {
  display: flex;
  flex-direction: column;
}
.group-info-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px; 
  line-height: 150%;
  color: #201E29;
}
.group-info-main-top-left-img {
  margin-right: 20px;
} 
.group-info-menu-horizonta {
  cursor: pointer;
}
.group-info-main-tabs ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
}
.group-info-main-tabs .active-gi a {
  color: #201E29!important;
  font-weight: 500;
}
.group-info-main-tabs .active-gi a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #351BCE;
  border-radius: 10px 10px 0px 0px;
  left: 0;
  bottom: 0px;
}
.group-info-main-tabs ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px; 
  line-height: 140%;
  color: #6E6D74;
  padding: 14px 24px;
  display: block;
  position: relative;
}
.group-info-main-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #5C5B63;
  padding: 16px 20px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.group-info-dp {
  display: block;
  right: 22px;
  top: 67px!important;
}
.group-info-dp button {
  padding: 6px 10px!important;
}
.cstm-add-members .cstm-aml-box-left {
  display: flex;
  align-items: center;
}  
.cstm-add-members  .cstm-aml-box-left-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
  max-width: 374px;
}
.cstm-add-members ul.chat-list-user-box li {
  padding: 8px 12px;
  height: 56px; 
  cursor: pointer;
} 
.cstm-add-members .cstm-avat-box {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.cstm-add-members a.chat-sg-ttl-main {
  padding-left: 56px;
  width: 80%;
}
.cstm-add-members ul.chat-list-user-box {
  margin: 0px 8px 5px 8px;
  overflow: auto;
  height: 534px;
}
.cstm-add-members .header-chat-cont-list-cont {
  padding: 0px; 
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cstm-add-members .header-chat-cont-list-cont input[type="checkbox"] {
  width: 18px; 
  height: 18px;
  margin: 0px 12px 0px 0px;
}
.cstm-add-members ul.chat-list-user-box::-webkit-scrollbar {
  width: 5px;
}
.cstm-add-members ul.chat-list-user-box::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cstm-add-members ul.chat-list-user-box::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 10px;
}
.chateditactive img {
  border: 1px solid #F3F5F7!important;
}
.chat-edit-sd img {
  border: 1px solid #E6E6E6!important;
  border-radius: 10px;
  height: 44px;
  width: 44px;
}
.input-chat-container{
  display: flex;
  margin: 5px 10px;
  align-items: flex-end;
}
.cstm-aml-box-right input {
  width: 18px;
  height: 18px;
  margin: 0px;
}
span.cstm-aml-am-text {
  background: #201E29;
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 50px;
  margin-right: 16px;
  color: #FFF; 
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  align-items: center;
  justify-content: center;
}
.cstm-add-members-list-box li {
  list-style: none;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cstm-add-members-list-box ul {
  padding: 0px;
  margin: 0px;
}
.cstm-add-members-title-list {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #5C5B63;
  padding: 16px 20px 8px;
}
span.cstm-aml-text {
  background: #111928;
  height: 32px;
  width: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  border-radius: 50px;
  justify-content: center;
}
span.cstm-aml-close {
  padding: 4px;
  cursor: pointer;
}
span.cstm-aml-close img {
  height: 20px;
  width: 20px;
}
span.cstm-aml-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201E29;
  padding: 0px 4px 0px 8px;
}
.cstm-add-members-list {
  padding: 16px 16px 0px;
}
span.cstm-aml-photo img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 50px;
}
.cstm-add-members-list ul {
  margin: 0px;
  padding: 0px; 
}
.cstm-user-uplaod-edit {
  position: relative;
  width: 112px;
  margin: 0px auto 8px;
  border-radius: 150px;
  overflow: hidden;
}
span.cstm-uue-edit {
  padding-top: 4px;
  width: 100%;
  height: 30px;
  background: #00000096;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0px;
  right: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
} 
.cstm-user-uplaod-edit img {
  background: #F2F2F2;
  border-radius: 100px;
  height: 112px;
  width: 112px;
  object-fit: cover;
}
.cstm-add-members-header-close button {
  padding: 7px;
  margin: -7px;
}
.cstm-user-uplaod-input input {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #201E29;
  width: 174px;
  background: #FFF;
  border-radius: 0px;
  padding: 0px 8px 8px;
  height: 35px;
  border-bottom: 1px solid #E6E6E6;
  text-align: center;
}
.cstm-user-uplaod-input input::-webkit-input-placeholder {
  font-size: 18px;
  color: #9f9ea3;
}
.cstm-user-uplaod-input input:-ms-input-placeholder {
  font-size: 18px;
  color: #9f9ea3;
}
.cstm-user-uplaod-input input::placeholder {
  font-size: 18px;
  color: #9f9ea3;
} 
.cstm-user-uplaod-name {
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #201E29;
}
.cstm-user-uplaod-icon img {
  cursor: pointer;
}
.cstm-user-uplaod {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 16px;
}
.cstm-user-uplaod-icon {
  margin-bottom: 8px;
}
span.cstm-owner-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: right;
  color: #201E29;
  height: 26px;
  background: #DEDDDF;
  border-radius: 8px;
  display: block;
  padding: 4px 6px;
}
.cstm-cancel-btn {
  background: #FFF;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201E29;
  border: none;
  padding: 10px 20px;
}
.cstm-cancel-btn:Hover {
  color: #351BCE;
}
.cstm-add-members-list ul li {
  list-style: none;
  float: left;
  background: #F6F6F6;
  border-radius: 40px;
  padding: 4px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 8px;
}
.cstm-add-members-search input {
  height: 44px;
  background: #ffffff;
  border: 1px solid #e6e6e6; 
  border-radius: 10px;
  padding: 10px 16px 10px 46px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
}
.chat-search-list-box {
  display: flex;
  justify-content: space-between;
  position: relative;
}
hr.cstm-cgc-line {
  opacity: 1;
  background: #E6E6E6;
  height: 0px;
  border: none;
  margin: 0px;
  padding-bottom: 1px;
}
span.cstm-aml-box-left-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #201E29;
}
.cstm-add-members-search {
  position: relative;
  padding: 0px 16px 16px 16px;
}
.cstm-add-members-search:after {
  background-image: url(../Images/SearchSidebar.svg); 
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  top: 11px;
  left: 32px;
}
.closeicon {
  padding: 0px;
  border: none;
  background: transparent;
}
span.cstm-aml-box-left-photo img {
  height: 40px;
  width: 40px;
  margin-right: 16px;
  border-radius: 50px;
  object-fit: cover;
}
.cstm-add-members-header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #201E29;
  display: flex;
  align-items: center;
}
.cstm-add-members-header {
  padding: 16px 16px 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cstm-add-members-footer-left {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #5C5B63;
}
.cstm-add-members-footer-right {
  display: flex;
  gap: 12px;
  align-items: center;
}
.cstm-add-members-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 20px;
  height: 72px;
  border-top: 1px solid #E6E6E6;
}
.cstm-add-members {
  max-width: 580px;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 100%;
  overflow: inherit!important;
}
.cstm-search-drop {
  right: 0!important;
  top: 46px!important;
  width: 145px!important;
  border: 1px solid #E6E6E6!important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08)!important;
  border-radius: 6px!important;
}
.cstm-search-drop button {
  padding: 6px 10px!important;
}
.messages-other-box-head-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.messages-other-box-head-left {
  display: flex;
}
.messages-other-box-head-right {
  display: flex;
}
.cstm-gp-us {
  display: flex;
  margin-left: 12px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.cstm-gp-us-name a {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #201E29 !important;
  font-family: 'Inter', sans-serif;
}
.cstm-gp-us-name a:hover {
  color: #351BCE !important;
} 
.messages-other-box-type-text textarea {
  min-height: 25px;
  height: 25px;
  padding: 0px;
  border-radius: inherit !important;
  background: transparent;
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
}
.cstm-mob-dtd {
  position: relative;
}
.cstm-mob-dtd img {
  cursor: pointer;
}
.messages-other-ebox-right {
  margin-bottom: 15px;
  text-align: right; 
}
.chat-coversation-box  .messages-other-ebox-left {
  margin-bottom: 8px;
  padding-right: 60px;
}
.cstm-profile-dox {
  position: absolute;
  width: 340px;
  height: 172px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  z-index: 2;
  bottom: 14px;
  left: -20px;
  padding: 20px;
  display: none;
}
.cstm-profile-dox:after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 18px;
  width: 24px;
  height: 7px;
  background-image: url(../Images/ProfileDownArrow.png);
  z-index: 111;
}
.header-chat-cont {
  width: 329px;
  height: calc(100vh - 90px);
  position: fixed !important;
  left: inherit !important;
  top: 72px !important;
  right: 16px !important;
  background: #FFFFFF;
  border: 1px solid rgba(32, 30, 41, 0.1);
  border-radius: 0px 0px 10px 10px;
  transform: inherit !important;
}
.view-in-chat-box a {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #351BCE;
}
.view-in-chat-box-icon {
  line-height: 20px;
  border: none;
  background: transparent;
  padding: 0px;
  color: #000;
  font-size: 28px;
}
.header-chat-cont-list .cstm-avat-box {
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.header-chat-cont-list {
  overflow: auto;
  height: calc(100vh - 237px);
}
.header-chat-cont-list::-webkit-scrollbar {
  width: 6px;
}
.messages-other-box-type-text button.react-input-emoji--button svg {
  height: 20px;
  width: 20px;
}
.messages-other-box-type-text .react-input-emoji--button {
  padding: 0;
}
.messages-other-box-type-text .react-input-emoji--placeholder {
  left: 0;
  width: 100%;
}
.messages-other-box-type-text .react-input-emoji--container {
  margin: 0px;
  width: 189px;
  border: none;
  border-radius: 10px !important;
  background: #F3F5F7;
}
.messages-other-box-type-text .react-input-emoji--input {
  padding: 0px;
}
.messages-say-icon {
  margin-bottom: 16px;
}
.messages-say-hello {
  padding: 12px 32px;
  background: #351BCE;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.messages-say-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #201E29;
  margin-bottom: 16px;
}
.messages-other-minus {
  border: none;
  padding: 0px;
  line-height: 16px;
  background: transparent;
  font-size: 45px;
  margin-left: 15px;
  font-weight: 200;
}
.messages-other-minus:hover {
  color: var(--yellow);
}
.messages-other-close {
  border: none;
  padding: 0px;
  line-height: 16px;
  background: transparent;
  font-size: 25px;
  margin-left: 15px;
}
.messages-other-close:hover {
  color: var(--yellow);
}
.messages-obhl-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091F48;
}
.messages-obhl-photo img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  object-fit: cover;
}
.messages-other-photo-in {
  margin-top: 8px;
  position: relative;
}
.messages-other-photo-in img {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: cover;
}
.messages-other-box {
  margin-right: 20px;
  background: #FFFFFF;
  border: 1px solid #e8e8e9;
  border-radius: 10px;
  width: 320px;
}
.messages-other-photo {
  display: flex;
  flex-direction: column;
}
.messages-other {
  position: fixed;
  right: 365px;
  z-index: 9;
  bottom: 18px;
  display: flex;
  align-items: flex-end;
}
.header-chat-cont-list::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
  background-clip: padding-box;
}
.header-chat-cont-head {
  padding: 8px 16px 16px 16px;
  border-bottom: 1px solid #d9d9d9;
}
.header-chat-cont-head-top-left {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #201E29;
}
.header-chat-cont-head-top-right {
  display: flex;
}
.header-chat-cont-head-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.input-chat-container{
  display: flex;
  margin: 5px 10px;
  align-items: flex-end;
}
.emoji-opener{
  width: 25px;
  height: 25px;
  margin: 7px;
  cursor: pointer;
}
.emoji-container{
  position: absolute;
  right: 0px;
  bottom: 54px;
}
.drop-file-container{
  position: absolute;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(6.5);
  opacity: 0.9;
  font-size: 1.4rem;
  line-height: 1;
  border: 1px dashed;
  color : black;
  padding: 41px;
  font-weight: 600;
}
span.chat-sg-edit-msg-icn {
  position: absolute;
  left: -25px;
  opacity: 0.5;
  margin-top: 2px;
}
.messages-other-ebox-right.cstm-pfl-search::before {
  right: -20px;
  left: initial;
}
.massages-ebox-left span.url-msg {
  text-decoration-line: underline;
  color: #351bce;
}
.cstm-video-only-main {
  position: relative;
}
.cstm-video-only-main:before {
  content: "";
  position: absolute;
  background-image: url(../Images/play-video.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 36px;
  height: 36px;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  margin: -18px auto 0px;
}
span.url-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  color: #f3f5f7;
  margin-bottom: 8px;
  word-break: break-all;
  cursor: pointer;
}
.chat-cdv-file-type {
  display: flex;
  padding: 4px;
  background: #ffffff;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 8px;
}
.chat-cdv-file-type-left {
  border-radius: 10px;
  width: max-content;
  display: contents;
}
.chat-cdv-file-type-right {
  margin-left: 8px;
  cursor: pointer;
  overflow: hidden;
}
.cchat-cdv-file-type-right-link {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-cdv-file-type-left img {
  height: 56px;
  width: 56px;
  object-fit: cover;
  background: #f2f2f2;
  border-radius: 10px;
}
.cchat-cdv-file-type-right-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-attached-view-box span.cstm-ttl-tp {
  font-size: 23px;
  text-transform: uppercase;
  bottom: 19px;
}
.cstm-file-tp-all {
  position: relative;
}
.header-chat-cont .header-chat-cont-list-cont {
  padding-left: 64px;
}
span.cstm-ttl-tp {
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  margin: auto;
  border: darkblue;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}
.chat-rd-msg-file-type-right-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.chat-rd-msg-file-type-right-md {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-rd-msg-file-type-left {
  margin-right: 8px;
}
.chat-rd-msg-file-type {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  padding: 4px 12px 4px 4px;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  width: auto;
}
.chat-pdf-name {
  padding-right: 30px;
  padding-left: 8px;
  overflow: hidden;
}
.chat-msg-ttl {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.chat-massages-file-type {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 4px;
}
.chat-massages-file-type {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.chat-massages-file-type-left {
  margin-right: 8px;
}
.chat-msg-mb {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.cstm-new-chat-input {
  position: relative;
}
.cstm-new-chat-input input {
  height: 44px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 10px 36px 10px 46px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.cstm-new-chat-input:after {
  background-image: url(../Images/SearchSidebar.svg);
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  top: 11px;
  left: 18px;
}
img.cstm-new-chat-cancel {
  position: absolute;
  right: 16px;
  top: 11px;
  cursor: pointer;
}
.cstm-new-chat-input input:focus {
  border: 1px solid #9b9a9f;
}
.chat-write-block {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201e29;
  background: #f2f2f2;
  border-top: 1px solid #e6e6e6;
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
}
.cstm-pfl-search {
  position: relative;
}
.cstm-pfl-search:before {
  content: "";
  width: calc(100vw - 336px);
  height: inherit;
  position: absolute;
  background: #a89dea;
  opacity: 0.1;
  z-index: 1;
  left: -24px;
  top: 0px;
  bottom: -8px;
  min-height: 53px;
}
.cstm-moel-photo:hover .cstm-profile-dox {
  display: block;
}
.view-profile-btn {
  padding: 10px 18px;
  gap: 12px;
  isolation: isolate;
  width: 300px;
  height: 44px;
  background: #351bce;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: none;
}
.view-profile-btn:hover {
  background: var(--yellow);
}
.cstm-profile-dox-prf-right-gmail {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.cstm-profile-dox-prf-right-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 1px;
  color: #201e29;
}
.cstm-profile-dox-prf-left {
  margin-right: 12px;
  position: relative;
}
.pfl-on-off {
  border-radius: 50%;
  border: 2px solid #ffffff;
  height: 16px;
  width: 16px;
  position: absolute;
  content: "";
  bottom: 2px;
  right: 2px;
}
.pfl-photo {
  height: 72px;
  width: 72px;
  border-radius: 50px;
  object-fit: cover;
  background: #201e29;
}
.cstm-profile-dox-prf {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.cstm-avat-comment {
  border-radius: 50px;
  width: 36px;
  height: 36px;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.cstm-upload-avar {
  height: 72px;
  width: 72px;
  object-fit: cover;
  border: 2px solid #ffffff !important;
  box-shadow: 0px 3px 15px #e3e3e3;
  filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.13));
  border-radius: 50px;
  background: #201e29;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 36px;
}
.cstm-avat-header-img {
  width: 72px;
  height: 72px;
  display: flex;
  border: 2px solid #ffffff;
  object-fit: cover;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.1));
  border-radius: 400px;
  margin: auto;
  background: #201e29;
  color: #fff;
  font-size: 36px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}
.cstm-avat-header-tp {
  height: 32px;
  width: 32px;
  background: #3a3842;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.cstm-bdr1 {
  border: 1px solid #9b9a9f;
}
.cstm-bdr2 {
  border: 1px solid #dedddf;
}
.cstm-messasewbm-main {
  border-top: 1px solid #e6e6e6;
}
.cstm-icon-all-ct:hover {
  background: #f3f5f7;
  border-radius: 10px;
}
.cstm-avat-box {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  background: #201e29;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center; 
  color: #ffffff;
  text-transform: capitalize;
}
.messages-right-box-sec .quote-msg-sec .quote-msg-inner-sec p {
  color: #f3f5f7;
}
.messages-right-box-sec .quote-msg-sec .quote-msg-info {
  color: #e3e3e3;
}
.messages-right-box-sec .message-text {
  color: #ffffff;
}
.messages-right-box-sec .quote-msg-sec .quote-msg-inner-sec {
  border-bottom: 1px solid #a89dea;
}
.messages-right-box-sec .quote-icons img {
  filter: invert(1000%) sepia(1000%) saturate(100%) hue-rotate(378deg) brightness(1000%) contrast(100%);
}
.chat-massages-file-type-right {
  padding-right: 8px;
}
.cstm-cstm-no-chat-found {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9b9a9f;
  height: calc(100vh - 156px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.msg-load-more {
  width: 100%;
  text-align: center;
  padding: 13px 0px;
  position: absolute;
  top: 0;
  z-index: 9;
}
.msg-load-more img {
  background: #fff;
  padding: 0px 60px;
  width: 150px;
}
img.cstm-chat-cpc-ld {
  width: 30px;
}
.search-msg .messages-left-box-sec, .search-msg .messages-right-box-sec {
  background: #806fe0;
}
.chatimage .modal-dialog {
  max-width: 800px !important;
}
.chatimage .modal-dialog .list-group-item-success {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videomsg:before {
  content: "";
  position: absolute;
  background-image: url(../Images/play-video.svg);
  background-repeat: no-repeat;
  width: 56px;
  height: 56px;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  margin: -28px auto 0px;
}
.messages-only-main {
  /* max-width: 1170px; */
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.messages-other-box-type.message-write-box {
  margin: auto;
  width: 100%;
}
.cstm-mob-search {
  margin-right: 12px;
  cursor: pointer;
}
.active-search .cstm-src1 {
  display: none;
}
.active-search .cstm-src2 {
  display: block;
}
.cstm-src2 {
  display: none;
}
.cstm-loading-star {
  position: absolute;
  background: #fff;
}
.cstm-loading-star img {
  width: 24px;
}
.cstm-mob-star {
  position: relative;
}
.cstm-typing {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  padding: 3px 24px 8px;
  background: #fff;
  /* max-width: 1170px; */
  width: 100%;
  margin: auto;
}
.quote-msg-sec .quote-msg-inner-sec p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
  margin-bottom: 2px;
  margin-top: 6px; 
}
.quote-msg-sec .quote-msg-info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
  padding-top: 6px;
}
.quote-msg-sec .quote-msg-inner-sec {
  border-bottom: 1px solid rgba(32, 30, 41, 0.2);
  padding-bottom: 12px;
}
.quote-icons {
  margin-bottom: 12px;
}
.mobh-ar {
  width: 24px;
  height: 24px;
  padding: 0px;
  border: 0px;
  color: #afafaf;
  background: #f6f6f6;
  border-radius: 8px;
}
.mobh-ar:hover {
  background: #201e29;
  color: #fff;
}
.mobh-close {
  padding: 0px;
  border: none;
  background: transparent;
  position: absolute;
  right: 17px;
  top: 10px;
  font-size: 25px;
  font-weight: 300;
  color: #201e29;
  font-family: "Inter", sans-serif;
}
.cstm-smicc {
  height: 44px !important;
  border-radius: 10px !important;
  padding-left: 44px !important;
  padding-right: 35px !important;
  background: #ffffff !important;
  border: 1px solid #e6e6e6 !important;
}
.cstm-smicc:focus {
  box-shadow: 0 0 0 1px #351bce;
  border: 1px solid #351bce !important;
}
.cstm-smicc::-webkit-input-placeholder {
  color: #201e29;
}
.cstm-smicc:-ms-input-placeholder {
  color: #201e29;
}
.cstm-smicc::placeholder {
  color: #201e29;
}
.cstm-cancel-link {
  border: none;
  padding: 0px;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  color: #6f7071;
}
.cstm-cancel-link:hover {
  color: #351bce;
}
.mobh-cont {
  margin-left: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.mobh-updn {
  border-width: 0px 1px;
  border-style: solid;
  border-color: rgba(32, 30, 41, 0.1);
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0px 16px;
  padding: 0px 16px;
  justify-content: space-between;
  gap: 8px;
}
.mobh-field {
  width: 100%;
  position: relative;
}
.mobh-field:after {
  background-image: url(../Images/SearchSidebar.svg);
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  top: 12px;
  left: 16px;
}
.messages-other-box-head-bottom {
  display: flex;
  margin-top: 12px;
  align-items: center;
}
.messages-other-box-head {
  padding: 12px 24px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.massages-name-time {
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  color: #606060;
}
.welcome-to-mds {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #201e29;
  margin-bottom: 16px;
}
.a-place-where {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #606060;
  max-width: 551px;
  width: 100%;
}
.welcome-to-icon img {
  margin: 0 auto 16px;
}
.welcome-chat-cm-icon img {
  margin: 0 auto 16px;
  width: 160px;
}
.welcome-chat-cm-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9b9a9f;
}
.cstm-chat-loading {
  text-align: center;
  height: 100%;
}
.cstm-chat-loading img {
  height: 50px;
  position: absolute;
  top: 50%;
}
.cstm-gmc-admin {
  background: #ffcc00;
  border-radius: 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  color: #201e29;
  padding: 3px 10px;
}
.cstm-gmc-view {
  margin-left: 6px;
  cursor: pointer;
}
.cstm-gmc-view img {
  vertical-align: -5px;
}
.cstm-group-member-cont ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.cstm-group-member-cont ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.cstm-group-member-cont ul li:last-child {
  margin-bottom: 0px;
}
.cstm-group-member-cont-left {
  display: flex;
  align-items: center;
}
.cstm-gmc-img img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #cdcdcd !important;
}
.cstm-gmc-img {
  margin-right: 8px;
}
.cstm-gmc-title a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
}
.cstm-gmc-title a:hover {
  color: #ffcc00;
}
.cstm-group-member {
  padding: 16px;
  border-bottom: 1px solid rgba(32, 30, 41, 0.2);
}
.cstm-group-member-header {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cstm-group-member-header-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}
.add-members-link {
  padding: 0px;
  background-color: transparent;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #351bce;
}
.add-members-link:hover {
  color: #ffcc00;
}
.cstm-gmc-img img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border: 1px solid #cdcdcd !important;
}
.add-members-link svg {
  height: 18px;
}
.cstm-group-member-cont-right {
  display: flex;
  align-items: center;
}
.react-emoji-picker--wrapper {
  width: 356px !important;
}
.emoji-mart-scroll {
  -ms-overflow-style: none;
 /* Internet Explorer 10+ */
  scrollbar-width: none;
 /* Firefox */
}
.emoji-mart-scroll::-webkit-scrollbar {
  display: none;
 /* Safari and Chrome */
}
.header-chat-widget .header-chat-cont-head-search {
  position: initial;
  padding: 0;
  border-bottom: 0;
}
.header-chat-cont-list-photo {
  position: absolute;
  border-radius: 50px;
}
.small-chat-widget .messages-obhl-photo {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: block;
  margin: 0 !important;
}
.small-chat-widget .messages-other-box-head-left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 8px;
}
img.chat-user-phto {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.small-chat-widget .messages-obhl-name {
  flex-grow: 1;
  width: calc(100% - 50px);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-chat-widget .messages-obhl-photo {
  margin-right: 12px;
  position: relative;
}
.small-chat-widget .message-text {
  font-size: 12px;
  line-height: 16px;
}
.small-chat-widget .massages-ebox-left, .small-chat-widget .massages-ebox-right {
  padding: 8px 10px;
}
.small-chat-widget .massages-name-time {
  display: block;
  font-size: 10px;
  line-height: 14px;
  margin-top: 5px;
}
.small-chat-widget .message-convo-img-div {
  width: 120px;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;
}
.small-chat-widget .message-convo-img-div img, .small-chat-widget .message-convo-img-div video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.small-chat-widget .massages-ebox-file-type .message-convo-img-div {
  padding: 8px 10px;
}
.massages-ebox-file-type .message-convo-img-div .file-type {
  font-size: 8px;
  line-height: 12px;
}
p.file-name {
  font-size: 12px;
  line-height: 14px;
}
.small-chat-widget .massages-ebox-content-sec .massages-ebox-left, .small-chat-widget .massages-ebox-content-sec .massages-ebox-right {
  padding: 6px 8px;
}
.small-chat-widget .messages-other-ebox-left, .small-chat-widget .messages-other-ebox-right {
  max-width: 240px;
  width: auto;
  margin-bottom: 10px;
}
.small-chat-widget .messages-other-ebox-right {
  margin-left: auto;
}
.small-chat-widget .quote-msg-sec {
  padding: 8px 8px;
}
.small-chat-widget .quote-icons {
  line-height: 10px;
}
.small-chat-widget .quote-icons svg {
  width: 12px;
  height: 12px;
}
.small-chat-widget .quote-msg-inner-sec {
  padding-left: 10px;
}
.small-chat-widget .quote-msg-inner-sec p {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}
.small-chat-widget .quote-msg-info {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}
.small-chat-widget .quote-msg-inner-sec .massages-ebox-file-type p {
  margin-bottom: 0;
}

.messages-other-box.small-chat-widget {
  height: 450px;
  display: flex;
  flex-direction: column;
}
.messages-other-box.small-chat-widget {
  transform: scale(0);
  transition: 0.5s all;
}
.messages-other-box.small-chat-widget.active {
  transform: scale(1);
  transition: 0.5s all;
  transform-origin: bottom right;
}
.messages-other-box.small-chat-widget .react-input-emoji--input::-webkit-scrollbar {
  width: 4px;
}
/* 
.messages-other-box.small-chat-widget .messages-other-box-main {
  overflow: hidden;
}
.messages-other-box.small-chat-widget .messages-other-box-type {
  padding: 0px;
}
.messages-other-box.small-chat-widget .message-write-box-main {
  padding: 8px 12px 10px;
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  gap: 8px;
}
.messages-other-box.small-chat-widget .file-attached-view-box {
  padding: 8px 10px;
  gap: 16px 12px;
  border-radius: 0px;
  max-height: 130px;
}
.messages-other-box.small-chat-widget .attached-item {
  width: 48px;
  height: 48px;
  border-radius: 10px;
}
.messages-other-box.small-chat-widget .delete-attached-items button.messages-other-close {
  width: 20px;
  height: 20px;
}
.messages-other-box.small-chat-widget .attached-item-innner {
  border-radius: 10px;
}
.messages-other-box.small-chat-widget .delete-attached-items {
  right: -10px;
}
.messages-other-box.small-chat-widget .attached-item-innner.file-type-item {
  padding: 8px 12px; 
}
.messages-other-box.small-chat-widget .messages-other-box-main-ebox {
  height: 100%;
}
.messages-other-box.small-chat-widget .quotedmsg-send-box {
  padding: 8px 32px 8px 10px;
  border-radius: 0 0;
}
.messages-other-box.small-chat-widget .quotedmsg-send-box .massages-ebox-file-type {
  max-width: 240px;
  width: fit-content;
}
.messages-other-box.small-chat-widget .quote-model button {
  width: 100%;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
}
.messages-other-box.small-chat-widget .quote-model button svg {
  margin-right: 4px;
  width: 18px;
  height: 18px;
}
.messages-other-box.small-chat-widget .quotedmsg-send-box {
  max-height: 150px;
  min-height: 50px;
}
.messages-other-box.small-chat-widget .close-icons {
  top: 4px;
  z-index: 10;
  right: 12px;
}
.messages-other-box.small-chat-widget .close-icons svg {
  width: 20px;
  height: 20px;
}
.messages-other-box.small-chat-widget .react-input-emoji--wrapper {
  margin: 4px 0px 2px 0px !important;
}
.messages-other-box.small-chat-widget .messages-other-box-type-icon {
  gap: 8px;
}
.messages-other-box.small-chat-widget .react-emoji {
  align-items: flex-end;
  gap: 8px;
}
.messages-other-box.small-chat-widget .react-input-emoji--button {
  width: 28px;
  height: 28px;
}
.messages-other-box.small-chat-widget .react-input-emoji--container {
  width: 186px;
}
.messages-other-box.small-chat-widget .messages-send-btn {
  padding: 0;
  font-size: 12px;
  margin-left: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages-other-box.small-chat-widget .react-input-emoji--input {
  padding: 0px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.messages-other-box.small-chat-widget .react-input-emoji--placeholder {
  left: 0;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
} */
.messages-other-photo-in {
  border-radius: 50px;
  background-color: #e8e8e8;
  border: 1px solid #d9d9d9;
}
.messages-other-photo-in img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  object-fit: cover;
}

.chat-module-main {
  padding: 72px 0px 0px 0px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: flex;
}
.cstm-mob-star {
  margin-right: 12px;
  cursor: pointer;
}
.chat-noti-lt2 {
  border-radius: 50px;
  border: 2px solid #ffffff;
  height: 12px;
  width: 12px;
  position: absolute;
  content: "";
  bottom: 0px;
  right: 0;
}
.mobile-back-arrow, .user-chat-listing-back {
  display: none;
  margin-right: 16px;
  cursor: pointer;
}
.chat-module-left {
  height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  transition: all 0.5s;
  overflow: hidden;
  background: #fff;
  width: 330px;
  border-right: 1px solid #e6e6e6;
  position: fixed;
  border-top: 1px solid #e6e6e6;
  z-index: 1;
}
.chat-module-right {
  height: calc(100vh - 96px);
  max-height: calc(100vh - 96px);
  transition: all 0.5s;
  overflow: hidden;
  background: #fff;
}
.chat-module-center {
  width: 100%;
  margin: auto;
  grid-area: chatmsg;
  height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  transition: all 0.5s;
  overflow: hidden;
  background: #fff;
  padding-left: 330px;
  border-top: 1px solid #e6e6e6;
}
.chat-module-left .userlist {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  float: none;
  display: block;
  margin: 0 auto;
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0 !important;
}
.chat-module-left .card {
  box-shadow: none;
  border: none !important;
}
.cstm-new-chat-right img {
  cursor: pointer;
}
.cstm-new-chat-left {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
}
.chat-edit-sd {
  cursor: pointer;
}
.cstm-new-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.header-chat-cont-head-search {
  position: sticky;
  padding: 24px 12px 16px 24px;
}
.chat-search-list-box {
  display: flex;
  justify-content: space-between;
}
.chat-left-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.chat-left-heading h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 0px;
}
.chat-left-heading a {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #351bce;
}
.chat-search-list {
  position: relative;
}
.header-chat-cont-head-search {
  position: relative;
}
.chat-search-close {
  padding: 0px;
  border: none;
  background: transparent;
  position: absolute;
  right: 14px;
  top: 8px;
  font-size: 25px;
  font-weight: 300;
  display: none;
  color: #201e29;
  font-family: "Inter", sans-serif;
}
.chat-search-list:after {
  background-image: url(../Images/SearchSidebar.svg);
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  top: 11px;
  left: 18px;
}
.chat-search-list input {
  width: 238px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 10px 16px 10px 46px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
}
.cstm-chat-mn-sd {
  overflow: auto;
  height: calc(100vh - 156px);
  transition: all 0.3s;
  padding: 0px 8px 0px 12px;
  margin-right: 4px;
}
.cstm-chat-mn-sd::-webkit-scrollbar {
  width: 5px;
}
.cstm-chat-mn-sd::-webkit-scrollbar-track {
  border-radius: 10px;
}
.cstm-chat-mn-sd::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.cstm-chat-mn-sd:hover::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.chat-card-header .btn-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
  text-decoration: initial;
  padding: 0px 0px 0px 24px;
  border: 0px;
  position: relative;
  font-family: 'Inter', sans-serif;
}
.chat-card-header .btn-link:hover {
  color: var(--navyblue);
}
.chat-card-header .btn-link:before {
  background-image: url(../Images/ArrowDownSD.svg);
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0px;
  transform: rotate(270deg) !important;
}
.chatarrow .btn-link:before {
  transform: rotate(0deg) !important;
}
.chat-card-header {
  padding: 8px 12px;
}
.chat-module-left img.chat-user-phto {
  border-radius: 50px;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.header-chat-cont-list-cont {
  padding: 0px 90px 0px 72px;
}
.chat-module-left ul.chat-list-user-box li {
  padding: 12px;
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 3px 0px;
}
.chat-module-left ul.chat-list-user-box li:hover {
  background: #f3f5f7;
  border-radius: 12px;
}
.chat-sg-count {
  background: #f05252;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  height: 18px;
  min-width: 18px;
  text-align: center;
  padding: 0px 4px;
}
.chat-sg-msg {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 185px;
  white-space: nowrap;
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-sg-ttl-main {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #201e29 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 122px;
  white-space: nowrap;
  display: block;
  margin-bottom: 2px;
}
.chat-noti-lt {
  border-radius: 6px;
  border: 2px solid #ffffff;
  height: 12px;
  width: 12px;
  position: absolute;
  content: "";
  bottom: 0;
  right: 0px;
}
ul.chat-list-user-box {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.chat-list-user-box li {
  padding: 9px 16px;
  position: relative;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
li.chat-list-select {
  background:#EBE8FA!important;
  border-radius: 12px;
}
.header-chat-cont-list-date {
  position: absolute;
  right: 12px;
  top: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.chat-sg-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-coversation-box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.messages-other-box-main {
  flex-grow: 1;
  /* overflow-y: auto; */
  overflow-y: hidden; 
  position: relative;
  overflow-x: hidden;
}
.messages-other-box-main-ebox {
  display: block;
  overflow: auto;
  padding: 0px 24px 0px;
  height: 100%;
  /* height: 300px; */
}
.messages-other-box-main-ebox::-webkit-scrollbar {
  width: 6px;
  background-color: #e8e8e8;
}
.messages-other-box-main-ebox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e8e8e8;
  border-radius: 10px;
  background-color: #e8e8e8;
}
.messages-other-box-main-ebox::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 px #d8d8d8;
  background-color: #d8d8d8;
}
.messages-other-box-main {
  height: 100%;
}
.messages-other-box-main-say {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.chat-status-log-sec {
  width: 100%;
  text-align: center;
  padding: 13px 0px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.chat-status-log-sec p {
  margin-bottom: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #201e29;
  display: inline-block;
  background: #fff; 
  padding: 3px 80px;
  border-radius: 10px;
}
.chat-coversation-box .messages-other-ebox-left,
 .chat-coversation-box .messages-other-ebox-right {
  max-width: fit-content;
  width: auto;
}
.chat-coversation-box .messages-other-ebox-right {
  margin-left: auto;
  padding-left: 70px;
}
.chat-coversation-box .massages-ebox-left, .chat-coversation-box .massages-ebox-right {
  text-align: left;
  max-width: 100%;
  width: 100%;
  display: block;
}
.messages-left-box-sec, .messages-right-box-sec {
  position: relative;
  padding: 0 0px;
  width: fit-content;
  max-width: 600px;
}
.messages-left-box-sec {
  background: #edf0f2;
  border-radius: 10px 10px 0px 10px;
  margin-left: 48px;
}
.messages-right-box-sec {
  margin-left: auto;
  border-radius: 10px 10px 0px 10px;
  background: #5646e5;
  text-align: left;
}
.dotted-icon {
  position: absolute;
  top: 0px;
  border: none;
  background: 0;
  display: none;
  z-index: 2;
}
.dotted-icon.quote-active {
  display: block;
}
.messages-other-ebox-left .dotted-icon {
  right: -35px;
}
.messages-other-ebox-left:hover .dotted-icon {
  display: block;
}
.messages-other-ebox-right .dotted-icon {
  left: -50px;
}
.messages-other-ebox-right:hover .dotted-icon {
  display: block;
}
.massages-ebox-left, .massages-ebox-right {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px;
  display: inline-block;
}
.massages-ebox-left {
  color: #201e29;
  background: transparent;
  border-radius: 0px 0px 10px 0px;
}
.massages-ebox-right {
  color: #fff;
  background: transparent;
  border-radius: 0px 0px 0px 10px;
}
.media-only .massages-ebox-right .message-convo-img-div {
  border-bottom-right-radius: 0px;
}
.media-only .massages-ebox-left .message-convo-img-div {
  border-bottom-right-radius: 0px; 
}
.media-only .massages-ebox-right {
  padding: 0px;
}
.media-only .quote-msg-sec {
    padding: 0px;
}
.media-only .quote-icons {
  padding: 12px 16px 0px 16px;
}
.media-only p.message-text {
  padding: 12px 16px;
  max-width: 200px;
}
.media-only .quote-msg-info {
  padding: 6px 16px 0px 16px;
}
.media-only .quote-msg-inner-sec p {
  padding: 12px 16px 0px 16px;
  max-width: 200px;
  word-wrap: break-word;
}
.media-only .massages-ebox-left {
  padding: 0px;
}
.chat-coversation-box .message-convo-img-div {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  position: relative; 
} 
.chat-coversation-box .message-convo-img-div img, .chat-coversation-box .message-convo-img-div video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.chating-empty {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.chating-start-screen {
  text-align: center;
}
.chat-pdf-icon {
  cursor: pointer;
  position: absolute;
  right: 12px;
}
.massages-ebox-content .file-name {
  color: #201e29;
}
.chat-pdf-name-title { 
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}
.chat-pdf-name-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}
.chat-pdf-download {
  margin-right: 8px;
  display: contents;
}
.chat-pdf-size {
  height: 48px;
  width: 48px;
  object-fit: cover;
  min-width: 48px;
}
.massages-ebox-file-type {
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  padding: 4px 12px 4px 4px;
  align-items: center;
  position: relative;
}
.massages-ebox-file-type .message-convo-img-div {
  width: 48px;
  height: 48px;
  padding: 5px 10px;
  border-radius: 13px;
  background: #351bce;
}
.chat-coversation-box .massages-ebox-file-type .message-convo-img-div {
  width: 64px;
  height: 64px;
  padding: 8px 14px;
  border-radius: 20px;
}
.file-name {
  flex: 1 1;
  margin-bottom: 0;
  width: calc(100% - 72px);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.massages-ebox-file-type .message-convo-img-div {
  position: relative;
}
.massages-ebox-file-type .message-convo-img-div .file-type {
  position: absolute;
  bottom: 12px;
  width: 100%;
  text-align: center;
  left: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
}
.chat-coversation-box .message-convo-img-div .file-type {
  bottom: 16px;
}
.chat-coversation-box .message-write-box-main {
  display: flex;
  width: 100%;
  align-items: end;
  padding: 16px 76px 16px 16px;
  /* padding: 16px 12px; */
  /* max-width: 1222px; */ 
  margin: auto;
}
.chat-coversation-box .messages-other-box-type-text {
  flex-grow: 1;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: relative;
}
.message-write-box-main-mn {
  border-top: 1px solid #e6e6e6;
}
.messages-send-btn {
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-left: 16px;
}
.messages-other-box-type-icon {
  display: flex;
  align-items: center;
}
.messages-send-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.chat-coversation-box .react-input-emoji--button {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-coversation-box .react-input-emoji--wrapper {
  margin: 13px 0px;
}
.chat-coversation-box .messages-other-box-type-text .react-input-emoji--input {
  padding: 0px;
  word-break: break-all;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
  font-family: "Inter", sans-serif;
}
.chat-coversation-box .react-emoji {
  align-items: end !important;
}
.chat-coversation-box .react-input-emoji--button svg {
  fill: #53637f;
}
.chat-coversation-box .messages-other-box-type-text .react-input-emoji--container {
  width: 100%;
  background: transparent !important;
}
.messages-other-box-type-text .react-input-emoji--placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
  font-family: "Inter", sans-serif;
}
.react-input-emoji--input::-webkit-scrollbar {
  width: 6px;
}
.react-input-emoji--input::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-radius: 0px;
}
.chat-coversation-box .react-input-emoji--input::-webkit-scrollbar-thumb {
  border-radius: 50px;
  border: 4px solid transparent;
  -webkit-box-shadow: inset 0 0 px #d8d8d8;
}
.file-attached-view-box {
  /* padding: 22px 20px 14px 20px; */
  padding: 22px 24px 14px 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(76px, 1fr));
  overflow-y: auto;
  gap: 16px;
  /* max-width: 1218px; */
  margin: auto;
}
.file-attached-view-box::-webkit-scrollbar {
  width: 6px;
}
.file-attached-view-box::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-radius: 0px;
  background-clip: padding-box;
}
.attached-item {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  -webkit-aspet-ratio: 1;
  -o-aspet-ratio: 1;
  aspect-ratio: 1;
}
.attached-item-innner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.attached-item img, .attached-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.delete-attached-items {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}
.attached-item-innner.file-type-item {
  position: relative;
}
.chat-coversation-box .attached-item-innner.file-type-item .file-type {
  position: absolute;
  bottom: 16px;
  width: 100%;
  text-align: center;
  left: 0;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
}
.chat-coversation-box .message-convo-img-div .file-type {
  bottom: 16px;
}
.delete-attached-items button.messages-other-close {
  width: 20px;
  height: 20px;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: none;
  background: #201e29;
  color: #fff;
  border: 1px solid #ffffff;
}
.quote-model {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius:6px;
  overflow: hidden;
  position: absolute;
  top: 38px;
  z-index: 10; 
  width: max-content;
  display: none;
  min-width: 120px;
}
.quote-model.quote-active {
  display: block;
}
.cstm-mob-dtd .quote-model {
  right: 5px;
}
.quote-model button {
  width: 100%;
  padding: 8px 10px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #fff;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
}
.quote-model button:hover {
  background: #f3f5f7;
}
.quote-model button svg {
  margin-right: 8px;
}
.quote-model button img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}
.cstm-delete-only {
  color: #ff4852 !important;
}
.chat-profile-sec {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100%;
}
.chat-user-basic-details {
  padding: 24px 24px 16px;
  border-bottom: 1px solid rgba(32, 30, 41, 0.2);
  text-align: center;
  position: relative;
}
.chat-user-profile-img {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  background: #f3f5f7;
  filter: drop-shadow(0px 2px 8px rgba(39, 39, 53, 0.1));
  border-radius: 120px;
  overflow: hidden;
  position: relative;
}
.chat-user-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-user-profile-details h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 12px;
}
.chat-user-profile-details p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #201e29;
  opacity: 0.7;
}
.chat-user-details-sec {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.chat-user-details-content {
  flex-grow: 1;
  height: auto;
  overflow-y: hidden;
}
.chat-user-details-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}
.chat-user-tabs {
  width: 33.33%;
  font-style: normal;
  text-align: center;
}
.chat-user-tabs-a {
  padding: 8px 0px;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(32, 30, 41, 0.2);
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(9, 31, 72, 0.7);
}
.chat-user-tabs-a.active {
  color: #351bce;
  border-bottom: 1px solid #351bce;
}
.chat-tab-content {
  height: 100%;
  overflow: hidden;
}
.chat-tab-content .tab-pane {
  height: 100%;
  overflow: auto;
  padding: 16px;
}
.message-write-box-bx {
  border-top: 1px solid #e6e6e6;
}
.chat-tab-content .tab-pane::-webkit-scrollbar {
  width: 3px;
  background-color: #e8e8e8;
}
.chat-tab-content .tab-pane::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffffff;
  border-radius: 10px;
  background-color: #ffffff;
}
.chat-tab-content .tab-pane::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 px #d8d8d8;
  background-color: #d8d8d8;
}
.chat-user-grid-box {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(96px, 96fr));
}
.chat-user-grid-img {
  width: 96px;
  height: 96px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #f3f5f7;
}
.chat-user-grid-img img, .chat-user-grid-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-user-file-box, .chat-user-link-box {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.chat-user-link-box {
  padding: 8px;
  border: 1px solid rgba(32, 30, 41, 0.2);
  border-radius: 10px;
}
.chat-user-file-icon {
  width: 48px;
  height: 48px;
  border-radius: 15px;
  background: #351bce;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-user-link-icon {
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-user-file-content, .chat-user-link-content {
  flex-grow: 1;
  padding: 0px 16px 0px 10px;
}
.chat-user-file-content {
  width: calc(100% - 56px);
}
.chat-user-link-content {
  width: calc(100% - 40px);
}
.chat-user-file-content h4, .chat-user-link-content h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.chat-user-link-content h4 {
  margin-bottom: 0;
}
.chat-user-file-content p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 0;
}
.message-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 0;
  color: #201e29;
}
.massages-ebox-left .message-text {
  color: #201e29;
  word-break: break-all;
}
.massages-ebox-right .message-text {
  word-break: break-all;
  text-align: left;
}
.messages-other-ebox-right .massages-name-time {
  margin-left: auto;
}
.messages-other-ebox-left .massages-name-time {
  display: flex;
  margin-top: 4px;
  align-items: center;
  position: relative;
  margin-bottom: 6px;
}
.quote-msg-sec {
  padding: 12px 16px 0px 16px;
  border-radius: 10px 10px 0px 0px;
}
.messages-right-box-sec .quote-msg-sec {
  text-align: left;
}
.messages-left-box-sec .quote-msg-sec {
  text-align: left;
}
.quote-icons, .close-icons {
  color: #201e29;
}
.message-write-quote-filearea .quote-msg-inner-sec p {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
  opacity: 1;
  margin-bottom: 7px;
  word-break: break-all;
  text-align: left;
}
.message-write-quote-filearea .quote-msg-info {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}

.massages-ebox-right.msg-media-div .message-convo-img-div, .massages-ebox-right.msg-media-div .massages-ebox-file-type, .massages-ebox-left.msg-media-div .message-convo-img-div, .massages-ebox-left.msg-media-div .massages-ebox-file-type {
  /* margin-bottom: 8px; */
}
.quotedmsg-send-box .message-convo-img-div {
  margin-bottom: 12px;
}
.quotedmsg-send-box {
  padding: 14px 40px 14px 24px;
  position: relative;
  overflow-y: auto;
}
.quotedmsg-send-box::-webkit-scrollbar {
  width: 6px;
}
.quotedmsg-send-box::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-radius: 0px;
  background-clip: padding-box;
}
.quote-msg-inner-sec .massages-ebox-file-type .message-convo-img-div {
  margin-bottom: 0px;
}
.quote-msg-inner-sec .massages-ebox-file-type {
  margin-bottom: 10px;
}
.massages-ebox-file-type .message-convo-img-div {
  margin-bottom: 0px !important;
}
.message-write-box .quotedmsg-send-box .massages-ebox-file-type {
  max-width: 240px;
  margin-bottom: 10px;
}
.quote-msg-written-box-mn {
  border-top: 1px solid #e6e6e6;
}
.quote-close-btn-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.quote-msg-written-box {
  position: relative;
  width: 100%;
  margin: auto;
}
.close-icons {
  position: absolute;
  top: 13px;
  z-index: 10;
  right: 24px;
  cursor: pointer;
}
.chat-coversation-box .messages-obhl-name {
  font-weight: bold;
  margin-left: 8px;
}
.small-chat-widget .typing-div {
  font-size: 12px;
  line-height: 16px;
  padding: 8px 10px;
  margin-bottom: 10px;
}
.typingdot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  margin-left: 2px;
  display: inline-block;
}
.typingdot:nth-child(1) {
  animation-delay: 200ms;
}
.typingdot:nth-child(2) {
  animation-delay: 300ms;
}
.typingdot:nth-child(3) {
  animation-delay: 400ms;
}
.typingdot:first-child {
  margin-left: 0;
}
@keyframes mercuryTypingAnimation {
  0% {
      transform: translateY(0px);
 }
  28% {
      transform: translateY(-7px);
 }
  44% {
      transform: translateY(0px);
 }
}
.messages-other-ebox-left .quote-msg-inner-sec .massages-ebox-file-type {
  background: #f3f5f7;
}
.chat-back-cta {
  display: none;
  position: absolute;
  cursor: pointer;
}
.chat-back-cta svg {
  fill: #091f48;
}
.chat-mobile {
  display: none;
}
@media screen and (max-height:930px) {
.cstm-add-members ul.chat-list-user-box {
  height: 350px;
}
}
@media screen and (max-height:750px) {
  .cstm-add-members ul.chat-list-user-box {
    height: 200px;
  }
  }

@media screen and (max-width: 1439.98px) {
  .header-chat-cont-list-cont {
      width: 100%;
 }
  .chat-user-grid-box {
      grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
 }
  .chat-user-grid-img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
 }
}
@media screen and (max-width: 1365.98px) {
  .chat-module-main {
      grid-template-columns: minmax(0, 6fr) minmax(584px, 584px) minmax(0px, 6fr);
 }
  .chat-sg-msg {
      width: calc(100% - 12px);
 }
}
@media screen and (max-width: 1279.98px) {
  .chat-user-grid-box {
      grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
 }
  .file-attached-view-box {
      grid-template-columns: repeat(auto-fill, minmax(56px, 1fr));
 }
}
@media screen and (max-width: 1199.98px) {
  .chat-module-main {
      grid-template-columns: minmax(0, 6fr) minmax(500px, 500px) minmax(0px, 6fr);
      padding-top: 80px;
 }
  .chat-coversation-box .messages-other-ebox-left, .chat-coversation-box .messages-other-ebox-right {
      max-width: 380px;
 }
  .chat-coversation-box .message-convo-img-div {
      width: 120px;
      height: 90px;
      aspect-ratio: 4/3;
 }
  .massages-ebox-left, .massages-ebox-right {
      padding: 8px;
 }
  .chat-module-left, .chat-module-right, .chat-module-center {
      height: calc(100vh - 80px);
      max-height: calc(100vh - 80px);
 }
}
@media screen and (max-width: 1099.98px) {
  .chat-module-main {
      position: relative;
      grid-template-areas: "leftside chatmsg";
      grid-template-columns: minmax(0, 300px) minmax(500px, 1fr);
 }
  .chat-module-center {
      max-width: 100%;
 }
  .chat-module-right {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
      z-index: 4;
      background: rgba(9, 31, 72, 0.6);
      transition: all 0.2s;
 }
  .chat-module-right.active {
      display: block;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
 }
  .chat-module-right .chat-profile-sec {
      width: 300px;
      margin-left: auto;
      background: #ffffff;
      position: relative;
      transform: translateX(100%);
      transition: all 0.5s;
 }
  .chat-module-right.active .chat-profile-sec {
      transform: translateX(0%);
 }
  .chat-user-basic-details {
      position: relative;
 }
  .chat-back-cta {
      display: block;
      left: 8px;
      top: 16px;
 }
  .header-noti-box-in {
      left: auto;
      right: 0;
 }
}
@media screen and (max-width: 1023.98px) {

  .header-ft-menu .header-ft-menu-view {
      display: block;
 }
}
@media screen and (max-width: 999.98px) {
  .header-ft-menu-view {
      display: none;
 }
  .header-ft-menu .header-menu-icon {
      display: flex;
      width: 100%;
      height: 100%;
 }
}
@media screen and (max-width: 839.98px) {
  .webfooter.active {
      border-radius: 00px;
      right: 0px;
      bottom: 0px;
      box-shadow: none;
      visibility: visible;
      background-color: #fff;
      padding: 8px 16px 20px;
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 10;
      border-top: 1px solid #b9b9b9;
 }
  .webfooter.active .footer-icon {
      display: block;
 }
  .webfooter.active .header-ft-menu-view .footer-icon {
      width: auto;
      height: auto;
 }
  .webfooter.active .header-ft-menu-view {
      padding: 0 19px;
 }
  .chat-module-main {
      padding-top: 56px;
 }
  .chat-module-left, .chat-module-center {
      height: calc(100vh - 76px);
      max-height: calc(100vh - 76px);
 }
  .quote-msg-sec {
      padding: 7px 8px;
 }
  .chat-module-right {
      position: fixed;
      z-index: 99;
      height: 100%;
      max-height: 100%;
 }
  .chat-module-main {
      grid-template-columns: minmax(200px, 270px) minmax(auto, 1fr);
 }

 .chat-left-heading {
      margin-bottom: 8px;
 }
}
@media screen and (max-width: 767.98px) {
  .close-icons {
      right: 10px;
 }
  .quotedmsg-send-box {
      padding: 14px 30px 14px 15px;
 }
  .messages-other-box-main-ebox {
      padding: 0px 15px 0px;
 }
  .mobh-cont {
      margin-right: 16px;
 }
  .mobh-updn {
      display: none;
 }
  .messages-other-box-head {
      padding: 12px;
 }
  .chat-module-center {
      padding-left: 0px;
 }
  .chat-search-list {
      width: 83%;
 }
  .chat-module-left {
      width: 100%;
      border-right: none;
 }
  .chat-module-center {
      position: fixed;
      z-index: 10;
      left: 100%;
      transition: 0.5s all;
 }
  .chat-module-center.active {
      left: 0%;
 }
  .messages-other-box-head-left, .messages-other-box-head-left .messages-obhl-photo {
      display: flex;
      align-items: center;
 }
  .chat-module-main {
      grid-template-columns: minmax(100%, 100%) minmax(0, 0);
      grid-gap: 0;
      padding-left: 0;
      padding-right: 0;
 }
  .mobile-back-arrow, .user-chat-listing-back {
      display: block;
 }
  .chat-sg-ttl-main {
      width: calc(100% - 40px);
 }
  .header-ft-menu-view {
      display: block;
 }
  .header-ft-menu .header-menu-icon {
      display: none;
 }
  .chat-heading-mobile {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
 }
  .chat-heading-mobile .user-chat-listing-back {
      margin-right: 10px;
 }
  .quote-icons svg {
      width: 18px;
      height: 18px;
 }
}
