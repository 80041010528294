.onebutton-btn-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: var(--spacing-16, 16px);
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
  border-radius: 0px 0px 12px 12px;
}

.twobuttons-btn-footer {
  display: flex;
  width: 100%;
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  background: var(--color-bg-elevated, #fff);
  justify-content: space-between;
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
  border-radius: 0px 0px 12px 12px;
}

.fluidbutton-btn-footer {
  display: flex;
  width: 100%;
  padding: var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-16, 16px);
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-elevated, #fff);
  border-radius: 0px 0px 12px 12px;
}

.fluidbutton-btn-footer button {
  width: 100%;
}

.fluidbutton-p {
  display: flex;
  padding: 0px var(--spacing-16, 16px);
  gap: 10px;
  align-self: stretch;
  color: var(--color-text-neutral-secondary);
  text-align: center;
  font: var(--body-medium);
  justify-content: center;
}

.fluidbutton-p a {
  color: var(--color-text-brand-strong, #0b42cf);
  font-weight: 700;
  cursor: pointer;
}
