.chipmember-container {
    padding: var(--spacing-4);
    border-radius: var(--radius-full);
    border: 1px solid var(--color-border-neutral-strong);
    width: -moz-fit-content;
    width: fit-content;
    max-height: 32px;
}

.chipmember-content {
    padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-8);
}

.chipmember-content span {
    font: var(--body-small);
    color: var(--color-text-neutral-strong);
    display: block;
}