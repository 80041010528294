.avatar-large {
    display: inline-flex;
    position: relative;
}

.avatar-large-in {
    border-radius: 50px;
    overflow: hidden;
    position: relative;
}

.avatar-large-96 {
    display: flex;
    width: 96px;
    height: 96px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 50px;
}

.avatar-large .online-status {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.avatar-large-edit {
    width: 100%;
    padding: var(--spacing-6) var(--spacing-8);
    background: var(--color-bg-blackout-strong);
    bottom: 0px;
    cursor: pointer;
    box-sizing: border-box;
    color: var(--color-text-static-white);
    text-align: center;
    font: var(--body-2x-small);
}

.avatar-large-96 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    flex-shrink: 0;
    background: var(--color-bg-neutral-strong, #041527);
    border-radius: var(--radius-full, 999px);
    color: var(--color-text-inverse, #FFF);
    text-align: center;
    font: var(--title-title1);
    letter-spacing: -0.3px;
    text-transform: capitalize;
}

.mds-large-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg-neutral-secondary);
}

.model-group-icon-container {
    padding: var(--spacing-12) var(--spacing-24) var(--spacing-20);
    width: 100%;
    gap: var(--spacing-16);
}

.model-group-icon-container .text-field-main {
    max-width: 100%;
}

.model-group-icon-container .hr-spacing-8 {
    height: 8px;
}