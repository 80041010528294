.your-social-email-text {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-medium);
  text-align: left;
  margin-bottom: 24px;
}
.something-icon {
  height: 25px;
  vertical-align: sub;
}

.cstm-login-screen-popup .desk-globle-model-box-inner {
  max-width: 490px;
  position: relative;
  width: 92%;
}

.cstm-login-screen-popup .modal-content {
  padding: 40px;
}

.cstm-sww-close {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
  color: #a3a3a3;
  font-size: 32px;
}

.cstm-login-screen-popup h2 {
  font-family: Muller;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: var(--black2);
  margin-bottom: 30px;
}

.cstm-sso-modal .sso-modal-content {
  width: 100%;
  padding: 30px;
}

.cstm-sso-modal .sso-modal-content .cstm-rn-error {
  font-size: 12px;
}

.cstm-sso-modal .sso-modal-content label {
  margin-bottom: 10px;
  margin-top: 25px;
  font-weight: 500;
}

.cstm-sso-modal .sso-modal-content .top-error-message {
  padding-bottom: 20px;
  font-size: 12px;
}
.cstm-sso-modal .sso-modal-content .text-field-main {
  max-width: 100%;
}

.cstm-sso-modal .cstm-try-contact-btn {
  width: 100%;
  margin: 20px 0 0;
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.cstm-somr-icom {
  text-align: center;
  margin-bottom: 24px;
}

.cstm-login-screen-popup p {
  font-size: 16px;
  line-height: 24px;
}

.cstm-login-screen-popup p b {
  color: var(--black2);
  font-weight: 600;
}

.cstm-try-contact-btn {
  margin-top: 22px;
  gap: 20px;
  display: flex;
  justify-content: center;
}

.cstm-try-contact-btn button {
  width: 170px;
  padding: 12px;
}

.cstm-gmv-main-img.zoom-img {
  transform: scale(2);
}

.input-chat-container .MuiInputBase-root:after {
  border-bottom: inherit !important;
}

.input-chat-container .MuiInputBase-root:before {
  border-bottom: none !important;
}

.uic-fl-mn-right .uic-fl-btn {
  position: relative;
}

.export-loading-image {
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffffe3;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cstm-pli-bg-exportfile img {
  width: 30px;
  margin-top: 10px;
}

.uic-fl-import-btn {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-left: 20px;
}

.uic-fl-mn {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}

.import-loading-image {
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffffe3;
  width: 100%;
  height: 100%;
  text-align: center;
}

.import-loading-image img {
  width: 30px;
  margin-top: 9px;
}

.import.text {
  background: #351bce;
  border: 1px solid #351bce;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  height: 48px;
  padding: 0px 40px;
  margin: 0px;
  box-shadow: none;
  width: auto;
  display: block;
}

.import.text:hover {
  background: #201e29;
  border: 1px solid #201e29;
}

span.cstm-resend-code-loader img {
  width: 28px;
}

span.cstm-resend-code-loader-img {
  width: 100%;
  position: absolute;
  background: #ffffffc7;
}

span.cstm-resend-code-loader {
  position: relative;
}

.active-list {
  background: #f3f5f7 !important;
}

.deactivate-loading-image {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 999;
  left: 0;
  background: #ffffffcf;
  top: 0px;
  text-align: center;
  line-height: 46px;
}

.deactivate-loading-image img {
  width: 24px;
}

button.deactivate-loader-container {
  position: relative;
}

.cstm-hide-right-shadow .owl-stage-outer:after {
  display: none !important;
}

.cstm-hv-op-drop.cstm-open-dp {
  display: block;
}

.cstm-hv-op-drop.cstm-open-dp {
  display: block;
}

.historyvideo-remove-saved-video-loader {
  position: relative;
}

.historyvideo-remove-saved-video-loader .historyvideo-loading-image {
  line-height: 46px;
}

.cstm-an-red {
  background: #fdf2f2 !important;
  border: 1px solid #e02424 !important;
}

.cstm-an-red .cstm-success-msg-container-title {
  color: #e02424;
}

.cstm-an-red .cstm-success-msg-container-cont {
  color: #e02424;
}

.cstm-recent-dropdown-menu ul li a.cstm-filter-active {
  color: #2329c9 !important;
}

.cstm-dwdt {
  position: absolute;
  right: 50px;
  bottom: 24px;
  display: flex;
}

span.cstm-dwdt-in {
  cursor: pointer;
  margin-left: 24px;
}

.cstm-gmv-close {
  background: none;
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  padding: 0px;
}

.cstm-gmv-main-img video {
  max-height: 100%;
  object-fit: cover;
}

.cstm-gmv-main-img img {
  max-height: 100%;
  object-fit: cover;
}

.cstm-gv-user-info-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  word-break: break-all;
}

.cstm-gv-user-info-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  opacity: 0.7;
}

.cstm-gv-user-photo img {
  width: 48px;
  height: 48px;
  min-height: 48px;
  max-width: 48px;
  object-fit: cover;
  border-radius: 50px;
}

.cstm-popup-ptimg .modal-dialog {
  max-width: 100%;
  margin: 20px;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(54.5px);
  backdrop-filter: blur(54.5px);
  border-radius: 20px;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cstm-gmv-main {
  width: 100%;
  text-align: center;
  background: transparent;
  height: 100%;
}

.cstm-gv-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  color: #fff;
  z-index: 11;
  left: 20px;
  top: 20px;
}

.cstm-gv-user-photo {
  margin-right: 16px;
}

.cstm-gv-user-info {
  text-align: left;
}

.cstm-selected-video {
  background: #f3f5f7 !important;
  border-radius: 10px;
}

.cstm-gmv-main-img {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: "textfield";
}

.cstm-all-content-library-main-only .owl-nav .disabled {
  display: none;
}

.historyvideo-loading-image {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 999;
  left: 0;
  background: #ffffffcf;
  top: 0px;
  text-align: center;
}

.historyvideo-loading-image img {
  width: 27px;
}

li.historyvideo-remove-single-video-loader {
  position: relative;
}

.historyvideo-save-loading-image {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 999;
  left: 0;
  background: #ffffffcf;
  top: 0px;
  text-align: center;
}

.historyvideo-save-loading-image img {
  width: 27px;
}

li.historyvideo-save-single-video-loader {
  position: relative;
}

.cstm-no-history-video-container {
  padding-right: 20px !important;
}

.savedvideo-save-loading-image {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 999;
  left: 0;
  background: #ffffffcf;
  top: 0px;
  text-align: center;
}

.savedvideo-save-loading-image img {
  width: 27px;
}

li.savedvideo-save-single-video-loader {
  position: relative;
}

.cstm-oops-main {
  text-align: center;
  margin-top: 136px;
}

body .cstm-clear-all-watch-history.filedvalue {
  border: 1px solid #9b9a9f !important;
}

/* .sidebar-menu-popup-main:before {
     position: fixed;
     inset: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.5);
     z-index: -1;
     display: flex;
     align-items: center;
     content: '';
     transition: 0.0s;
} */

.sidebar-menu-popup-main {
  transition: 0.3s;
  -webkit-animation-name: menupp;
  -webkit-animation-duration: 2s;
  animation-name: menupp;
  animation-duration: 2s;
  background: transparent !important;
}

@-webkit-keyframes menupp {
  0% {
    left: -240px;
  }

  25% {
    left: 0px;
  }
}

@keyframes menupp {
  0% {
    left: -240px;
  }

  25% {
    left: 0px;
  }
}

.cstm-active-pp .cstn-pp-f {
  display: none;
}

.cstm-active-pp .cstn-pp-t {
  display: block !important;
}

.cstm-active-pp button {
  color: #fff !important;
  background: #351bce !important;
}

.cstm-ssvpl-pv-drop {
  position: absolute;
  right: -6px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 187px;
}

.cstm-my-videos-history-list-main:hover .cstm-hv-op-drop {
  display: block;
}

.cstm-my-videos-history-list-main-box-img .cstm-rv-time {
  right: 8px;
  bottom: 8px;
}

.cstm-manage-history-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 12px;
}

body .form-group .filedvalue {
  border: 1px solid #9b9a9f !important;
}

.cstm-success-msg-container-title img {
  margin-right: 10px;
}

.cstm-success-msg-container-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #057a55;
  margin-bottom: 4px;
}

.cstm-success-msg-container-cont {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #057a55;
  margin-left: 30px;
}

.cstm-success-msg-container {
  background: #f3faf7;
  border: 1px solid #0e9f6e;
  border-radius: 8px;
  padding: 14px 10px 12px 10px;
  position: fixed;
  left: 24px;
  bottom: 24px;
  width: 100%;
  max-width: 451px;
  transition: 0.5s;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 4s;
  z-index: 3;
}

@-webkit-keyframes example {
  0% {
    left: -451px;
  }

  25% {
    left: 24px;
  }
}

@keyframes example {
  0% {
    left: -451px;
  }

  25% {
    left: 24px;
  }
}

.cstm-pi-main-btn-all img {
  height: 30px;
}

.cstm-pi-main-btn-all {
  text-align: center;
  position: absolute;
  background: #fffffff5;
  width: 100%;
  height: 100%;
  line-height: 40px;
}

hr.mv-line {
  opacity: 1;
  border-top: 1px solid #d9d9d9;
  margin-top: 20px;
  margin-bottom: 24px;
}

body.menuview .card-header button.btn-link:hover span.cstm-tlp {
  display: block;
}

body.menuview .card-header button.btn-link .cstm-tlp {
  width: max-content;
  font-size: 12px;
  position: absolute;
  font-weight: 500;
  line-height: 150%;
  display: none;
  color: #ffffff;
  background: #201e29;
  border-radius: 5px;
  padding: 4px 6px;
  left: 30px;
  top: 39px;
  z-index: 1;
}

body .cstm-admin-area .cstm-all-library-video-details .all-content-main-center {
  padding-left: 0px !important;
}

body.menuview .card-header button.btn-link {
  padding: 12px 14px !important;
}

body.menuview .all-content-main-left {
  width: auto !important;
}

body.menuview .card-header button img {
  margin-right: 0px !important;
}

img.cstn-cl-t {
  display: none;
}

.cstm-recent-dropdown-menu {
  position: absolute;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  top: 32px;
  z-index: 1;
  width: 120px;
}

.cstm-recent-dropdown-menu ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.cstm-recent-dropdown-menu ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29 !important;
  display: block;
  padding: 6px 10px;
}

.cstm-recent-dropdown-menu ul li a:hover {
  color: #351bce !important;
}

.sctm-da-close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0px;
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 200;
}

.cstm-reset-link {
  padding: 0px;
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201e29;
}

.cstm-reset-link:hover {
  color: #351bce;
}

.chatloader {
  padding-top: 35px;
}

.chatloader-main {
  display: flex;
  margin-bottom: 35px;
  gap: 12px;
  justify-content: flex-start;
}

.chatloader .chatloader-main:nth-child(even) {
  justify-content: flex-end;
}

.chatloader .chatloader-main:nth-child(even) .chatloader-main-left {
  order: 2;
}

.chatloader-main-left {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.chatloader-main-right1 {
  height: 15px;
}

.chatloader-main-right2.skeleton-box {
  height: 50px;
}

.chatloader-main-right {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 280px;
}

.cstm-payment-chng {
  max-width: 452px !important;
  justify-content: center;
}

.cstm-payment-chng .um-membership-details-in-price {
  width: 100%;
}

.cstm-preferred {
  max-width: 452px !important;
  justify-content: center;
}

.cstm-cstm-vpp-photo img {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.cstm-vpp-popup {
  max-width: 550px !important;
}

.btn5 {
  background: rgba(147, 145, 151, 0.3);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #201e29;
  height: 48px;
  padding: 12px 32px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

.btn5:hover {
  background: var(--yellow);
  color: #ffffff;
}

.cstm-ep-ldr {
  position: relative;
}

.cstm-ep-ldr-icon {
  position: absolute;
  background: #ffffffeb;
  width: 100%;
  height: 100%;
  text-align: center;
}

.cstm-ep-ldr-icon img {
  width: 30px;
  position: relative;
  top: 5px;
}

.cstm-edit-pf-ld {
  position: relative;
}

.cstm-replace-pp .ReactCrop {
  width: 100%;
}

.cstm-replace-pp .ReactCrop__child-wrapper img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.cstm-rpp-btn {
  margin-top: 20px;
}

.cstm-rpp-main .desk-globle-model-box-inner {
  max-width: 550px;
}

.cstm-sd-line {
  height: 1px;
  width: 87%;
  background: rgba(32, 30, 41, 0.1);
  display: block;
  margin: 12px 0px 10px 16px;
  box-sizing: border-box;
}

.cstm-contant-page ul {
  padding-left: 18px;
}

.mv-no-data-found {
  position: absolute;
  left: 0;
  right: 0;
  top: 48%;
  text-align: center;
  color: #d30000;
  font-weight: 500;
  font-size: 16px;
}

.cstm-tabs-mv-cont {
  min-height: 600px;
  position: relative;
}

span.csrtm-vl-dt {
  margin: 0px 8px;
  font-size: 11px;
}

.cstm-vl-img img {
  border-radius: 10px;
  aspect-ratio: 16/9;
}

.cstm-vl-bt {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #201e29;
}

span.cstm-vl-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #201e29;
  opacity: 0.9;
}

.cstm-vl-title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #201e29;
}

.cstm-vl-tp {
  display: flex;
  flex-direction: column;
}

.cstm-my-videos-list-box-img {
  aspect-ratio: 16/9;
  width: 16%;
  position: relative;
}

.cstm-my-videos-list-box-info {
  width: 84%;
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cstm-my-videos-list-box-img {
  aspect-ratio: 16/9;
  width: 16%;
  position: relative;
}

.cstm-my-videos-list-box-dt {
  position: absolute;
  right: -10px;
  cursor: pointer;
}

.cstm-vl-new {
  background: #ffffff;
  border-radius: 40px;
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 1px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  align-items: center;
  color: #351bce;
}

.cstm-vl-img {
  aspect-ratio: 16/9;
}

.cstm-my-videos-list-box {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.cstm-my-videos-list {
  padding: 24px 24px 4px 24px;
  margin-bottom: 24px;
}

.cstm-my-videos-tabs ul {
  padding: 0px 16px;
  margin: 0px;
  list-style: none;
  display: flex;
}

.cstm-my-videos-tabs ul li a {
  padding: 7px 17px;
  display: block;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #383838;
}

.cstm-my-videos-tabs ul li a:hover:after {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #351bce;
  content: "";
  bottom: -1px;
  left: 0;
}

.cstm-my-videos-tabs .nav-tabs a:after {
  position: absolute;
  height: 2px;
  width: 100%;
  content: "";
  bottom: -1px;
  left: 0;
}

.cstm-my-videos-tabs .nav-tabs a.active:after {
  font-weight: 700 !important;
  color: #000 !important;
  background: #351bce;
}

.get-videos {
  grid-template-columns: initial !important;
  display: block !important;
}

.cstm-my-videos-main {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.cstm-my-videos-title {
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #201e29;
}

.profile-loadind-post {
  position: absolute;
  z-index: 1;
  background: #ffffffeb;
  width: 100%;
  text-align: center;
  left: 0;
  height: 100%;
  min-height: 600px;
}

.profile-loadind-post img {
  height: 50px;
  position: relative;
  top: 48%;
}

span.thumb_err {
  font-size: 14px;
  margin-bottom: 15px;
  display: block;
  margin-top: -15px;
  color: red;
}

.cstm-ns-main-box-right {
  margin-left: 20px;
}

.cstm-email-noti {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #091f48;
  margin-bottom: 30px;
  margin-top: 30px;
}

.cstm-choose-when {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  margin-bottom: 30px;
  margin-top: -15px;
}

.cstm-ps-lable {
  display: block;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  color: #201e29;
  margin-bottom: 8px;
}

span.cstm-ps-cont {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
}

.cstm-ns-main-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
}

.cstm-ns-main {
  padding: 24px;
}

.btn4 {
  background: #fff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  height: 48px;
  padding: 12px 32px;
  display: inline-block;
  text-transform: inherit;
  border: 1px solid #201e29;
  color: #201e29;
}

.btn4:hover {
  background: var(--yellow);
  color: #fff;
  border: 1px solid var(--yellow);
}

.cstm-to-request {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(32, 30, 41, 0.7);
  border-top: 1px solid #d9d9d9;
  margin-top: 32px;
  padding-top: 20px;
}

.cstm-to-request a {
  font-weight: bold;
}

.delete-image {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  opacity: 0.7;
  margin-top: 6px;
  cursor: pointer;
}

.cstm-ac-se-title {
  padding: 16px;
  border-bottom: 1px solid rgba(32, 30, 41, 0.1);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
}

.cstm-profile-info-email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #606060;
  word-wrap: break-word;
}

.apple-btn {
  background-color: #201e29;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 54px;
  width: 100%;
  border: none;
}

.apple-btn:hover {
  background: var(--yellow);
}

.cstm-apple-btn {
  margin-top: 16px;
}

.apple-btn i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 8px;
}

.cstm-error-messages {
  color: red;
}

.cstm-rn-error {
  color: var(--red);
  display: block;
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.cstm-register-now {
  margin: 25px 0px 15px 0px;
}

.cstm-register-now a {
  color: #351bce;
  font-weight: 600;
}

.cstm-register-now a:hover {
  color: #ffcc00;
}

.cstn-pf-dp .quote-model {
  right: 10px;
}

.cstn-pf-dp {
  position: absolute;
  right: 10px;
}

.cstn-pf-dp {
  cursor: pointer;
}

.chat-user-profile {
  position: relative;
}

.cstm-moel-main {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.cstm-moel-name {
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  color: #999999;
  margin-bottom: 5px;
  display: none;
}

.cstm-gp-us-ct {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #606060;
}

.migrate-loading {
  height: 70px;
}

.cstm-sim-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #201e29;
  margin-bottom: 32px;
}

.cstm-simplity {
  height: 100vh;
  max-width: 452px;
  margin: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cstm-wlm {
  width: 100%;
}

.cstm-wlm-main {
  max-width: 454px;
  margin: auto;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cstm-wlm-title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #201e29;
  margin-bottom: 16px;
}

.cstm-wlm-email {
  background: rgba(19, 114, 209, 0.1);
  border-radius: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  padding: 7px 25px;
  margin-bottom: 32px;
}

.cstm-wlm-email i {
  margin-right: 7px;
  color: #351bce;
  font-weight: bold;
}

.cstm-wlm-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 32px;
}

.cstm-wlm-field input {
  height: 64px !important;
  width: 64px !important;
  border-radius: 10px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #201e29 !important;
}

.cstm-wlm-field input:focus {
  background: #dde8f3;
  border: 1px solid #351bce;
  border-radius: 10px;
}

.cstm-wlm-field-slc {
  background: #dde8f3 !important;
  border: 1px solid #351bce !important;
  border-radius: 10px !important;
}

.cstm-wlm-field-slc2 {
  background: #ffebec !important;
  border: 1px solid #ff4852 !important;
  border-radius: 10px !important;
}

.cstm-wlm-field-msg {
  color: #ff4852 !important;
}

.cstm-wlm-field {
  display: flex;
  gap: 14px;
  margin-bottom: 32px;
}

.cstm-didn {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #201e298c;
}

.cstm-didn span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #091f48;
  cursor: pointer;
}

.cstm-didn span:hover {
  color: #ffcc00;
}

.loading-post {
  display: flex;
  flex-direction: column;
}

.cstm-content-archive-main-top h3 {
  max-width: 280px;
  width: 100%;
}

span.loading-btn-up img {
  height: 35px;
}

span.loading-btn-up {
  position: absolute;
  width: 100%;
  background: #ffffffbf;
  text-align: center;
  height: 60px;
  line-height: 55px;
}

.cstm-cm-open-only {
  display: block !important;
}

.cstm-cont-nm {
  float: right;
}

.cstm-edit-profile-only {
  grid-template-columns: inherit !important;
  display: flex !important;
}

.header-menu-dp ul li a i {
  margin-right: 12px;
}

.hf-m {
  display: none !important;
}

.cstm-login .um-left:before {
  position: fixed;
}

.cstm-login .um-left:after {
  position: fixed;
}

.cstm-glb-search {
  grid-template-columns: inherit !important;
  column-gap: 0px !important;
}

.cstm-glb-search .all-content-main-center.cstm-all-content-center {
  max-width: 100%;
  width: 100%;
  padding-left: 30px;
}

.mrgn-bm {
  margin-bottom: 0px !important;
}

.sd-detilas-title button.btn2 {
  margin-left: 20px;
  padding: 4px 12px;
  height: 30px;
  font-size: 12px;
}

.newsfeed-sort-all.show ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.newsfeed-sort-all.show ul li {
  font-size: 16px;
  line-height: 20px;
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.2s all;
}

.newsfeed-sort-all.show ul li:hover {
  background-color: #e7f1fa;
}

.newsfeed-sort-all.show .dropdown-menu.newsfeed-select-in.show {
  padding: 0px;
}

.ms-All-read {
  margin-left: 4px;
}

.an-globle-model-box,
.cstm-change-password {
  display: none;
}

.globle-model-box.globle-search-model-box {
  display: none;
}

.btn-view-more {
  background: #351bce;
  border: 1px solid #351bce;
  box-sizing: border-box;
  border-radius: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 48px;
  margin: 20px auto;
  display: block;
  padding: 0px 32px;
  box-shadow: initial;
  width: auto;
}

.loader-comment-sec {
  margin-top: 16px;
  padding: 16px;
  border-top: 1px solid #ececec;
}

.loader-comment-heading {
  height: 32px;
  width: 30%;
}

.loader-comment-inner {
  margin-top: 10px;
  display: flex;
  gap: 4px;
}

.loader-comment-inner-img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.loader-comment-inner-text {
  width: calc(100% - 48px);
  height: 40px;
}

.loader-comment-inner-2 {
  padding-left: 60px;
  margin-top: 30px;
}

.loader-comment-inner-2 .loader-comment-inner-text {
  height: 90px;
}

.up-sbmit-btn {
  margin-bottom: 60px;
  text-align: center;
  margin-top: 40px;
}

.up-sbmit-btn .btn2 {
  width: 200px;
}

.profile-detilas-comm button.btn2 {
  height: 40px;
  padding: 0px 20px;
}

.cstm-content-details-main .ss-cstm-clss {
  position: relative;
}

.video-details-tabing .cstm-vd-box-in {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.my-anchor-css-class {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
}

.arrow-left-i {
  margin-right: 4px;
}

.cstm-vd-cnt-right button:before {
  display: none;
}

.cstm-vd-cnt-right button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.v-icon-eye,
.v-icon-comment {
  color: #091f48;
  margin-right: 4px;
  transition: 0.2s all;
}

.gallery-main-icon:hover span {
  transition: 0.2s all;
}

.shared-post-user-details {
  position: relative;
}

.shared-post-user-name {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  object-fit: cover;
}

.shared-post-comment-sec {
  background: #fff;
  margin-bottom: 16px;
  border-radius: 0px 0px 5px 5px;
}

.shared-post-user-details {
  display: flex;
  flex-direction: row;
  padding: 16px 12px;
  background: #e8f1fb;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
}

.shared-post-user-img-details {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 8px;
}

.shard-post-icons {
  padding-right: 16px;
}

.shard-post-icons .newsfeed-post-right-header-icon {
  position: inherit;
  right: auto;
}

.post-shared-date-info {
  flex-grow: 1;
}

.shared-post-comment-sec .newsfeed-post.cstm-newsfeed-post {
  margin-bottom: 0px;
}

.shared-post-comment-sec .newsfeed-post-right-cmt {
  padding: 0px 16px 16px;
}

.cstm-content-video-short-sec {
  display: flex;
  align-items: center;
  justify-content: end;
}

.cstm-content-video-short-sec .uic-fl-search {
  margin-bottom: 0px;
}

.frnd-list-name {
  font-weight: 700;
}

.cstmcloseBnt {
  display: block;
  width: 48px;
  height: 48px;
  font-size: 30px;
  line-height: 35px;
  margin: 0;
  background: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
}

.csm-main-pp-post .cp-btn button.cp-btn-dotted img {
  margin-right: 4px;
  margin-top: -3px;
}

.csstm-add-topic-listing.tag-csstm-add-topic-listing .topics-search-label-in {
  height: max-content;
}

.tag-a-friend {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 2px;
}

.csstm-add-topic-listing.tag-csstm-add-topic-listing .topics-search-ovr-in {
  padding: 16px 16px;
}

.cstm-tag-friend-list {
  flex-grow: 1;
  margin-bottom: 20px;
  overflow: auto;
}

.csstm-add-topic-listing .topics-search-ovr {
  /* overflow: auto;
     */
  flex-grow: 1;
  margin-top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.csstm-add-topic-listing .topics-search-ovr.tag-search-ovr-main {
  margin-top: 0px;
}

.cstm-tagfriend-popup:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.cstm-tagfriend-popup .modal-dialog.modal-dialog-centered {
  z-index: 1;
}

.cstm-img-peview-model .awssld__controls__arrow-left:before,
.cstm-img-peview-model .awssld__controls__arrow-right:before,
.cstm-img-peview-model .awssld__controls__arrow-left:after,
.cstm-img-peview-model .awssld__controls__arrow-right:after {
  height: 20px;
  width: 2px;
  opacity: 1 !important;
}

.cstm-img-peview-model .awssld__controls button .awssld__controls__arrow-left,
.cstm-img-peview-model .awssld__controls button .awssld__controls__arrow-right {
  opacity: 1 !important;
}

.cstm-img-peview-model .awssld__controls__arrow-left:before,
.cstm-img-peview-model .awssld__controls__arrow-right:before {
  top: -5%;
}

.cstm-img-peview-model .awssld__controls__arrow-left:after,
.cstm-img-peview-model .awssld__controls__arrow-right:after {
  top: 45%;
}

.cstm-img-peview-model .awssld__controls button {
  opacity: 1;
  transition: 0.5s all;
}

.cstm-img-peview-model .awssld__controls button:hover {
  opacity: 0.7;
}

.cstm-img-peview-model
  .awssld__controls
  button:hover
  .awssld__controls__arrow-left:before,
.cstm-img-peview-model
  .awssld__controls
  button:hover
  .awssld__controls__arrow-right:after {
  opacity: 1;
  transform: rotate(45deg);
}

.cstm-img-peview-model
  .awssld__controls
  button:hover
  .awssld__controls__arrow-left:after,
.cstm-img-peview-model
  .awssld__controls
  button:hover
  .awssld__controls__arrow-right:before {
  opacity: 1;
  transform: rotate(-45deg);
}

.cstm-img-peview-model .awssld__controls button {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #ffffff;
  top: 50%;
  transform: translateY(-50%);
}

.cstm-img-peview-model .awssld__controls button.awssld__next {
  margin-right: 40px;
}

.cstm-img-peview-model .awssld__controls button.awssld__prev {
  margin-left: 40px;
}

.cstm-img-peview-model span.awssld__controls__arrow-left {
  margin-left: 12px;
  margin-top: 3px;
  opacity: 1;
}

.cstm-img-peview-model span.awssld__controls__arrow-right {
  margin-right: 12px;
  margin-top: 3px;
  opacity: 1;
}

.cstm-img-peview-model
  .awssld__controls--active
  .awssld__controls__arrow-right {
  transform: translate3d(0%, 0, 0);
}

.cstm-img-peview-model .awssld__controls__arrow-right--active {
  transform: translate3d(0%, 0, 0);
}

.cstm-img-peview-model .awssld__controls__arrow-right--active:before {
  transform: rotate(-45deg) translate3d(0%, 0, 0) !important;
}

.cstm-img-peview-model .awssld__controls__arrow-right--active:after {
  transform: rotate(45deg) translate3d(0%, 0, 0) !important;
}

.cstm-img-peview-model .awssld__controls__arrow-left--active:before {
  transform: rotate(45deg) translate3d(0%, 0, 0) !important;
}

.cstm-img-peview-model .awssld__controls__arrow-left--active:after {
  transform: rotate(-45deg) translate3d(0%, 0, 0) !important;
}

.cstm-img-peview-model .awssld__controls--active .awssld__controls__arrow-left,
.cstm-img-peview-model
  .awssld__controls--active
  .awssld__controls__arrow-right {
  transform: translate3d(0%, 0, 0);
}

.responsive-cr-heading-sec {
  display: none;
}

.tag-a-friend p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 10px;
}

.bijausernoclass {
  display: none;
}

.userpoteclass .cstm-link-dash-btn,
.userpoteclass .cstm-save-dash-btn {
  display: none;
}

.what-post-btn-all.what-post-btn-all-mobile {
  display: none;
}

.react-input-emoji--mention--loading {
  display: none !important;
}

.user-title-click {
  cursor: pointer;
}

.edit-post-content-inner-main {
  height: 100%;
}

.edit-post-content-inner-sub {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
}

.edit-pp-post {
  position: relative;
}

.edit-pp-post:hover .edit-pp-post-btn {
  opacity: 1;
}

.edit-pp-post-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: #0000007e;
  opacity: 0;
  transition: 0.5s all;
}

.edit-pp-post-btn button {
  font-size: 14px;
  border-radius: 6px;
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  pointer-events: initial;
}

.edit-pp-post-btn .editimgcount5 {
  width: 100%;
  margin: auto;
}

.editimgcount4 {
  width: 68%;
  margin: auto;
}

.editimgcount3 {
  width: 68%;
  margin: auto;
}

.editimgcount2 {
  width: 40%;
  margin: auto;
}

.editimgcount1 {
  width: 40%;
  margin: auto;
}

.editimgcount0 {
  width: 40%;
  margin: auto;
}

.no-img-video {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-img-video p {
  margin-bottom: 0px;
  margin-top: 10px;
}

.msg-popup.create-popup-post .edit-img-modal-dialog .modal-content {
  padding: 24px;
  width: 100%;
  background: transparent;
  border-radius: 0px;
}

.edit-post-content-inner-sub {
  background: #fff;
  border-radius: 15px;
  padding: 16px;
}

.edit-img-inner-main .list-group-item,
.edit-img-inner-main .edit-cstm-img-inner-sec {
  position: relative;
  overflow: hidden;
  height: 250px;
}

.editimgcount5 .edit-img-inner-main .list-group-item,
.editimgcount5 .edit-img-inner-main .edit-cstm-img-inner-sec {
  width: 32.33%;
}

.editimgcount4 .edit-img-inner-main .list-group-item,
.editimgcount4 .edit-img-inner-main .edit-cstm-img-inner-sec,
.editimgcount3 .edit-img-inner-main .list-group-item,
.editimgcount3 .edit-img-inner-main .edit-cstm-img-inner-sec {
  width: 48%;
}

.editimgcount1 .edit-img-inner-main .list-group-item,
.editimgcount1 .edit-img-inner-main .edit-cstm-img-inner-sec,
.editimgcount2 .edit-img-inner-main .list-group-item,
.editimgcount2 .edit-img-inner-main .edit-cstm-img-inner-sec {
  width: 100%;
}

.edit-cstm-img-inner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  z-index: 0;
  -webkit-filter: blur(2px);
  background-position: center !important;
}

.edit-img-inner-main .list-group-item .um-ulpoad-close,
.edit-img-inner-main .edit-cstm-img-inner-sec .um-ulpoad-close {
  position: absolute;
  top: 2px;
  right: 12px;
  z-index: 1;
  width: 32px;
  height: 32px;
  background: #ffffff;
  line-height: 28px;
  border-radius: 50px;
}

.edit-img-cstm-btn {
  padding: 24px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-img-cstm-btn-left {
  display: flex;
}

.edit-img-cstm-btn-left .cp-btn {
  width: 120px;
}

.edit-post-content-inner-main .edit-img-inner-main {
  max-width: 1440px;
  margin: auto;
  column-gap: 1.4rem;
  gap: 1rem;
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.edit-img-inner-main .list-group-item.list-group-item-success img,
.edit-img-inner-main .edit-cstm-img-inner-sec img {
  max-height: 250px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.edit-img-inner-main .list-group-item.list-group-item-success video,
.edit-img-inner-main .edit-cstm-img-inner-sec video {
  max-height: 250px;
  width: 100%;
  height: 100%;
}

.edit-post-content-inner-main.editimgcount1 .edit-img-inner-main {
  width: 100%;
}

.edit-post-content-inner-main.editimgcount2 .edit-img-inner-main {
  width: 100%;
}

.edit-post-content-inner-main.editimgcount3 .edit-img-inner-main {
  width: 100%;
}

.edit-post-content-inner-main.editimgcount4 .edit-img-inner-main {
  width: 100%;
}

.edit-post-content-inner-main.editimgcount5 .edit-img-inner-main {
  width: 100%;
}

.create-popup-post.edit-img-popup-post:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #000000;
  transition: 0.4s all;
  opacity: 0.5;
}

.cstm-posted-in {
  font-weight: 400;
}

.create-popup-post .modal-dialog.edit-img-modal-dialog {
  max-width: 1300px;
}

/* img slider */
.cstm-img-peview-model.modal-dialog.modal-dialog-centered {
  max-width: 100%;
  width: 95%;
}

.profile-details-tabs .tab-pane .pro-followers-following-cont,
.profile-details-tabs .tab-pane .cstm-media-all-main {
  min-height: calc(100vh - 462px);
  height: 100%;
  margin-bottom: 0px;
}

.files-post-tab-inner .pro-about {
  min-height: calc(100vh - 472px);
  height: 100%;
}

.cstm-news-no-post {
  padding: 40px;
  min-height: 250px;
  height: calc(100vh - 506px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.newsfeed-post.cstm-news-no-post.no-req-found {
  height: calc(100vh - 567px);
}

.newsfeed-post.cstm-news-no-post.no-cl-found {
  height: calc(100vh - 300px);
  grid-column: 5/1;
}

.cstm-news-no-post .emptymsg {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-top: 20px;
}

.cstm-media-all-main.cstm-news-no-post {
  height: calc(100vh - 472px);
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered .owl-nav button {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6.5px);
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered
  .owl-nav
  button:hover {
  background: rgba(255, 255, 255, 0.4);
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered
  .list-group-item-success {
  color: #0f5132;
  background: #2f2f2f;
  padding: 0;
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered .owl-nav button span {
  font-size: 56px;
  line-height: 40px;
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.create-popup-post .cstm-img-peview-model .modal-content {
  border-radius: 5px;
  padding: 0px;
  max-height: calc(100vh - 58px);
  overflow: auto;
}

.cstm-img-peview-model .item.cstm-cl-items {
  height: 100%;
}

.create-popup-post .cstm-img-peview-model .cstm-cl-items img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.create-popup-post .cstm-img-peview-model .awssld__wrapper {
  height: 100%;
  max-height: calc(100vh - 124px);
  width: 100%;
  background: var(--content-background-color);
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered
  .list-group-item.list-group-item-success {
  height: 100%;
  font-weight: 100;
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered
  .list-group-item.list-group-item-success
  video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cstm-img-peview-model .awssld__bullets {
  display: none;
}

.cstm-img-peview-model.modal-dialog.modal-dialog-centered .awssld {
  max-height: calc(100vh - 124px);
}

/* loader css start */
.cstm-starred-group.cstm-starred-group-sad {
  padding-bottom: 0px;
  padding-top: 0px;
}

.loadermain span {
  vertical-align: middle;
  border-radius: 100%;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 3px 2px;
  -webkit-animation: loadermain 0.8s linear infinite alternate;
  animation: loadermain 0.8s linear infinite alternate;
}

.res-edit-icon,
.rs-joined-group {
  display: none;
}

.loadermain span:nth-child(1) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(19, 114, 209, 0.6);
}

.loadermain span:nth-child(2) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(19, 114, 209, 0.8);
}

.loadermain span:nth-child(3) {
  -webkit-animation-delay: -0.26666s;
  animation-delay: -0.26666s;
  background: rgba(19, 114, 209, 1);
}

.loadermain span:nth-child(4) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: rgba(19, 114, 209, 0.8);
}

.loadermain span:nth-child(5) {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background: rgba(19, 114, 209, 0.4);
}

@keyframes loadermain {
  from {
    transform: scale(0, 0);
  }

  to {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes loadermain {
  from {
    -webkit-transform: scale(0, 0);
  }

  to {
    -webkit-transform: scale(1, 1);
  }
}

.upcoming-group-events-box-cont-brn {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}

a.my-anchor-css-class {
  color: var(--blue);
  font-weight: 800;
}

.csmt-front-vd-cunt .like-btn button.liked {
  background-color: #f02084;
}

.member-post-tab-inner,
.events-post-tab-inner,
.about-post-tab-inner,
.media-post-tab-inner,
.files-post-tab-inner {
  min-height: calc(100vh - 520px);
}

.upcoming-group-events-box-cont-brn button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 30px;
  outline: none;
  transition: 0.3s all;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.cstm-toggle-center {
  margin: 4px 0px;
}

.cstm-toggle-main span {
  width: 19px;
  height: 1px;
  border: 1px solid #ffffff;
  display: block;
  border-radius: 2px;
  transition: all 0.3s;
}

.header-ft-menu-view.show .cstm-toggle-main span.cstm-toggle-top {
  transform: rotate(45deg);
  width: 24px;
  margin-bottom: -23px;
  margin-top: 5px;
}

.header-ft-menu-view.show .cstm-toggle-main span.cstm-toggle-center {
  transform: rotate(-45deg);
  margin-left: 0px;
  margin-top: 21px;
  width: 24px;
}

.header-ft-menu-view.show .cstm-toggle-main span.cstm-toggle-bottom {
  display: none;
}

.cstm-vd-cnt.csmt-front-vd-cunt .cstm-videodes p {
  margin-bottom: 0px;
}

.cstm-vd-cnt.csmt-front-vd-cunt .icon-align {
  padding-right: 6px;
  margin-top: -3px;
}

.upcoming-e-join {
  width: 74px;
  color: #ffffff;
  background: #351bce;
  border: none;
}

.upcoming-e-join:hover {
  background: #ffcc00;
}

.upcoming-e-invite {
  width: 100px;
  color: #091f48;
  background: #ffffff;
  border: 1px solid #82cdb8;
}

.upcoming-e-invite:hover {
  background: hsl(163deg 43% 90%);
}

.upcoming-e-share {
  width: 100px;
  color: #091f48;
  background: #ffffff;
  border: 1px solid #ff4852;
}

.upcoming-e-share:hover {
  background: hsl(357deg 100% 90%);
}

.upcoming-e-invite img,
.upcoming-e-share img {
  margin-right: 8px;
}

li.cstm-opend-group {
  background: #e7f1fa !important;
  padding-top: 16px !important;
  padding-bottom: 8px !important;
  margin: 8px 8px 0px;
  border-radius: 8px 8px 0px 0px;
  color: #091f48;
}

.cstm-opend-group-topis {
  background: #e7f1fa !important;
  padding: 8px 16px 16px;
  margin: 0px 8px 0px;
  border-radius: 0px 0px 8px 8px;
}

.cstm-opened-topic-list {
  background: #fff;
  border-radius: 6px;
}

.cstm-opened-topic-list .group-topics-ls {
  font-size: 14px;
  line-height: 22px;
  padding: 11px 0px 0px 8px;
}

.cstm-opened-topic-list .sort-by-main {
  padding: 14px 20px 0px 8px;
}

.cstm-opened-topic-list .cstm-sg-ttl-main {
  color: #351bce !important;
}

.cstm-opened-topic-list .search-topics-box {
  padding: 10px 8px 0px 8px;
}

.cstm-opened-topic-list .search-topics-box:after {
  top: 19px;
  right: 21px;
}

span.span-topic-main {
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
}

.cstm-opened-topic-list .cstm-starred-group {
  border-bottom: none !important;
}

.cstm-content-details-video button.vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  height: 64px;
  background: rgba(32, 30, 41, 0.8);
  border-radius: 50px;
  border: none;
  line-height: 64px;
  font-size: 40px;
}

.cstm-newsfeed-drop {
  position: absolute;
  z-index: 99;
  background: #fff;
  width: 95%;
  max-height: 274px;
  overflow: auto;
}

.um-not.cstm-um-note {
  text-align: left;
  margin-top: 16px;
  color: rgba(255, 255, 255, 0.24);
}

.um-not.cstm-um-note a {
  font-weight: 700;
  color: #fff;
  padding-left: 5px;
}

.um-not.cstm-um-note a:hover {
  font-weight: 700;
  color: #ffcc00;
}

.um-login-sec {
  max-width: 540px;
}

.um-login-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: var(--white);
  margin-bottom: 16px;
}

.cstm-login .um-login-title {
  margin-bottom: 0;
}

.edit-img-inner-main::-webkit-scrollbar {
  width: 5px;
}

.edit-img-inner-main::-webkit-scrollbar-track {
  background: var(--btn3);
}

.edit-img-inner-main::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}

.cstm-newsfeed-drop::-webkit-scrollbar {
  width: 5px;
}

.cstm-newsfeed-drop::-webkit-scrollbar-track {
  background: var(--btn3);
}

.cstm-newsfeed-drop::-webkit-scrollbar-thumb {
  background: var(--navyblue);
  border-radius: 50px;
}

.cstm-content-library-menu .cstm-active-tag {
  background: #f3f5f7;
}

.cstm-newsfeed-drop .item {
  padding: 7px 12px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #091f48;
  border: 1px solid #e5e5e5;
  margin-bottom: -1px;
  cursor: pointer;
}

.cstm-newsfeed-drop .item:hover {
  color: var(--yellow);
}

ul.cstm-cstm-clm-drop-main-block {
  display: block;
}

.cstm-comments-lk {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #201e29;
  margin-right: 10px;
  cursor: pointer;
}

.cstm-views-lk {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #201e29;
  margin-right: 10px;
  cursor: pointer;
}

.rvc-dave {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 4px;
}

.cl-v-comment-sec .cmt-arrow-down {
  display: none;
}

.related-videos-main {
  background: #ffffff;
  border-radius: 0px;
  height: 100%;
  /* margin: 24px 0px 0px; */
  /* margin: 50px 0px 0px; */
}

.cstm-back-mn {
  margin-bottom: 16px;
}

.cstm-clm-drop a {
  display: block;
  width: 100%;
  position: relative;
}

.cstm-clm-drop a i {
  position: absolute;
  right: 0;
  font-size: 22px;
  line-height: 20px;
}

ul.cstm-cstm-clm-drop-main {
  display: none;
}

ul.cstm-cstm-clm-drop-main li {
  padding: 6px 0px !important;
}

.cstm-content-library-menu ul {
  list-style: none;
  padding: 10px 0px;
}

.cstm-content-library-menu ul li {
  line-height: 20px;
}

.cstm-content-library-menu ul li a {
  font-weight: 600;
  font-size: 14px;
  color: #091f48 !important;
  padding: 7px 16px 7px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cstm-content-library-menu ul li a:hover {
  background: #e7f1fa;
}

/* .cstm-cv-video-in:hover .gallery-content1 {
  color: #351bce;
} */

/* .cstm-cv-video-in:hover .cstm-gle {
     padding: 0px 12px 7px 12px;
} */

.cstm-cv-video-in:hover .gallery-content1 {
  padding-top: 7px;
  margin-bottom: 0px;
}

.cstm-cv-video-in:focus-visible {
  outline: inherit;
}

.cstm-cv-video-in:hover
  .cstm-gallery-img.cstm-gallery-img-main
  .cstm-videoPlayericons {
  opacity: 1;
  visibility: visible;
}

.cstm-ca-search-box {
  padding: 8px 20px 0px;
  border-bottom: 1px solid #d2d2d4;
  margin-bottom: 20px;
}

.cstm-ca-search-box ul {
  margin: 0px;
  padding: 0px;
  overflow: auto;
  display: flex;
}

.cstm-ca-search-box ul::-webkit-scrollbar {
  height: 5px;
}

.cstm-ca-search-box ul::-webkit-scrollbar-track {
  background: #fff;
}

.cstm-ca-search-box ul::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 50px;
}

.cstm-ca-search-box ul li {
  background: #f4f3f4;
  border: 1px solid #dedede;
  border-radius: 40px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091f48;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 15px;
  cursor: pointer;
  white-space: pre;
}

select.cstm-content-archive-sort-select {
  border-radius: 0px;
  padding: 0px;
  height: auto;
  width: 165px;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  color: #201e29;
}

.uic-sort-by-title {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #201e29;
  margin-right: 10px;
}

.cstm-content-archive-main-top h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 0px;
}

.cstm-content-archive-main-top {
  display: flex;
  justify-content: space-between;
}

button.wym-close {
  position: absolute;
  right: -5px;
  top: -4px;
  line-height: 8px;
  border-radius: 30px;
  font-size: 14px;
  border: 1px solid #0000006b;
  color: #000;
  background: #ffffffc7;
  padding: 3px 4px 5px 4px;
}

.write-your-message-upload {
  display: flex;
  flex-wrap: wrap;
  background: #f3f5f7;
  border-top: 1px solid #d1d1d1;
  padding: 10px 0px 0px 10px;
  position: absolute;
  bottom: 52px;
  width: 318px;
}

.write-your-message-upload-box {
  width: 17%;
  margin-right: 3%;
  position: relative;
  margin-bottom: 12px;
}

.write-your-message-upload-box img,
.write-your-message-upload-box video {
  border-radius: 12px;
  height: 51px;
  width: 51px;
  background: white;
  border: 1px solid #cdcdcd;
}

.view-in-chat-box {
  margin-right: 16px;
}

.notifications-ps-main-cont {
  position: relative;
}

.notifications-ps-main-cont .tab-content {
  overflow: inherit !important;
  max-height: inherit !important;
}

.notifications-ps-main {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.1);
  box-shadow: 0px 4px 16px rgb(154 154 154 / 25%);
  border-radius: 5px 5px 10px 10px;
}

.tab-content.header-noti-cont-tabs-main {
  overflow: auto;
  max-height: calc(100vh - 220px);
}

.tab-content.header-noti-cont-tabs-main::-webkit-scrollbar {
  width: 6px;
}

.tab-content.header-noti-cont-tabs-main::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e8e8e8;
}

.tab-content.header-noti-cont-tabs-main::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
}

.noti-line {
  margin-bottom: 0px;
}

.head-noti-name {
  font-size: 14px;
  line-height: 18px;
  color: #091f48;
  display: block;
  width: 100%;
}

.head-noti-name a {
  font-weight: 700;
}

.head-noti-time {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #351bce;
}

.head-noti-new {
  padding: 3px 6px;
  background: #ffcc00;
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #091f48;
}

.header-noti-box-cont-tabs-cont {
  padding: 0px 16px;
}

.header-noti-box-cont-tabs-head-view-all {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #091f48;
}

.header-noti-box-cont-tabs-head-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.header-noti-cont-tabs-main .tab-pane {
  padding: 0px;
}

img.noti-usr-icon {
  position: absolute;
  right: 0;
  height: 24px;
  width: 24px;
  bottom: 0;
  object-fit: cover;
  border-radius: 50px;
  background: #f1f1f1;
}

img.noti-usr-photo {
  object-fit: cover;
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.head-noti-user {
  position: absolute;
  left: 0;
}

.header-noti-box-cont-tabs-cont ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.header-noti-box-cont-tabs-cont ul li {
  padding-left: 78px;
  padding-right: 60px;
  position: relative;
  min-height: 64px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.header-noti-box-cont-tabs-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
}

.mark-as-all-read img {
  margin-right: 6px;
}

.noti-nav-tabs {
  border-bottom: 1px solid #dbdbdb;
  padding: 0px 16px;
}

.noti-nav-tabs li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--blue);
  padding: 9px 16px;
  display: inline-block;
  position: relative;
}

.noti-nav-tabs li a.active {
  color: var(--navyblue) !important;
  font-weight: 700 !important;
}

.noti-nav-tabs li a.active:before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  background: var(--navyblue);
  bottom: -1px;
  left: 0;
}

.mark-as-all-read {
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #091f48;
  right: 16px;
  top: 10px;
  cursor: pointer;
}

.mark-as-all-read:hover {
  color: var(--yellow);
}

.header-noti-box-cont-tabs {
  position: relative;
}

.header-noti-box-cont-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  padding: 10px 16px 12px 16px;
}

.header-noti-cont-tabs-main .tab-pane.unreadnoti {
  padding-top: 10px;
}

.close-noti {
  position: absolute;
  right: 16px;
  top: 8px;
}

.header-noti-box:hover .header-noti-box-in {
  display: block !important;
}

.header-noti-box-cont {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.1);
  box-shadow: 0px 4px 16px rgb(154 154 154 / 25%);
  border-radius: 5px 5px 10px 10px;
  width: 457px;
}

.header-noti-box-in {
  position: absolute;
  display: none;
  border-top: 27px solid transparent;
  left: -220px;
  z-index: 9;
}

#joinedgrp {
  padding-bottom: 130px;
}

.dis-loader3 {
  width: 28px;
}

.um-rgt-btn-loading2 span.loading-btn-in2 {
  z-index: 1;
  height: 54px;
  background: #f3f5f7db;
  border-radius: 10px;
  border: none;
}

.all-content-main-left-sidebar-user {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.all-content-main-left-sidebar-user .card {
  flex-grow: 1;
}

.cstm-asl-link ul li a {
  display: flex;
  justify-content: space-between;
}

.sd-logout a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ff4852 !important;
}

.cstm-asl-link ul li a {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.cstm-asl-link ul li a:hover {
  color: var(--yellow);
}

.cstm-asl-link ul {
  padding: 0px;
  margin: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  list-style: none;
}

.cstm-asl-link ul li {
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

table.bitable {
  width: 100%;
}

table.bitable th {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
  opacity: 0.7;
}

table.bitable td {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  padding-top: 16px;
}

.um-billing-information-table {
  padding: 12px 24px 24px 24px;
}

.um-billing-info-main-left img {
  margin-right: 16px;
}

.um-billing-informatio-title {
  padding: 16px 24px;
  width: 100%;
  display: table;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.um-billing-information {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 16px;
}

.cstm-remove-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #351bce;
  padding: 0px;
  background: transparent;
  border: none;
}

.cstm-remove-link:hover {
  color: #ffcc00;
}

a.leave-group {
  color: #ea3200 !important;
}

span.profile_edit_image img {
  background: #c79505b8;
  text-decoration: initial;
  padding: 4px;
  border-radius: 6px;
}

span.profile_edit_image img:hover {
  background: #2a72d1;
}

.profile_edit_image {
  position: absolute;
  right: 10px;
  top: 10px;
}

.profile-bg-img {
  position: relative;
}

.um-billing-info-main {
  display: flex;
  justify-content: space-between;
}

.um-payment-info {
  background: #ffffff;
  border-radius: 15px;
  padding: 16px 24px;
  margin-bottom: 16px;
}

.um-billing-info-price {
  margin-bottom: 72px;
}

.um-billing-info-date {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.cancle-subscription-link button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  border: none;
  padding: 0px;
  background: transparent;
}

.cancle-subscription-link button:hover {
  color: var(--yellow);
}

.cancle-subscription-link {
  margin-left: 20px;
}

.um-billing-info-right-upgrade {
  margin-top: 25px;
}

.um-billing-info-right p {
  margin-bottom: 5px;
}

.um-billing-info-left {
  width: 35%;
}

.um-billing-info-right {
  width: 65%;
  /* padding-top: 50px;
     */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.um-billing-info-price-in {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #201e29;
}

.um-billing-info-price sup {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-right: 5px;
  color: #201e29;
}

.um-billing-info {
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
  margin-bottom: 16px;
}

.um-billing-info-details {
  display: flex;
  justify-content: space-between;
}

.um-billing-info-lable {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  display: inline-block;
  border-radius: 10px;
  padding: 3px 10px;
  background: var(--yellow);
  margin-bottom: 16px;
}

.all-content-main-edit-profile-header .um-back {
  margin-bottom: 15px;
}

.cstm-edit-profile {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #091f48;
}

.profile-loading-image img {
  width: 40px;
  position: relative;
  top: 26px;
}

.cstm-pli-bg {
  background: #ffffff9e;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 50%;
}

.profile-loading-image img {
  width: 40px;
  position: relative;
  top: 30px;
}

.profile-loading-image {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
  background-image: url(../Images/dummyiprofil-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #ffffff !important;
  box-shadow: 0px 3px 15px #e3e3e3;
  position: relative;
  text-align: center;
}

.delete-pro-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: #201e29;
  opacity: 0.3;
}

.all-content-main-edit-profile-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 24px;
}

.group-media-slider img,
.group-media-slider video {
  border-radius: 5px;
}

.group-media-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 16px;
  display: flex;
}

.group-media-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.cstm-media-all-main {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 16px;
}

.cstm-joined-group {
  margin-bottom: 20px;
}

.cstm-sg-join {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.cstm-sg-join a {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--navyblue);
}

.cstm-sg-join a:hover {
  color: var(--yellow);
}

.cstm-joined-group ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-joined-group ul li {
  padding: 6px 0px;
  display: flex;
  align-items: center;
  position: relative;
}

.cstm-cgyj-jg .card-header button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cstm-cgyj-jg .card-header {
  border-bottom: none;
}

.cstm-cgyj-jg {
  margin: 10px 16px;
}

.cstm-cgyj-main {
  margin: 10px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 10px;
}

.cstm-cgyj-main ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-cgyj-main ul li {
  padding: 6px 0px;
  display: flex;
  align-items: center;
  position: relative;
}

.common-groups-you {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  padding: 0px 16px;
}

.user-pt {
  margin: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 17px;
}

.user-pt.cstm-user-pt {
  border: none;
  padding: 0;
}

.user-pt-main {
  background: var(--navyblue);
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
}

.user-pt img {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  object-fit: cover;
  margin-right: 5px;
}

.sd-detilas-info-in:last-child {
  margin-bottom: 0px;
}

.sd-detilas-info-in {
  position: relative;
  margin-bottom: 16px;
}

.sd-map img {
  width: 100%;
}

.sd-detilas-info-in-icon {
  position: absolute;
  left: 0;
  line-height: 18px;
}

.sd-detilas-info-in-info {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
  opacity: 0.7;
  display: table;
  width: 100%;
  word-break: break-all;
}

.sd-detilas-info-in-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 3px;
  display: table;
  width: 100%;
}

.sd-detilas-info {
  padding: 16px;
}

.sd-detilas-title {
  padding: 12px 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.sd-detilas {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
}

.follow-bf {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #351bce;
  cursor: pointer;
}

.follow-af {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #ff4852;
  cursor: pointer;
}

.pro-followers-following-cont {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 16px 16px 0px 16px;
}

.pro-followers-following-cont .pro-group-members {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 18px;
}

.pro-followers-following-cont .pro-group-members-list {
  border-bottom: none;
  margin-bottom: 0px;
}

.pro-followers-following-cont .pro-group-members-list-left-img img {
  border-radius: 50px;
}

.pd-ng {
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #c1c1c1;
}

.pd-rs {
  color: #351bce;
}

.profile-detilas-flw-in {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.profile-detilas-all-username-left2 {
  padding-left: 76px;
}

.profile-detilas-user-photo img {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  object-fit: cover;
}

.profile-detilas-user-photo {
  position: absolute;
  left: 16px;
}

.profile-detilas-comm {
  cursor: pointer;
}

.gm-active {
  background: var(--navyblue) !important;
  color: var(--white) !important;
}

.group-media-main ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
}

.group-media-main ul li button:hover {
  background: var(--navyblue);
  color: var(--white);
}

.acvite-gm {
  background: var(--navyblue) !important;
  color: var(--white) !important;
}

.group-media-main ul li {
  display: inline-block;
  margin-right: 16px;
  float: left;
}

.group-media-main ul li button {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #091f48;
  height: 32px;
  padding: 0px 24px;
  margin-bottom: 18px;
}

.user-photo-img {
  vertical-align: middle;
  margin: -16px 8px -10px 0px;
  display: inline-block;
}

.user-photo-img img {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  object-fit: cover;
  vertical-align: inherit !important;
  margin: 0px !important;
}

.search-topics-box {
  padding: 16px 16px 0px 16px;
  position: relative;
}

.search-topics-box input[type="text"] {
  height: 40px;
  border-radius: 10px;
  padding: 10px 42px 10px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  background-color: #f4f4f4;
}

.search-topics-box:after {
  content: "";
  height: 21px;
  width: 21px;
  background-image: url(../Images/search.svg);
  position: absolute;
  z-index: 1;
  top: 26px;
  right: 30px;
  cursor: pointer;
  background-size: contain;
}

.group-topics-ls {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  position: absolute;
  padding: 11px 0px 0px 16px;
}

.loading-btn {
  position: relative;
}

.loading-btn-in {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  background: #ffffffeb;
  height: 54px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-btn-in img {
  width: 36px;
}

.loadind-post {
  text-align: center;
  position: relative;
  background: #ffffff;
  border-radius: 15px;
  padding: 16px 24px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.loadind-post img {
  width: 35px;
  position: absolute;
}

.loadind-post.cmt-loading {
  height: 50px !important;
}

.news-votes-cunt {
  position: absolute;
  right: 24px;
  top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
}

.newsfeed-agree-all {
  position: relative;
  padding-right: 80px;
  padding-left: 16px;
}

.selectedplan:before {
  content: "";
  height: 35px;
  width: 35px;
  position: absolute;
  background: url(../Images/yes.svg);
  top: -18px;
  z-index: 999;
  background-repeat: no-repeat;
  background-size: contain;
  right: 5px;
  margin: auto;
}

.StripeElement {
  border-radius: 15px;
  background: var(--grey2);
  border: none;
  font-style: normal;
  font-weight: 600;
  color: var(--black2);
  font-size: 14px;
  line-height: 20px;
  height: 48px;
  padding: 17px 20px;
  width: 100%;
}

.upcoming-group-events-box-cont-sub {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 5px;
}

.upcoming-group-events-box-cont-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 5px;
}

.upcoming-group-events-box-wed {
  font-weight: 700;
  font-size: 12px;
  line-height: 8px;
  letter-spacing: 0.02em;
  color: #ff4852;
  margin-top: 12px;
}

.upcoming-group-events-box-cale {
  position: absolute;
  left: 16px;
  background: #ffffff;
  border: 1px solid #351bce63;
  border-radius: 15px;
  height: 83px;
  width: 64px;
  text-align: center;
}

.upcoming-group-star {
  position: absolute;
  right: 16px;
}

.upcoming-group-events-box {
  background: #ffffff;
  border-radius: 15px;
  padding: 16px 57px 16px 98px;
  position: relative;
  margin-bottom: 16px;
}

.view-active img {
  filter: brightness(0) invert(1);
  opacity: 1 !important;
}

.view-by-btn button img {
  opacity: 0.4;
}

.view-active {
  background: #091f48 !important;
}

.view-by-btn button {
  border-radius: 10px;
  border: none;
  background: #fff;
  padding: 8px 9px;
  display: flex;
}

.view-by-btn {
  background: #ffffff;
  border: 1px solid rgba(9, 31, 72, 0.1);
  border-radius: 10px;
  display: flex;
}

.pro-uge-view-btn {
  display: flex;
  align-items: center;
}

.view-by-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
  margin-right: 16px;
}

.pro-uge-view-check input {
  height: 16px !important;
  width: 16px !important;
}

.pro-uge-view-check {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
  border-right: 1px solid #afafaf;
  margin-right: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.pro-uge-view {
  display: flex;
  align-items: center;
}

.pro-events-top {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.pro-uge-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.pro-group-members-list:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.pro-group-members-list-right-upload {
  line-height: 20px;
  cursor: pointer;
}

.pro-group-members-list-right-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  margin-right: 22px;
}

.pro-group-members-list-left-file {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
}

.pro-group-members-list-left-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--blue);
  margin-bottom: 5px;
}

.btn-uf img {
  margin-right: 5px;
  vertical-align: -5px;
}

.btn-uf {
  background: #091f48;
  border-radius: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  padding: 8px 18px;
  border: none;
}

.btn-uf:hover {
  background: var(--yellow);
}

.pro-group-members-list-left {
  padding-left: 75px;
}

.pro-group-members-list-right {
  display: flex;
  align-items: center;
}

.pro-group-members-list-left-img {
  position: absolute;
  margin-left: -75px;
  top: 0;
}

.pro-group-members-list-left-img img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 15px;
}

.pro-group-members-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 75px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
  margin-bottom: 12px;
  position: relative;
}

.pro-group-members-search {
  position: relative;
}

.pro-group-members-search:after {
  content: "";
  height: 18px;
  width: 18px;
  background-image: url(../Images/search.svg);
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 12px;
  cursor: pointer;
  background-size: cover;
}

.search-members-input {
  width: 276px !important;
  height: 36px !important;
  border-radius: 10px !important;
  padding: 0px 40px 0px 15px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.02em !important;
  background: #f4f4f4 !important;
}

.pro-group-members-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.pro-group-members {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.pro-map {
  margin-bottom: 20px;
}

.pro-map img {
  width: 100%;
  border-radius: 15px;
}

.pro-post-list-main-in-link-view button {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #091f48;
  border: none;
  background: transparent;
  padding: 0px;
}

.pro-post-list-main-in-link-view button:hover {
  color: #ffcc00;
}

.pro-post-list-main-in-link-unfollow {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #091f48;
  cursor: pointer;
}

.pro-post-list-main-in-link {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pro-post-green {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #82cdb8;
  background: #ffffff;
  border: 1px solid #82cdb8;
  border-radius: 10px;
  padding: 2px 8px;
  margin: 0px 5px 5px 0px;
  display: inline-block;
}

.pro-post-blue {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #351bce;
  background: #ffffff;
  border: 1px solid #351bce;
  border-radius: 10px;
  padding: 2px 8px;
  margin: 0px 5px 5px 0px;
  display: inline-block;
}

.pro-post-list-main-in-hobbies {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  margin-bottom: 3px;
}

.pro-post-list-main-in-cont {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.9;
  margin-bottom: 18px;
}

.pro-post-time-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
}

.pro-post-user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
}

.pro-post-list-main-in-user-right {
  /* padding-left: 75px;
     */
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.pro-post-list-main-in-user-left img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50px;
}

.pro-post-list-main-in-admin {
  position: absolute;
  right: 13px;
  top: -12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
  background: #ffcc00;
  border-radius: 10px;
  padding: 3px 10px;
}

.pro-post-list-main-in-user {
  display: flex;
  margin-bottom: 10px;
  min-height: 60px;
  align-items: center;
  gap: 12px;
}

.pro-post-list-main {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  list-style: none;
}

.pro-post-list-main-in {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 15px;
  padding: 16px 10px;
  position: relative;
}

.pro-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 15px;
}

.cstm-grup-admin {
  margin-bottom: 16px;
}

.cstm-grup-admin .pro-title {
  margin-bottom: 24px;
}

.pro-about-list {
  margin-bottom: 15px;
}

.pro-about-list:last-child {
  margin-bottom: 0px;
}

.pro-about-list-cont {
  padding-left: 28px;
}

.pro-about-list-name img {
  margin-left: -28px;
  position: absolute;
}

.pro-about-list-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  padding-left: 27px;
}

.pro-about {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 16px;
}

.pd-invite-in {
  left: -166px !important;
}

.pd-invite-in ul li {
  padding: 8px 12px;
}

.pd-invite-in ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.pd-invite-in-sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #838fa2;
  padding-left: 20px;
  display: table;
  width: 100%;
}

.pd-invite-in-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #091f48;
  display: flex;
  width: 100%;
  padding-left: 20px;
  align-items: center;
}

.pd-invite-in-title img {
  position: absolute;
  width: 14px;
  margin-left: -20px;
}

.pd-invite-in {
  min-width: 300px;
  padding: 5px 0px;
  margin-top: 12px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
}

.pd-dottod-in {
  min-width: 132px;
  padding: 5px 0px;
  margin-top: 12px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
  left: -118px !important;
}

.pd-dottod-in ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.pd-dottod-in ul li a {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  display: block;
}

.pd-dottod-in ul li a:hover {
  color: var(--yellow);
}

.profile-detilas-cmn {
  position: relative;
}

.pd-joind-in {
  min-width: 132px;
  padding: 5px 0px;
  margin-top: 12px;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
}

.pd-joind-in ul li a {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  display: block;
}

.pd-joind-in ul li a:hover {
  color: var(--yellow);
}

.pd-joind-in ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.pd-joind-btn {
  background: var(--navyblue);
  box-shadow: 0px -4px 8px -4px rgb(0 0 0 / 15%);
  border-radius: 10px;
  padding: 10px 16px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: var(--white);
}

.pd-joind-btn:hover {
  background: var(--yellow);
  color: var(--white);
}

.pd-joind-btn i {
  margin-left: 20px;
  font-size: 20px;
  vertical-align: bottom;
  font-weight: 700;
}

.pd-dottod-btn {
  background: transparent;
  border: none;
  padding: 5px 8px 5px 8px;
  line-height: 10px;
}

.pd-invite-btn {
  border: 1px solid #201e29;
  border-radius: 10px;
  background: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  padding: 9px 16px;
}

.pd-invite-btn:hover {
  background: var(--yellow);
  color: #fff;
  border: 1px solid var(--yellow);
}

.pd-invite-btn i {
  margin-left: 20px;
}

.profile-detilas-all-username-right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile-detilas-user-icon-list img {
  margin-right: 7px;
  vertical-align: -5px;
}

span.profile-detilas-user-icon-list {
  margin-right: 17px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091f48;
}

.profile-detilas-user-icon-list {
  margin-right: 17px;
}

.profile-detilas-user-title {
  font-weight: 700;
  font-size: 24px;
  color: #091f48;
  margin-bottom: 8px;
}

.profile-detilas-all-nav {
  padding: 12px;
}

.profiledt-menu li a {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #838fa2;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 10px;
}

.profiledt-menu li a:hover {
  background-color: #351bce;
  color: #fff;
}

.profiledt-menu li a.active {
  background-color: #351bce;
  color: #fff;
}

.profile-detilas-all {
  background: #ffffff;
  margin: -96px 16px 24px;
  position: relative;
  border-radius: 5px;
}

.profile-detilas-all-username {
  padding: 16px;
  border-bottom: 1px solid #cfcfcf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-bg-img-mn {
  width: 100%;
  border-radius: 5px;
  height: 250px;
  object-fit: cover;
  background: #fff;
}

.social-checked:before {
  position: absolute;
  content: "\f058";
  font-family: FontAwesome;
  left: 29px;
  font-size: 26px;
  margin-top: 2px;
}

.um-membership-details-in.selectedplan {
  border: 1px solid var(--black);
}

.cstm-login input[type="text"],
.cstm-login input[type="email"],
.cstm-login input[type="password"],
.cstm-login input[type="number"],
.cstm-login input[type="date"],
.cstm-login input[type="time"],
.cstm-login input[type="file"] {
  border-radius: 10px;
  background: rgb(24 22 33);
  border: none;
  font-style: normal;
  font-weight: 600;
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
  height: 48px;
  padding: 10px 20px;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.cstm-login .um-right-in-label {
  color: rgba(255, 255, 255, 0.7);
}

.cyt-lets-get-started-link {
  position: relative;
  z-index: 1;
}

.topics-img-title {
  font-weight: 700;
  font-size: 18px;
  color: #091f48;
}

.topic-file-image-left {
  margin-right: 18px;
}

.topic-file-image-left img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50px;
}

.topic-file-image {
  display: flex;
  margin-top: 35px;
  align-items: center;
  margin-bottom: 40px;
}

.cyp-btm-btn .btn2 {
  vertical-align: top;
  margin-left: 20px;
}

.ct-down-box {
  position: relative;
}

.ct-down-box-in {
  position: absolute;
  background: #fff;
  z-index: 1;
  border: 1px solid #d2d2d4;
  margin-top: -1px;
  width: 100%;
  display: none;
}

.ct-down-box-in ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.ct-down-box-in ul li {
  padding: 3px 20px;
  font-size: 14px;
  cursor: pointer;
}

.ct-lbl-main .form-group {
  margin-bottom: 0px;
}

.ct-fl-main {
  position: relative;
}

.ct-fl-main:before {
  content: "#";
  position: absolute;
  font-size: 25px;
  color: #091f48;
  left: 28px;
  top: 11px;
  font-weight: 500;
}

.ct-fl-main input {
  padding-left: 60px !important;
}

.cyt-lets-get-started-link a {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #091f48;
  margin-right: 14px;
}

.cyt-lets-get-started-link a:hover {
  color: var(--yellow);
}

.create-yuor-topic h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #091f48;
  margin-bottom: 16px;
}

.cyt-cont {
  margin-bottom: 32px;
  display: table;
  width: 100%;
}

.view-more-comments-link {
  text-align: center;
  padding-bottom: 6px;
}

.view-more-comments-link a {
  color: #2a72d1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.view-more-comments-link a:hover {
  color: #000;
}

.emoji-picker {
  position: absolute;
  right: 0;
  top: 40px;
}

.pu-progress-fil {
  background: #e6f5f1;
  border-radius: 30px;
  width: 100%;
  height: 5px;
}

.pu-progress-bar {
  background: #82cdb8;
  border-radius: 30px;
  height: 5px;
}

.pu-progress-title {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.post-uploading-in-progress {
  background: #ffffff;
  border: 1px solid rgba(9, 31, 72, 0.3);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 16px 20px 20px 20px;
}

.post-close-msg {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  position: absolute;
  right: 20px;
  color: #d5e7ff;
  font-size: 24px;
}

.post-close-msg:hover {
  color: #ffcc00;
}

.post-sucessfully {
  background: #351bce;
}

.post-alert {
  background: #ff4852;
}

.post-msg-all {
  padding: 18px;
  display: flex;
  border-radius: 15px;
  margin-bottom: 20px;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
  position: fixed;
  width: 52.1%;
  top: 12%;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
}

.post-msg-all a {
  color: #fff;
  text-decoration: underline;
  margin: 0px 5px;
}

.post-msg-all a:hover {
  color: #ffcc00;
}

.post-msg-all img {
  margin-right: 8px;
}

.newsfeed-votes-main {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 0px 16px;
}

.cstm-poll-will-close {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #351bce;
  cursor: pointer;
}

.cstm-votes-ct {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
  margin-right: 20px;
}

.newsfeed-agree-main-color {
  position: absolute;
  height: 48px;
  z-index: 0;
  left: 0;
  width: 0%;
  transition: width 0.2s ease;
}

.newsfeed-agree-main-title {
  z-index: 1;
  position: relative;
}

.newsfeed-agree-main-ps {
  z-index: 1;
  position: relative;
}

.newsfeed-agree-main {
  display: flex;
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 5px;
  height: 48px;
  padding: 6px 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #201e29;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.newsfeed-agree-dis {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 16px;
}

.new-lbl {
  position: absolute;
  z-index: 1;
  right: 12px;
  top: 16px;
  padding: 3px 12px;
  background: #ff4852;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
  border-radius: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.announcements-list-image ~ span.new-lbl {
  top: 24px;
}

.announcements-list-image {
  margin-bottom: 16px;
  position: relative;
}

.announcements-list-image img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.announcements-list-cont {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  margin-bottom: 10px;
}

.announcements-list-read a {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #351bce;
}

.announcements-list-read a:hover {
  color: #ffcc00;
}

.announcements-list-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 4px;
}

.cstm-announcements-list {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0px 16px;
  border-radius: 0px 5px;
}

.cstm-announcements-list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-announcements-list ul li {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 16px;
  /* margin-bottom: 18px;
     */
  padding-top: 16px;
}

.cstm-announcements-list.cstm-announcements-list-main ul li {
  position: relative;
}

.cstm-announcements-list.cstm-announcements-list-main ul li:last-child {
  border-bottom: none;
}

.cstm-events-list-left-months {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #091f48;
  background: #e7f1fa;
  border-radius: 0px 0px 13px 13px;
  height: 21px;
}

.cstm-events-list-left-nubmer {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #091f48;
}

.cstm-events-list-right-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.cstm-events-list-right-title a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
}

.cstm-events-list-right-join button {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #351bce;
  border: none;
  background: transparent;
  padding: 0px;
  display: inherit;
}

.cstm-events-list-right-join button:hover {
  color: #ffcc00;
}

.cstm-events-list-right-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  line-height: 20px;
}

.cstm-events-list-left {
  position: absolute;
  background: #ffffff;
  border: 1px solid #351bce63;
  border-radius: 15px;
  height: 64px;
  width: 64px;
  text-align: center;
}

.cstm-events-list-right {
  padding-left: 74px;
  min-height: 64px;
}

.cstm-events-list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-events-list ul li {
  display: block;
  align-items: center;
  padding: 12px 16px;
}

.cstm-events-list {
  border-top: 1px solid #d8d8d8;
  padding: 4px 0px;
}

.cstm-newsfeed-post .newsfeed-post-right-write {
  position: relative;
  border-top: 1px solid #d7d7d7;
  padding: 16px 16px 16px;
  margin-top: 0;
}

.cstm-newsfeed-post .react-input-emoji--input {
  text-transform: none;
}

.cstm-newsfeed-post .react-input-emoji--placeholder {
  font-weight: normal;
  text-transform: none;
}

.newsfeed-write-your-comments-icon button {
  padding: 0px;
  border: none;
  margin: 5px 16px 0px 0px;
}

.newsfeed-write-your-comments-icon button {
  padding: 0px;
  border: none;
}

.newsfeed-write-your-comments-tx textarea {
  border-radius: 10px;
  background: rgb(32 30 41 / 6%);
  padding: 10px 80px 10px 16px;
  text-transform: capitalize;
  height: 40px;
}

.newsfeed-write-your-comments-icon {
  position: absolute;
  right: 0;
  top: 0;
}

.newsfeed-post-right-cmt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.cstm-newsfeed-post .newsfeed-post-right-cmt {
  padding: 0px 16px 16px;
}

.newsfeed-post-right-topik {
  margin-bottom: 16px;
}

.cstm-newsfeed-post .newsfeed-post-right-topik {
  padding: 0px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.topik-in {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #351bce;
  cursor: pointer;
}

.newsfeed-post-right-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #201e29;
  margin-bottom: 8px;
  word-break: break-word;
}

.cstm-newsfeed-post .newsfeed-post-right-text {
  padding: 0px 16px;
}

.yesicon {
  margin: 0px 3px;
}

.topics-select-bar {
  margin-bottom: 20px;
}

.topics-select-bar select {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  background-color: #ffffff;
  border: 1px solid #201e29;
}

.csstm-add-topic-listing {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
}

.tag-csstm-add-topic-listing {
  height: 100%;
  overflow: hidden;
}

.csstm-add-topic-listing .topics-search-label {
  overflow: auto;
  flex-grow: 1;
  max-height: 140px;
  margin-bottom: 20px;
}

.csstm-add-topic-listing.tag-csstm-add-topic-listing .topics-search-label {
  overflow: auto;
  flex-grow: 1;
  max-height: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 80px;
}

.newfeed-dp button {
  background: transparent;
  padding: 0px;
  border: none;
}

.user-name-date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #201e29;
  opacity: 0.7;
}

.newsfeed-post-right-header-name {
  display: flex;
  flex-direction: column;
}

.user-name-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 5px;
}

.newsfeed-post-right-header {
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 110px;
  min-height: 60px;
  margin-bottom: 16px;
}

.cstm-newsfeed-post .newsfeed-post-right-header {
  padding-right: 110px;
  padding-top: 23px;
  margin-bottom: 16px;
  padding-left: 90px;
}

.newsfeed-post-right-header-icon {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
}

.cstm-newsfeed-post .newsfeed-post-right-header-icon {
  right: 24px;
}

.newsfeed-post {
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 16px;
  position: relative;
}

.newsfeed-post-right {
  padding-left: 72px;
}

.cstm-newsfeed-post .newsfeed-post-right {
  padding-left: 0px;
}

.newsfeed-post-left {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 2;
  cursor: pointer;
}

.newsfeed-post-left img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50px;
}

.newsfeed-select-in {
  left: -124px !important;
  padding: 10px;
}

.newsfeed-select:after {
  content: "\f107";
  font-family: FontAwesome;
  margin-left: 6px;
  color: #000;
  font-size: 15px;
  cursor: pointer;
  font-weight: 200;
}

.newsfeed-select {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
}

.newsfeed-sort-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
  margin-right: 4px;
}

.newsfeed-sort {
  display: flex;
  align-items: center;
}

.newsfeed-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.newsfeed-main {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
}

.what-post-btn-all {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.what-post {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.what-post-left {
  width: 48px;
  height: 48px;
}

.what-post-left img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50px;
}

.what-post-right {
  flex: 1;
}

.what-post-right-input {
  margin-bottom: 16px;
}

.what-post-right-input input {
  border-radius: 15px;
  background: rgb(32 30 41 / 6%);
  padding: 10px 20px;
  text-transform: capitalize;
}

.what-is-happening-main {
  background: #ffffff;
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 26px;
}

.nbr-rd {
  background: #ff4852;
  border-radius: 30px;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin-left: 8px;
  padding: 2px 5px;
  min-height: 17px;
  display: inline-block;
  vertical-align: 2px;
}

.sort-by-main img {
  margin-left: 10px;
  cursor: pointer;
}

.sort-by-main {
  padding: 5px 20px 0px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.cp-btn button.cp-btn-dotted {
  overflow: hidden;
}

.cp-btn button.cp-btn-dotted img {
  margin-right: 0px;
  margin-top: 0px;
}

#feeling,
#editfeeling {
  background: rgba(0, 0, 0, 0.3);
}

#topics {
  background: #000000db;
}

select.poll-duration-select {
  background-color: transparent;
  border: 1px solid rgba(32, 30, 41, 0.3);
}

.poll-duration-main {
  margin-bottom: 20px;
}

.addpoll-delete {
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #351bce;
  border: none;
  background: transparent;
  right: 16px;
  padding: 0px;
  top: 12px;
}

.add-poll-main-box2 input {
  background: transparent;
  padding-right: 44px;
}

.plus-add-btn {
  position: absolute;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #351bce;
  border: none;
  background: transparent;
  right: 16px;
  padding: 0px;
}

.plus-add-btn:hover {
  color: #000;
}

.plus-add-btn img {
  margin-right: 6px;
}

.add-poll-main-box input {
  background: transparent;
  padding-right: 85px;
}

.add-poll-main-box {
  background: #351bce17;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}

.add-poll-main-box2 {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.add-poll-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 14px;
}

.add-poll-main {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 15px;
  padding: 10px 20px;
  margin: 16px 0px 20px;
}

.create-topics-box-id {
  margin-right: 10px;
  margin-bottom: 6px;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #351bce;
}

.feelings-emoji {
  margin-top: 10px;
  margin-bottom: 14px;
}

.feelings-emoji-close {
  background: #4d4b54;
  mix-blend-mode: multiply;
  border-radius: 15px;
  color: #fff;
  height: 24px;
  width: 24px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  cursor: pointer;
}

.feelings-emoji-icon {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  border: 1px solid #351bce;
  border-radius: 15px;
  background: #fff;
  display: inline-block;
  justify-content: flex-start;
  padding: 5px 8px;
  margin-right: 8px;
}

.feelings-emoji-icon::first-letter {
  font-size: 20px;
  vertical-align: -2px;
  margin-right: 4px;
}

.remove-srarred-in b {
  font-size: 18px;
  vertical-align: -2px;
  margin-right: 4px;
}

.remove-srarred-img {
  cursor: pointer;
}

.remove-srarred-in {
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 7px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  min-width: auto;
  width: 130px;
  cursor: pointer;
  left: -16px !important;
}

.remove-srarred-in:hover {
  border: 1px solid #2a72d1;
  color: #2a72d1;
}

.cstm-files-main .cstm-sg-dotted {
  top: 0px;
}

.cstm-sg-date {
  position: absolute;
  right: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
  bottom: 10px;
}

.cstm-sg-ttl-name {
  line-height: 17px;
  flex: 1 1;
}

.cstm-sg-sb {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #201e29;
  opacity: 0.7;
}

.cstm-sg-ttl-main {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 165px;
  white-space: nowrap;
  display: block;
}

.cstm-sg-name {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 125px;
  white-space: nowrap;
}

.cstm-sg-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
}

.cstm-sg-name a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
}

.cstm-sg-dotted {
  position: absolute;
  right: 20px;
  cursor: pointer;
  display: flex;
}

.all-content-main-right .cstm-starred-group {
  border-top: 1px solid #d8d8d8;
}

.cstm-starred-group {
  padding: 10px 0px 16px 0px;
}

.cstm-starred-group.cstm-left-starred-group {
  padding-top: 0px;
}

.cstm-starred-group ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-bottom: 8px;
}

.cstm-starred-group ul li {
  padding: 6px 46px 6px 16px;
  display: flex;
  align-items: center;
  position: relative;
}

img.sctm-pt-mn {
  border-radius: 15px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

img.sctm-pt-star {
  position: absolute;
  right: -8px;
  top: 0px;
}

.cstm-sg-list {
  position: relative;
  margin-right: 12px;
}

.modal-content .btn:hover {
  background: var(--yellow);
  border: 1px solid var(--yellow);
}

.cstm-lict-box-in ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-lict-box-in ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #848fa4;
  padding: 16px;
  width: 100%;
  display: inline-block;
}

.cstm-lict-box-in ul li a:hover {
  background: #e7f1fa;
  text-decoration: inherit;
  color: #091f48;
}

.cstm-all-content-center.all-content-main-center {
  max-width: 694px;
  grid-area: newsfeed;
}

.all-content-main-right {
  overflow: auto;
  height: calc(100vh - 96px);
  position: fixed;
  right: 16px;
}

.all-content-main-right::-webkit-scrollbar {
  width: 6px;
  background: #fff;
}

.all-content-main-right:-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e8e8e800;
}

.all-content-main-right::-webkit-scrollbar-thumb {
  background: #e8e8e800;
  border-radius: 10px;
}

.all-content-main-right:hover::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

.cstm-ctg-list ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.cstm-ctg-list ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #848fa4;
  padding: 16px;
  width: 100%;
  display: inline-block;
}

.cstm-ctg-listul li a:hover {
  background: #e7f1fa;
  text-decoration: inherit;
  color: #091f48;
}

.all-content-main-right-sidebar {
  width: 100%;
}

.all-content-main-right-sidebar .card-header {
  padding: 0px;
  border: none;
}

.all-content-main-right-sidebar .card {
  box-shadow: none;
  background: #ffffff;
  border-radius: 5px;
  border: none;
  margin-bottom: 8px;
}

.cstm-topic-star-listing .cstm-sg-ttl-main {
  width: 220px;
}

.all-content-main-right-sidebar .card-header button {
  width: 100%;
  border: none;
  padding: 16px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48 !important;
  text-decoration: initial;
  background-color: #fff;
  border-radius: 5px 0px;
}

.all-content-main-right-sidebar .card-header button.btn-link.collapsed {
  border-radius: 5px;
}

.all-content-main-right-sidebar .card-header button i {
  float: right;
  font-size: 23px;
}

.all-content-main-right-sidebar .card-header button:hover {
  background: #e7f1fa;
  text-decoration: inherit;
  color: #091f48;
}

.all-content-main-right-sidebar .active2-sd {
  font-weight: 700 !important;
  color: #091f48 !important;
  background: #e7f1fa !important;
}

li.logout-only a {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ff4852 !important;
  justify-content: space-between;
}

.mn-close {
  display: none;
}

.header-ft-search-select {
  width: 28%;
  border-left: 1px solid #bdbdbd;
  padding-left: 20px;
}

.header-ft-search-select select {
  height: 24px;
  padding: 0px;
  background-color: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
}

.create-pp-post-public-gl:before {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url(http://localhost:3000/static/media/public.2c073a7….svg);
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 15px;
}

select.cp-public-select {
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 8px;
  height: 40px;
  background-color: #fff;
  padding-right: 38px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #091f48;
}

.create-pp-post-public-gl:before {
  content: "";
  height: 20px;
  width: 20px;
  background-image: url(../Images/public.svg);
  position: absolute;
  z-index: 111;
}

.create-pp-post-public-gl {
  position: relative;
}

.create-pp-post-cont-text textarea {
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
  height: auto;
  margin-top: 5px;
  min-height: 30px;
  margin-bottom: 20px;
}

.csm-main-pp-post .create-topics-box {
  margin-top: 30px;
}

.cstm-cp ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.cstm-cp ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  padding: 8px 10px;
}

.cstm-cp ul li:hover {
  color: #2a72d1;
}

.cstm-cp ul li img {
  margin-right: 4px;
}

.cstm-cp-dp {
  position: relative;
}

.cstm-cp-dp:hover .cstm-cp {
  display: block;
}

.cp-btn-disable {
  pointer-events: none;
  opacity: 0.6;
  border: 1px solid #6e6e6e;
}

.cp-btn-disable img {
  filter: grayscale(100%);
}

.cstm-cp {
  position: absolute;
  right: -3px;
  background: #ffffff;
  border: 1px solid rgba(32, 30, 41, 0.3);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 10%);
  border-radius: 5px;
  top: -43px;
  width: 129px;
  cursor: pointer;
  display: none;
  z-index: 2;
}

.what-are-you-feeling {
  display: flex;
  flex-wrap: wrap;
}

.wayf-main img {
  margin-right: 6px;
}

.wayf-main::first-letter {
  font-size: 20px;
  vertical-align: -2px;
  margin-right: 6px;
}

.wayf-main {
  width: 33.3%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #46444e;
  align-items: center;
  padding: 8px 12px;
  border-radius: 15px;
}

.wayf-main:hover {
  cursor: pointer;
  background: #82cdb8;
  border-radius: 15px;
}

.topics-search-label-in {
  background: rgba(19, 114, 209, 0.1);
  border: 1px solid rgba(32, 30, 41, 0.2);
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
  display: inline-block;
  padding: 8px 12px;
  margin: 0px 8px 8px 0px;
  cursor: pointer;
}

.topics-search-label-in:hover {
  border: 1px solid #2a72d1;
}

.topics-search-label-in button {
  border: none;
  background: transparent;
  padding: 0px;
  font-size: 18px;
  margin-left: 6px;
  vertical-align: -2px;
}

.add-topics-btn .btn {
  width: auto;
  padding: 10px 42px;
  margin: 0px;
}

.add-topics-btn {
  margin-top: 20px;
  text-align: right;
}

.csstm-add-topic-listing .topics-search-ovr {
  overflow: auto;
  flex-grow: 1;
  margin-top: 16px;
}

.topics-search-ovr::-webkit-scrollbar,
.topics-search-label::-webkit-scrollbar,
.create-pp-post.csm-main-pp-post .create-pp-post-cont::-webkit-scrollbar {
  width: 6px;
}

.topics-search-ovr::-webkit-scrollbar-track,
.topics-search-label::-webkit-scrollbar-track,
.create-pp-post.csm-main-pp-post .create-pp-post-cont::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.topics-search-ovr::-webkit-scrollbar-thumb,
.topics-search-label::-webkit-scrollbar-thumb,
.create-pp-post.csm-main-pp-post .create-pp-post-cont::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
}

.cstm-add-topic-btn {
  max-width: 160px;
  margin: 16px 0px 0px auto;
  width: 100%;
}

.tso-post {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #201e29;
}

.tso-id {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #091f48;
  display: block;
  width: 100%;
}

.topics-search-ovr-in {
  padding: 6px 16px;
}

.topics-search-ovr-in:hover {
  background: #f3f5f7;
  border-radius: 15px;
  cursor: pointer;
}

.topics-search-bar input {
  background-color: #f4f4f4;
  border-radius: 15px;
  height: 48px;
  padding-right: 48px;
}

.topics-search-bar img {
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
}

.topics-search-bar {
  position: relative;
  margin-bottom: 20px;
}

.back-btn-mn {
  opacity: 0.5;
  padding: 0px;
  border: none;
  margin-right: 16px;
  background: transparent;
}

.back-btn-mn:hover {
  opacity: 1;
}

.create-pp-cunt {
  position: absolute;
  top: 0;
  z-index: 2;
  background: #0000009c;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}

.create-pp-post-img3-left {
  width: 67%;
  position: relative;
}

.create-pp-post-img3-right-in {
  position: relative;
  width: 100%;
  max-height: 159px;
  overflow: hidden;
  height: 100%;
}

.create-pp-post-img3-right-in img,
.create-pp-post-img3-right-in video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create-pp-post-img3-left img,
.create-pp-post-img3-left video {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 320px;
}

.create-pp-post-img3 {
  margin-top: 12px;
}

.create-pp-post-img3 {
  display: flex;
  justify-content: space-between;
  gap: 2px;
}

.create-pp-post-img3-left {
  width: 65%;
  overflow: hidden;
}

.create-pp-post-img3-right {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.create-pp-post-img2-in img,
.create-pp-post-img2-in video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 340px;
}

.create-pp-post-img2-in {
  width: 50%;
  position: relative;
}

.create-pp-post-img2 {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  gap: 2px;
}

.bx-close {
  background: #201e29a3;
  border-radius: 15px;
  color: #fff;
  border: none;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
  font-size: 18px;
  height: 24px;
  width: 24px;
  padding: 0px;
  line-height: normal;
  cursor: pointer;
  z-index: 1;
}

.bx-close:hover {
  background: #351bce;
}

.create-pp-post-img1 {
  margin-top: 12px;
  position: relative;
}

.create-pp-post-img1 img,
.create-pp-post-img1 video {
  border-radius: 15px;
  width: 100%;
}

.create-pp-post-img1 div {
  width: 100% !important;
  height: 100% !important;
}

.cr-pp-name {
  display: none;
}

.create-pp-post-public-btn button {
  margin: 0px;
  padding: 12px 70px;
}

.cp-public-gl {
  border: 1px solid rgba(32, 30, 41, 0.3);
  border-radius: 8px;
  background: #fff;
  height: 40px;
  padding: 0px 35px 0px 15px;
  display: flex;
  align-items: center;
  min-width: 140px;
  position: relative;
}

.cp-public-gl:after {
  font-family: FontAwesome;
  position: absolute;
  content: "\f107";
  right: 15px;
  font-size: 19px;
}

.create-pp-post-public {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.cp-public-gl img {
  margin-right: 8px;
}

.cp-btn button {
  background: #fff;
  border-width: 1px;
  border-radius: 25px;
  padding: 6px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #091f48;
}

.cp-btn button span {
  line-height: 20px;
}

.cp-btn-photos {
  border: 1px solid #82cdb8;
}

.cp-btn-videos {
  border: 1px solid #ff4852;
}

.cp-btn-feelingn {
  border: 1px solid #351bce;
}

.cp-btn-topics {
  border: 1px solid #091f48;
}

.cp-btn-dotted {
  border: 1px solid #ffcc00;
}

.cp-btn button img {
  margin-right: 7px;
  margin-top: -4px;
}

.cp-btn button:hover {
  border: 1px solid #000000;
  background: #f2f2f2;
}

.create-pp-post-btn {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.create-pp-post-cont-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #201e29;
}

.create-pp-post-btn span.cp-btn {
  width: 20%;
}

.create-pp-post-btn span.cp-btn:nth-child(4) {
  width: 25%;
}

.create-pp-post-btn span.cp-btn:nth-child(5) {
  width: 15%;
}

.create-pp-post-btn button {
  margin: 0px !important;
  width: 100%;
}

.create-pp-post-cont {
  border-radius: 15px;
  background: rgb(32 30 41 / 6%);
  padding: 10px 20px 20px 20px;
  margin-bottom: 18px;
}

.poll-duration-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 8px;
  display: flex;
}

.cr-pp-img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50px;
}

.create-pp-post-left {
  position: absolute;
}

.create-pp-post-right {
  padding-left: 65px;
}

.create-ps-title-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
}

.create-ps-title-title.ccstm-creatr-post-main h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 0px;
}

.create-ps-title-title.ccstm-creatr-post-main {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.create-ps-title-title.ccstm-creatr-post-main select {
  height: auto;
  padding: 2px 32px 2px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1px;
  margin-left: 5px;
  margin-right: 10px;
  color: #091f48;
  font-weight: 600;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.create-ps-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
}

.create-ps-title-title.ccstm-creatr-post-main input[type="text"] {
  height: auto;
  padding: 2px 10px 2px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1px;
  margin-left: 5px;
  margin-right: 10px;
  color: #091f48;
  font-weight: 600;
  width: fit-content;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.create-ps-title-close .close {
  font-weight: 200;
  font-size: 21px;
}

form-check.um-cp-check {
  padding: 0px;
  margin-bottom: 10px;
}

span.um-fm-tl {
  margin-left: 5px;
}

select {
  border-radius: 15px;
  background: var(--grey2);
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--input);
  height: 48px;
  padding: 10px 20px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../Images/s.png);
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

.create-popup-post .modal-dialog {
  max-width: 730px;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.create-popup-post .modal-content {
  border-radius: 15px;
  padding: 16px;
  max-height: calc(100vh - 124px);
  overflow: auto;
  position: relative;
}

.create-post-content-inner-main {
  height: 100%;
  position: relative;
}

span.um-ulpoad-close {
  float: right;
  font-size: 28px;
  margin-top: 5px;
  cursor: pointer;
}

.um-upload-msg .list-group-item-success {
  background: #82cdb8;
  border-radius: 15px;
  border: none;
  font-weight: 600;
  margin-bottom: 10px;
}

.um-upload-msg img {
  margin-right: 14px;
  color: #000;
}

.um-drap-main-box img {
  width: 100%;
  margin-bottom: 20px;
}

.cstm-sm-form textarea:focus {
  box-shadow: 0 0 0 1px #351bce;
  border: 1px solid #351bce !important;
}

.create-pp-post.csm-main-pp-post {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
}

.create-pp-post.csm-main-pp-post .create-pp-post-right {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 260px);
}

.create-pp-post.csm-main-pp-post .create-pp-post-cont {
  flex-grow: 1;
  overflow: auto;
}

.um-channels-th {
  display: flex;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  margin-bottom: 10px;
  margin-top: 5px;
  color: var(--blue);
}

.um-channels {
  margin-bottom: 20px;
}

.um-channels-td-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--black);
  width: 39%;
  padding: 0px 1% 0px 3%;
}

.um-channels-td-checkbox {
  display: flex;
  width: 10.3%;
  justify-content: center;
}

.um-channels-td {
  background: #ffffff;
  border-radius: 15px;
  margin-bottom: 12px;
  display: flex;
  padding: 15px 0px;
  align-items: center;
}

.um-channels-td-checkbox .form-check-input {
  position: initial;
  margin: 0px;
  height: 18px;
  width: 18px;
}

.um-thank-you-left:before,
.um-thank-you-left:after {
  display: none;
}

.um-calendar-box-top {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--text2);
  margin-bottom: 10px;
}

.um-calendar-box-bottom {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--black);
}

.um-calendar {
  display: flex;
  align-items: center;
}

.um-calendar-box {
  background: #ffffff;
  border-radius: 15px;
  padding: 12px 16px;
  margin-right: 16px;
  text-align: center;
}

.um-drap-main {
  padding-left: 32px;
  margin-top: 15px;
}

.um-drap-main h3 {
  color: var(--black);
  font-weight: 700;
  line-height: 20px;
}

.um-drap-main h4 {
  line-height: 18px;
  margin-bottom: 20px;
}

.um-checkbox-main {
  margin-top: 12px;
}

.um-checkbox .form-check-input {
  margin: 15px 0px 0px 15px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.um-checkbox {
  margin-bottom: 12px;
}

.um-checkbox .form-check-label {
  margin-bottom: 0;
  width: 100%;
}

.um-cl-sl {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  height: 48px;
  padding: 14px 18px 14px 45px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  cursor: pointer;
  border: 1px solid var(--white);
}

.form-check-input:checked + .um-cl-sl {
  border: 1px solid var(--black);
}

.um-ck-ac {
  pointer-events: none;
  background: #ffffff;
  border-radius: 15px;
  height: 48px;
  padding: 14px 18px 14px 42px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  width: 104px;
  cursor: pointer;
  border: 1px solid var(--white);
}

.form-check-input:checked + .um-ck-ac {
  border: 1px solid var(--black);
}

.um-radio {
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  width: 168px;
}

.um-radio .form-check-label {
  margin-bottom: 0;
  cursor: pointer;
}

.um-radio .form-check-input {
  margin: 15px 0px 0px 15px;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.um-radio-main {
  margin-top: 12px;
}

.um-questions:before {
  display: none;
}

body .um-questions:after {
  content: "";
  position: absolute;
  background-image: url(../Images/questions.png);
  height: 407px;
  width: 308px;
  z-index: 0;
  bottom: 0;
  right: 0;
}

.um-right-in-label b {
  color: var(--black);
}

.um-number {
  position: absolute;
  right: 0;
  line-height: 40px;
}

.um-number b {
  color: var(--black);
}

.um-credit-cards img {
  right: 12px;
  top: 6px;
  position: absolute;
}

.um-payment-left:before {
  display: none;
}

body .um-payment-left:after {
  content: "";
  position: absolute;
  background-image: url(../Images/payment.png);
  height: 275px;
  width: 279px;
  z-index: 0;
  bottom: 52px;
  right: 25px;
}

.um-total-amout {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.um-total-amout-label {
  margin-right: 8px;
}

.um-cstm-rac-in-content {
  color: var(--black);
  font-weight: 600;
  width: 42%;
  text-align: right;
  word-break: break-word;
}

.um-plan-information .um-ysp-right {
  color: var(--text3);
}

.um-plan-information {
  box-sizing: border-box;
  border-radius: 15px;
  padding: 24px 24px 10px 24px;
  width: 100%;
  display: flex;
  background: var(--navyblue);
  margin-bottom: 30px;
}

.um-right-in-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: var(--black);
  margin-bottom: 16px;
}

.um-cstm-rac {
  margin-bottom: 32px;
}

.um-cstm-rac-in-label {
  width: 48%;
}

.um-cstm-rac-in-content {
  color: var(--black);
  font-weight: 600;
  width: 45%;
  text-align: right;
}

.um-total-amout-content {
  color: var(--black);
  font-weight: 600;
}

.um-cstm-rac-in {
  border-bottom: 1px solid var(--line);
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
}

.um-birthade {
  position: relative;
}

.um-birthade:before {
  content: "\f133";
  position: absolute;
  font-family: FontAwesome;
  right: 18px;
  color: var(--black);
  top: 10px;
  font-size: 21px;
  cursor: pointer;
}

.um-fm-tl i {
  color: var(--text);
  opacity: 0.7;
  cursor: pointer;
}

.um-captcha {
  margin-bottom: 40px;
  margin-top: 5px;
}

.um-ysp-price {
  margin-bottom: 16px;
  color: var(--white);
}

.um-ysp-price-in {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
}

.um-ysp-lable {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  display: inline-block;
  border-radius: 10px;
  padding: 3px 10px;
  background: var(--yellow);
  margin-bottom: 20px;
}

.um-ysp-price sup {
  font-weight: 600;
  font-size: 24px;
  margin-right: 6px;
  top: -15px;
}

.um-ysp-left {
  width: 50%;
}

.um-ysp-right {
  width: 50%;
  color: var(--text2);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.um-ysp {
  background: var(--black);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 15px;
  max-width: 547px;
  padding: 24px;
  width: 100%;
  display: flex;
}

.um-left {
  background-color: var(--black);
  height: 100vh;
}

.cstm-um-main.cstm-login .um-left {
  background-image: url(../Images/mds-login-bg.png);
  background-size: contain;
  background-position: right center;
  height: 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
  height: 100%;
  display: table;
}

.cstm-um-main.cstm-login .um-left::before,
.cstm-um-main.cstm-login .um-left::after {
  display: none;
}

.cstm-um-main .um-left,
.cstm-um-main .um-right {
  min-height: 750px;
}

.um-right {
  overflow: auto;
  height: 100vh;
  display: flex;
}

.um-right::-webkit-scrollbar {
  /* width: 10px;
     */
  width: 0px;
}

.um-right::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--grey2);
}

.um-right::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
}

.um-right::-webkit-scrollbar-thumb:hover {
  background: var(--yellow);
  cursor: pointer;
}

.um-right-in {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 456px;
  margin: auto;
  width: 100%;
  padding-top: 20px;
}

.um-logo {
  margin-top: 28px;
}

.um-title {
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 72px;
  color: var(--white);
  margin-bottom: 18px;
}

.um-title h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 54px;
  color: var(--white);
  margin-bottom: -4px;
}

.um-title2 {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  color: var(--white);
  margin-bottom: 24px;
}

.um-title2 h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
}

.um-left-in-mn {
  padding-left: 50px;
  padding-top: 150px;
  padding-bottom: 50px;
}

.um-left-in-mn.csmt-um-left-in-mn.login-um-left-in-mn {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cstm-um-main.login-um-main .um-left-in {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.um-text {
  margin-bottom: 30px;
  max-width: 440px;
  width: 100%;
  color: var(--text2);
}

.cstm-um-main.login-um-main .um-text {
  max-width: 100%;
}

.um-text p {
  margin-bottom: 30px;
  max-width: 440px;
  width: 100%;
  color: var(--text2);
}

.cstm-um-main.login-um-main .um-text p {
  max-width: 100%;
}

.um-left:before {
  content: "";
  position: absolute;
  background-image: url(../Images/caht-bg.png);
  height: 273px;
  width: 361px;
  z-index: 0;
  right: 0;
}

.um-left:after {
  content: "";
  position: absolute;
  /* background-image: url(../Images/pl.png);
     */
  /* background-image: url(../Images/ppl-img.svg);
     */
  height: 100%;
  width: 100%;
  z-index: 0;
  bottom: 0;
  right: 0;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 38%;
}

.um-left-in {
  position: relative;
  z-index: 1;
}

.um-people {
  display: flex;
  align-items: center;
}

.um-people {
  display: flex;
  align-items: center;
}

.um-people-photo {
  margin-right: 44px;
}

span.um-people-photo-in img {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.um-people-joined a {
  font-weight: 700;
  color: var(--white);
  text-decoration: underline;
}

.um-people-joined a:hover {
  color: var(--yellow);
}

span.um-people-photo-in {
  margin-right: -20px;
  float: left;
}

.um-right-in-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: var(--black);
  margin-bottom: 16px;
}

.um-right-in-sub {
  display: flex;
  width: 100%;
  padding-bottom: 15px;
}

.um-not {
  padding-top: 8px;
}

.um-not a {
  font-weight: 700;
}

.um-rmb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.um-right-in-remember label {
  margin-bottom: 0px;
  cursor: pointer;
}

.um-right-in-remember {
  display: flex;
  align-items: center;
}

a.lets-get {
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--white);
  margin-right: 8px;
}

a.lets-get:hover {
  color: var(--yellow);
  text-decoration-line: inherit;
}

body .um-meme-det-only {
  min-height: 410px;
}

.um-right-in2 {
  display: flex;
  flex-direction: column;
  max-width: 605px;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 50px 0px;
  position: relative;
}

.um-title-how {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: var(--black);
  margin-bottom: 16px;
}

.um-rgt-btn {
  padding-bottom: 30px;
  padding-top: 10px;
  display: block;
  width: 100%;
}

.um-rgt-btn .btn2 {
  float: right;
}

.um-member-step {
  margin-bottom: 40px;
  overflow-x: auto;
  width: 100%;
  overflow-y: hidden;
  min-height: 330px;
}

.um-member-step-in-box {
  width: 240px;
  background: #ffffff;
  border-radius: 15px;
  margin-right: 16px;
  padding: 16px;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.um-member-step-in-box-number {
  background: #ffcc00;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--black);
  text-align: center;
  width: auto;
  display: inline-block;
  padding: 3px 10px;
  position: absolute;
  right: 25px;
  top: -11px;
}

.um-member-step-in-box-icon {
  margin-top: 15px;
  margin-bottom: 22px;
}

.um-member-step-in-box-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 7px;
  width: 208px;
}

.um-member-step-in-box-content {
  font-size: 14px;
  line-height: 24px;
}

.um-member-step-in {
  display: flex;
}

.um-member-step::-webkit-scrollbar {
  height: 10px;
}

.um-member-step::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--grey2);
}

.um-member-step::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
}

.um-member-step::-webkit-scrollbar-thumb:hover {
  background: var(--yellow);
  cursor: pointer;
}

a.read-more {
  font-weight: 700;
  text-decoration: underline;
}

.back-btn {
  border: none;
  padding: 0px;
  background: transparent;
  color: var(--black);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.back-btn:hover {
  color: var(--yellow);
}

.back-btn img {
  margin-right: 3px;
  vertical-align: -1px;
}

.um-back {
  margin-bottom: 13px;
}

.um-membership-details {
  display: flex;
  gap: 20px;
  padding-top: 40px;
  margin-bottom: 0;
}

.um-membership-details-in {
  background: var(--white);
  border-radius: 15px;
  padding: 33px 16px 20px 16px;
  position: relative;
  border: 1px solid var(--white);
  cursor: pointer;
}

.um-membership-details-in:hover {
  border: 1px solid var(--black);
}

span.um-md-price {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: var(--title);
}

.um-membership-details-in-price sup {
  font-weight: 600;
  font-size: 24px;
  color: var(--black);
  margin-right: 6px;
  top: -15px;
}

.um-annual-payment {
  background: var(--yellow);
}

.um-quarterly-payments {
  background: var(--aqua);
}

.um-membership-details-in-lable {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
  display: inline-block;
  border-radius: 10px;
  padding: 3px 10px;
  text-transform: capitalize;
}

.um-save {
  position: absolute;
  right: 20px;
  top: -44px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--white);
  background: url(../Images/sale.png);
  height: 95px;
  width: 95px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.um-membership-details-in-price {
  margin-top: 22px;
  margin-bottom: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey2);
  width: 250px;
}

a.linked-agreement {
  font-weight: 800;
  text-decoration: underline;
}

.webfooter {
  visibility: hidden;
  display: none;
}

.modal-content.cstm-modalcontent {
  padding: 20px;
}

span.msg-btn button {
  margin: 0;
}

/* 03-08-2022 */
.postdetailcontet {
  align-self: center;
  text-align: left;
  padding-left: 0;
}

.postdetailcontet h5 {
  font-size: 16px;
  font-weight: 700;
  color: #091f48;
  margin: 0;
}

.postdetailcontet p {
  font-weight: 400;
  font-size: 12px;
  color: #201e29;
  opacity: 0.7;
  margin: 0;
}

.postdetailtext1 {
  font-size: 14px;
  font-weight: 400;
  color: #201e29;
  text-align: left;
  margin: 0;
  padding-bottom: 8px;
}

.postdetailtext2 {
  font-size: 14px;
  font-weight: 700;
  color: #351bce;
  text-align: left;
  margin: 0;
  padding-bottom: 26px;
}

.PostDetailLike {
  text-align: left;
}

.msg-popup .modal-content {
  padding: 30px 40px 30px 40px;
  width: 88%;
  margin: auto;
}

.postdetail {
  padding: 16px;
}

.border-bottom1 {
  border-bottom: 1px solid #cccccc;
  margin: 16px 0;
  padding: 0;
}

.header-chat-cont-head-top.post-detail {
  margin: 0;
  padding: 21px 21px 0 21px;
}

.cstm-starred-group ul li:last-child {
  padding-bottom: 0px;
}

.header-chat-cont-head-top.post-detail.post-detail-cstm {
  padding-top: 0;
}

.postdetail.cstm-postdetail {
  padding: 16px 16px 0 16px;
}

.um-back.cstm-um-back {
  margin-bottom: 10px;
}

.uic-title.cstm-uic-title {
  margin-bottom: 10px;
}

.gl-title-sub.cstm-gl-title-sub {
  margin-bottom: 0;
}

.error.cstm-error {
  margin: -14px 0 0 0;
}

.col-sm-6.form-group.cstm-fromgroup {
  margin-top: 9px;
}

.gl-title-sub.cstm-gl-title-sub {
  margin-bottom: 0;
}

.create-pp-post-img1 img {
  max-height: 675px;
  object-fit: contain;
  border-radius: 0px;
  height: 100%;
}

.create-pp-post-img1 video {
  max-height: 675px;
  object-fit: contain;
  border-radius: 0px;
  height: 100%;
}

.cstm-user-social-main {
  display: grid;
  align-items: center;
  height: 100vh;
  min-height: 650px;
}

.cstm-main-header {
  text-align: center;
}

.social-icon-main-row {
  display: flex;
  justify-content: center;
  gap: 15px;
  height: 100%;
}

.social-connet-main {
  display: flex;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}

.cstm-main-header h2 {
  line-height: 50px;
  color: #000000;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
}

.social-col-main {
  width: 48%;
}

.cstm-main-social-box {
  min-height: 240px;
  height: 100%;
  border-radius: 13px;
  text-align: center;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
}

.main-soical-box-facebook {
  background-color: #4267b2;
  border: 1px solid #4267b2;
  transition: 0.3s ease-in-out;
}

.main-soical-box-linkdin {
  background-color: #0077b5;
  border: 1px solid #0077b5;
  transition: 0.3s ease-in-out;
}

.cstm-social-facebook-btn {
  background-color: transparent;
  border: 0;
  font-size: 38px;
}

.cstm-social-linkdin-btn {
  background-color: transparent;
  border: 0;
  font-size: 38px;
}

.box-label-content-facebook {
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  margin: 0;
}

.box-label-content-linkedin {
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
  margin: 0;
}

.cstm-icon-main-facebook {
  font-size: 65px;
  color: #ffffff;
}

.cstm-icon-main-linkedin {
  font-size: 65px;
  color: #ffffff;
}

.main-soical-box-facebook:hover {
  background: #4267b2f2;
}

.main-soical-box-linkdin:hover {
  background-color: #0077b5e6;
}

.cstm-main-header p {
  margin: 0;
  padding-bottom: 20px;
}

.checkmark i {
  color: #ffffff;
  font-size: 35px;
}

/* New page */
.new-login-box-wrapper {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.new-login-page .um-logo {
  margin: 18px 12px;
}
.new-login-page .cstm-sim-title {
  margin-bottom: 20px;
  letter-spacing: -0.36px;
}
.new-login-page .cstm-simplity {
  height: auto;
  margin: auto;
  max-width: 360px;
  width: 100%;
  padding: 80px 0;
}
.new-login-page .um-logo img {
  height: 28px;
}
.new-login-page .btn-facebook {
  background-color: #0866ff;
}
.new-login-page .btn-facebook:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}
button.apple-btn.new-login-page-btn:hover {
  background-color: #000000;
}
.new-login-page .cstm-register-now {
  font-size: 15px;
  margin: 32px 0 0;
}
button.new-login-page-btn {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.new-login-page-btn svg {
  margin-right: 6px;
  flex-shrink: 0;
}
.new-login-page .cstm-register-now a {
  color: var(--color-text-brand-strong, #0b42cf);
  font-weight: 700;
}
.new-login-page .cstm-apple-btn {
  margin-top: 12px;
}
@media (max-width: 1440px) {
  .cstm-um-main.cstm-login .um-left {
    background-image: url(../Images/mds-login-bg.png);
    background-size: cover;
  }

  .cstm-my-videos-list-box-img {
    width: 27%;
  }

  .cstm-my-videos-list-box-info {
    width: 73%;
  }
}

@media (max-width: 1250px) {
  .um-comm-prefe .col-sm-4 {
    flex: 100%;
    max-width: 100%;
  }

  .um-comm-prefe .col-sm-4:nth-child(2) {
    margin: 20px 0px;
  }
}

@media (max-width: 1200px) {
  .um-ysp-price sup {
    font-size: 18px;
    top: -12px;
  }

  .um-ysp-price-in {
    font-size: 36px;
    line-height: 42px;
  }

  .um-save {
    font-size: 14px;
    line-height: 18px;
    height: 75px;
    width: 75px;
  }

  .um-membership-details-in-price sup {
    font-size: 18px;
    top: -10px;
  }

  span.um-md-price {
    font-size: 32px;
    line-height: 42px;
  }

  .um-left:before {
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: right top;
  }

  .um-left:after {
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .login-um-main .um-left:after {
    background-size: 38%;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  body .um-questions:after {
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  span.um-people-photo-in img {
    height: 40px;
    width: 40px;
  }

  .um-title {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 12px;
  }

  .um-title2 {
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 12px;
  }

  .um-left-in-mn {
    padding-left: 0px;
    padding-top: 110px;
  }
}

@media (max-width: 999px) {
  .cstm-vh-title {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
  }

  .cstm-my-videos-history-list-main-box-img {
    width: 45%;
    margin-right: 10px;
  }

  .cstm-my-videos-history-list-main-box-info {
    width: 55%;
  }

  .search-user-input {
    width: 200px !important;
  }

  .hf-m {
    display: flex !important;
  }

  .webfooter {
    visibility: visible;
    display: block;
  }

  .um-title h1 {
    font-size: 42px;
    line-height: 64px;
  }

  .login-um-left-in-mn .um-login-title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .um-captcha {
    margin-bottom: 20px;
  }

  .um-rgt-btn .btn3 {
    width: 100%;
    margin-bottom: 20px;
  }

  .um-membership-details {
    margin-bottom: 20px;
  }

  .um-member-step {
    margin-bottom: 20px;
  }

  .um-rgt-btn .btn2 {
    width: 100%;
  }

  .um-logo {
    -webkit-filter: grayscale(1) invert(0.95);
    filter: grayscale(1) invert(0.95);
  }

  .um-logo {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .um-left {
    background-color: transparent;
  }

  .um-left-in-mn {
    display: none;
  }

  .um-left:before,
  .um-left:after {
    display: none;
  }

  .login-um-main .um-left:before,
  .login-um-main .um-left:after {
    display: block;
  }

  .um-rgt-btn {
    padding-bottom: 0;
  }

  .um-right-in2 {
    padding: 30px 0px;
  }

  .um-left {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .um-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  span.um-people-photo-in img {
    height: 30px;
    width: 30px;
  }

  .um-people-photo {
    margin-right: 34px;
  }

  .um-left-in-mn {
    padding-left: 0px;
    padding-top: 45px;
  }

  .um-right {
    overflow: auto;
    height: inherit;
    display: flex;
  }

  .um-left {
    height: 100%;
  }

  .all-content-main-right,
  .sidebar-icon,
  .sidebar2-icon,
  .sidebar-icon.sidebarweb,
  .all-content-main-left,
  .header-ft-search-search input,
  .header-ft-search-select,
  span.header-ft-name-user {
    display: none;
  }

  .all-content-main-center {
    width: 100%;
  }

  .what-post-btn-all .cp-btn {
    margin-right: 2px;
  }

  .cp-btn button {
    font-size: 0 !important;
    padding: 8px !important;
    margin-right: 10px !important;
  }

  button.cp-btn-photos img,
  button.cp-btn-videos img {
    padding-right: 4px;
  }

  .newsfeed-post-right {
    padding-left: 0;
  }

  .newsfeed-post-right-header-name {
    padding-left: 70px;
  }

  .all-content-main {
    padding: 100px 0 0px 0;
  }

  .what-is-happening-main,
  .newsfeed-main {
    margin: 0 16px 24px 16px;
  }

  .newsfeed-post-right-header-icon {
    flex-direction: row-reverse;
  }

  .span.header-ft-photo-user {
    order: 2;
  }

  .header-ft-menu-view {
    order: 1;
  }

  .header-ft-search-search:after {
    height: 36px;
    width: 33px;
    top: -11px;
    right: -279px;
    background-repeat: no-repeat;
  }

  .newsfeed-write-your-comments-tx .react-input-emoji--button svg {
    margin-top: 1px;
  }

  .newsfeed-select-in {
    left: 0px !important;
  }

  .webfooter {
    visibility: visible;
    background-color: #fff;
    padding: 8px 0 20px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    border-top: 1px solid #b9b9b9;
    display: none;
  }

  .footer-icon {
    padding: 0 19px;
  }

  .newsfeed-post-right-cmt {
    margin-top: 19px;
  }

  .post-msg-all {
    width: 100%;
  }

  .profile-detilas-all-username {
    display: block;
  }

  .profile-detilas-all-username-left2 {
    padding-bottom: 22px;
  }

  .um-billing-info-right {
    width: 50%;
  }

  .cancle-subscription-link {
    margin-left: 0;
  }

  .um-billing-info-right-upgrade button {
    margin-bottom: 7px;
  }

  .profile-detilas-user-title {
    font-size: 18px;
  }

  .search-members-input {
    width: 258px !important;
  }

  .page-not-found {
    font-size: 18px;
  }

  .header-menu-dp.footer-menu ul li a {
    font-size: 16px;
    line-height: 17px;
  }

  .profile-bg-img-mn {
    height: 200px;
  }

  .profile-detilas-user-icon {
    margin-bottom: 15px;
  }

  .allpost-main {
    margin-bottom: 5rem;
  }

  .cstm-um-main .um-left,
  .cstm-um-main .um-right {
    min-height: auto;
  }

  .um-main.cstm-um-main.login-um-main .um-left {
    height: 100vh;
    min-height: 450px;
  }

  .um-main.cstm-um-main.login-um-main .um-left {
    background: var(--black);
  }

  .um-main.cstm-um-main.login-um-main .um-left .um-logo {
    filter: none;
  }

  .cstm-um-right-2 .um-right-in2 {
    max-width: 100%;
  }

  .cstm-um-right-2 .um-right-in2 .um-rgt-btn {
    text-align: center;
  }

  .cstm-um-right-2 .um-right-in2 .um-rgt-btn .btn2 {
    max-width: 200px;
    float: none;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .header-ft-search-search:after {
    right: -258px;
  }
}

@media (max-width: 767px) {
  .something-icon {
    height: 18px;
  }

  .cstm-try-contact-btn button {
    width: 135px;
    padding: 5px;
    font-size: 13px;
  }

  .cstm-try-contact-btn {
    margin-top: 12px;
    gap: 12px;
  }

  .cstm-login-screen-popup p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .cstm-login-screen-popup h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .cstm-somr-icom img {
    width: 80px;
  }

  .cstm-somr-icom {
    margin-bottom: 10px;
  }

  .cstm-login-screen-popup .modal-content {
    padding: 20px;
  }

  .cstm-menu-responsive {
    display: block !important;
  }

  .cstm-hd-as {
    display: block !important;
  }

  .cstm-conten-listing-pg-only .cstm-cv-video {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 160px;
  }

  .edit-profile {
    padding-top: 54px !important;
  }

  .cstm-cstm-vpp-photo img {
    height: 320px;
  }

  .cstm-replace-pp .ReactCrop__child-wrapper img {
    height: 320px;
  }

  .globle-model-box {
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .cstm-tabs-mv-cont {
    min-height: 200px;
    position: relative;
  }

  .cstm-my-videos-list-box-dt {
    background: #fff;
    position: absolute;
    right: 8px;
    cursor: pointer;
    top: 8px;
    border-radius: 8px;
  }

  .cstm-my-videos-tabs ul li a {
    font-size: 13px;
    padding: 8px 10px;
  }

  .cstm-my-videos-list {
    margin-bottom: 70px;
  }

  .cstm-my-videos-list-box-info {
    width: 100%;
    padding: 0px;
    margin-top: 10px;
  }

  .cstm-my-videos-list-box-img {
    width: 100%;
  }

  .cstm-my-videos-list-box {
    flex-direction: column;
  }

  .cstm-profile-ct {
    min-height: inherit;
  }

  .cstm-ac-se-nav ul li a svg {
    margin-right: 2px;
  }

  .cstm-ac-se-nav ul li a {
    width: max-content;
  }

  .cstm-ac-se-nav ul {
    display: flex;
    overflow: auto;
  }

  .btn4 {
    height: 42px;
    padding: 8px 22px;
  }

  .cstm-profile-sd {
    display: none;
    width: 100%;
    z-index: 3;
    background: #fff;
    top: 54px;
    padding: 10px 15px 0px 15px;
    margin: 0px;
    height: 50px;
  }

  .cstm-profile-ct {
    flex-direction: column;
  }

  .all-content-main-edit-profile {
    width: 100%;
  }

  .apple-btn {
    height: 42px;
  }

  .cstm-sim-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #201e29;
    margin-bottom: 32px;
  }

  .cstm-didn span {
    font-size: 14px;
  }

  .cstm-didn {
    font-size: 14px;
  }

  .cstm-wlm-sub {
    font-size: 14px;
  }

  .cstm-wlm-email {
    background: rgba(19, 114, 209, 0.1);
    font-size: 14px;
    padding: 5px 20px;
  }

  .cstm-wlm-title {
    font-size: 24px;
  }

  .cstm-wlm-field {
    gap: 5px;
  }

  .cstm-wlm-field input {
    padding: 5px !important;
    height: 42px !important;
    width: 42px !important;
    border-radius: 8px !important;
    font-size: 20px !important;
  }

  .cstm-wlm-main {
    padding: 40px 20px 0px 20px;
  }

  .cstm-ca-search-box {
    display: block;
  }

  .cstm-ca-search-box .owl-nav {
    display: none;
  }

  .emptymsg {
    margin-top: 20px;
  }

  .cstm-ttl2 {
    height: inherit;
  }

  .uic-fl-search i {
    top: 11px;
  }

  .search-user-input {
    width: 100% !important;
  }

  .um-title h1 {
    font-size: 32px;
    line-height: 50px;
    text-align: center;
  }

  .cstm-um-main.login-um-main .um-text p {
    text-align: center;
  }

  .login-um-left-in-mn .um-login-title {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .um-not.cstm-um-note {
    text-align: center;
    color: rgba(255, 255, 255, 0.24);
  }

  .login-um-left-in-mn .um-login-sec {
    max-width: 450px;
    margin: auto;
  }

  .create-ps-title-title {
    font-size: 14px;
  }

  .topics-search-bar img {
    top: 8px;
  }

  .create-pp-post-cont {
    padding: 10px;
  }

  .addpoll-delete {
    top: 8px;
  }

  .add-poll-main {
    padding: 10px 10px;
  }

  .create-pp-post-public-btn {
    margin-left: 10px;
  }

  .wayf-main {
    width: 50%;
  }

  .create-pp-cunt {
    font-size: 16px;
  }

  .cp-public-gl:after {
    right: 0px;
  }

  .cp-public-gl {
    border: none;
    padding: 0px 25px 0px 0px;
    display: flex;
    min-width: 90px;
  }

  .create-pp-post-public-btn button {
    margin: 0px;
    padding: 2px 30px;
    height: 48px;
  }

  .cp-btn button {
    font-size: 0;
    padding: 6px 6px;
    margin-right: 16px;
  }

  .cp-btn button img {
    margin-right: 0;
  }

  .create-pp-post-btn {
    justify-content: flex-start;
  }

  .create-pp-post-right {
    padding-left: 0;
  }

  .cr-pp-img {
    width: 40px;
    height: 40px;
  }

  .create-pp-post-left {
    position: inherit;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #091f48;
    margin-bottom: 16px;
  }

  .cr-pp-name {
    display: block;
    margin-left: 8px;
  }

  .cr-pp-name {
    display: block;
    margin-left: 8px;
  }

  .um-member-step-in {
    width: 100%;
  }

  /* */
  .selectedplan:before {
    right: 0;
  }

  .um-membership-details {
    padding-top: 0;
  }

  /* */
  .um-member-step-in-box {
    width: 180px;
  }

  .um-credit-cards img {
    right: 10px;
    top: 10px;
    width: 100px;
  }

  .um-plan-information {
    padding: 15px 15px 10px 15px;
    flex-direction: column;
  }

  .um-back {
    margin-bottom: 18px;
  }

  .um-ysp-left {
    width: 100%;
  }

  .um-ysp-right {
    width: 100%;
  }

  .um-ysp {
    padding: 15px;
    flex-direction: column;
  }

  .um-cp-check .form-check-label {
    padding: 8px 12px;
  }

  .um-cp-check .form-check-input {
    top: 15px;
  }

  .um-membership-details {
    flex-direction: column;
  }

  .um-left {
    height: inherit;
  }

  input[type="checkbox"],
  input[type="radio"] {
    height: 21px;
    width: 21px;
  }

  .btn,
  .btn-facebook {
    border-radius: 8px;
    height: 42px;
  }

  .btn2 {
    height: 42px;
    padding: 8px 22px;
  }

  .btn3 {
    height: 42px;
    padding: 8px 22px;
  }

  select {
    border-radius: 8px;
    height: 40px;
    padding: 10px 15px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  input[type="date"],
  input[type="time"],
  input[type="file"] {
    border-radius: 8px;
    height: 40px;
    padding: 10px 15px;
  }

  span.um-people-photo-in {
    margin-right: -10px;
  }

  .um-right-in-title {
    font-size: 24px;
    line-height: 30px;
  }

  .um-people-photo {
    margin-bottom: 15px;
  }

  .um-people {
    align-items: flex-start;
    flex-direction: column;
  }

  .um-title {
    font-size: 24px;
    line-height: 30px;
  }

  .um-title2 {
    font-size: 24px;
    line-height: 30px;
  }

  .um-title-how {
    font-size: 20px;
    line-height: 26px;
  }

  .all-content-main-right,
  .sidebar-icon,
  .sidebar2-icon,
  .sidebar-icon.sidebarweb,
  .all-content-main-left,
  .header-ft-menu-icon.header-noti-box,
  .header-ft-search-search input,
  .header-ft-search-select,
  span.header-ft-name-user {
    display: none;
  }
}

@media (max-width: 600px) {
  .cstm-cv-video {
    grid-gap: 10px;
  }

  .um-title h1 {
    font-size: 32px;
    line-height: 50px;
    text-align: left;
  }

  .login-um-left-in-mn .um-login-title {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 10px;
    text-align: left;
  }

  .cstm-um-main.login-um-main .um-text p {
    text-align: left;
  }

  .um-channels-main {
    overflow: auto;
  }

  .um-channels {
    width: 600px;
  }

  .um-left {
    height: inherit;
  }

  .cstm-um-main .um-left,
  .cstm-um-main .um-right {
    min-height: auto;
  }

  .header-ft-search-search:after {
    height: 36px;
    width: 33px;
    top: -11px;
    right: -60px;
    background-repeat: no-repeat;
  }
}

.sidebar2-icon2 i {
  transform: rotate(180deg);
}

.cstm-vd-box-in22 {
  display: table;
  width: 100%;
  margin-bottom: 25px;
}

@media (max-width: 1365.98px) {
  .profile-detilas-all-username {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-detilas-all-username-left,
  .profile-detilas-all-username-right {
    width: 100%;
  }

  .profile-detilas-all-username-left {
    margin-bottom: 16px;
  }

  .profile-detilas-cmn {
    flex-grow: 1;
  }

  .pd-joind-btn {
    width: 100%;
    text-align: center;
  }

  .dropdown-menu.pd-joind-in.show {
    width: 100%;
  }

  button.pd-invite-btn {
    width: 100%;
    text-align: center;
  }

  .profile-detilas-cmn.pd-dottod {
    flex-grow: 0;
  }

  .pd-joind-btn i,
  .pd-invite-btn i {
    margin-left: 8px;
  }

  .profile-detilas-all-nav ul.nav.profiledt-menu {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .profile-detilas-all-nav .profiledt-menu li a {
    white-space: nowrap;
  }

  .profile-detilas-all-nav {
    padding: 12px 12px 0px;
  }

  .profiledt-menu li a {
    border-radius: 0px !important;
    border-bottom: 3px solid #ffffff;
  }

  .profiledt-menu li a.active {
    background-color: white;
    color: #351bce;
    border-bottom: 3px solid #351bce;
    border-radius: 0px !important;
    /* font-weight: 800;
         */
  }

  body .profile-detilas-all-nav ul.nav.profiledt-menu::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  body .profile-detilas-all-nav ul.nav.profiledt-menu::-webkit-scrollbar-track {
    background: transparent;
  }

  body .profile-detilas-all-nav ul.nav.profiledt-menu::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0px;
  }
}

@media (max-width: 1199.98px) {
  .cstm-saved-single-video-title {
    font-size: 14px;
    line-height: 120%;
  }

  .cstm-saved-single-video-in {
    padding: 12px;
  }

  .cstm-cl-recent {
    padding-bottom: 6px;
  }

  span.cstm-vh-info-dt {
    /* margin: 0px 5px;
         */
    font-size: 8px;
  }

  .cstm-vh-info {
    font-size: 12px;
    line-height: 130%;
  }

  .cstm-my-videos-history-list-main-box-info {
    padding-right: 28px;
  }

  .cstm-hv-op-drop-dt img {
    padding: 3px;
  }

  .cstm-vh-title {
    font-size: 14px;
    line-height: 130%;
  }

  .cstm-video-history-col-2 {
    width: 180px;
  }

  .cstm-clear-all-watch-history {
    padding: 6px 35px 6px 10px !important;
  }

  .cstm-video-history-search img {
    right: 10px;
  }

  .cstm-all-content-library-main-only .all-content-main-left {
    width: 180px;
  }

  .cstm-videos-history-only {
    padding: 8px 210px 8px 10px;
  }

  .all-content-main.cstm-all-content-main {
    grid-template-columns: minmax(0, 6fr) minmax(500px, 500px) minmax(0px, 6fr);
  }

  .cp-btn button span {
    display: none;
  }

  .cp-btn {
    width: 20%;
  }

  .cp-btn button {
    width: 100%;
  }
}

@media (min-width: 1500px) {
  .cstm-um-main .um-left-in {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .um-left-in-mn.csmt-um-left-in-mn {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .um-left-in-content-sec {
    max-width: calc(100% - 40%);
  }

  .csmt-um-left-in-mn .um-text {
    max-width: 100%;
  }

  .um-text p {
    margin-bottom: 24px;
    max-width: 100%;
    width: 100%;
    color: var(--text2);
    font-size: 16px;
  }

  .cstm-login .um-text {
    margin-bottom: 24px;
  }

  .csmt-um-left-in-mn .um-people {
    margin-bottom: 20%;
  }

  .um-right-in.csmt-um-left-in-mn .um-right-in-title {
    font-size: calc(28px + (60 - 48) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(28px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
  }

  .um-right-in.csmt-um-left-in-mn .um-right-in-sub,
  .um-right-in.csmt-um-left-in-mn .um-not {
    font-size: calc(16px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  }

  .um-title2 h2 {
    font-size: calc(28px + (50 - 22) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(28px + (60 - 16) * ((100vw - 320px) / (1920 - 320)));
  }

  .um-text.um-text-2 {
    margin-bottom: 30%;
  }

  .um-right-in2 .um-title-how {
    font-size: calc(16px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
  }

  .um-right-in2 p {
    font-size: calc(16px + (19 - 18) * ((100vw - 320px) / (1920 - 320)));
  }

  .um-right.cstm-um-right-2 .um-right-in2,
  .um-right.cstm-um-right-2-1 .um-right-in2 {
    max-width: 80%;
  }

  .cstm-um-right-2 .um-right-in2:before,
  .cstm-um-right-2 .um-right-in2:after {
    content: "";
    flex: auto;
  }
}

.all-content-main-left-sidebar.global-left-side .global-das {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.global-left-side .cstm-starred-group {
  padding-top: 0px;
}

.all-content-main-left-sidebar.global-left-side .card:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.all-content-main-left-sidebar.global-left-side .card:last-child .card-header {
  border-bottom: 0px;
}

.global-result {
  padding: 12px 16px;
  background: rgb(211, 239, 231);
  border-radius: 0;
}

.global-result span {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #091f48;
  margin-bottom: 0;
  opacity: 0.9;
}

.global-result p {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #091f48;
  margin-bottom: 0;
}

.mobile.global-result,
.global-result-buttom {
  display: none;
}

@media (max-width: 767.98px) {
  .cstm-cl-mv-ldr {
    display: block;
  }

  .cstm-contant-page {
    word-wrap: break-word;
  }

  .form-group {
    margin-bottom: 12px;
  }

  .um-right-in-label {
    margin-bottom: 2px;
  }

  .cstm-contant-page p {
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 18px;
  }

  .cstm-contant-page h1 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .cstm-contant-page h2 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .cstm-per-inf-title {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .cstm-ac-se-nav ul li a {
    padding: 4px 8px !important;
    font-size: 13px;
  }

  .all-content-main-edit-profile {
    width: 100%;
    padding-left: 0;
  }

  .header-menu-dp ul li a {
    margin: 0px 5px;
  }

  .cstm-history-top-title img {
    display: none;
  }

  .cstm-hv-op-drop {
    top: 12px;
    right: 0px;
  }

  .cstm-hv-op-drop-dt img {
    width: 25px;
  }

  span.cstm-vh-info-dt {
    font-size: 7px;
  }

  .cstm-vh-info {
    font-size: 11px;
  }

  .cstm-video-history-col-1 .cstm-cl-title {
    margin-bottom: 6px;
  }

  .cstm-vh-title {
    margin-top: 0;
  }

  .cstm-manage-history-title {
    margin-bottom: 6px;
  }

  .cstm-cawh {
    padding-bottom: 6px;
  }

  .cstm-video-history-search {
    margin-bottom: 6px;
  }

  .cstm-video-history-col-2 {
    width: 100%;
    position: fixed;
    left: 0;
    padding: 0px 15px;
    background: #fff;
    top: 98px;
    display: none;
  }

  .cstm-videos-history-only {
    padding: 0px 15px 8px 15px;
    width: 100%;
  }

  .cstm-history-top-title {
    font-size: 16px;
  }

  .cstm-my-videos-only {
    padding: 10px 15px 15px 15px;
  }

  .icon-box {
    font-size: 12px;
  }

  .cstm-cv-video-in {
    margin-bottom: 20px;
  }

  body.menuview .all-content-main-left {
    width: 100% !important;
  }

  body.menuview .card-header button.btn-link .cstm-tlp {
    width: inherit;
    font-size: inherit;
    position: inherit;
    font-weight: inherit;
    line-height: inherit;
    display: inherit;
    color: inherit;
    background: inherit;
    border-radius: inherit;
    padding: 0px;
    left: inherit;
    top: inherit;
    z-index: inherit;
  }

  body.menuview
    .cstm-all-content-library-main-only
    .card-header
    button.btn-link {
    padding: 4px 8px !important;
    font-size: 13px !important;
  }

  .cstm-cv-video {
    display: block;
  }

  .cstm-cl-bottom {
    margin-bottom: 6px;
  }

  .cstm-cl-search img {
    right: 12px;
    top: 8px;
  }

  .cstm-cl-title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .cstm-all-content-library-main-only .all-content-main-left {
    display: none !important;
    width: 100%;
    z-index: 3;
    top: 54px !important;
    padding: 10px 15px;
  }

  .mobile.global-result {
    display: flex;
    position: sticky;
    top: 76px;
    z-index: 10;
    align-items: center;
    gap: 10px;
  }

  .global-menu-btn button {
    border: none;
    background-color: #ffffff;
    outline: none;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
  }

  .global-result-inner {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .global-result-inner span {
    font-size: 14px;
  }

  .mobile.global-result p {
    font-size: 14px;
    line-height: 20px;
  }

  .all-content-main-left.cstm-all-content-main-left.glob-hidden {
    position: fixed;
    left: -100%;
    top: 76px;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 20;
    max-height: calc(100vh - 80px);
  }

  .all-content-main-left.cstm-all-content-main-left.glob-hidden.active {
    left: 0%;
  }

  .global-das {
    display: none !important;
  }

  .global-left-side .global-result {
    display: flex;
    justify-content: space-between;
  }

  .global-left-side .global-result button {
    padding: 0 !important;
    border: none;
    background-color: #ffffff;
    outline: none;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 0px rgb(0 0 0 / 10%);
  }

  .glob-hidden .all-content-main-left-sidebar .card-header button {
    padding: 10px;
  }

  .glob-hidden .all-content-main-left-sidebar .card-header button img {
    margin-right: 6px;
  }

  .global-result-buttom {
    display: block;
  }

  .global-search-active {
    height: 100vh;
    overflow: hidden;
  }

  .video-details-tabing .cstm-vd-tabs .nav {
    flex-wrap: nowrap;
    width: 100%;
    overflow: scroll;
    white-space: nowrap;
    flex-direction: row;
    width: 100%;
  }

  .cstm-vd-tabs {
    overflow: hidden;
  }

  .video-details-tabing .cstm-vd-tabs .nav li a {
    display: block;
  }
}

.cstm-rpp-main .desk-globle-model-box-inner {
  max-width: fit-content;
  width: 100%;
  max-height: 100%;
}

.cropImgDis {
  min-width: 300px;
  max-width: 600px;
  height: 100%;
}

.mds-purchase-details {
  background: #ffffff;
}

.mds-purchase-details .cstm-per-inf-title {
  padding: 0 0px 16px;
  margin-bottom: 0;
}

.mds-purchase-details .cstm-to-delete-request {
  padding: 16px 16px;
  max-width: 660px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
}

.mds-purchase-details .cstm-sub-title {
  font-weight: 600;
  font-size: 16px;
}

.mds-purchase-cancel {
  display: block;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  color: #351bce !important;
}

.mds-plan-details-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.mds-plan-details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #111928;
}

.mds-plan-price h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  color: #111928;
  margin-bottom: 0;
}

.mds-cancel-membership-model {
  max-width: 360px !important;
  padding: 24px 20px 14px 20px;
  position: relative;
}

.mds-cancel-membership-model h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
  margin-bottom: 12px;
  text-align: center;
}

.mds-cancel-membership-model p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201e29;
  margin-bottom: 16px;
}

.mds-model-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mds-model-button button {
  padding: 10px 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  border: transparent;
  background: #fff !important;
  transition: 0.5s all;
}

.mds-model-button button.button-do-not-cancel {
  color: #201e29;
}

.mds-model-button button.button-do-not-cancel:hover {
  color: #351bce;
}

.mds-model-button button.model-button-cancel {
  color: #351bce;
}

.mds-model-button button.model-button-cancel:hover {
  color: #240ea7;
}
