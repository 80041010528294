.messagebar-section {
  padding: var(--spacing-2) 0;
  gap: var(--spacing-24);
}

.chat-emojies-sec {
  position: absolute;
  bottom: calc(100% + 31px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.send-btn .button.ghost svg path {
  fill: var(--color-icon-brand-strong);
}

/* Emojies picker design As per the Figma*/
.EmojiPickerReact {
  background-color: var(--color-bg-elevated);
}

.EmojiPickerReact .epr-header .epr-header-overlay {
  padding: var(--spacing-24) var(--spacing-16) var(--spacing-8);
}

.EmojiPickerReact .epr-category-nav {
  padding: var(--spacing-4) var(--spacing-16) var(--spacing-8);
  border-bottom: 1px solid var(--color-border-neutral-tertiary);
}

.EmojiPickerReact .epr-category-nav>button.epr-cat-btn {
  filter: brightness(0.3);
}

.EmojiPickerReact:not(.epr-search-active) .epr-category-nav>button.epr-cat-btn.epr-active,
.EmojiPickerReact:not(.epr-search-active) .epr-category-nav>button.epr-cat-btn:hover {
  background-color: var(--color-bg-neutral-tertiary);
}

.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
  color: var(--color-text-neutral-strong);
}

.EmojiPickerReact ul.epr-emoji-list li {
  padding: var(--spacing-12) 0;
}

.EmojiPickerReact .epr-search-container input.epr-search {
  border-radius: var(--radius-full);
  border: 1px solid var(--color-border-neutral-strong);
  display: flex;
  padding: var(--spacing-8) var(--spacing-32) var(--spacing-8) var(--spacing-40);
  align-items: center;
  gap: var(--spacing-12);
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  color: var(--color-text-neutral-strong);
  text-overflow: ellipsis;
  font: var(--body-medium);
  box-sizing: border-box;
  background-color: var(--bg-screen-strong);
}

.EmojiPickerReact .epr-search-container input.epr-search:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}

.EmojiPickerReact .epr-search-container input.epr-search::placeholder {
  color: var(--color-text-neutral-tertiary, #84898F) !important;
}

.EmojiPickerReact .epr-search-container input.epr-search::-ms-input-placeholder {
  color: var(--color-text-neutral-tertiary, #84898F) !important;
}

.EmojiPickerReact .epr-search-container input.epr-search:active {
  border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}

.EmojiPickerReact .epr-search-container input.epr-search:focus {
  border: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
  outline: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
  background-color: var(--bg-screen-strong);
}