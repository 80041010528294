.chat-badge-mention {
    display: flex;
    width: 20px;
    height: 20px;
    padding: var(--spacing-2, 2px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: var(--color-bg-info-strong, #0D99FF);
    align-content: center;
}