.progress-bar{
    display: flex;
    max-width: 400px;
    width: 100%;
    padding-bottom: var(--spacing-2, 2px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-8, 8px);
}
.progress-bar-info {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
    justify-content: space-between;
    color: var(--color-text-neutral-strong, #060D14);   
    font: var(--body-x-small);
}
.progress-bar-track {
    background: var(--color-bg-neutral-secondary, #EAEDF1);
    height: 4px;
    width: 100%;
    border-radius: var(--radius-x-small, 4px); 
    position: relative;
}
.progress-bar-track-color {
    position: absolute;
    height: 4px;
    border-radius: var(--radius-x-small, 4px);
    background: var(--color-bg-inverse-neutral, #041527);
    left: 0;
}

.progress-bar-track-color.blue{
    background: var(--color-bg-brand-strong, #0B42CF);
}
.progress-bar-track-color.white2{
    background: var(--color-bg-screen, #FFF);
}
.progress-bar-track.grey {
    background: #ffffff4d;
}
.progress-bar.white2 .progress-bar-track {
    background: #ffffff4d;
}
.progress-bar.white2 .progress-bar-info {
    color: var(--color-bg-screen, #FFF)
}

.progress-bar-info-filename {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
