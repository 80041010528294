.text-field-main {
    display: flex;
    max-width: 269px;
    width: 100%;
    flex-direction: column;
    gap: var(--spacing-8, 8px);
}

.text-field {
    border-radius: var(--radius-small, 8px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    display: flex;
    padding: var(--spacing-8, 8px) var(--spacing-12, 12px) var(--spacing-8, 8px) var(--spacing-16, 16px);
    padding-right: 52px;
    align-items: center;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font: var(--body-medium);
    box-sizing: border-box;
    color: var(--color-text-neutral-strong);
}

.text-field::placeholder {
    color: var(--color-text-neutral-tertiary, #84898F) !important;
    opacity: 1;
}

.text-field::-ms-input-placeholder {
    color: var(--color-text-neutral-tertiary, #84898F) !important;
}

.text-field:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
}

.text-field:active {
    border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}

.text-field:focus {
    border: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
    outline: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
}

.text-field-box {
    position: relative;
}

.text-field-box svg,
.text-field-box img {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
}

.helper-text-field {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
}

.text-field-error {
    color: var(--color-text-danger-strong, #DB1D26);
    font: var(--body-x-small);
}

.text-field.radius {
    border-radius: var(--radius-full, 120px) !important;
}

.text-field-error-border {
    border: 1px solid var(--color-border-danger-strong, #DB1D26) !important;
}

.text-field-search {
    border-radius: var(--radius-small, 8px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    display: flex;
    padding: var(--spacing-8, 8px) var(--spacing-48, 48px) var(--spacing-8, 8px) var(--spacing-48, 48px);
    align-items: center;
    gap: var(--spacing-12, 12px);
    align-self: stretch;
    width: 100%;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    font: var(--body-medium);
    box-sizing: border-box;
    background-color: var(--bg-screen-strong);
}

.text-field-search::placeholder {
    color: var(--color-text-neutral-tertiary, #84898F);
    opacity: 1;
}

.text-field-search::-ms-input-placeholder {
    color: var(--color-text-neutral-tertiary, #84898F);
}

.text-field-search:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
}

.text-field-search:active {
    border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}
.text-field-search-main.tonal-search-field .text-field-search:focus-visible:focus,
.text-field-search-main.tonal-search-field .text-field-search:focus-visible,
.text-field-search:focus {
    border: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
    outline: 1px solid var(--color-border-inverse-neutral, #0D0D0D);
}

.text-field-search-main {
    position: relative;
    max-width: 400px;
    width: 100%;
}

.text-field-search-main.tonal-search-field .text-field-search {
    background-color: var(--color-bg-neutral-secondary);
    border-color: var(--color-bg-neutral-secondary);
}
.text-field-search-main.tonal-search-field .text-field-search:hover {
    background-color: var(--color-bg-neutral-secondary-hovered);
    border-color: var(--color-bg-neutral-secondary-hovered);
}
.text-field-search-main.tonal-search-field .text-field-search:focus-visible:focus,
.text-field-search-main.tonal-search-field .text-field-search:focus-visible {
    background-color: transparent;
    /* border-color: transparent; */
}

.text-field-search-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
}

.text-field-cancel-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    cursor: pointer;
}

.text-field-search.radius {
    border-radius: var(--radius-full, 120px) !important;
}