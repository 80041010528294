:root {
  --blue: #091f48;
  --navyblue: #351bce;
  --black: #201e29;
  --black2: #000;
  --yellow: #ffcc00;
  --aqua: #82cdb8;
  --grey: #f3f5f7;
  --grey2: #e8eaed;
  --btn3: #adb5c3;
  --text: #5f5f67;
  --text2: #bcbbbf;
  --text3: #b8d5f1;
  --white: #fff;
  --placeholder: #5c5b64;
  --red: #e11111;
  --line: #e5e5e5;
}

* {
  letter-spacing: -0.1px;
}
body {
  font: var(--body-small);
}

a {
  text-decoration: inherit;
  /* color: var(--blue); */
}

a:hover {
  text-decoration: inherit;
  /* color: var(--color-bg-brand-strong); */
}

img {
  max-width: 100%;
  height: auto;
}

video,
img {
  vertical-align: middle;
}

input:focus,
secect:focus,
textarea:focus {
  outline: none;
}

/* Start Skeleton  */

.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #ececec;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* End Skeleton  */

.cstm-profile-back a {
  display: flex;
  padding: 12px 4px;
  align-items: center;
  gap: var(--spacing-8, 8px);
}
.cstm-profile-back-label {
  color: var(--color-text-neutral-strong, #060d14);
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
hr.cstm-profile-back-line {
  width: 88% !important;
  margin: 0px auto 12px !important;
}

.request-new-seat-btn {
  display: flex;
  padding: var(--spacing-space-small, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3x-small, 6px);
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  background-color: #fff;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;

  /* controls/controls-large-emphasized */
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.request-new-seat-btn:hover {
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5));
}
.request-new-seat-btn:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7));
}

.invite-btn {
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  border-radius: var(--radius-full, 999px);
  background: var(--color-bg-brand-strong, #0b42cf);
  border: none;
  color: var(--color-text-brand-onstrong, #fff);
  text-align: center;

  /* controls/controls-large-emphasized */
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.invite-btn:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}
.invite-btn:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
}

.co-users-main-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-24, 24px);
  align-self: stretch;
  justify-content: space-between;
}

.co-users-main-row-right {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-24, 24px);
  align-self: stretch;
}

.cstm-collaborator-request-msg-container {
  display: flex;
  width: 100%;
  padding: var(--spacing-16, 16px) var(--spacing-24, 24px);
  align-items: center;
  gap: var(--spacing-24, 24px);
  border-radius: var(--radius-12, 12px);
  background: var(--color-bg-warning-secondary, #FFE0C2);
  margin-bottom: 24px;
}
span.cstm-collaborator-request-msg-text h5 {
  font-weight: 500;
}

span.cstm-collaborator-request-msg-icon {
  width: 4%;
}

span.cstm-collaborator-request-msg-text {
  width: 80%;
}

span.cstm-collaborator-request-msg-btn a {
  display: block;
  border: 1px solid #d2c9a0;
  padding: 15px;
  border-radius: 35px;
  color: black;
  font-weight: 600;
}
.cstm-collaborator-request-msg-icon {
  display: flex;
  width: 56px;
  min-width:56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}
.cstm-collaborator-request-msg-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4, 4px);
  flex: 1 0 0;
}
.cstm-collaborator-request-msg-btn a {
  display: flex;
  padding: var(--spacing-space-small, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3x-small, 6px);
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
  color: var(--color-text-neutral-strong, #060D14);
  /* font:var(--body-large-emphasized); */
  font-family: Figtree;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.cstm-collaborator-request-msg-btn a:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
}
.cstm-collaborator-request-msg-btn a:active {
  border: 1px solid var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}
.cstm-collaborator-request-msg-text h5 {
  color: var(--color-text-neutral-secondary, #394046);
  font:var(--body-small);
  height: 20px;
  align-self: stretch;
}
.no-of-teammates {
  gap: 6px;
  display: flex;
  align-items: center;
  margin-top: 6px;
}

iframe.ifrane-wordpress {
  width: 100%;
  height: 85vh;
}
.desk-globle-model-box {
  align-items: center;
  background: #00000080;
  display: flex;
  height: 100%;
  inset: 0;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

button:focus {
  outline: inherit;
}

.all-content-main-section {
  padding-top: 64px;
}

.all-content-main-section-in {
  padding-left: 248px;
}

input:focus,
secect:focus,
textarea:focus {
  outline: none;
}

.error {
  color: var(--red);
  display: block;
  position: absolute;
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  height: 24px;
  width: 24px;
  accent-color: var(--navyblue);
  margin-right: 8px;
  cursor: pointer;
}

.cstm-tbl-arrow {
  margin-left: 12px;
  vertical-align: -2px;
  cursor: pointer;
}

.cstm-tbl-arrow2 {
  margin-left: 12px;
  vertical-align: -1px;
  cursor: pointer;
}

input.cstm-cl-search-input {
  padding: 10px 50px 10px 50px !important;
  width: 700px !important;
  height: 44px !important;
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 50px !important;
}

input.cstm-cl-search-input:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered) !important;
}

input.cstm-cl-search-input:focus {
  border: 2px solid var(--color-border-inverse-neutral) !important;
}

.anything-else-you-textarea {
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  background-color: var(--color-bg-static-white);
  padding: 12px 16px;
  height: 144px;
  font: var(--body-medium);
  color: var(--color-text-neutral-strong, #060d14);
}

.anything-else-you-textarea::-webkit-input-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
}

.anything-else-you-textarea:-moz-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
  opacity: 1;
}

.anything-else-you-textarea::-moz-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
  opacity: 1;
}

.anything-else-you-textarea:-ms-input-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
}

.anything-else-you-textarea::-ms-input-placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
}

.anything-else-you-textarea::placeholder {
  color: var(--color-text-neutral-tertiary, #84898f);
}

.anything-else-you-textarea:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
}

.anything-else-you-textarea:focus {
  border: 2px solid var(--color-border-neutral-strong-pressed);
}

.characters-count {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-x-small);
  display: block;
}

.access-expires-soon {
  max-width: 648px;
  width: 90%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-space-4-x-large, 48px)
    var(--spacing-space-x-large, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-space-x-large, 24px);
  align-self: stretch;
}

.access-expires-soon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.access-expires-soon-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.access-expires-soon-icon {
  height: 120px;
  width: 100%;
  text-align: center;
}

.access-expires-soon-title {
  margin-bottom: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-medium);
  letter-spacing: -0.24px;
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
}

.access-expires-soon-p {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-medium);
  max-width: 480px;
  margin: 0 auto;
  width: 100%;
}

.access-expired-page {
  max-width: 648px;
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-space-x-large, 24px);
  padding: var(--spacing-space-4-x-large, 48px) 0px;
  justify-content: center;
  height: calc(100vh - 72px);
}

.access-expires-page-icon {
  height: 120px;
  width: 100%;
  text-align: center;
}

.access-expires-page-title {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
  margin: 0px;
}

.access-expires-page-p {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-small);
  margin: 0px;
}

.access-expires-page-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  max-width: 480px;
  margin: 0px auto;
  width: 100%;
}

hr.cstm-sidebar-sep {
  margin: 12px;
  background: var(--color-border-neutral-tertiary);
  height: 1px;
  border: 0px;
}

h3.cstm-second-lbl {
  color: var(--color-text-neutral-strong);
  font: var(--title-x-small);
  margin: 4px 12px 8px;
}

.cstm-all-content-library-main-only .all-content-main-left {
  width: 248px;
  position: fixed !important;
  padding: 12px;
  max-height: inherit !important;
  border-radius: inherit !important;
  overflow: auto !important;
  top: inherit !important;
  height: calc(100vh - 73px);
  left: inherit !important;
  z-index: 1;
}

.all-content-main-left-sidebar {
  width: 100%;
}

.all-content-main-left.cstm-all-content-main-left,
.all-content-main-right.cstm-all-content-main-right {
  position: sticky;
  top: 116px;
  max-height: calc(100vh - 126px);
  border-radius: 5px;
  overflow: auto;
}

.all-content-main-left.cstm-all-content-main-left {
  grid-area: leftside;
}

.all-content-main-right.cstm-all-content-main-right {
  grid-area: rightside;
}

.um-cp-check .form-check-input {
  top: 19px;
  right: 15px;
  margin: 0px;
  height: 18px;
  width: 18px !important;
  padding: 0px !important;
}

.all-content-main-left-sidebar .card-header button {
  width: 100%;
  border: none;
  padding: 16px;
  text-align: left;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48 !important;
  text-decoration: initial;
  background-color: #fff;
}

.all-content-main-left-sidebar .card-header button:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.cstm-tlp {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.all-content-main-left-sidebar .card-header button i {
  float: right;
  font-size: 23px;
}

img.sidebar-arrow-link {
  margin-left: 4px;
}

span.cstm-name-menu {
  white-space: nowrap;
}

body
  .cstm-all-content-library-main-only
  .card-header.active-content-menus
  button
  img.cstn-cl-t {
  display: block;
}

body
  .cstm-all-content-library-main-only
  .card-header.active-content-menus
  button
  img.cstn-cl-f {
  display: none;
}

.cstm-all-content-library-main-only .card-header {
  border: 0px !important;
}

.um-cp-check .form-check-label {
  width: 100%;
  background: var(--white);
  border-radius: 15px;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: var(--black);
  cursor: pointer;
  line-height: 32px;
  border: 1px solid var(--white);
}

.um-cp-check .form-check-label:hover {
  border: 1px solid var(--black);
}

.um-cp-check .form-check-label img {
  margin-right: 8px;
}

.form-check.um-cp-check {
  padding: 0px;
  margin-bottom: 20px;
}

.all-content-main.cstm-all-content-main.edit-profile .form-check.um-cp-check {
  border: 1px solid#d9d9d9;
  margin-bottom: 0px;
  border-radius: 15px;
}

.um-comm-prefe label.form-check-label {
  padding-right: 40px;
  line-height: 21px;
  height: 56px;
}

.cstm-create-speaker-cropper {
  z-index: 9999 !important;
}

.not-found-image2 {
  margin-bottom: 34px;
  height: 251px;
  width: 100%;
  text-align: center;
}

.not-found-page {
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sorry-but-cont {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #5c5b63;
  max-width: 450px;
  width: 100%;
  margin-bottom: 32px;
}

.not-found-image {
  margin-bottom: -74px;
}

.not-access-image {
  margin-bottom: 44px;
}

.not-found-page h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
  color: #201e29;
  margin-bottom: 16px;
  background: #fff;
  padding: 0px 4px;
}

.desk-globle-model-box {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
}

.modal-fadein {
  opacity: 1;
  position: fixed;
  transition: all 0.3s ease-in-out;
  visibility: visible;
  z-index: 999;
}

.modal-fadeout {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  z-index: 999;
}

.desk-globle-model-box-inner {
  max-width: 1280px;
  width: 90%;
  margin: auto;
  max-height: calc(100vh - 30px);
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.desk-globle-model-box-inner::-webkit-scrollbar {
  width: 5px;
}

.desk-globle-model-box-inner::-webkit-scrollbar-track {
  background: var(--btn3);
}

.desk-globle-model-box-inner::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}

.desk-globle-model-box .globle-back-btn {
  margin-left: auto;
  margin-top: 16px;
  margin-right: 16px;
  border: none;
  background: transparent;
  width: 40px;
  height: 40px;
}

.desk-globle-model-box .um-membership-details {
  justify-content: center;
  align-items: center;
}

.desk-globle-model-box .um-member-step.um-meme-det-only {
  flex-grow: 1;
  align-items: start;
  margin: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0px 110px;
}

.globle-hsk {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #201e29;
  width: 100%;
}

.desk-globle-model-box .um-membership-details-in {
  border: 1px solid #e9eaec;
  transition: all 0.5s ease;
}

.all-content-main-center.cstm-all-content-main-center {
  grid-area: newsfeed;
}

.desk-globle-model-box .um-membership-details-in:hover {
  border: 1px solid var(--black);
  background-color: #f3f3f3;
}

.desk-globle-model-box .um-membership-details-in.selectedplan {
  background-color: #e8f1fb;
}

.desk-globle-model-box .um-membership-details-in.selectedplan span.um-md-price {
  font-weight: 700;
  color: #201e29;
}

.desk-globle-model-box .um-membership-details-in-price {
  width: 100%;
}

.stm-menu-pp-top-logo img {
  height: 35px;
  vertical-align: text-top;
}

.sidebar-menu-popup {
  background: #fff;
  height: 100vh;
  width: 248px;
}

.sidebar-menu-popup-main.hide-menu .sidebar-menu-popup {
  transform: translateX(-100%);
  transition: all 0.2s ease;
}

.sidebar-menu-popup-main.show-menu .sidebar-menu-popup {
  transition: all 0.2s ease;
  transform: translateX(0%);
}

.custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.cstm-menu-pp-top {
  display: flex;
  height: 72px;
  align-items: center;
  padding-left: 14px;
}

.cstm-menu-pp-menu ul li button {
  padding: 12px 16px;
  display: flex;
  color: #201e29;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  width: 100%;
  border: none;
  background: transparent;
}

.cstm-menu-pp-menu ul li button:hover {
  background: #f3f5f7;
}

.stm-menu-pp-top-view {
  margin-right: 16px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
}

.stm-menu-pp-top-view:hover {
  background: #f3f5f7;
}

img.cstn-pp-t {
  display: none;
}

.all-content-main-edit-profile {
  width: 100%;
  transition: all 0.5s;
  background: #fff;
  padding-left: 240px;
}

.all-content-main-edit-profile-main {
  padding: 8px 20px;
  width: 100%;
  position: relative;
}

.cstm-per-inf-title {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #201e29;
}

.cstm-edit-profile-only-mn {
  max-width: 640px;
  width: 100%;
}
.cstm-edit-profile-only-mn .link-text {
  text-underline-offset: 2px;
  color: #351bce;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
}
.cstm-edit-profile-only-mn .link-text:hover {
  color: #060D14;
  text-decoration: unset;
}
.cstm-edit-profile-only-mn .link-text svg {
  margin-left: 5px;
}
.cstm-edit-profile-only-mn .link-text svg path {
  fill: #351bce;
}
.cstm-edit-profile-only-mn .link-text:hover svg path {
  fill: #060d14;
}

.form-group {
  margin-bottom: 24px;
  position: relative;
}

.all-content-main-edit-profile-upload {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
}

.all-content-main-edit-profile-main .cstm-profile-upload-img {
  position: relative;
}

.cstm-ld-user-img {
  height: 72px;
  width: 72px;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #ffffff !important;
  filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.13));
  border-radius: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a9a9a9;
}

.cstm-profile-upload-btn {
  display: flex;
  flex-direction: column;
}

.cstm-profile-upload-btn .edit-pro-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  cursor: pointer;
}

.cstm-profile-upload-cont {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.um-right-in-label {
  margin-bottom: 8px;
  color: #414049;
}

.btn {
  background: var(--navyblue);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 54px;
  width: 100%;
  border: none;
  text-transform: inherit;
  margin: 0;
}

.btn:hover {
  background: var(--yellow);
}

.btn2 {
  background: var(--navyblue);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 48px;
  padding: 12px 32px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

.btn2:hover {
  background: var(--yellow);
}

.btn3 {
  background: var(--btn3);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--white) !important;
  height: 48px;
  padding: 12px 32px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

.btn3:hover {
  background: var(--yellow);
}

hr.um-line {
  margin-top: 0;
  margin-bottom: 30px;
  border-top: 1px solid var(--line);
  width: 100%;
}

.btn6 {
  background: var(--navyblue);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--white) !important;
  height: 40px;
  padding: 10px 20px;
  border: none;
  display: inline-block;
  cursor: pointer;
  text-transform: inherit;
}

.btn6:hover {
  background: var(--yellow);
}

hr {
  opacity: 0.2;
  margin-top: 8px;
}

.um-right-in-box {
  position: relative;
}

.cstm-sm-form .error {
  margin: 0px;
  font-size: 12px;
}

.cstm-sm-form .um-right-in-box input {
  border-radius: var(--radius-8, 8px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px);
  align-items: center;
  gap: var(--spacing-12, 12px);
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font: var(--body-medium);
  box-sizing: border-box;
  background-color: var(--bg-screen-strong);
}

.cstm-sm-form input:focus {
  box-shadow: 0 0 0 1px #351bce;
  border: 1px solid #351bce !important;
}

.cstm-sub-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 4px;
}

.cstm-click-here-link {
  color: #201e29;
}

.cstm-click-here-link:hover {
  color: #351bce;
}

.cstm-pi-main-btn-ldr {
  position: relative;
}

.cstm-contant-page p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  color: #34333c;
}

.cstm-contant-page h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #091f48;
  margin-bottom: 12px;
}

.cstm-contant-page h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #201e29;
  margin-bottom: 12px;
}

.cstm-ac-se-nav ul li a.active-ac {
  color: #fff !important;
  background: #351bce !important;
}

.cstm-ac-se-nav ul li a svg {
  height: 18px;
  margin-right: 10px;
}

.cstm-ac-se-nav ul li a img {
  margin-right: 17px;
}

.cstm-ac-se-nav ul {
  padding: 0px;
  list-style: none;
}
.cstm-ac-se-nav ul li {
  position: relative;
}

.cstm-ac-se-nav ul li a {
  padding: 12px 16px;
  display: flex;
  color: #201e29 !important;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
}

.cstm-ac-se-nav ul li a:hover {
  background: #f3f5f7;
}
.nav-beta-badge {
  position: absolute;
  right: 10px;
  top: 10.3px;
  background: #fff;
  padding: 1px 8px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #351bce;
  color: #201e29;
}

.cstm-ac-se-nav a.active-ac img {
  filter: invert(0.1) sepia(1) saturate(100) hue-rotate(222deg);
}

.cstm-ac-se-nav ul li a:hover img {
  filter: invert(0.1) sepia(1) saturate(100) hue-rotate(222deg);
}

.cstm-deactivate-account-only {
  max-width: 360px !important;
  padding: 24px 20px 14px 20px;
  position: relative;
}

.cstm-deactivate-account-main-btn {
  margin-top: 24px;
}

.cstm-deactivate-account-main {
  text-align: center;
}

.cstm-deactivate-account-main-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
  margin-bottom: 12px;
}

.cstm-deactivate-account-main-content {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201e29;
  margin-bottom: 16px;
}

.ays-cancel-btn button {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #201e29;
  padding: 10px 18px;
  border: none;
  background: transparent;
}

.ays-cancel-btn button:hover {
  color: #351bce;
}

.cstm-pi-main-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 32px;
  align-items: center;
}

.cstm-textarea-pf {
  height: 140px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background: #ffffff !important;
}

select:focus {
  outline: none;
}

textarea {
  background: var(--grey2);
  border: none;
  font-style: normal;
  font-weight: 500 !important;
  color: #201e29;
  font-size: 14px;
  line-height: 20px;
  /* height: 120px; */
  padding: 10px 16px;
  width: 100%;
  border-radius: 15px;
  vertical-align: middle;
}

.all-content-main {
  display: flex;
  justify-content: space-between;
  padding: 116px 16px 0px 16px;
}

.all-content-main.cstm-all-content-main {
  max-width: 1440px;
  margin: auto;
  column-gap: 1.4rem;
  display: grid;
  grid-area: content;
  grid-template-rows: auto;
  row-gap: 1.4rem;
  grid-template-areas: "leftside newsfeed rightside";
  grid-template-columns: minmax(0, 6fr) minmax(684px, 684px) minmax(0px, 6fr);
}

.all-content-main.cstm-all-content-main.cstm-all-library-content-main {
  column-gap: 1.25rem;
  grid-template-areas: "leftside content-video";
  grid-template-columns: minmax(0, 4fr) minmax(0, 12fr);
}

.all-content-main.cstm-all-content-main.cstm-all-library-content-main
  .all-content-main-center.all-content-main-center {
  grid-area: content-video;
  width: 100%;
}

.cstm-content-archive-main {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.all-content-main-left-sidebar .card-header {
  padding: 0px;
  margin-bottom: 0 !important;
  border-bottom: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.cstm-all-content-library-main-only img.cstn-cl-t {
  display: block;
  filter: inherit;
}

.cstm-all-content-library-main-only img.cstn-cl-t {
  display: none;
}

.all-content-main-left-sidebar .card {
  overflow: visible;
}

.all-content-main-left-sidebar .card {
  border-width: 0px;
  box-shadow: none;
}

.cstm-all-content-library-main-only .owl-nav span {
  font-size: 0px;
}

.cstm-all-content-library-main-only .owl-nav button.owl-prev {
  background-image: url(../Images/ArrowCL1.svg) !important;
  background-repeat: no-repeat !important;
  height: 24px;
  width: 24px;
}

.cstm-all-content-library-main-only .owl-nav button.owl-next {
  background-image: url(../Images/ArrowCL2.svg) !important;
  background-repeat: no-repeat !important;
  height: 24px;
  width: 24px;
  float: right;
}

.cstm-all-content-library-main-only .owl-nav {
  position: absolute;
  top: 4px;
  width: 100%;
}

.cstm-all-content-library-main-only .card-header {
  background: transparent !important;
}

.cstm-all-content-library-main-only .card-header button.btn-link {
  color: var(--color-text-neutral-strong, #060d14);
  padding: 8px 12px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  position: relative;
  font: var(--body-medium);
}

body
  .cstm-all-content-library-main-only
  .card-header.active-content-menus
  button {
  background: var(--color-bg-neutral-secondary) !important;
  color: var(--color-text-neutral-strong) !important;
  font-weight: 700;
}

.cstm-all-content-library-main-only .owl-stage-outer:before {
  height: 32px;
  width: 40px;
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  background-image: linear-gradient(to right, white, #ffffff00);
  z-index: 1;
  display: none;
}

.all-content-main-left {
  height: calc(100vh - 96px);
  background: #fff;
  position: fixed;
  left: 16px;
}

.sidebar-icon {
  display: none;
  position: fixed;
  left: 21.8%;
  background: #201e29;
  color: #fff;
  padding: 0px 10px 3px 8px;
  font-size: 30px;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all 0.5s;
}

.sidebar-hd {
  transition: all 0.7s;
  left: -21% !important;
}

.sidebar-icon2 {
  transition: all 0.7s;
  left: 0% !important;
  padding: 3px 8px 0px 10px;
}

.sidebar-icon2 i {
  transform: rotate(180deg);
}

.sidebar2-icon2 {
  transition: all 0.7s;
  right: 0% !important;
  padding: 3px 8px 0px 10px !important;
}

.sidebar2-icon {
  position: fixed;
  right: 21.8%;
  background: #201e29;
  color: #fff;
  padding: 0px 8px 3px 10px;
  font-size: 30px;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s;
}

.all-content-main-left::-webkit-scrollbar,
.cstm-tag-friend-list::-webkit-scrollbar {
  width: 6px;
}

.all-content-main-left::-webkit-scrollbar-track,
.cstm-tag-friend-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e8e8e800;
}

.all-content-main-left::-webkit-scrollbar-thumb,
.cstm-tag-friend-list::-webkit-scrollbar-thumb {
  background: #e8e8e800;
  border-radius: 10px;
}

.all-content-main-left:hover::-webkit-scrollbar-thumb,
.cstm-tag-friend-list:hover::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 10px;
}

.mn-cnt-wd .all-content-main-center {
  margin-left: 2%;
  width: 74%;
}

.mn-cnt3-wd .all-content-main-center {
  margin-right: 2%;
  width: 74%;
}

.mn-cnt-both .all-content-main-center {
  width: 96%;
  margin: auto;
}

.mn-cnt3-wd .all-content-main-center {
  margin-bottom: 10px;
}

.cstm-profile-sd {
  width: 224px;
  position: fixed;
  margin: 0px 8px;
}

.cstm-profile-ct {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  min-height: 800px;
}

.edit-profile {
  grid-template-columns: inherit !important;
  column-gap: inherit !important;
  display: flex !important;
  width: 100%;
  background: #fff;
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 72px !important;
}

.cstm-clear-watch-history-link a {
  position: relative;
}

.cstm-cwh-cancel-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #201e29 !important;
  padding: 10px 18px;
  margin-right: 16px;
  display: inline-block;
  background: transparent;
  border: none;
}

.cstm-cwh-cancel-btn:hover {
  color: #351bce !important;
}

.cstm-cwh-clear-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #351bce !important;
  padding: 10px 18px;
  display: inline-block;
  background: transparent;
  border: none;
}

.cstm-cwh-clear-btn:hover {
  color: #201e29 !important;
}

.cstm-clear-watch-history-link {
  text-align: center;
}

.cstm-clear-watch-history-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 20px;
  text-align: center;
}

.cstm-clear-watch-history-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
  margin-bottom: 12px;
  text-align: center;
}

.cstm-clear-watch-history-mn {
  padding: 24px 20px 14px 20px;
}

.cstm-clear-watch-history {
  max-width: 360px !important;
  width: 100% !important;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px !important;
}

.wf-1 {
  width: 1%;
}

.wf-2 {
  width: 2%;
}

.wf-3 {
  width: 3%;
}

.wf-4 {
  width: 4%;
}

.wf-5 {
  width: 5%;
}

.wf-6 {
  width: 6%;
}

.wf-7 {
  width: 7%;
}

.wf-8 {
  width: 8%;
}

.wf-9 {
  width: 9%;
}

.wf-10 {
  width: 10%;
}

.wf-11 {
  width: 11%;
}

.wf-12 {
  width: 12%;
}

.wf-13 {
  width: 13%;
}

.wf-14 {
  width: 14%;
}

.wf-15 {
  width: 15%;
}

.wf-16 {
  width: 16%;
}

.wf-17 {
  width: 17%;
}

.wf-18 {
  width: 18%;
}

.wf-19 {
  width: 19%;
}

.wf-20 {
  width: 20%;
}

.wf-21 {
  width: 21%;
}

.wf-22 {
  width: 22%;
}

.wf-23 {
  width: 23%;
}

.wf-24 {
  width: 24%;
}

.wf-25 {
  width: 25%;
}

.wf-26 {
  width: 26%;
}

.wf-27 {
  width: 27%;
}

.wf-28 {
  width: 28%;
}

.wf-29 {
  width: 29%;
}

.wf-30 {
  width: 30%;
}

.wf-31 {
  width: 31%;
}

.wf-32 {
  width: 32%;
}

.wf-32 {
  width: 32%;
}

.wf-33 {
  width: 33%;
}

.wf-34 {
  width: 34%;
}

.wf-35 {
  width: 35%;
}

.wf-36 {
  width: 36%;
}

.wf-38 {
  width: 38%;
}

.wf-39 {
  width: 39%;
}

.wf-40 {
  width: 40%;
}

.wf-41 {
  width: 41%;
}

.wf-42 {
  width: 42%;
}

.wf-43 {
  width: 43%;
}

.wf-44 {
  width: 44%;
}

.wf-45 {
  width: 45%;
}

.wf-46 {
  width: 46%;
}

.wf-47 {
  width: 47%;
}

.wf-48 {
  width: 48%;
}

.wf-49 {
  width: 49%;
}

.wf-50 {
  width: 50%;
}

.wf-60 {
  width: 60%;
}

.wf-70 {
  width: 70%;
}

.wf-80 {
  width: 80%;
}

.wf-90 {
  width: 90%;
}

.wf-100 {
  width: 100%;
}

.mds-partners-head-right-sort {
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 6px;
}

.mds-partners-head-right-sort h6 {
  margin: 0px;
  font: var(--body-medium);
  color: var(--color-text-neutral-strong);
}

.cstm-drop-label {
  overflow: hidden;
  color: var(--color-text-neutral-strong);
  text-overflow: ellipsis;
  font: var(--body-medium-bold);
  padding-right: 26px;
  cursor: pointer;
  position: relative;
}

span.cstm-drop-label:after {
  background-image: url(../Images/icn_m_chevron_down.svg);
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-size: cover;
  top: 0px;
  margin-left: 6px;
}

.cstm-drop-up-arrow::after {
  rotate: 180deg;
}

ul.cstm-drop-list::-webkit-scrollbar {
  width: 6px;
}

ul.cstm-drop-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary-hovered);
}

ul.cstm-drop-list::-webkit-scrollbar-thumb {
  background: var(--color-border-neutral-secondary);
  border-radius: 10px;
}

ul.cstm-drop-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  margin: 0px;
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-small);
  padding: 8px 0px;
  min-width: 160px;
  margin-top: 4px;
  max-height: 350px;
  overflow: auto;
}

ul.cstm-drop-list li {
  padding: 8px 16px;
  overflow: hidden;
  color: var(--color-text-neutral-strong);
  text-overflow: ellipsis;
  font-family: "Figtree", sans-serif !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

ul.cstm-drop-list li:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

ul.cstm-drop-list li:focus {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.cstm-drop-list-selected {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.cstm-drop-label img {
  margin-left: 6px;
}

.cstm-drop-up-arrow img {
  rotate: 180deg;
}

.mds-partners-head-right-sort ul.cstm-drop-list {
  right: 0px;
}

.cstm-content-user-main {
  padding: var(--spacing-24, 24px) var(--spacing-32, 32px);
  width: 100%;
  max-width: 1536px;
  margin: auto;
}

.cstm-user-title {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-large);
  letter-spacing: -0.36px;
  margin: 0px 0px 24px 0px;
}

.cstm-user-header-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.cstm-user-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.btn8:disabled {
  background: #dddddd;
  color: #5b5858;
}

.btn8 {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong, #0b42cf);
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  color: var(--color-text-brand-onstrong, #fff);
  text-align: center;
  font: var(--body-medium-bold);
  border: none;
}

.btn8:hover {
  background: var(--color-bg-brand-strong-hovered, #0735ab);
}

.btn8:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
}

.cstm-user-data-left-search {
  position: relative;
}

.cstm-user-search-input {
  border: 1px solid var(--color-border-neutral-strong) !important;
  height: 40px !important;
  border-radius: 120px !important;
  width: 320px !important;
  background: var(--color-bg-static-white) !important;
  padding: 8px 16px 8px 48px !important;
  color: var(--color-text-neutral-tertiary, #84898f) !important;
  text-overflow: ellipsis !important;
  font: var(--body-medium) !important;
}

.cstm-user-search-input:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered) !important;
}

.cstm-user-search-input:focus {
  border: 2px solid var(--color-border-inverse-neutral) !important;
}

.cstm-user-search-icon {
  position: absolute;
  cursor: pointer;
  margin: 10px 10px 10px 16px;
  height: 20px;
  width: 20px;
}

.cstm-user-table {
  margin-top: 24px;
}

/* Start Table */
.table-fn-only .MuiPaper-root {
  box-shadow: inherit !important;
}

.table-fn-only th.MuiTableCell-root {
  padding: 14px 16px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-2x-small);
}

.table-fn-only td.MuiTableCell-root {
  padding: 16px;
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.table-fn-only .MuiTableRow-hover:hover {
  background: transparent !important;
}

.cstm-save-icon {
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  width: 40px;
  min-width: 40px;
  cursor: pointer;
}

.cstm-save-icon:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.file-name-box {
  display: flex;
  gap: 12px;
  align-items: center;
}

.file-name-box-img img {
  object-fit: cover;
  height: 48px;
  width: 48px;
  min-width: 48px;
}

.file-name-box-info {
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.cstm-file-name-title {
  margin: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font: var(--title-2x-small);
}

.cstm-file-name-description {
  margin: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font: var(--body-x-small);
}

.pd-16-8 {
  padding: 16px 8px !important;
}

.like-icon-cont {
  display: flex;
  align-items: center;
  gap: 12px;
}

.like-cont-nbr {
  font: var(--body-x-small);
  color: var(--color-text-neutral-secondary, #394046);
}

.pho-name-date-box {
  display: flex;
  gap: 12px;
  align-items: center;
}

.pho-name-date-box-img img {
  object-fit: cover;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50px;
}

.pho-name-date-box-info {
  display: flex;
  gap: 2px;
  flex-direction: column;
}

.cstm-table-doc-label-main {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  flex-wrap: wrap;
}

.cstm-table-doc-label {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-x-small);
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.2));
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4, 4px);
}

.icon-dots-vertical {
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px);
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  cursor: pointer;
}

.docs-table-only {
  overflow: auto;
}

.docs-table-only .MuiPaper-root {
  width: 1128px;
}

.table-fn-pagination-show {
  display: flex;
  align-items: center;
  gap: 8px;
}

.table-fn-pagination-show h6 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium);
  margin: 0px;
  white-space: nowrap;
}

.table-fn-pagination-show select {
  background-color: transparent;
  padding: 0px;
  height: auto;
  width: 42px;
  font: var(--body-medium-bold);
  color: var(--color-text-neutral-strong, #060d14);
  background-image: url(../Images/icn_m_chevron_down.svg);
}

.table-fn-pagination-number {
  display: flex;
  align-items: center;
  gap: 12px;
}

.table-fn-pagination {
  display: flex;
  padding: 32px 0px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.table-fn-pagination-cont {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.table-fn-pagination-cont li {
  height: 32px;
  width: 32px;
}

.table-fn-pagination-cont li a {
  border-radius: 28px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-medium-bold);
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
}

.table-fn-pagination-cont li a:hover {
  background: var(--color-bg-neutral-secondary, #eaedf1);
}

.table-fn-pagination-cont li a:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.table-fn-pagination-cont .active {
  background: var(--color-bg-neutral-secondary, #eaedf1);
  border-radius: 28px;
}

.table-fn-pagination-show-select {
  position: relative;
}

.table-fn-pagination-show-select .cstm-drop-list {
  left: -70px;
  min-width: 114px;
}

.pagination-right-arrow {
  display: flex;
  padding: var(--spacing-space-2-x-small, 8px);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  cursor: pointer;
  min-width: 42px;
}

.pagination-right-arrow:hover {
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
}

.pagination-right-arrow:active {
  border: 1px solid
    var(--color-border-neutral-secondary-pressed, rgba(13, 13, 13, 0.7));
}

.table-fn-invite-a-user {
  display: flex;
  padding: var(--spacing-48, 48px) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24, 24px);
  align-self: stretch;
  width: 100%;
}

.table-fn-invite-a-user-icon {
  height: 120px;
  width: 100%;
  text-align: center;
}

.table-fn-invite-a-user-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  max-width: 480px;
  width: 100%;
  margin: auto;
}

.table-fn-invite-a-user-title h4 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
}

.table-fn-invite-a-user-title h5 {
  margin: 0px;
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-small);
}

.cstm-table-fn-Loader {
  height: 364px;
  width: 100%;
  display: table-caption;
  text-align: center;
}

.cstm-table-fn-Loader img {
  position: relative;
  top: 50%;
  width: 80px;
  margin-top: -40px;
}

.table-fn-invite-a-user-main {
  width: 100%;
  display: table-caption;
}

/* End Table */

.cstm-btn-user-label {
  padding: 11px 8px !important;
  text-align: right !important;
  gap: 8px;
  display: flex !important;
  justify-content: flex-end;
}

.cstm-user-label-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-secondary, #eaedf1);
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium-bold);
}

.cstm-user-label-btn:hover {
  background: var(--color-bg-neutral-secondary-hovered, #dce0e5);
}

.cstm-user-label-btn:active {
  background: var(--color-bg-neutral-secondary-pressed, #ced3d9);
}

.cstm-user-accepted {
  color: var(--color-text-success-onstrong, #fff);
  font: var(--body-x-small-bold);
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-success-strong, #14ae5c);
  padding: var(--spacing-5, 5px) var(--spacing-8, 8px);
  text-transform: capitalize;
}

.cstm-user-pending {
  color: var(--color-text-warning-onstrong, #0d0d0d);
  font: var(--body-x-small-bold);
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-warning-strong, #fac505);
  padding: var(--spacing-5, 5px) var(--spacing-8, 8px);
  text-transform: capitalize;
}

.cstm-user-revoke {
  color: var(--color-text-success-onstrong, #fff);
  font: var(--body-x-small-bold);
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-neutral-strong, #041527);
  padding: var(--spacing-5, 5px) var(--spacing-8, 8px);
  text-transform: capitalize;
}

.cstm-suer-email {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font: var(--body-x-small);
  word-break: break-all;
}

.cstm-user-pgf-text {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-medium);
  margin: 0px;
}

.cstm-user-email-address-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}

.cstm-user-email-address {
  max-width: 648px;
  width: 100%;
}

.cstm-user-email-address-box h5 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-2x-small);
}

.cstm-input-field {
  border-radius: var(--radius-8, 8px) !important;
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3)) !important;
  display: flex !important;
  background-color: var(--color-text-static-white, #fff) !important;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px)
    var(--spacing-8, 8px) var(--spacing-16, 16px) !important;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  font: var(--body-medium) !important;
  height: 40px !important;
}

.cstm-input-field:hover {
  border: 1px solid
    var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.5)) !important;
}

.cstm-input-field:active {
  border: 1px solid
    var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.7)) !important;
}

.cstm-input-field:focus {
  border: 2px solid var(--color-border-inverse-neutral, #0d0d0d) !important;
}

.cstm-user-email-address-btn {
  display: flex;
  padding: var(--spacing-24, 24px) 0px;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
}

.disabled-btn-color:disabled {
  background: var(--color-bg-disabled-strong, #dadde0) !important;
  color: var(--color-text-disabled-onstrong, #84898f) !important;
}

.error-ft {
  color: var(--color-text-danger-strong, #db1d26);
  font: var(--body-x-small);
}

.error-ft-border {
  border: 1px solid var(--color-border-danger-strong, #db1d26) !important;
}

.invitation-successfully-msg-close {
  cursor: pointer;
}

.red-only {
  background: var(--color-bg-danger-strong, #f1343d) !important;
}

.invitation-successfully-msg h4 {
  color: var(--color-text-success-onstrong, #fff);
  font: var(--body-small-bold);
  margin: 0px;
  min-width: 204px;
}

.invitation-successfully-msg {
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-success-strong, #14ae5c);
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-12, 12px)
    var(--spacing-12, 12px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  position: fixed;
  left: 32px;
  bottom: 32px;
  transition: 0.5s;
  -webkit-animation-name: example;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s;
  /* Safari 4.0 - 8.0 */
  animation-name: example;
  animation-duration: 4s;
  z-index: 3;
}

@-webkit-keyframes example {
  0% {
    left: -451px;
  }

  25% {
    left: 24px;
  }
}

@keyframes example {
  0% {
    left: -451px;
  }

  25% {
    left: 24px;
  }
}

.cstm-invite-loader img {
  height: 48px;
}

.cstm-invite-loader {
  position: absolute;
  background: #ffffffba;
  width: 100%;
  height: 100%;
}

.btn-review {
  position: relative;
}

iframe.ifrane-wordpress {
  width: 100%;
  height: 91.44vh;
}

@media screen and (max-width: 850px) {
  .cstm-user-table .css-rqglhn-MuiTable-root {
    min-width: 550px;
    width: 100%;
  }
  .cstm-content-user-main {
    padding: 24px;
  }
}

@media screen and (max-width: 600px) {
  .co-users-main-row {
    gap: 0;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .cstm-user-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .cstm-user-search-input {
    width: 270px !important;
  }
  .co-users-main-row-right {
    gap: 12px;
  }
  .invite-btn {
    font-size: 15px;
  }
  .request-new-seat-btn {
    font-size: 15px;
  }
}
.center-view-box {
  display: flex;
  justify-content: center;
  min-height: 100vh;
}
.center-view-box > div {
  margin: auto;
}
.error-text {
  color: var(--color-text-danger-strong);
  font-size: var(--body-2x-small);
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.mb-32 {margin-bottom: 32px;}
.mt-32 {margin-top: 32px;}
.grid-full-row{
  grid-template-columns:1/-1;
}
.referral-heading {
  text-align: center;
  color: var(--color-text-neutral-strong);
  letter-spacing: -0.36px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  text-overflow: ellipsis;
  overflow: hidden;
}

.referal-description-heading {
  margin-top: var(--spacing-12);
  text-align: center;
  font: var(--body-medium);
  color: var(--color-text-neutral-secondary);
}

.cstm-modal-referal-body {
  padding: var(--spacing-48);
  display: grid;
  gap: 40px;
  overflow: auto;
  position: relative;
}

.copy-filed {
  padding: 6px 12px !important;
  width: calc(100% - 60px); /* Adjust width based on button size */
  box-sizing: border-box;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  display: inline-block;
  border-radius: 7px !important;
}

.referal-description-info {
  color: var(--color-text-neutral-secondary);
  font: var(--body-x-small);
}

.invite-throughEmail-btn {
  border-radius: 28px;
  background-color: #0B42CF;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  height: 47px !important;
  box-shadow: none;
}
.inner-child {
  display: flex !important;
  flex-direction: column !important;
  min-height: 360px;
  gap: var(--spacing-24);
  justify-content: space-between;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.copy-button {
  position: absolute;
  right: 0;
  border: none;
  background-color: #FFFFFF;
  color: #0B42CF;
  /* height: 100%; */
  /* padding: 0 12px; */
  margin-right: 4px;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.copy-btn{
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}
.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-right: 14px;
  margin-top: 14px;
}

.close-button:hover {
  color: #ff0000;
}
.invite-label{
  font: var(--body-small);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
  margin-bottom: 0;
}
.referal-email {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
.referral-link {
  border-top: 1px solid var(--color-border-neutral-tertiary);
  text-align: center;
  padding: var(--spacing-24);
}
.referral-link p {
  font: var(--body-medium);
}
.Referral-link {
  color: var(--color-text-brand-strong);
  font: var(--body-medium);
  font-weight: 600;
}
.grid-full-row{
  grid-column: 1/-1 !important;
}
.video-container {
  position: relative;
}

.label {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  z-index: 1;
}

.video-container.fullscreen .label {
  bottom: 100px;
}


/* Video player icons */
.right-icon svg,
.left-icon svg,
.center-icon svg {
  width: 40px;
  height: auto;
  fill: #fff;
}
.right-icon svg path,
.left-icon svg path,
.center-icon svg path {
  fill: #fff;
}
.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.left-icon {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.right-icon {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.volume-top {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 10px 25px;
  font-size: 30px;
  line-height: 1.25;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: var(--radius-4);
}
.volume-text{
  color: var(--white);
}
.animate {
  animation: zoom-in-zoom-out 0.15s linear forwards;
  opacity: 1;
  visibility: visible;
}
@keyframes zoom-in-zoom-out {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.75, 0.75);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1, 1);
    visibility: hidden;
  }
}
/* Modal Success Modal */
.modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  gap: var(--spacing-24);
  text-align: center;
}
.modal-success .modal-success-title {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
  letter-spacing: -0.3px;
}
.modal-success .modal-success-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  gap: var(--spacing-12);
}
.modal-success .modal-success-button {
  padding-top: var(--spacing-12);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  width: 100%;
} 
.video-container {
  position: relative;
}

.label {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  z-index: 1;
}

.video-container.fullscreen .label {
  bottom: 100px;
}


/* Video player icons */
.right-icon svg,
.left-icon svg,
.center-icon svg {
  width: 40px;
  height: auto;
  fill: #fff;
}
.right-icon svg path,
.left-icon svg path,
.center-icon svg path {
  fill: #fff;
}
.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.left-icon {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.right-icon {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 25px;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: 50px;
  opacity: 0;
  visibility: hidden;
}
.volume-top {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 10px 25px;
  font-size: 30px;
  line-height: 1.25;
  background-color: rgba(0, 0, 0, 0.608);
  border-radius: var(--radius-4);
}
.volume-text{
  color: var(--white);
}
.animate {
  animation: zoom-in-zoom-out 0.15s linear forwards;
  opacity: 1;
  visibility: visible;
}
@keyframes zoom-in-zoom-out {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.75, 0.75);
    visibility: visible;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1, 1);
    visibility: hidden;
  }
}

.invite-info-page {
  padding-top: 64px;
  min-height: 100vh;
  display: flex;
}

.invite-info-page .invite-text {
  font: var(--body-medium);
  color: var(--color-text-neutral-secondary);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-8);
}

.invite-info-page .invite-text a {
  color: var(--color-text-link-strong);
}

.invite-info-page .empty-state-icon img {
  width: 96px;
  height: 96px;
}
.invite-info-page .empty-state-info-title {
  font: var(--body-x-large);
  color: var(--color-text-neutral-secondary);
}
.invite-info-page .empty-state-info-title strong {
  color: var(--color-text-neutral-strong);
}
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}