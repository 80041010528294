.chatmessage-files-inner .chat-message { 
    padding: var(--spacing-8) var(--spacing-12);
    width: 296px;
}



.document-content {
    flex: 1 1 auto;
    width: calc(100% - 56px);
}

.document-content .file-title {
    color: var(--color-text-neutral-strong);
    font: var(--chat-reglular);
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}

.document-content .file-size {
    color: var(--color-text-neutral-tertiary);
    font: var(--body-x-small);
    margin: 0;
}

/* Outgoing */

.outgoing .document-content .file-title,
.outgoing .document-content .file-size {
    color: var(--color-text-brand-onstrong);
}

.chatmessage-files-inner.outgoing .chatmessage-advanced svg path{
    fill: var(--color-icon-brand-onstrong);
}