 .chat-details-notifications-container-in {
     display: flex;
     padding: var(--spacing-12, 12px) 0px;
     flex-direction: column;
     align-items: center;
     align-self: stretch;
 }

 .chat-details-notifications-hr {
     padding: 0px var(--spacing-20, 20px);
     width: calc(100% - 40px);
     align-items: center;
     margin: 0px auto !important;
 }