body.menuview .card-header button.btn-link img.cstn-cl-arrow {
  display: none;
}
.category-slider-menu-list {
  height: calc(100vh - 72px);
  overflow: auto;
  padding: 0px 8px;
}
.category-slider-menu-list::-webkit-scrollbar {
  width: 6px;
}
.category-slider-menu-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #f3f5f7;
}
.category-slider-menu-list::-webkit-scrollbar-thumb {
  background: #351bce;
  border-radius: 10px;
}
.category-slider-menu-list::-webkit-scrollbar-thumb:hover {
  background: #351bce;
  cursor: pointer;
}
.delete-video-modal .ctsm-modal-body {
  position: relative;
}
.delete-video-modal .ctsm-modal-body button {
  position: absolute;
  top: 12px;
  right: 12px;
}
ul.category-slider-menu-list li a {
  padding: 12px 16px;
  display: flex;
  color: #201e29 !important;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  position: relative;
}
ul.category-slider-menu-list li a:hover {
  background-color: #f3f5f7 !important;
}
ul.category-slider-menu-list li .active-cs {
  color: #351bce !important;
}
.ctm-ldr-only .video-list-datetime {
  height: 12px;
  width: 50%;
}
.ctm-ldr-only .video-list-heading {
  margin-top: 0;
}
.ctm-ldr-only .video-list-main {
  flex-direction: row;
  margin-bottom: -10px;
}
.ctm-ldr-only .video-list-thumb {
  width: 46%;
  height: 98px;
}
.ctm-ldr-only .ctm-ldr-rt {
  width: 54%;
}
.video-list-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}
.video-list-thumb {
  aspect-ratio: 16/9;
  position: relative;
  width: 100%;
}
.video-list-heading {
  margin-top: 8px;
  height: 32px;
  width: 100%;
}
.video-list-datetime {
  height: 8px;
  width: 100%;
}
.video-list-view-cmt {
  display: flex;
  align-items: center;
  gap: 12px;
}
.video-list-view,
.video-list-cmt {
  height: 12px;
  width: 30%;
}
.loading-video-loader-inner {
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
}
.loading-video-loader-heading-sec {
  display: flex;
  gap: 16px;
  margin: 12px 16px;
  justify-content: space-between;
}
.loading-video-loader-left {
  width: 60%;
}
.loading-video-loader-right {
  width: 100%;
  max-width: 180px;
}
.loading-video-loader-heading {
  height: 32px;
  width: 50%;
}
.loading-video-loader-butn {
  height: 32px;
  width: 100%;
  margin-bottom: 4px;
}
.loading-video-loader-butn.loading-butn {
  height: 16px;
  width: 100%;
}
.loading-video-tag-sec {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}
.loading-video-tag {
  width: 110px;
  height: 24px;
  border-radius: 50px;
}
.vjs-caption-settings .vjs-tracksetting select {
  margin-bottom: 8px !important;
  color: #201e29 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 36px !important;
  border-radius: 10px !important;
  width: 100% !important;
  font-family: "Inter", sans-serif !important;
}
.vjs-caption-settings {
  border: 1px solid white !important;
}
.vjs-caption-settings .vjs-tracksetting span {
  margin-left: 0 !important;
}
.show-more-less-clickable:hover {
  color: #351bce;
}
.newsfeed-write-your-comments-tx .react-input-emoji--container {
  margin: 0px;
}
.wyc-send {
  position: absolute;
  right: 17px;
  top: 10px;
  color: var(--navyblue);
  font-size: 18px;
  cursor: pointer;
}
.wyc-send:hover {
  color: #201e29 !important;
}
.react-input-emoji--placeholder {
  left: 0 !important;
}
body .newsfeed-write-your-comments-tx .react-input-emoji--container {
  max-height: 72px !important;
  overflow: auto;
  border: none;
  height: auto !important;
  padding-bottom: 0px;
  text-transform: none !important;
}
body
  .newsfeed-write-your-comments-tx
  .react-input-emoji--container::-webkit-scrollbar {
  width: 5px;
}
body
  .newsfeed-write-your-comments-tx
  .react-input-emoji--container::-webkit-scrollbar-track {
  background: var(--btn3);
}
body
  .newsfeed-write-your-comments-tx
  .react-input-emoji--container::-webkit-scrollbar-thumb {
  background: #c9c9c9;
  border-radius: 50px;
}
.newsfeed-post-right-write2 {
  margin-bottom: 15px;
  position: relative;
}
.newsfeed-post-right-write2 .newsfeed-post-right-write-left {
  top: 1px;
}
.cstm-content-details-main .newsfeed-post-right-write2 {
  margin-bottom: 15px;
  position: relative;
  margin-top: 16px;
}
.comment-time-st {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #666666;
  cursor: pointer;
  vertical-align: middle;
}
.comment-time-st img {
  vertical-align: text-bottom;
}
.newsfeed-post-right-cmt-right .newfeed-img-name {
  margin-left: 16px;
}
.newfeed-img-name img {
  margin-right: 5px;
}
.newfeed-img-name {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #3b3943;
  cursor: pointer;
}
.video-details-tabing .cstm-vd-tabs ul li a {
  padding: 12px;
}
.video-details-tabing .cstm-vd-box-in-main {
  float: none;
  max-width: 100px;
  margin-right: 0px;
  width: auto;
}
.video-details-tabing .cstm-vd-box-in-main img {
  cursor: pointer;
  width: 48px !important;
  height: 48px;
  padding: 4px;
  border-radius: 50px;
  border: 1px solid #cccccc;
  object-fit: cover;
  margin: auto;
}
.cstm-vd-box-in-main {
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 10px;
  height: 100%;
  float: left;
  width: 150px;
  margin-right: 15px;
  position: relative;
  text-align: center;
}
.cstm-vd-box-in-main img {
  height: 60px;
  object-fit: contain;
  cursor: pointer;
  width: 48px !important;
  height: 48px;
  padding: 4px;
  border-radius: 50px;
  border: 1px solid #cccccc;
  object-fit: cover;
  margin: auto;
}
.cstm-vd-box-in-main {
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 10px;
  height: 100%;
  float: left;
  width: 150px;
  margin-right: 15px;
  position: relative;
  text-align: center;
}
.cstm-vd-box-in-main img {
  height: 60px;
  object-fit: contain;
  cursor: pointer;
}
span.cstm-vd-box-in-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #091f48;
  display: block;
  width: 100%;
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.cstm-all-library-video-details
  .newsfeed-write-your-comments-tx
  .react-input-emoji--container {
  padding-right: 80px;
  background: #ffffff;
  border: 1px solid #e6e6e6 !important;
}
.cstm-back-video-detail-cl .comment-main-box:last-child {
  border-bottom: 0px;
  margin-left: 54px;
}
.cstm-back-video-detail-cl .comment-main-box-right-icon {
  margin-bottom: 0px;
}
.cstm-edit-delete-poll {
  padding: 0px;
  margin-top: 8px;
  left: -107px !important;
  min-width: 130px !important;
}
.cstm-edit-delete-poll ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.cstm-edit-delete-poll ul li a {
  padding: 9px 10px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #091f48;
}
.cstm-edit-delete-poll ul li a:hover {
  color: #ffcc00;
}
.cstm-edit-delete-poll ul li img {
  margin-right: 6px;
}
.cstm-poll-ad {
  cursor: pointer;
}
.newfeed-dp {
  margin-left: 20px;
}
.comment-main-box-right-icon-left .newfeed-img-name {
  margin-right: 18px;
  vertical-align: middle;
}
.comment-main-box-right-icon {
  display: table;
  width: 100%;
  margin-bottom: 12px;
}
.comment-main-box-right-icon-left {
  float: left;
}
.comment-main-box-right-icon-right {
  float: right;
}
.comment-main-box-right-box-cont {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3b3943;
}
.comment-main-box-right-box-name-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 5px;
  padding-right: 40px;
}
.comment-main-box-right-box {
  background: #f3f5f7;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 6px;
}
.comment-main-box-right-box-name-dotted {
  position: absolute;
  right: 0;
  top: 0;
}
.comment-main-box-right-box-name {
  display: block;
  width: 100%;
  position: relative;
}
.comment-main-box {
  margin-left: 54px;
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 16px;
  padding-bottom: 16px;
}
.cstm-newsfeed-post .comment-main-box {
  padding-bottom: 0px;
}
.comment-main-box-left {
  position: absolute;
}
.comment-main-box-right {
  padding-left: 54px;
}
.comment-main-box-left img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.comment-main {
  margin-top: 16px;
  padding: 16px 16px;
}
.comment-main-box-right-box-cont b {
  color: #351bce;
}
.comment-main-box-right-recmt-left {
  position: absolute;
}
.comment-main-box-right-recmt-left img {
  height: 36px;
  width: 36px;
  object-fit: cover;
  border-radius: 50px;
}
.comment-main-box-right-recmt-right {
  padding-left: 44px;
}
.cstm-content-details-main .comment-main {
  margin-top: 0px !important;
  padding: 0;
}
.cstm-content-details-main .comment-main-box-right-recmt-right {
  padding-left: 44px;
  margin-top: 12px;
}
.newsfeed-write-your-comments-tx .react-input-emoji--button svg {
  padding: 2px;
  fill: #201e29;
  margin-top: -4px;
}
.newsfeed-write-your-comments-tx .react-input-emoji--container {
  border-radius: 10px !important;
  background: rgb(32 30 41 / 6%);
  padding: 0px 50px 10px 0px;
  text-transform: capitalize;
  height: 40px !important;
  border: none;
}
.newsfeed-write-your-comments-tx .react-input-emoji--button {
  position: absolute;
  right: 41px;
  z-index: 1;
  top: 10px;
}
.react-input-emoji--wrapper {
  margin-left: 20px !important;
}
.react-input-emoji--input {
  padding-left: 0px !important;
}
.cstm-wlm-email img {
  margin-right: 9px;
  vertical-align: -5px;
}
.historyvideo-clearall-loading-image {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 999;
  left: 0;
  background: #ffffffcf;
  top: 0px;
  text-align: center;
  line-height: 46px;
}
.historyvideo-clearall-loading-image img {
  width: 24px;
}
a.historyvideo-remove-all-videos-loader {
  position: relative;
}
.mark-btn-watched {
  position: relative;
}
.savedvideo-loading-image {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffffe8;
  line-height: 36px;
}
.savedvideo-loading-image img {
  width: 24px;
}
.confirm-account-deletion-btn button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #e02424;
  padding: 10px 18px;
  border: none;
  background: transparent;
}
.confirm-account-deletion-btn button:hover {
  color: #351bce;
}
.gallery-img {
  position: relative;
}
.video_progress_wrapper {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 4px;
  left: 0px;
  background: #FFFFFF66;
}
.video_progress_bar {
  background-color: #F1343D;
  height: 4px;
}
.cstm-gallery-img {
  border-radius: 10px;
}
.cstm-my-videos-history-list-main-box-img {
  border-radius: 10px;
  overflow: hidden;
}
.cstm-conten-listing-pg-only .cstm-cv-video {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 22px;
}
.cstm-all-content-library-main-only {
  grid-template-columns: inherit !important;
  column-gap: inherit !important;
  display: flex !important;
  width: 100%;
  background: #fff;
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 64px !important;
}
.cstm-cl-only-sk {
  position: sticky;
  top: 64px;
  z-index: 2;
  background: #fff;
  padding: var(--spacing-12) var(--spacing-24);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  z-index: 3;
}
.cstm-cl-only-sk .dropdown-wrapper {
  position: relative;
}
.cstm-cl-only-sk .dropdown-wrapper .dropdown-list {
  right: 0;
}
.cstm-cl-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cstm-cl-bottom {
  /* margin-bottom: 22px; */
  padding-bottom: 22px;
}
.cstm-cl-search {
  position: relative;
}
.search-videolist {
  position: absolute;
  left: 18px;
  top: 12px;
}
.cstm-all-content-library-main-only .owl-carousel .owl-stage-outer {
  margin: 0px 30px 0px 0px;
  z-index: 1;
}
.cstm-all-content-library-main-only .owl-stage-outer:after {
  height: 32px;
  width: 40px;
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  background-image: linear-gradient(to right, #ffffff00, white);
}

.cstm-cl-items-list {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #201e29;
  cursor: pointer;
  white-space: pre;
  padding: 6px 12px;
}
.cstm-cl-items-list:hover {
  background: #f3f5f7;
  border-radius: 8px;
}
.cstm-cl-recent-box {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
}
.cstm-cl-recent-box img {
  height: 11px;
  width: 9px;
}
.cstm-cl-recent-box:hover {
  color: #351bce;
}
.cstm-cl-recent {
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.cstm-cv-video {
  display: grid;
  grid-gap: 19px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
}
.cstm-cv-video-in video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cstm-cv-video-in img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background: #fff;
}
.csg-video-inner video {
  object-fit: cover;
}
.gallery-content1 {
  padding-top: 6px;
  margin-bottom: 2px;
}
.icon-box img {
  color: #091f48;
  align-self: center;
  padding-right: 6px;
}
.icon-box {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #3b3943;
}
.gallery-main-icon span {
  vertical-align: middle;
}
.cstm-cv-video-in {
  transition: transform 0.2s;
  background: #fff;
  margin-bottom: 7px;
  box-shadow: 0px 2px 10px transparent;
}
/* .cstm-cv-video-in:hover {
  cursor: pointer;
  z-index: 1;
  box-shadow: 0px 2px 10px #b1b1b1;
  border-radius: 10px;
  border: none;
  margin-bottom: 0px;
} */
.cstm-cl-title {
  font: var(--title-large);
  letter-spacing: -0.4px;
  color: var(--color-text-neutral-strong);
}
.gallery-img img {
  position: relative;
}
.gallery-img > img {
  width: 100%;
}
.cstm-ttl2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}
.cstm-rv-time {
  right: 12px;
  z-index: 1;
  background: rgba(32, 30, 41, 0.8);
  border-radius: 15px;
  bottom: 12px;
  position: absolute;
  padding: 3px 4px;
  letter-spacing: 0.3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
.cstm-vh-sh {
  display: flex;
  margin-bottom: 16px;
}
.cstm-vh-sh-left {
  width: 100%;
  max-width: 180px;
  height: 98px;
  margin-right: 20px;
  border-radius: 10px;
}
.cstm-vh-sh-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.cstm-vh-sh-right-top {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.cstm-vh-sh-right-bottom {
  width: 60%;
  height: 18px;
}
.cstm-video-history-col-1 .cstm-cl-title {
  margin-bottom: 16px;
}
.cstm-video-history-col-1 {
  width: 100%;
  max-width: 788px;
  margin: auto;
  min-height: 500px;
}
.cstm-video-history-col-2 {
  width: 320px;
  float: left;
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 2;
}
.cstm-videos-history-only {
  padding: 8px 370px 8px 20px;
  width: 99%;
  position: relative;
  margin-bottom: 20px;
}
.cstm-saved-single-video-in {
  background-image: url(../Images/BackgroundBackdrop.png) !important;
  padding: 24px;
  border-radius: 10px;
  height: calc(100vh - 110px);
  margin-top: 10px;
}
.cstm-saved-single-video-gallery-img-main {
  aspect-ratio: 16/9;
}
.cstm-saved-single-video-gallery-img-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background-color: #000;
}
.cstm-saved-single-video-gallery-img {
  position: relative;
  margin-bottom: 12px;
}
.cstm-saved-single-video-gallery-img-over {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
}
.cstm-saved-single-video-gallery-img-over img {
  position: relative;
  top: 50%;
  margin-top: -28px;
  cursor: pointer;
}
.cstm-my-videos-history-list-main {
  position: relative;
}
.cstm-my-videos-history-list-main:hover {
  background: #f3f5f7;
  border-radius: 10px;
}
.cstm-my-videos-history-list-main-box-img {
  aspect-ratio: 16/9;
  width: 24%;
  position: relative;
  margin-right: 20px;
}
.cstm-my-videos-history-list-main-box-info {
  width: 76%;
  padding-right: 40px;
}
.cstm-my-videos-history-list-main-box {
  display: flex;
  padding: 8px;
}
.cstm-saved-single-video-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cstm-saved-single-video-lbl {
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  color: #c7c7ca;
}
hr.cstm-ssv-line {
  border-top: 1px solid #d9d9d9;
  opacity: 0.3;
  margin: 12px 0px;
}
.cstm-ssv-description {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 8px;
}
.cstm-saved-single-video-desc {
  max-height: calc(100vh - 550px);
  overflow: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #c7c7ca;
  margin-bottom: 28px;
}
.cstm-saved-single-video-desc::-webkit-scrollbar {
  width: 5px;
}
.cstm-saved-single-video-desc::-webkit-scrollbar-track {
  background: #48454e;
  border-radius: 5px;
}
.cstm-saved-single-video-desc::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
}
.cstm-ssvpl-pv-dtd {
  cursor: pointer;
}
.cstm-ssvpl-dp {
  position: relative;
  min-width: 40px;
}
.cstm-ssvpl-btn {
  width: 100%;
  padding-right: 12px;
}
.cstm-ssvpl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cstm-play-video-ssv {
  width: 100%;
  background: #351bce;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  border: none;
  height: 40px;
}
.cstm-vh-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 4px;
  margin-top: 8px;
}
.cstm-vh-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #3b3943;
}
span.cstm-vh-info-dt {
  margin: 0px 8px;
  font-size: 10px;
  color: #5c5b63;
  vertical-align: 2px;
}
.cstm-hv-op-drop {
  position: absolute;
  top: 13px;
  z-index: 1;
  right: 6px;
  display: none;
}
.cstm-hv-op-drop-dt {
  cursor: pointer;
}
.cstm-hv-op-drop-dt img {
  padding: 3px 10px;
}
.cstm-removeOpen-saved-btns {
  width: 214px !important;
}
.cstm-dropin {
  position: absolute;
  right: 13px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: max-content;
}
.cstm-dropin ul {
  list-style: none;
  margin: 0px 0px 2px 0px;
  padding: 0px;
}
.cstm-dropin ul li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29 !important;
  display: block;
  padding: 6px 10px;
}
.cstm-dropin ul li a img {
  margin-right: 3px;
  vertical-align: -3px;
}
.cstm-dropin ul li a:hover {
  color: #351bce !important;
}
.cstm-cl-only button i.fa.fa-angle-up,
.cstm-cl-only button i.fa.fa-angle-down {
  display: none;
}
.all-content-main-left-sidebar .active2-sd {
  font-weight: 700 !important;
  color: #091f48 !important;
  background: #e7f1fa !important;
}
.cstm-all-content-library-main-only .all-content-main-center {
  width: 100%;
  background: #fff;
  padding-left: 248px;
  margin-right: inherit !important;
  margin-bottom: inherit !important;
}
.gallery-content1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  padding-top: 12px;
  margin-bottom: 8px;
  cursor: pointer;
}
.gallery-content2 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #3b3943;
  margin-bottom: 2px;
}
.cstm-gallery-img {
  aspect-ratio: 16/9;
  position: relative;
}
.cstm-my-videos-only .icon-box {
  font-size: 12px;
}
.cstm-bullet {
  margin: 0px 8px;
  font-size: 10px;
  color: #5c5b63;
  vertical-align: 2px;
}
.cstm-my-videos-only {
  padding: 8px 20px 20px 20px;
}
.cstm-history-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}
.cstm-history-top-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
  display: flex;
  gap: 10px;
}
.cstm-history-top-seeall {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #351bce;
  cursor: pointer;
}
.cstm-history-top-seeall:hover {
  color: #201e29;
}
.cstm-gallery-img.cstm-gallery-img-main .cstm-videoPlayericons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all;
  display: none;
}
.cstm-conten-listing-pg-only {
  padding-bottom: 20px;
}
.cstm-conten-listing-pg-only .cstm-cl-mv-ldr {
  padding-right: 20px;
  padding-left: 20px;
}
.cstm-cl-mv-ldr {
  display: grid;
  grid-gap: 19px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
}
.cstm-cl-mv-ldr-title {
  width: 100%;
  height: 42px;
  margin-bottom: 12px;
}
.cstm-cl-mv-ldr-time {
  width: 40%;
  height: 20px;
  margin-bottom: 6px;
}
.cstm-cl-mv-ldr-view.skeleton-box {
  width: 70%;
  height: 20px;
}
.cstm-cl-mv-ldr-main {
  margin-bottom: 16px;
}
.cstm-cl-mv-ldr-video {
  aspect-ratio: 16/9;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
}
.cstm-open-in .owl-stage-outer:before {
  display: block !important;
}
.cstm-open-in .owl-stage-outer {
  margin-left: 35px !important;
}
.cstm-my-videos-only .cstm-cv-video {
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
}
.cstm-my-videos-only .gallery-content1 {
  font-size: 14px;
}
.cstm-my-videos-only .gallery-content2 {
  font-size: 12px;
}
span.cstm-ssv-dt {
  font-size: 7px;
  margin: 0px 5px;
}
.cstm-dropin.cstm-ssvpl-pv-drop {
  width: max-content;
  right: 14px;
  margin-top: -6px;
}
.cstm-dropin.cstm-ssvpl-pv-drop ul {
  margin-bottom: 0px;
}
.cstm-vl-time {
  background: #201e29;
  border-radius: 40px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 2px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #ffffff;
}
.cstm-vh-vi img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background-color: #000;
}
.cstm-vh-vi {
  aspect-ratio: 16/9;
  position: relative;
}
.cstm-saved-single-video-gallery-img:hover
  .cstm-saved-single-video-gallery-img-over {
  display: block;
  cursor: pointer;
}
.cstm-cawh {
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #201e29;
}
.cstm-cawh:hover {
  color: #351bce;
}
.cstm-cawh img {
  margin-right: 8px;
  vertical-align: text-bottom;
}
.cstm-video-history-search {
  position: relative;
  margin-bottom: 28px;
}
.cstm-video-history-search img {
  position: absolute;
  right: 18px;
  top: 8px;
}
.cstm-clear-all-watch-history {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  display: flex !important;
  align-items: center !important;
  color: #606060 !important;
  padding: 6px 45px 6px 16px !important;
  height: 36px !important;
}
.cstm-clear-all-watch-history:hover {
  box-shadow: 0 0 0 1px #351bce;
  border: 1px solid #351bce !important;
}
.all-content-main.cstm-all-content-main.cstm-all-library-video-details {
  grid-template-columns: minmax(375px, 2.4fr) minmax(0, 1fr);
  grid-template-areas: "videoinnerleft videorightside";
  column-gap: 24px;
  padding-top: 88px;
  padding-bottom: 15px;
}
.all-content-main.cstm-all-content-main.video-details-grid {
  grid-template-columns: 880px 392px;
  max-width: 1296px;
  padding-right: 0;
  padding-left: 0;
}
.all-content-main.cstm-all-content-main.cstm-all-library-video-details .all-content-main-center {
  grid-area: videoinnerleft;
}
.all-content-main.cstm-all-content-main.cstm-all-library-video-details .all-content-main-right.cstm-all-content-main-right {
  grid-area: videorightside;
}
.cl-main-all-sec {
  height: 100%;
}
.cstm-back-video-detail-cl {
  background: #ffffff;
  border-radius: 5px 5px 0px 0px;
  height: 100%;
}
.cstm-back-video-detail-cl .back-link {
  padding: 0px 0px 16px;
  border-radius: 5px 5px 0px 0px;
}
.cstm-back-video-detail-cl .back-link h3 {
  margin-bottom: 0px;
  width: max-content;
}
.mark-btn-watched button {
  opacity: 0.7;
}
.mark-btn-watched button:hover {
  opacity: 1;
  background: #201e29;
}
.back-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #091f48;
  cursor: pointer;
}
.back-link:hover {
  color: var(--yellow);
}
.back-link img {
  margin-right: 8px;
}
.cstm-all-library-video-details
  .all-content-main-right.cstm-all-content-main-right {
  max-height: 100%;
  overflow: hidden;
  position: unset;
  height: 100%;
}
.related-videos-main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #201e29;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 16px;
  padding-bottom: 10px;
}
.related-videos-contant-in {
  display: grid;
  grid-area: content;
  grid-template-rows: auto;
  column-gap: 16px;
  grid-template-areas: "newsfeed rightside";
  grid-template-columns: minmax(0, 168px) minmax(0, 1fr);
  padding-bottom: 16px;
}
.related-videos-contant-in-left img {
  width: 100%;
  height: 100%;
  background: #000;
  aspect-ratio: 16/9;
  object-fit: cover;
  border-radius: 10px;
}
.related-videos-contant-in-left {
  height: 98px;
  position: relative;
}
.related-videos-contant-in-left img {
  object-fit: cover;
}
.related-videos-contant-in-right {
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  cursor: pointer;
}
.rvc-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #201e29;
  margin-bottom: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cstm-content-details-video {
  margin-bottom: var(--spacing-40);
  border-radius: 12px;
  overflow: hidden;
}
.cstm-content-details-video .video-js .vjs-tech {
  top: 0 !important;
  left: 0 !important;
}
.cstm-video-sponsor-card {
  background-color: #1e1e1f;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  gap: 16px;
}
.cstm-video-sponsor-card .cstm-video-sponsor-icon {
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
.cstm-video-sponsor-card .cstm-video-sponsor-name {
  font: var(--title-small);
  color: #fff;
}
.cstm-video-sponsor-card .cstm-video-sponsor-link a {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}
.cstm-video-sponsor-card .cstm-video-sponsor-link a svg path {
  color: #fff;
  fill: #fff;
}
.cstm-video-sponsor-card .cstm-video-sponsor-link {
  margin-left: auto;
}
/* .cstm-back-video-detail-cl .cstm-content-details-video, */
.cstm-back-video-detail-cl .csg-video-inner,
.cstm-back-video-detail-cl .video-js {
  /* aspect-ratio: 16/9; */
  aspect-ratio: 2/1;
  padding: 0;
}
.no-aspect-ratio {
  aspect-ratio: unset !important;
}
.vjs-poster {
  background-size: cover;
}
.no-aspect-ratio.video-js .vjs-tech {
  position: relative;
  top: unset !important;
  left: unset !important;
}
.cstm-back-video-detail-cl .video-js {
  height: 100%;
}
.cstm-vd-cnt.csmt-front-vd-cunt {
  column-gap: 2.4rem;
  display: grid;
  grid-area: content;
  grid-template-rows: auto;
  row-gap: 1.4rem;
  grid-template-areas: "vleftcontent vrightcontent";
  grid-template-columns: minmax(0, 1fr) minmax(0px, 180px);
  margin-bottom: 16px;
}
.video-details-heading-text h4 {
  font: var(--title-medium);
  color: var(--color-text-neutral-strong);
  margin: 0;
  padding-bottom: 8px;
  letter-spacing: -0.3px;
}
.tab-conten {
  padding-top: 48px;
}
.tab-conten .text-wrapper:not(:last-child) {
  padding-bottom: 20px;
}
.tab-conten .text-wrapper p {
  color: var(--color-text-neutral-secondary);
  font: var(--body-medium);
}
.tab-conten .text-wrapper h5 {
  margin-top: 12px;
  color: var(--color-text-neutral-strong);
}
.tab-conten .text-wrapper h3 {
  margin-bottom: 24px;
}
.hashtag-list {
  display: flex;
  align-items: center;
  gap: 12px 8px;
}
.chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px 8px;
}
.speakers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.speakers-wrapper .speakers-item {
  display: flex;
  align-items: center;
}
.speakers-wrapper .speakers-item .avtar-container {
  margin-right: 16px;
}
.speakers-wrapper .speakers-item .type-text-only .table-h5 {
  margin-top: 0;
}
.cstm-vd-cnt.csmt-front-vd-cunt .cstm-video-innr-text-tag {
  margin-top: 24px;
}
.cstm-vd-cnt.csmt-front-vd-cunt .cstm-vd-cnt-right {
  margin-left: auto;
}
.cstm-vd-cnt-right {
  min-width: 180px;
  margin-left: 30px;
}
.cstm-vd-cnt-right button {
  background-color: var(--navyblue);
  border: none;
  border-radius: 8px;
  width: 100%;
  color: var(--white);
  font-size: 14px;
  padding: 5px 0;
  margin-bottom: 8px;
  font-weight: 500;
  height: 32px;
}
.video-card .video-card-text .video-details-view-date span,
.video-details-wrapper .video-details-buttons .video-details-view-date span {
  color: #394046;
  font: var(--body-medium);
  letter-spacing: -0.1px;
}
.video-card .video-card-text .video-details-view-date span:not(:first-child),
.video-details-wrapper
  .video-details-buttons
  .video-details-view-date
  span:not(:first-child) {
  display: flex;
  align-items: center;
}
.video-card
  .video-card-text
  .video-details-view-date
  span:not(:first-child)::before,
.video-details-wrapper
  .video-details-buttons
  .video-details-view-date
  span:not(:first-child)::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 50%;
  margin: 0 6px 0px;
}
.related-event-video-card-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.video-card.card-spacing {
  /* padding: var(--spacing-6) var(--spacing-20); */
  padding: var(--spacing-6) var(--spacing-48)
    var(--spacing-6) var(--spacing-20);
  border-radius: 0;
}
.has-dotmenu .video-card.card-spacing {
  /* padding: var(--spacing-6) var(--spacing-48) var(--spacing-6) var(--spacing-20); */
  border-radius: 0;
  outline: none;
}
.has-dotmenu .video-dot-btn {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 99;
}
.has-dotmenu:focus-visible .video-dot-btn,
.has-dotmenu:hover .video-dot-btn {
  opacity: 1;
  visibility: visible;
}
.video-card {
  display: flex;
  border: 2px solid transparent;
  transition: all 0.5s ease;
}
.video-card.card-spacing:hover {
  background-color: var(--color-bg-neutral-tertiary-hovered);
}
.video-card.card-spacing:focus-visible {
  border-color: var(--color-border-inverse-neutral);
  outline: none;
}
.video-card:not(.card-spacing):focus-visible {
  border-color: var(--color-border-inverse-neutral);
  outline: none;
  padding: 2px;
  margin: -2px;
  border-radius: var(--radius-8);
}
.video-card .video-card-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: var(--spacing-2) 0;
}
.video-card .video-card-text .video-details-view-date,
.video-details-wrapper .video-details-buttons .video-details-view-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.video-card .video-card-text .video-card-title {
  font: var(--body-small);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}
.video-card .video-card-text .video-type-text,
.video-card .video-card-text .video-details-view-date span {
  font: var(--body-x-small);
  color: #394046;
}
.video-card .video-thumb-image img {
  border-radius: inherit;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-card .video-thumb-image {
  position: relative;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 128px;
  border-radius: var(--radius-8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-16);
  flex-shrink: 0;
}
.video-card .video-thumb-image .video-duration {
  position: absolute;
  bottom: 4px;
  right: 4px;
  color: var(--color-text-static-white);
  background: var(--color-bg-blackout-strong);
  padding: 4px 8px;
  border-radius: 6px;
  font: var(--body-2x-small-bold);
}
.video-card .video-thumb-image.img-file {
  background-color: #394046;
}
.video-card .video-thumb-image.img-file .file-icon {
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
}
.video-card .video-thumb-image.img-sponsor {
  background-color: #1e1e1f;
}
.video-card .video-thumb-image.img-sponsor .sponsor-icon {
  width: 72px;
  height: 28px;
  object-fit: contain;
  object-position: center;
}
.video-details-wrapper .video-details-buttons .video-details-btns .btn-item {
  width: fit-content;
  display: flex;
  align-items: center;
  /* gap: 5px; */
  position: relative;
}
.video-details-wrapper
  .video-details-buttons
  .video-details-btns
  .btn-item
  .btn-item-text {
  font: var(--body-x-small);
  letter-spacing: -0.1px;
}
.video-details-wrapper .video-details-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.video-details-buttons .video-details-btns {
  display: flex;
  gap: 16px;
}
.comment-option,
.download-option {
  position: absolute;
  left: 0;
  top: 100%;
  width: 240px;
  padding: 12px 0;
  box-shadow: 0px 2px 6px 0px #04152733;
  border-radius: var(--radius-8);
  margin-top: 5px;
  background-color: var(--color-bg-static-white);
  z-index: 1;
}
.comment-option button,
.download-option button {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  font: var(--body-medium);
  border: 0;
  background-color: transparent;
}
.content-list {
  margin-bottom: 12px;
}
.video-details-right-box:not(:last-child) {
  margin-bottom: 20px;
}
.video-details-right-box {
  border: 1px solid #0d0d0d1a;
  border-radius: var(--radius-12);
}
.video-details-right-box.same-event-videos-wrapper .video-listing {
  max-height: 452px;
  overflow: auto;
}
.video-details-right-box .video-listing {
  padding-bottom: var(--spacing-12);
}
.video-details-right-box h4 {
  padding: 16px 20px;
}
.main-comment-box {
  gap: 8px;
  padding: 24px 0;
  border-bottom: 1px solid #0d0d0d1a;
  position: relative;
}
.reply-comment-box {
  gap: 8px;
  padding: 24px 0;
  position: relative;
}
.comment-field .react-input-emoji--container {
  border: 0 !important;
  border-radius: 0 !important;
  margin: 0;
}
.comment-field svg {
  cursor: pointer;
}
.comment-field .react-input-emoji--container .react-input-emoji--wrapper {
  margin-left: 0 !important;
}
.reply-comment-box .comment-field .comment-input,
.main-comment-box .comment-field .comment-input {
  display: block;
  width: 100%;
  /* margin-left: 16px; */
  word-break: break-word;
  outline: none;
  /* max-height: 180px; */
  overflow: auto;
  font: var(--body-medium);
  color: var(--color-text-neutral-strong);
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
/* .reply-comment-box .comment-field .comment-input {
  max-height: 150px;
} */
.reply-comment-box .comment-field p.comment-input::before,
.main-comment-box .comment-field p.comment-input::before {
  content: attr(aria-label);
  color: var(--color-text-neutral-tertiary);
  font: inherit;
}
.reply-comment-box .comment-field p.comment-input:not(:empty)::before,
.main-comment-box .comment-field p.comment-input:not(:empty)::before {
  display: none;
}
.reply-comment-box .comment-field p.comment-input *,
.main-comment-box .comment-field p.comment-input * {
  background-color: transparent !important;
  color: var(--color-text-neutral-secondary) !important;
  font-size: inherit !important;
  font-family: inherit !important;
}
.emoji-modal {
  position: absolute !important;
  z-index: 111;
  left: 0;
  top: 100%;
}
.comment-emoji-btn {
  margin-right: auto;
  flex-shrink: 0;
  height: 40px;
}
.comment-btn {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  position: relative;
}
.comment-list-item {
  padding: var(--spacing-8) 0;
  position: relative;
}
.comment-list-wrapper {
  padding-top: var(--spacing-12);
  padding-right: var(--spacing-20);
}
.comment-list-item .comment-wrapper {
  display: flex;
  align-items: flex-start;
}

.comment-list-item .comment-wrapper .comment-text .comment-dot-btn {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 6px !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
.comment-list-item .comment-wrapper .comment-text .comment-dot-btn svg {
  transition: all 0.5s ease;
}
.comment-list-item .comment-wrapper .comment-text {
  padding-right: 40px;
  position: relative;
}
.comment-list-item .comment-wrapper .comment-text:hover .comment-dot-btn {
  opacity: 1;
  visibility: visible;
}
.comment-field .avtar-container,
.reply-comment-box .avtar-container,
.comment-list-item .comment-wrapper .avtar-container {
  margin-right: 16px;
  flex-shrink: 0;
}
.comment-list-item .comment-wrapper .comment-details {
  width: 100%;
}
.comment-list-item .comment-wrapper .comment-details .comment-heading {
  display: flex;
  align-items: center;
  gap: 8px;
}
.comment-list-item .comment-wrapper .comment-details h5 {
  color: var(--color-text-neutral-strong);
  font: var(--body-small);
  font-weight: 600;
}
.comment-list-item .comment-wrapper .comment-text p {
  font: var(--body-small);
  color: var(--color-text-neutral-secondary);
  word-break: break-word;
}
.comment-list-item .comment-wrapper .comment-text .show-more-less-clickable {
  font: var(--body-small);
  color: var(--color-text-info-strong);
  letter-spacing: -0.1px;
  cursor: pointer;
  display: inline-block;
}
.comment-list-item .comment-wrapper .comment-text p b {
  color: var(--color-text-info-strong);
  font: inherit;
  letter-spacing: -0.1px;
  display: inline-block;
  padding-right: 5px;
}
.comment-list-item .comment-wrapper .comment-details .comment-date {
  font: var(--body-x-small);
  color: var(--color-text-neutral-secondary);
}
.comment-list-item
  .comment-wrapper
  .comment-details
  .reply-wrapper
  .reply-btns {
  margin-left: -16px;
  margin-top: 4px;
}
.comment-list-item
  .comment-wrapper
  .comment-details
  .reply-wrapper
  .reply-btns
  button {
  font: var(--body-small);
  font-weight: 600;
}
.comment-list-item .comment-wrapper .comment-details .comment-reply-btn {
  color: #007be5;
}
.comment-list-item .comment-wrapper .comment-details .comment-reply-btn svg {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.comment-list-item
  .comment-wrapper
  .comment-details
  .comment-reply-btn.collapsed
  svg {
  transform: rotate(180deg);
}
.comment-list-item
  .comment-wrapper
  .comment-details
  .comment-reply-btn
  svg
  path {
  fill: #007be5;
}
.reply-comment-list {
  margin-top: 8px;
}
.comment-option {
  min-width: 160px;
  right: 0;
  left: unset;
  width: auto;
  z-index: 99;
}
.comment-option.top {
  top: -8px;
  transform: translateY(-100%);
}
.comment-option button.danger-text {
  color: #c90007;
}
.comment-option button.danger-text svg path {
  fill: #c90007;
}
.comment-option button {
  position: relative;
  border: 2px solid transparent;
  width: 100%;
}
.comment-option button:hover {
  background-color: var(--color-bg-neutral-tertiary-hovered);
}

.comment-option button:focus-visible {
  outline: none;
  border-color: var(--color-text-neutral-strong);
}
.report-modal .ctsm-modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.sponsor-modal .modal-sponsor-banner {
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  background-color: var(--color-bg-static-black);
}
.sponsor-modal .modal-sponsor-banner .sponsor-image {
  max-width: 188px;
  max-height: 72px;
  object-position: center;
  object-fit: contain;
}
.sponsor-modal .sponsor-top {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.sponsor-modal h3.sponsor-title {
  color: var(--color-text-neutral-strong);
  letter-spacing: -0.3px;
  margin-bottom: 0;
}
.sponsor-modal .sponsor-disc,
.sponsor-modal .sponsor-type {
  color: var(--color-text-neutral-secondary);
  font: var(--body-medium);
  letter-spacing: -0.1px;
}
.sponsor-modal hr {
  margin: 24px 0 !important;
}
.sponsor-modal .sponsor-link {
  color: var(--color-icon-brand-strong);
  display: flex;
  align-items: center;
  gap: 6px;
}

.Heart3FillIcon1.active,
.Heart3LineIcon.active {
  display: flex;
}
.Heart3FillIcon1,
.Heart3LineIcon {
  display: none;
}
.videodetailicon-sec {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}
.videodetailicon {
  display: block;
}
.videodetailicon-i {
  color: #201e29;
  margin-right: 4px;
}
.videodetailicon {
  font-size: 12px;
  font-weight: 500;
}
.video-details-tabing {
  padding-bottom: 16px;
}
.video-details-tabing .tabs-fluid {
  padding-top: 16px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
  justify-content: flex-start;
}
.video-details-tabing .tabs-fluid .tabs-button {
  width: auto;
}
.cstm-vd-tabs {
  padding-top: 16px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 0;
}
.cstm-vd-tabs ul li a {
  display: block;
  font: var(--title-x-small);
  letter-spacing: 0.02em;
  padding: 14px 12px;
  position: relative;
  color: var(--color-text-neutral-strong);
}
.cstm-vd-tabs ul li .active {
  border-radius: 10px;
}
.cstm-vd-tabs ul li .active::before {
  content: "";
  /* border-top: 5px solid var(--color-bg-brand-strong); */
  background-color: var(--color-bg-brand-strong);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 50px 50px 0 0;
  height: 5px;
}
.cstm-vd-cnt.csmt-front-vd-cunt a.musicvideobtn {
  display: inline-block;
  margin-left: 0;
}
span.cstm-vd-ttl-sb {
  font-weight: 600;
  font-size: 16px;
  color: #201e29;
  margin: 0px;
  margin-bottom: 10px;
  display: flex;
}
.comment-main-title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #201e29;
}
.cstm-back-video-detail-cl .comment-main-title {
  padding-top: 12px;
  margin-bottom: 0px;
  border-top: 1px solid #e6e6e6;
}
.cl-v-comment-sec .comment-main.cstm-no-comment-sec {
  min-height: 120px;
  height: 100%;
  display: flex;
  align-items: center;
}
.comment-main .emptymsg {
  padding: 0px 0px 16px;
}
.emptymsg {
  text-align: center;
  display: table;
  width: 100%;
  color: #dc3305;
}
.cstm-cl-active {
  background: #201e29;
  border-radius: 8px;
  color: #fff !important;
}
.cstm-cl-active:hover {
  background: #201e29 !important;
  border-radius: 8px;
  color: #fff !important;
}
span.cstm-bullet img {
  width: 4px;
  height: 4px;
  padding: 0px;
}
.video-js .vjs-tech {
  top: 1px !important;
  left: 1px !important;
}
.cstm-vd-cnt {
  display: flex;
  justify-content: space-between;
}
.related-videos-contant-in-left .cstm-rv-time {
  right: 8px;
  bottom: 8px;
}
.cstm-back-video-detail-cl .newsfeed-post-right-write {
  border-top: 0px;
  margin-top: 0px;
  padding: 16px 0px;
}
.newsfeed-post-right-write {
  position: relative;
  border-top: 1px solid #d7d7d7;
  padding: 16px 16px;
  margin-top: 16px;
}
.newsfeed-post-right-write-left {
  position: absolute;
  top: 18px;
}
.newsfeed-post-right-write-left img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50px;
}
.newsfeed-post-right-write-right {
  padding-left: 54px;
  position: relative;
}
.cstm-recent-dropdown-menu {
  position: absolute;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  top: 32px;
  z-index: 1;
  width: 120px;
}
.cstm-recent-dropdown-menu ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.cstm-recent-dropdown-menu ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #201e29 !important;
  display: block;
  padding: 6px 10px;
}
.cstm-recent-dropdown-menu ul li a:hover {
  color: #351bce !important;
}
.cstm-no-history {
  text-align: center;
  margin-top: 120px;
}
.cstm-oops-main-img {
  margin-bottom: 16px;
}
.cstm-oops-main-ctn {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9b9a9f;
  max-width: 290px;
  margin: auto;
}
/* Video library new css 31-05-2024 */
.clif-notes-wrapper p, .clif-notes-wrapper ul li {
  font: var(--body-medium);
  color: var(--color-text-neutral-secondary);
}
.video-library-grid {
  padding: var(--spacing-24);
  display: grid;
  grid-gap: var(--spacing-24) var(--spacing-16);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
.video-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-12);
}
.video-filter-container .video-buttons-tabs-wrapper {
  display: flex;
  gap: var(--spacing-12);
  align-items: center;
  max-width: calc(100% - 122px);
  width: 100%;
}
.video-filter-container .video-buttons-tabs-wrapper .vertical-seprator {
  width: 1px;
  height: 24px;
  background: var(--color-border-neutral-secondary);
}
.video-filter-container .filter-button-wrapper,
.video-filter-container .video-buttons-tabs-wrapper .video-browse-tabs {
  align-items: center;
  display: flex;
  gap: var(--spacing-8);
}
.videos-history-saved-grid {
  padding: var(--spacing-24);
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-16);
}
.scrollbar-wrapper {
  position: relative;
  max-width: calc(100% - 350px);
}
.video-filter-container .hidden-scrollbar {
  overflow: auto;
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}
.video-filter-container .grediant-shadow .hidden-scrollbar::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 32px;
  background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
}
.video-filter-container .hidden-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
} 

.video-filter-container .hidden-scrollbar::-webkit-scrollbar-thumb {
  border: 0px solid var(--color-border-inverse-screen);
  min-height: 0px; 
}

.video-filter-container .hidden-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(4, 21, 39, 0.3);
  cursor: pointer;
}