.checkin-modal-body-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing-24);
}
.checkin-modal-body-wrapper .user-image-name img {
  width: 150px;
  height: 150px;
  object-position: center;
  object-fit: cover;
  border-radius: var(--radius-8);
}
.checkin-modal-body-wrapper .user-image-name {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
.checkin-modal-body-wrapper h4 {
  color: var(--color-text-neutral-strong);
}
.checkin-modal-body-wrapper .user-image-name .user-name p {
  color: var(--color-text-neutral-secondary);
  font: var(--body-small);
}
button.check-btn.checked {
  color: var(--color-icon-success-strong);
}
.checkins-list {
  display: flex;
  flex-direction: column;
}
.checkins-list .checkins-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-16);
  padding: var(--spacing-8) 0;
  border-bottom: 1px solid var(--color-border-neutral-secondary);
}
.questionnaire {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}
.questionnaire .questionnaire-list {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.questionnaire .questionnaire-list .questionnaire-item {
  padding: var(--spacing-8) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-4);
}
.questionnaire .questionnaire-list .questionnaire-item h5 {
  font-weight: 400;
  color: #000000;
}
.questionnaire .questionnaire-list .questionnaire-item p {
  opacity: 0.6;
}
.member-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
.member-details .member-filed {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
