@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;500;700&display=swap");

.reviews-cont {
  height: 32px;
  width: 32px;
  background: var(--color-text-neutral-strong, #060d14);
  color: var(--color-text-static-white);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 500;
}

.user-loader img {
  width: 60px;
}

.user-loader {
  text-align: center;
  height: 426px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.mds-partners-head-right {
  position: relative;
}

.partner-slider-arrow-only ul.slick-dots {
  display: none !important;
}

.share-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong);
  color: var(--color-text-static-white);
  font: var(--body-large-bold);
  padding: 0px 12px;
  display: inline-block;
  border: none;
  font-size: 12px;
  line-height: 22px;
}

.share-btn:hover {
  background: var(--color-bg-brand-strong-hovered);
  color: var(--color-text-static-white);
}

.historty-ptn-icon,
.search-ptn-icon {
  margin-right: 12px;
}

.cstm-search-data .cstm-cl-search-sug-container {
  width: 400px !important;
}

.cstm-search-data .cstm-cl-search-sug-history-title {
  padding-right: 10px;
}

.cstm-rating-feedback {
  position: relative;
}

.loader-button1-footer {
  position: relative;
  display: inline;
}

.loader-button1-footer .loader-btn {
  position: absolute;
  left: 0;
  z-index: 2;
  background: #ffffffd6;
  width: 100%;
  text-align: center;
  line-height: 50px;
  top: 0;
}

.partners-details-popup-offer-details-loading {
  height: 145px;
  width: 100%;
  margin-bottom: 24px;
}

.partners-details-popup-offer-details-cont-title-loading {
  height: 35px;
  width: 30%;
  margin: 0px 24px 15px;
}

.partners-details-popup-offer-details-cont-cont-loading {
  height: 90px;
  width: 92%;
  margin: 0px 4% 15px;
}

.partnes-popup-offer-banner-title {
  font: var(--title-large);
  color: var(--color-text-static-white, #fff);
  text-align: center;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
  width: 90%;
}

.your-review-title {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-medium);
  margin: 0px;
}

p.sometihng-p {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  margin: 0px;
  font: var(--body-medium);
}

.any-category-could-post-left img {
  transition: transform 0.8s;
}

.partners-details-popup-your-review {
  display: flex;
  padding: var(--spacing-space-4-x-large, 48px)
    var(--spacing-space-x-large, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-space-x-large, 24px);
  align-self: stretch;
}

.dropdown-active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.partnes-banner-title {
  margin: auto;
  color: var(--color-text-static-white, #fff);
  text-align: center;
  font: var(--title-x-large);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1032px;
  left: 0;
  right: 0;
}

.any-category-could-post-left {
  overflow: hidden;
}

.any-category-could-post-left:hover img {
  transform: scale(1.05);
}

.leave-review-tex {
  border: 1px solid #db1d26 !important;
}

.partners-details-popup-leave-star .MuiRating-iconFilled svg path {
  color: #060d14;
}

.partners-details-popup-leave-star .css-1bg55nt-MuiRating-icon {
  transform: scale(1) !important;
}

.partner-slider-arrow-only
  .mds-partners-cont-post:hover
  .mds-partners-cont-post-img
  img {
  transform: scale(1.05);
}

.mds-partners-cont-post:hover {
  border-radius: 12px;
}

.partners-fresh-only
  .mds-partners-cont-post:hover
  .mds-partners-cont-post-img
  img {
  transform: scale(1.05);
}

.partners-meet-slider .slick-track {
  margin-left: inherit;
}

.mds-partners-cont-post {
  transition: transform 0.8s;
  position: relative;
  transform: translateZ(0);
}

.partners-more-benefits
  .mds-partners-cont-post:hover
  .mds-partners-cont-post-img
  img {
  transform: scale(1.03);
}

.partners-more-offer
  .mds-partners-cont-post:hover
  .mds-partners-cont-post-img
  img {
  transform: scale(1.05);
}

.no-data-found-partners {
  grid-column: 6/1;
  display: flex;
  padding: var(--spacing-space-4-x-large, 48px) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-space-x-large, 24px);
  flex: 1 0 0;
}

h2.nothing-found-title {
  margin-bottom: -16px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
}

.sorry-nothing-was {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-small);
  margin: 0px;
  max-width: 480px;
  width: 100%;
}

.clear-all-filters-btn {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong);
  padding: 12px 24px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.clear-all-filters-btn:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
  color: var(--color-text-neutral-strong, #060d14);
}

hr.line-pd-od {
  border-color: #e5e5e5;
  margin: 24px 0px;
}

.partners-details-popup-offer-details-cont-info p a {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium);
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.partners-details-popup-offer-details-cont-info p a:hover {
  text-decoration: inherit;
  color: var(--color-bg-brand-strong);
}

.partners-details-popup-offer-details-cont-info p {
  display: flex;
  margin-bottom: 12px;
  gap: 8px;
  align-items: center;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-medium);
}

.partners-details-popup-offer-details-cont-info p:last-child {
  margin-bottom: 0px;
}

.partners-details-popup-offer-details-cont p {
  margin-bottom: 12px;
}

.partners-details-popup-offer-details-cont h3 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
  margin-bottom: 12px;
  letter-spacing: -1px;
}

.partners-details-popup-offer-details-cont h4 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-small);
  margin-bottom: 12px;
}

.partners-details-popup-offer-details-cont {
  padding: 24px;
  max-height: calc(100vh - 270px);
  overflow: auto;
  word-break: break-word;
}

.partners-details-popup-offer-details-cont::-webkit-scrollbar {
  width: 5px;
}

.partners-details-popup-offer-details-cont::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-secondary);
}

.partners-details-popup-offer-details-cont::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-bg-neutral-secondary-pressed);
}

.partners-details-popup-offer-details {
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  position: relative;
}

.partners-details-popup-offer-details img {
  object-fit: cover;
  height: 144px;
  width: 100%;
}

.mds-other-offers-only {
  grid-gap: 40px 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  display: grid;
}

.partners-details-reviews-list-man-loading {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.partners-details-reviews-list-loading {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.partners-details-reviews-list-date-loading {
  width: 25%;
  height: 20px;
  border-radius: 12px;
}

.partners-details-reviews-list-head-loading {
  width: 50%;
  height: 30px;
  border-radius: 12px;
}

.partners-details-reviews-list-cont-loading {
  width: 100%;
  height: 50px;
  border-radius: 12px;
}

.partners-details-related-videos-loading {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.partners-details-related-videos-loading-in {
  width: 100%;
  height: 98px;
  border-radius: 12px;
}

.partners-details-reviews-list-popup {
  padding: 0px 24px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  height: 574px;
}

.partners-details-reviews-list-popup::-webkit-scrollbar {
  width: 5px;
}

.partners-details-reviews-list-popup::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-secondary);
}

.partners-details-reviews-list-popup::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-bg-neutral-secondary-pressed);
}

.partners-details-reviews-list-popup .partners-details-reviews-list {
  margin: 24px 0px;
}

.one-btn-right {
  justify-content: flex-end !important;
}

.partners-details-popup-leave-a-review .error {
  color: var(--color-text-danger-strong, #db1d26);
  font: var(--body-x-small);
  /* bottom: -17px; */
  position: inherit;
  bottom: -5px;
}

.btn-review {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong);
  color: var(--color-text-static-white);
  font: var(--body-large-bold);
  border: none;
}

.btn-review:hover {
  background: var(--color-bg-brand-strong-hovered);
  color: var(--color-text-static-white);
}

.btn-cancel {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  display: flex;
  padding: 11px 20px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-3-x-small, 6px);
  color: var(--color-text-neutral-strong, #060d14);
  background-color: var(--color-bg-static-white);
  text-align: center;
  font: var(--body-large-bold);
}

.btn-cancel:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
  color: var(--color-text-neutral-strong, #060d14);
}

.partners-details-popup-main-footer {
  padding: 16px 24px;
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.partners-details-popup-leave-a-review {
  padding: 24px;
}

.partners-details-popup-leave-a-review h5 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-small-bold);
  margin-bottom: 8px;
}

.reviews-list-p {
  margin-bottom: 8px;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-medium);
}

.partners-details-popup-leave-star {
  margin-bottom: 24px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.partners-details-popup-leave-star label {
  margin: 0px;
}

.partners-details-reviews-list-head {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.reviews-list-rating svg {
  height: 16px;
  width: 16px;
}

.reviews-list-img img {
  height: 30px;
  width: 30px;
}

.reviews-list-rating {
  display: flex;
  align-items: center;
}

.partners-details-reviews-list h6 {
  color: var(--color-text-neutral-tertiary, #84898f);
  font: var(--body-x-smal);
  margin: 0px;
}

.partners-details-reviews-list-head h3 {
  margin: 0px 0px 0px 4px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-x-small);
}

.reviews-list-img img {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  object-fit: cover;
}

.partners-details-reviews-list {
  list-style: none;
  padding: 0px;
  margin: 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.partners-details-reviews-list li {
  padding: 6px 0px;
}

.partners-details-add-you {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin-bottom: 24px;
}

.partners-details-add-you b {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-small-bold);
}

.reviews-mrn {
  margin-bottom: 12px !important;
}

.partners-details-related-posts-loading {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  margin-bottom: 24px;
}

.partners-details-related-posts-in-loading {
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  height: 192px;
}

.partners-details-helpful-list-loading {
  height: 76px;
  width: 100%;
  border-radius: 12px;
}

.partners-details-about-cont-loading {
  max-width: 57.5%;
  width: 100%;
  margin: 40px 0px;
}

.mds-about-partner-category-loading {
  height: 20px;
  width: 50%;
}

.mds-about-partner-title-loading {
  height: 35px;
  width: 70%;
}

.partners-details-pare-loading {
  height: 20px;
  width: 100%;
}

.partners-details-banner-loading {
  height: 288px;
  max-width: 1128px;
  width: 100%;
  margin: auto;
  border-radius: 12px;
  display: block;
}

.partners-details-post-card-loading {
  border-radius: 12px;
  position: absolute;
  right: 40px;
  top: -104px;
  height: 404px;
  max-width: 32%;
  width: 100%;
  text-align: center;
}

.partners-details-popup-close {
  position: absolute;
  right: 12px;
  padding: 8px;
  cursor: pointer;
}

.partners-details-popup-close:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.partners-details-popup-main-cont {
  padding: 24px 24px 0px;
  height: calc(100vh - 120px);
  overflow: auto;
  max-height: 665px;
}

.partners-details-popup-main-cont::-webkit-scrollbar {
  width: 5px;
}

.partners-details-popup-main-cont::-webkit-scrollbar-track {
  background: var(--color-bg-neutral-secondary);
}

.partners-details-popup-main-cont::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--color-bg-neutral-secondary-pressed);
}

.partners-details-popup-main-cont .partners-details-related-videos {
  gap: 16px;
}

.partners-details-popup-title {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-small);
}

.partners-details-popup-main-head {
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  display: flex;
  padding: 20px 24px;
  align-items: center;
  gap: 10px;
}

.partners-details-popup-main {
  max-width: 648px;
  position: relative;
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  overflow: inherit;
}

.partners-details-related-videos-cont h6 span {
  margin-right: 8px;
}

.related-videos-year-view {
  margin: -2px 0px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
}

ul.partners-details-related-videos li {
  display: flex;
  padding-right: var(--spacing-space-x-large, 0px);
  align-items: center;
  gap: var(--spacing-space-large, 20px);
  align-self: stretch;
  cursor: pointer;
}

.partners-details-related-videos-img {
  display: flex;
  width: 180px;
  height: 98px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: var(--radius-8, 8px);
}

.partners-details-related-videos-img img {
  border-radius: var(--radius-8, 8px);
  height: 98px;
  width: 100%;
  object-fit: cover;
  transition: transform 0.6s;
}

ul.partners-details-related-videos
  li:hover
  .partners-details-related-videos-img
  img {
  transform: scale(1.03);
}

.partners-details-related-videos-cont h4 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-x-small);
  margin: 0px;
  text-transform: capitalize;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.partners-details-related-videos-cont h6 {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin: 0px;
  text-transform: capitalize;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.partners-details-related-videos-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-space-4-x-small, 4px);
  flex: 1 0 0;
}

.partners-details-related-videos {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  margin-bottom: 24px;
}

.partners-details-btn-main {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong);
  padding: 12px 24px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  cursor: pointer;
  outline: none !important;
  background-color: var(--color-text-static-white);
}

.partners-details-btn-main:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
  color: var(--color-text-neutral-strong, #060d14);
}

.related-posts-label {
  margin: 0px;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-details-related-posts h4 {
  font: var(--title-x-small);
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 120px;
  margin-bottom: 4px;
}

.partners-details-related-posts {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  margin-bottom: 24px;
}

.partners-details-related-posts li {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-neutral-secondary, #eaedf1);
  padding: 24px 72px 24px 24px;
  position: relative;
  cursor: pointer;
}

.partners-details-related-posts li:hover {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-neutral-secondary-hovered, #dce0e5);
}

.partners-related-posts-link {
  position: absolute;
  right: 24px;
}

.partners-details-helpful-list-img img {
  height: 32px;
  width: 32px;
  object-fit: cover;
  border-radius: 5px;
}

.partners-details-helpful-list-link:hover {
  filter: invert(0.2) sepia(10) saturate(350) hue-rotate(-115deg);
}

.partners-details-helpful-list-cont h4 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-x-small);
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-details-helpful-list-cont h6 {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partners-details-helpful-list-cont {
  width: 100%;
  height: 44px;
  padding: 0px 48px 0px 56px;
}

.partners-details-helpful-list-img {
  position: absolute;
  left: 24px;
}

.partners-details-helpful-list-link {
  position: absolute;
  right: 24px;
}

.partners-details-helpful-list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  gap: 16px;
  display: flex;
  flex-direction: column;
}

.partners-details-helpful-list li {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  border-radius: var(--radius-medium, 12px);
  border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.2));
  position: relative;
}

.partners-details-helpful-list li:hover {
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
  cursor: pointer;
}

.partners-details-h3 {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
  margin-bottom: 24px;
}

.partners-details-about-cont hr {
  border-color: #e5e5e5;
  margin: 40px 0px !important;
}

.partners-details-pare {
  color: var(--color-text-neutral-secondary, #394046) !important;
  font-family: Inter;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.partners-details-pare div,
.partners-details-pare p,
.partners-details-pare span .partners-details-pare a,
.partners-details-pare h1,
.partners-details-pare h2,
.partners-details-pare h3,
.partners-details-pare h4,
.partners-details-pare h5,
.partners-details-pare h6 {
  color: var(--color-text-neutral-secondary, #394046) !important;
  font-size: 15px !important;
}

.partners-details-pare a {
  text-decoration-line: underline;
}

.partners-details-category h5 {
  display: inline-block;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin: 0px 4px 0px 0px;
  position: absolute;
  left: 0;
}

.partners-details-category {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 6px;
  align-self: stretch;
  flex-wrap: wrap;
}

.mds-about-partner-title {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-large);
  margin-bottom: 12px;
}

.partners-details-post-card-preferred {
  position: absolute;
  left: 0;
  bottom: -32px;
  width: 100%;
  border-radius: 0px 0px var(--radius-medium, 12px)
    var(--radius-medium, 12px);
  background: var(--color-bg-inverse-neutral, #041527);
  color: var(--color-text-inverse-screen, #fff);
  text-align: center;
  font: var(--title-x-small);
  padding: 8px;
}

.partners-details-post-card-exclusive {
  position: absolute;
  left: 0;
  bottom: -32px;
  width: 100%;
  border-radius: 0px 0px var(--radius-medium, 12px)
    var(--radius-medium, 12px);
  border-bottom: 1px solid rgba(6, 13, 20, 0.2);
  box-shadow: 0px 1px 1px 0px rgba(6, 13, 20, 0.12) inset;
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-2-x-small, 8px);
  color: #060d14;
  font-family: "Kaisei Opti", serif;
  background: radial-gradient(
        121.04% 676.11% at 56.94% -39.29%,
        #b1bacb 0%,
        #e7e7f0 25%,
        #f7f8f7 52.88%,
        #edeef0 65.1%,
        #d5d9e0 80.5%,
        #bebfcd 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        128.45% 387.63% at 65.28% -58.93%,
        #b1bacb 6.22%,
        #e7e7f0 25%,
        #f7f8f7 52.88%,
        #edeef0 65.1%,
        #d0d3d9 78.75%,
        #bec2cd 89.6%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      92.5deg,
      #b1bacb 0%,
      #e7e7f0 25%,
      #f7f8f7 52.08%,
      #b7bbbb 72.4%,
      #c0c4c4 100%
    );
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.partners-be {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin-right: 4px;
}

.partners-reviews-link {
  color: var(--color-text-neutral-strong, #060d14);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}

.partners-reviews-count {
  margin-right: 12px;
  display: flex;
  gap: 6px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium);
  align-items: center;
}

.partners-reviews-count .MuiSvgIcon-root {
  height: 16px !important;
  width: 16px !important;
}

.partners-reviews-count label {
  margin: 0px;
}

.partners-details-reviews {
  margin-bottom: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners-details-main-left-right {
  max-width: 1128px;
  width: 100%;
  margin: auto;
  position: relative;
  margin-top: -64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.partners-details-about-cont {
  max-width: 57.5%;
  width: 100%;
  margin: 104px 0px 40px 0px;
}

.partners-details-main {
  padding: 12px 32px 48px;
  width: 100%;
  margin: auto;
  position: relative;
}

.partners-details-popup-offer-details-loading {
  height: 145px;
  width: 100%;
}

.back-to-offers-link {
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 1;
  color: var(--color-bg-static-white);
  font: var(--body-small-bold);
  display: flex;
  align-items: center;
}

.back-to-offers-link:hover {
  color: var(--color-bg-static-white);
}

.back-to-offers-link img {
  margin-right: 8px;
  filter: invert(1000%) sepia(1000%) saturate(100%) hue-rotate(378deg)
    brightness(1000%) contrast(100%);
}

.partners-details-banner-img {
  height: 288px;
  width: 100%;
}

.partners-details-banner-img img {
  object-fit: cover;
  width: 100%;
  height: 288px;
  border-radius: var(--radius-medium, 12px);
}

.partners-details-banner {
  position: relative;
  max-width: 1128px;
  width: 100%;
  margin: auto;
}

.partners-details-banner:before {
  border-radius: var(--radius-medium, 12px);
  background-blend-mode: normal, normal, hue, normal, normal, normal;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0000005e;
}

.partners-details-post-card {
  border-radius: var(--radius-medium, 12px);
  box-shadow: var(--shadow-large);
  background: var(--color-bg-elevated, #fff);
  position: sticky;
  margin-right: 40px;
  padding: 24px 24px 48px;
  max-width: 32%;
  width: 100%;
  text-align: center;
  word-break: break-word;
  top: 100px;
  order: 2;
  height: fit-content;
}

.partners-details-post-card h3 {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.partners-details-post-card p div p {
  margin-bottom: 0px;
}

.partners-details-post-card p {
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin-bottom: 18px;
}

.partners-details-post-card p div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 60px;
}

.partners-details-post-card-logo {
  padding: 12px 0px 36px 0px;
  text-align: center;
}

.partners-details-post-card-logo img {
  height: 72px;
}

.get-details-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong);
  color: var(--color-text-static-white);
  font: var(--body-large-bold);
  padding: 12px 40px;
  display: inline-block;
}

.get-details-btn:hover {
  background: var(--color-bg-brand-strong-hovered);
  color: var(--color-text-static-white);
}

.any-category-could-post-img {
  width: 100%;
  position: absolute;
  height: 184px;
  border-radius: 12px;
  left: 0;
  top: 0;
  object-fit: cover;
}

.see-offers-btn {
  position: absolute;
  bottom: 20px;
  left: 16px;
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-static-white, #fff);
  color: var(--color-text-static-darkgray, #060d14);
  font: var(--body-large-bold);
  padding: 8px 16px;
  border: none;
  z-index: 1;
}

.see-offers-btn:hover {
  background: var(--color-bg-static-white-pressed);
}

.benefits-img-only {
  height: 261px !important;
}

.any-categoryh6 {
  color: var(--color-text-static-white, #fff);
  font: var(--body-smal);
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
  z-index: 1;
  position: relative;
}

.any-categoryh4 {
  color: var(--color-text-static-white, #fff);
  font: var(--title-small);
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 210px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 70px;
  word-wrap: break-word;
  z-index: 1;
  position: relative;
}

.any-category-could-slider-loading {
  gap: 24px;
  display: flex;
  padding: 24px 0px;
}

.any-category-could-slider-post-loading {
  height: 184px;
  width: 100%;
  border-radius: 12px;
}

.partners-have-an-awesome-loading {
  height: 272px;
  width: 100%;
  margin: 40px 0px;
  border-radius: 12px;
}

.any-category-could-post-left {
  padding: 16px;
  width: 100%;
  position: relative;
  height: 184px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  cursor: pointer;
}

.any-category-could-post {
  border-radius: 12px;
  background: var(--green-1000, #0a2019);
  display: flex !important;
  justify-content: space-between;
}

/* .slick-track .slick-slide:nth-child(2n+0) .any-category-could-post {
    background: var(--orange-1000, #2E1500);
}
.slick-track .slick-slide:nth-child(3n+0) .any-category-could-post {
    background: var(--light-blue-1000, #041027);
} */

.any-category-could-post:hover .any-category-could-post-right img {
  transform: scale(1.05);
}

.any-category-could-post-right {
  width: 35%;
  overflow: hidden;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.any-category-could-post-right img {
  height: 184px;
  object-fit: cover;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  transition: transform 0.2s;
}

.any-category-could-slider {
  padding: 24px 0px;
  margin: 0px -12px;
}

.any-categoty-title h3 {
  margin: 0px;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--title-medium);
}

.any-categoty-title {
  padding: 24px 0px;
  text-align: center;
}

.no-data-found-main {
  display: flex;
  text-align: center;
  height: 50vh;
  grid-column: 6/1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-data-found-main h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: var(--color-text-neutral-strong);
  margin: 12px 0px 0px;
}

.partners-explore-offers-banner-loading {
  height: 380px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 12px;
}

.partners-explore-offers-banner {
  margin-bottom: 24px;
  align-items: center;
  position: relative;
  border-radius: var(--radius-12);
  background: var(--light-blue-900);
  overflow: hidden;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
}

.partner-explore-offer-content {
  width: 100%;
  padding: 64px;
  color: var(--color-text-static-white);
  /* background: #0000005e; */
  min-height: 380px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.partner-explore-offer-img {
  width: 46.1%;
}

.partner-explore-offer-img img {
  object-fit: cover;
  width: 100%;
  height: 380px;
}

.partner-explore-offer-content h1 {
  margin-bottom: 16px;
  max-width: 600px;
}

.partner-explore-offer-content p {
  margin-bottom: 32px;
  max-width: 580px;
}

.partner-explore-offer-content h1 span {
  color: var(--light-blue-300);
}

.partners-meet-slider button.slick-arrow {
  top: 115px !important;
}

.partners-meet-slider .slick-slide {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.partners-meet-slider {
  padding: 0px 24px;
  margin: 0px -36px -20px -36px;
  /* height: 400px; */
  overflow: hidden;
}

.partner-slider-arrow-only .slick-disabled {
  display: none !important;
}

.partner-slider-arrow-only .slick-slide {
  padding: 0px 12px;
}

.partner-slider-arrow-only button.slick-arrow {
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  background: var(--color-bg-static-white, #fff);
  box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04),
    0px 4px 25px 0px rgba(4, 21, 39, 0.1);
  height: 36px;
  width: 36px;
  top: 93px;
}

.partner-slider-arrow-only button.slick-arrow:hover {
  border: 1px solid
    var(--color-border-neutral-tertiary-hovered, rgba(13, 13, 13, 0.3));
}

.partner-slider-arrow-only .slick-prev {
  left: -6px;
  z-index: 1;
}

.partner-slider-arrow-only .slick-next {
  right: -6px;
  z-index: 1;
}

.partner-slider-arrow-only .slick-arrow:before {
  display: none !important;
}

.partner-slider-arrow-only .slick-arrow:after {
  content: "";
  height: 20px;
  position: absolute;
  width: 20px;
  top: 7px;
  left: 7px;
}

.partner-slider-arrow-only .slick-prev:after {
  background: url(../Images/icn_m_chevron_left.svg);
}

.partner-slider-arrow-only .slick-next:after {
  background: url(../Images/icn_m_chevron_right.svg);
}

.partners-news-main {
  padding: 12px 32px 48px;
  max-width: 1192px;
  width: 100%;
  margin: auto;
}

.partners-have-an-awesome {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-brand-secondary);
  display: flex;
  padding: 48px;
  margin: 40px 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

.partners-have-an-awesome h2 {
  font: var(--title-large);
  color: var(--color-text-neutral-strong);
  max-width: 610px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
}

.partner-with-us-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong);
  color: var(--color-text-static-white);
  font: var(--body-large-bold);
  padding: 16px 28px;
}

.partner-with-us-btn:hover {
  background: var(--color-bg-brand-strong-hovered);
  color: var(--color-text-static-white);
}

.partners-get-exclusive {
  border-radius: var(--radius-medium, 12px);
  background: var(--light-blue-900, #112748);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.partners-get-exclusive-info {
  padding-left: 64px;
  max-width: 554px;
  color: var(--color-text-static-white);
}

.partners-get-exclusive-info h1 {
  font: var(--title-x-large);
  margin-bottom: 16px;
}

.partners-get-exclusive-image {
  padding-right: 54px;
}

.get-exclusive-cont {
  color: var(--color-text-static-white, #fff);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

.get-exclusive-cont-color {
  color: var(--light-blue-300, #bde3ff);
}

.partner-explore-offers-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-static-white);
  color: var(--color-text-static-darkgray) !important;
  font: var(--body-large-bold);
  padding: 16px 28px;
  display: inline-block;
}

.partner-explore-offers-btn:hover {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-static-white-pressed);
  color: var(--color-text-static-darkgray) !important;
}

.partners-title-sub {
  display: flex;
  padding: var(--spacing-space-x-large, 24px)
    var(--spacing-space-2-x-large, 32px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-space-small, 12px);
  align-self: stretch;
  text-align: center;
}

.partners-title-sub {
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.partners-title-sub h2 {
  color: var(--color-text-neutral-strong);
  font: var(--title-large);
  margin-bottom: 0px;
}

.partners-title-sub p {
  color: var(--color-text-neutral-secondary);
  font: var(--body-large);
  margin-bottom: 0px;
}

.parners-link-all {
  color: var(--color-text-brand-strong, #0b42cf);
  font: var(--body-large-bold);
}

.partners-browse-all-box {
  border-radius: var(--radius-medium, 12px);
  border: 1px solid var(--color-border-neutral-secondary);
  display: flex;
  padding: var(--spacing-space-x-large, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-medium, 16px);
  flex: 1 0 0;
  transition: transform 0.2s;
  min-height: 352px;
}

.partners-browse-all-box:hover {
  border-radius: var(--radius-medium, 12px);
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
  cursor: pointer;
}

.partners-browse-all-box h3 {
  color: var(--color-text-neutral-strong);
  font: var(--title-medium);
  margin-bottom: 0px;
  text-align: center;
}

.browse-all-link {
  color: var(--color-text-brand-strong, #0b42cf);
  font: var(--body-large-bold);
}

.partners-more-benefits .mds-partners-cont-post-img {
  aspect-ratio: 12/9;
}

.mds-partners {
  padding: 24px 32px;
}

.mds-partners-title {
  font-family: "Figtree", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.01em;
  text-align: left;
  color: var(--color-text-neutral-strong);
  margin-bottom: 24px;
}

.mds-partners-head {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mds-partners-cont {
    grid-gap: 24px 16px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    display: grid;
    padding: 24px 0px;
}

.mds-partners-cont-post-img {
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--color-bg-static-white-pressed);
    transform: translateZ(0);
}

.partners-more-offer .mds-partners-cont-post-img img {
    /* height: 168px; */
    aspect-ratio: 16 / 9;
}
.mds-partners-cont-post-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s;
}

.mds-partners-cont-post-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.mds-partners-head-left {
  display: flex;
  gap: 24px;
  align-items: center;
}

.cstm-part-btn {
  background: var(--color-bg-neutral-secondary);
  border-radius: 10px;
  padding: 2px;
}

.cstm-part-btn button {
  font-family: "Figtree", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 16px;
  border: none;
  color: var(--color-text-neutral-strong);
  background: transparent;
  border-radius: 10px;
}

.cstm-part-btn button:active {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

button.active-partbtn {
  box-shadow: 0px 2px 6px 0px #04152733;
  background: var(--color-bg-static-white) !important;
  color: var(--color-text-static-darkgray);
  border-radius: 10px;
  font-weight: 700;
}

.cstm-search-part-input {
  border: 1px solid var(--color-border-neutral-strong) !important;
  height: 40px !important;
  border-radius: 120px !important;
  width: 400px !important;
  background: var(--color-bg-static-white) !important;
  padding: 10px 56px 10px 16px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  font-family: "Figtree", sans-serif !important;
  color: #000 !important;
}

.cstm-search-part-input:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered) !important;
}

.cstm-search-part-input:focus {
  border: 2px solid var(--color-border-inverse-neutral) !important;
}

.cstm-search-part {
  position: relative;
}

img.cstm-search-part-icon {
  position: absolute;
  right: 16px;
  top: 8px;
  cursor: pointer;
}

.cstm-all-categories-select {
  margin-left: -8px;
}

select.cstm-all-categories-select-label {
  background: transparent url(../Images/icn_m_chevron_down.svg);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Figtree", sans-serif !important;
  color: var(--color-text-neutral-strong);
  padding: 0px 26px 0px 8px;
  height: auto;
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

select.cstm-sort-by-select {
  background: transparent url(../Images/icn_m_chevron_down.svg);
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Figtree", sans-serif !important;
  color: var(--color-text-neutral-strong);
  padding: 0px 26px 0px 8px;
  height: auto;
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

.cstm-parter-label {
  position: absolute;
  border-radius: 0px 120px 120px 0px;
  top: 12px;
  left: 1px;
  background: var(--color-bg-static-black);
  height: 28px;
  color: var(--color-text-static-white);
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Figtree", sans-serif;
  z-index: 1;
}

.mds-partners-cont-post {
    position: relative;
    height: fit-content;
}
.mds-partners-cont-post a {
    outline: none !important;
}
.mds-partners-cont-post a::after {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border-radius: var(--radius-14);
    display: flex;
    flex-direction: column;
    border: 2px solid var(--color-border-inverse-neutral);
    opacity: 0;
    visibility: hidden;
}
.mds-partners-cont-post a:focus-visible::after {
    opacity: 1;
    visibility: visible;
}
.mds-partners-cont-post-title {
  position: relative;
  padding-right: 70px;
  margin-bottom: 4px;
}

.mds-partners-cont-post-title h3 {
  margin: 0px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-text-neutral-strong);
  font: var(--body-large);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.mds--partnes-new {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: var(--color-text-neutral-strong);
}

.cstm-partnesh5 {
  font: var(--body-small);
  text-align: left;
  margin-bottom: 4px;
  color: var(--color-text-neutral-secondary);
}

.cstm-partnesh6 {
  margin-bottom: var(--spacing-12);
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

/* .cstm-partnesh6 div p {
    margin: 0px;
}

.cstm-partnesh6 div {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--color-text-neutral-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 60px;
} */

.cstm-exclusive-label {
  position: absolute;
  border-radius: 0px 120px 120px 0px;
  top: 12px;
  left: 1px;
  height: 28px;
  gap: 6px;
  display: flex;
  color: var(--color-text-static-darkgray);
  text-transform: capitalize;
  padding: 5px 12px 5px 12px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Kaisei Opti", serif;
  background: radial-gradient(
        121.04% 676.11% at 56.94% -39.29%,
        #b1bacb 0%,
        #e7e7f0 25%,
        #f7f8f7 52.88%,
        #edeef0 65.1%,
        #d5d9e0 80.5%,
        #bebfcd 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        128.45% 387.63% at 65.28% -58.93%,
        #b1bacb 6.22%,
        #e7e7f0 25%,
        #f7f8f7 52.88%,
        #edeef0 65.1%,
        #d0d3d9 78.75%,
        #bec2cd 89.6%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      92.5deg,
      #b1bacb 0%,
      #e7e7f0 25%,
      #f7f8f7 52.08%,
      #b7bbbb 72.4%,
      #c0c4c4 100%
    );
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.cstm-exclusive-label img {
  top: 0.5px;
  position: relative;
}

.mds-partners-cont-post-img2-loading {
  border-radius: 12px;
  margin-bottom: 16px;
  aspect-ratio: 12/9;
  width: 100%;
}

.mds-partners-cont-loading {
  grid-gap: 40px 24px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  display: grid;
  padding: 24px 0;
}

.mds-partners-cont-post-loading {
  position: relative;
}

.mds-partners-cont-post-img-loading {
  border-radius: 12px;
  margin-bottom: 16px;
  aspect-ratio: 16/8;
  width: 100%;
}

.mds-partners-cont-post-title-loading {
  width: 100%;
  height: 25px;
}

.cstm-partnesh5-loading {
  width: 35%;
  height: 15px;
}

.cstm-partnesh6-loading-loading {
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
}

.no-data-found-partner {
  text-align: center;
  font-size: 18px;
  color: red;
  margin-top: 200px;
}

.unlock-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  max-width: 480px;
  margin: auto;
}

.btn-unlock-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.btn-unlock-section .btn-review {
  min-width: 198px;
  cursor: pointer;
}

.btn-unlock-section .partners-details-btn-main {
  min-width: 198px;
  padding: 11px 24px;
  cursor: pointer;
}

.message-us-link {
  color: var(--color-text-brand-strong, #0b42cf) !important;
  font-weight: 700;
  cursor: pointer;
}

.select-partners-popup-main {
  max-width: 850px;
  position: relative;
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  overflow: inherit;
  height: calc(100vh - 60px);
  max-height: 720px;
}

.select-partners-pp-only h3 {
  text-align: center;
  width: 100%;
}

.select-partners-pp-icon-bank {
  position: absolute;
  left: 12px;
  cursor: pointer;
}

.select-partners-pp-icon-bank img:hover {
  filter: invert(68%) sepia(103%) saturate(9999%) hue-rotate(211deg)
    brightness(95%) contrast(190%);
}

.btn-footer-pp-only {
  border-top: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
}

.select-partners-pp-only {
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
  display: flex;
  padding: 20px 24px;
  align-items: center;
  gap: 10px;
}

.never-used-text {
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-medium);
  margin: 0px;
  text-align: center;
  width: 100%;
}

.disabled-btn {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-disabled-strong, #dadde0);
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  flex: 1 0;
  width: 100%;
  color: var(--color-text-disabled-onstrong, #84898f);
  text-align: center;
  font: var(--body-large-bold);
  border: none;
  pointer-events: none;
  position: relative;
}

.btn-pick-more {
  border-radius: var(--radius-full, 120px);
  background: var(--color-bg-brand-strong, #0b42cf);
  color: var(--color-text-onbrand-strong, #fff);
  display: flex;
  padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-6, 6px);
  flex: 1 0;
  width: 100%;
  text-align: center;
  font: var(--body-large-bold);
  border: none;
  position: relative;
}

.btn-pick-more:hover {
  background: var(--color-bg-brand-strong-hovered);
  color: var(--color-text-static-white);
}

.btn-pick-more:active {
  background: var(--color-bg-brand-strong-pressed, #052d93);
  color: var(--color-text-onbrand-strong, #fff);
}

.never-used-text a {
  color: var(--color-text-brand-strong, #0b42cf) !important;
  font-weight: 700;
}

.select-partners-pp-search-field {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  position: relative;
}

.select-partners-pp-search-img {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  overflow: auto;
  padding: 0px 24px 24px 24px;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  height: 466px;
  align-content: flex-start;
}

.select-partners-pp-search-img::-webkit-scrollbar {
  width: 6px;
}

.select-partners-pp-search-img::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary);
}

.select-partners-pp-search-img::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary);
  border-radius: 10px;
}

.select-partners-pp-search-img::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.select-partners-pp-search-img-in {
  cursor: pointer;
  position: relative;
  background: var(--color-bg-neutral-secondary, #eaedf1);
  border-radius: var(--radius-medium, 12px);
  aspect-ratio: 16/7.6;
}

.select-partners-pp-search-img-in img {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-medium, 12px);
  object-fit: cover;
}

.select-active {
  border: 2px solid var(--color-border-inverse-neutral, #0d0d0d);
  border-radius: 13px !important;
}

.select-active:before {
  border-radius: var(--radius-medium, 12px);
  border: 2px solid var(--color-border-inverse-screen, #fff);
  background: var(--color-bg-blackout-secondary, rgba(13, 13, 13, 0.5));
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

.select-partners-pp-search-img-in:hover:before {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-blackout-tertiary, rgba(13, 13, 13, 0.1));
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

.select-active:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  background: url(../Images/checkbox-select.svg);
  right: 8px;
  top: 8px;
}

.select-partners-pp-search-field input {
  display: flex;
  padding: 8px 56px 8px 16px;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.3));
  background-color: var(--color-bg-static-white);
  color: var(--color-text-neutral-strong, #060d14);
  height: 40px;
}

.select-partners-pp-search-field input:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered) !important;
}

.select-partners-pp-search-field input:focus {
  border: 2px solid var(--color-border-inverse-neutral) !important;
  padding-top: 7px;
  padding-bottom: 7px;
}

img.search-icon-field {
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 32px;
}

img.search-icon-cloce {
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 33px;
}

.select-partners-pp-search-no-data {
  height: 466px;
  display: flex;
  width: 850px;
  padding: var(--spacing-48, 48px) 0px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-24, 24px);
  justify-content: center;
  overflow: auto;
  margin-top: -24px;
}

.select-partners-pp-search-no-data::-webkit-scrollbar {
  width: 6px;
}

.select-partners-pp-search-no-data::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary);
}

.select-partners-pp-search-no-data::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary);
  border-radius: 10px;
}

.select-partners-pp-search-no-data::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.unlock-partners-icon {
  display: table;
  height: 120px;
  width: 100%;
  text-align: center;
}

.cstm-search-no-data-icon {
  display: table;
  height: 120px;
  width: 100%;
  text-align: center;
}

.cstm-oops-ttile {
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--title-small);
}

.cstm-please-adjust-sub-title {
  margin: 0px;
  color: var(--color-text-neutral-secondary, #394046);
  text-align: center;
  font: var(--body-small);
}

.leave-review-popup-main {
  max-width: 648px;
  position: relative;
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  overflow: inherit;
}

.leave-review-offer {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid
    var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

.leave-review-offer-left {
  min-width: 154px;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-neutral-secondary, #eaedf1);
}

.leave-review-offer-left img {
  width: 154px;
  height: 72px;
  border-radius: var(--radius-medium, 12px);
  object-fit: cover;
}

.leave-review-offer-clickup {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--color-text-neutral-secondary, #394046);
  font: var(--body-small);
  margin: 1px 0px;
}

h5.leave-review-offer-title {
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-overflow: ellipsis;
  font: var(--title-x-small);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  margin: 0px;
}

.leave-review-offer-right {
  word-break: break-all;
}

.leave-review-offer-rating-only {
  overflow: auto;
  max-height: calc(100vh - 330px);
}

.leave-review-offer-rating-only::-webkit-scrollbar {
  width: 6px;
}

.leave-review-offer-rating-only::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary);
}

.leave-review-offer-rating-only::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary);
  border-radius: 10px;
}

.leave-review-offer-rating-only::-webkit-scrollbar-thumb {
  background: var(--color-bg-neutral-tertiary-hovered);
  border-radius: 10px;
}

.continue-loaing-btn {
  position: absolute;
  background: var(--color-text-onbrand-secondary);
  width: 100%;
  left: 0;
  height: 100%;
  top: 0px;
}

.continue-loaing-btn img {
  height: 48px;
}

.checkbox-container label {
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin-bottom: 0;
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.checkbox-container input {
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox-container label > span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  border: 2px solid var(--color-border-neutral-strong);
  opacity: 0;
  margin-right: 0px;
  border-radius: var(--radius-4);
  opacity: 0.3;
  transition: all 0.3s;
}

.checkbox-container input:checked + label > span {
  border: 10px solid var(--color-bg-brand-strong);
  opacity: 1;
}

.checkbox-container input + label > span::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transform: rotate(45deg);
  border-color: #fff;
  opacity: 0;
}

.checkbox-container input:checked + label > span::before {
  opacity: 1;
}

.parters-content-pg-main {
    width: 100%;
    padding: 0px var(--spacing-24) var(--spacing-12);
    margin: auto;
    /* max-width: 1536px; */
}

.parters-header-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  padding-top: 12px;
  width: 100%;
}

.parters-header-main-top {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
}

.parters-header-main-bottom {
  display: flex;
  padding: var(--spacing-8, 8px) 0px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

.parters-header-main-bottom-left {
  display: flex;
  align-items: center;
  gap: var(--spacing-24, 24px);
}

.partners-details-about-cont-title {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-32, 32px);
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 24px;
}

.partners-details-about-cont-title-right {
  display: flex;
  gap: 24px;
  align-items: center;
}

.partners-visit-website {
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-small);
  text-decoration-line: underline;
  color: var(--color-text-neutral-strong, #060d14);
}

.partners-visit-website:hover {
  text-decoration: inherit;
  color: var(--color-bg-brand-strong);
}

.partners-visit-website img {
  margin-left: 4px;
}

.partner-list-search-suggestion {
  max-width: 400px;
  width: 100%;
  position: relative;
}

.partner-tags-hashtag {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 12px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 24px;
}

/* dropdown suggestion */

.cstm-cl-search-sug-common {
  position: absolute;
  padding: 12px 0;
  width: 100%;
  z-index: 2;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-elevated, #fff);
  box-shadow: var(--shadow-small);
  margin-top: 4px;
}

.cstm-cl-search-sug-common {
  max-height: 420px;
  overflow: auto;
}

.cstm-cl-search-sug-common::-webkit-scrollbar {
  width: 6px;
}

.cstm-cl-search-sug-common::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--color-bg-neutral-tertiary-hovered);
}

.cstm-cl-search-sug-common::-webkit-scrollbar-thumb {
  background: var(--color-border-neutral-secondary);
  border-radius: 10px;
}

.cstm-cl-search-sug-common ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.cstm-cl-search-sug-common ul li {
  padding: 10px 16px;
  cursor: pointer;
}

.cstm-cl-search-sug-common ul li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.cstm-cl-search-sug-history-common {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.cstm-cl-search-sug-history-remove-common {
  color: var(--color-text-brand-strong, #0b42cf);
  float: right;
  align-items: center;
  display: flex;
  padding: 0px;
  border: none;
  background: transparent;
  font: var(--body-medium);
  line-height: 20px;
}

.cstm-cl-search-sug-history-remove-common:hover {
  color: #000 !important;
}

.cstm-cl-search-sug-history-title-common {
  overflow-wrap: anywhere;
  color: var(--color-text-neutral-strong, #060d14);
  width: 100%;
  padding-right: 50px;
  font: var(--body-medium);
  line-height: 20px;
}

.cstm-cl-search-sug-history-title-common b {
  font-weight: 700;
}

/* PartnerInfo Modal CSS */
.offer-details-modal hr.line-pd-od {
  margin: 24px 0 !important;
}
