.stopRecording-sec {
  padding: var(--spacing-2);
  width: fit-content;
  border-radius: var(--radius-full);
}

.stopRecording-sec .stopRecordingButton {
  padding: var(--spacing-4);
  border-radius: var(--radius-full);
  background-color: var(--color-bg-brand-strong);
  position: relative;
  border: none;
  cursor: pointer;
}

.stopRecording-sec .stopRecordingButton:hover {
  background-color: var(--color-bg-brand-strong-hovered);
}
.stopRecording-sec .stopRecordingButton:active {
  background-color: var(--color-bg-brand-strong-pressed);
}

.stopRecording-sec .stopRecordingButton:focus {
  outline-offset: 2px;
  outline: 2px solid var(--color-border-inverse-neutral);
}

.stopRecording-sec .stopRecordingButton.disabled {
  background: var(--color-bg-disabled-strong);
}

.stopRecording-sec .stopRecordingButton.disabled svg path {
  fill: var(--color-icon-disabled-strong);
}
.stopRecording-sec .stopRecordingButton .tool-tip,
.stopRecording-sec .stopRecordingButton .spinner {
  display: none;
}

.stopRecording-sec .stopRecordingButton.loading svg {
  display: none;
}
.stopRecording-sec .stopRecordingButton.loading .spinner {
  display: block;
}

/* Tooltip*/
.stopRecording-sec .stopRecordingButton .tool-tip {
  position: absolute;
  top: calc(-100% - 4px);
  transition: 0.3s all;
}
.stopRecording-sec .stopRecordingButton:hover .tool-tip{
  display: block;
}

.stopRecording-sec .stopRecordingButton:hover .tool-tip .mds-tooltip{
  width: max-content;
}
.stopRecording-sec .stopRecordingButton.disabled .tool-tip .mds-tooltip,
.stopRecording-sec .stopRecordingButton.loading .tool-tip .mds-tooltip{
  display: none;
}
