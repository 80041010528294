.mark-btn-watched-news {
  margin-bottom: 15px;
}
.mark-btn-watched-news .share-btn {
  padding: 5px 20px;
  font-size: 16px;
  height: 32px;
}
.cstm-news-banner {
  margin-right: auto;
  border-radius: 12px;
  margin-bottom: 24px;
  margin-left: auto;
  max-width: 1472px;
}

.newsmanagement-main {
  padding: 0px 100px;
}

.cstm-news-banner .owl-stage-outer::after {
  display: none;
}

.cstm-news-banner .owl-stage-outer {
  margin: 0px !important;
  border-radius: 12px;
}

.cstm-news-banner .owl-carousel .owl-item img {
  height: 380px;
  border-radius: 12px;
  object-fit: cover;
}

.cstm-news-banner .owl-nav {
  z-index: 1;
}

.cstm-news-banner .owl-nav {
  position: absolute;
  z-index: 1;
  bottom: 32px;
  right: 32px;
  width: auto;
  top: inherit;
}

.cstm-news-banner .owl-nav button {
  background: #ffffff33 !important;
  height: 48px !important;
  width: 48px !important;
  border-radius: 50px;
  background-position: 50% !important;
}

.cstm-news-banner .owl-nav button:hover {
  background: #351bce !important;
  background-position: 50% !important;
}

.cstm-news-banner .owl-nav button.owl-next {
  margin-left: 12px;
}

.cstm-news-banner .owl-nav button.owl-prev {
  background-image: url(../Images/arrow-left-news.png) !important;
  background-repeat: no-repeat !important;
  height: 24px;
  width: 24px;
}

.cstm-news-banner .owl-nav button.owl-next {
  background-image: url(../Images/arrow-right-news.png) !important;
  background-repeat: no-repeat !important;
  height: 24px;
  width: 24px;
}

.trending-posts-main-all {
  max-width: 648px;
  width: 100%;
  margin: 0px auto;
  padding: 24px 0px;
}

.trending-posts-title {
  font-family: "Figtree", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #060d14;
  margin-bottom: 4px;
}

.trending-posts-date {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0px;
  color: #071018cc;
}

.trending-posts-main-box {
  padding: 24px 48px 24px 40px;
  /* border-bottom: 1px solid #3A536B1A; */
  position: relative;
  transition: all 0.5s ease;
}
.trending-posts-main-box:hover {
  border-radius: var(--radius-medium, 12px);
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
  cursor: pointer;
  padding: 24px 72px 24px 24px;
}

.trending-posts-main-box:hover img.trending-posts-link {
  right: 24px;
}

.trending-posts-main .trending-posts-main-box:last-child {
  border-bottom: 0px;
}

img.trending-posts-icon {
  position: absolute;
  left: 0;
  top: 24px;
  transition: 0.5s;
}
.trending-posts-main-box:hover img.trending-posts-icon {
  opacity: 0;
  left: 15px;
}

img.trending-posts-link {
  position: absolute;
  right: 0;
  top: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
}
img.trending-posts-link:hover {
  filter: invert(0.2) sepia(10) saturate(350) hue-rotate(-115deg);
}

.trending-posts-sub-title {
  margin-bottom: 4px;
  font: var(--body-large);
  font-weight: 600;
  color: var(--color-text-neutral-strong);
  text-align: left;
}

h6.trending-posts-sub-label {
  font: var(--body-small);
  text-align: left;
  color: #060d14bf;
  margin-bottom: 0px;
}

.trending-posts-main {
  padding: 24px 0px 48px 0px;
}

.cstm-news-banner-loading {
  height: 380px;
  width: 100%;
  border-radius: 12px;
  max-width: 1472px;
  margin: auto;
  display: block;
}

.trending-posts-main-box-loading {
  width: 100%;
  margin: 24px 0px;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 24px;
}

.trending-posts-sub-title-loading {
  height: 50px;
  width: 100%;
}

.trending-posts-sub-label-loading {
  height: 20px;
  width: 30%;
}

.news-updates-details {
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
}

.news-updates-details-box {
  box-shadow: 0px 10px 60px 0px #0415271a;
  width: 47.5%;
  margin-bottom: 32px;
  border-radius: 12px;
  position: relative;
  transition: transform 0.8s;
}
.news-updates-details-box-img {
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.news-updates-details-box-img img {
  width: 100%;
  height: 173px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: transform 0.8s;
}
.news-updates-details-box:hover .news-updates-details-box-img img {
  transform: scale(1.05);
}

.news-updates-details-box-title {
  font: var(--title-x-small);
  padding: 24px 24px 0px;
  margin-bottom: 10px;
  text-align: left;
  color: #060d14;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  height: 146px;
}

.news-updates-details-box-date {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #071018cc;
  padding: 0px 24px 24px;
}

.news-icon-link {
  position: absolute;
  bottom: 24px;
  right: 24px;
  cursor: pointer;
}

.news-updates-top {
  box-shadow: 0px 10px 60px 0px #0415271a;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px;
  border-radius: 12px;
  position: relative;
  transition: transform 0.2s;
}
.news-updates-top-img {
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.news-updates-top-img img {
  width: 100%;
  height: 338px;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: transform 0.8s;
}
.news-updates-top:hover .news-updates-top-img img {
  transform: scale(1.03);
}

.news-updates-top-title {
  font-family: "Figtree", sans-serif;
  padding: 24px 24px 0px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14;
}

.news-updates-top-date {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14bf;
  padding: 0px 24px 24px;
}

.news-top-icon-link {
  position: absolute;
  bottom: 24px;
  right: 24px;
  cursor: pointer;
}

.news-updates-btn {
  text-align: center;
  display: block;
  width: 100%;
  padding: 16px 0px 22px;
}

.show-more-news-btn {
  margin: auto;
  border-radius: var(--radius-full, 120px);
  border: 1px solid var(--color-border-neutral-strong);
  padding: 12px 24px;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  font: var(--body-large-bold);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  outline: none !important;
  background: var(--color-bg-elevated, #fff);
}

.show-more-news-btn:hover {
  border: 1px solid var(--color-border-neutral-strong-hovered);
  color: var(--color-text-neutral-strong, #060d14);
}

.news-updates-top-img-loading {
  height: 362px;
  width: 100%;
  margin-top: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.news-updates-top-title-loading {
  height: 45px;
  width: 100%;
}

.news-updates-top-date-loading {
  height: 20px;
  width: 20%;
  margin-bottom: 24px;
}

.cstm-updates-details-loading {
  display: flex;
  gap: 5%;
}

.cstm-updates-details-box-loading {
  width: 47.5%;
}

.cstm-updates-details-box-img-loading {
  width: 100%;
  height: 163px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.cstm-updates-details-box-title-loading {
  width: 100%;
  height: 45px;
}

.cstm-updates-details-box-date-loading {
  width: 42%;
  height: 20px;
}
.news-details-main {
  max-width: 680px;
  width: 100%;
  margin: 0px auto;
  padding: 24px 16px;
}
.news-details-pg-title {
  font-family: "Figtree", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 16px;
  color: #060d14;
}

.news-details-pg-banner img {
  border-radius: 12px;
  margin: 24px -18%;
  max-width: 135.9%;
  height: 496px;
  object-fit: cover;
}

.news-details-pg-line {
  border-top: 1px solid #3a536b1a;
  margin: 40px 0px;
}

.news-details-pg-h3 {
  font-family: "Figtree", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14;
  margin-bottom: 36px;
}

.news-details-main-list ul {
  padding: 0px;
  list-style: none;
  margin: 0px;
}

.news-details-main-list ul li {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
  cursor: pointer;
}
.news-details-main-list-img {
  overflow: hidden;
  border-radius: 12px;
  min-width: 224px;
  height: 128px;
  transition: transform 0.2s;
}
.news-details-main-list ul li:hover .news-details-main-list-img img {
  transform: scale(1.03);
}

.news-details-main-list-img img {
  min-width: 224px;
  height: 128px;
  border-radius: 12px;
  object-fit: cover;
  transition: transform 0.8s;
}

.news-details-main-sub-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14;
  margin-bottom: 24px;
}

.news-details-main-list-info h4 {
  font: var(--title-x-small);
  text-align: left;
  margin-bottom: 7px;
  color: var(--color-text-neutral-strong);
}
.news-details-main-list-info h4 a {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  color: var(--color-text-neutral-strong);
}
.news-details-pg-date {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14bf;
  margin-bottom: 14px;
}
.news-details-pg-date img {
  vertical-align: -4px;
  margin-right: 2px;
}

.news-details-test-edit {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14;
}

.news-details-test-edit img {
  border-radius: 12px;
  margin-bottom: 24px;
}

.news-details-main-list {
  margin-bottom: 92px;
}

.news-details-main-newsroom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  border-radius: 12px;
  padding: 32px 24px;
  margin-bottom: 24px;
}
.news-details-main-newsroom:hover {
  border: 1px solid
    var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.5));
  cursor: pointer;
}

.newsroom-icon {
  margin-bottom: 8px;
}

.newsroom-title {
  font-family: "Figtree", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #060d14;
  margin-bottom: 4px;
}

.newsroom-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #060d14;
  margin-bottom: 12px;
}

.newsroom-read-more {
  margin: 0px;
}

.newsroom-read-more a {
  font-family: "Figtree", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #143fd9;
  margin: 0px;
}

.newsroom-read-more a:hover {
  text-decoration: inherit;
  color: var(--yellow);
}

.cstm-news-details-date-loading {
  height: 20px;
  width: 100px;
}

.cstm-news-details-title-loading {
  height: 50px;
  width: 100%;
}

.cstm-news-details-image-loading {
  height: 400px;
  width: 100%;
}

.cstm-news-details-content-loading {
  height: 20px;
  width: 100%;
}

.news-details-main-list-loading {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 24px;
}

.news-details-main-list-left-loading {
  width: 35%;
  height: 128px;
  border-radius: 12px;
}

.news-details-main-list-right-loading {
  width: 65%;
}

.news-details-main-list-right-title-loading {
  width: 100%;
  height: 45px;
}

.news-details-main-list-right-date-loading {
  width: 100px;
  height: 20px;
}

.cstm-news-banner .slick-slide img {
    /* height: 380px;  */
    width: 100%;
    object-fit: cover;
    object-position: center;
} 
.cstm-news-banner .owl-nav {
  position: absolute;
  z-index: 1;
  bottom: 32px;
  right: 32px;
  width: auto;
  top: inherit;
}
.cstm-news-banner .slick-slider button.slick-arrow {
  background: #ffffff33 !important;
  height: 48px !important;
  width: 48px !important;
  border-radius: 50px;
  background-position: 50% !important;
  z-index: 1;
  transform: inherit;
  bottom: 32px !important;
}
.cstm-news-banner .slick-slider button.slick-arrow:hover {
  background: #351bce !important;
  background-position: 50% !important;
  color: #fff;
}
.cstm-news-banner .slick-slider button.slick-prev {
  background-image: url(../Images/arrow-left-news.png) !important;
  background-repeat: no-repeat !important;
  right: 92px;
  top: inherit;
  left: inherit;
}
.cstm-news-banner .slick-slider button.slick-prev:hover {
  background-image: url(../Images/arrow-left-news.png) !important;
  background-repeat: no-repeat !important;
}
.cstm-news-banner .slick-slider button.slick-next {
  background-image: url(../Images/arrow-right-news.png) !important;
  background-repeat: no-repeat !important;
  right: 32px;
  top: inherit;
}
.cstm-news-banner .slick-slider button.slick-next:hover {
  background-image: url(../Images/arrow-right-news.png) !important;
  background-repeat: no-repeat !important;
}
.cstm-news-banner .slick-slider button.slick-arrow:before {
  display: none !important;
}

.cstm-news-banner .slick-list {
  border-radius: 12px;
}
.modal-close-btn {
  position: absolute;
  top: 12px;
  right: 12px;
}
.refer-link-group .text-field-search,
.refer-link-group .text-field-main {
  max-width: 100%;
}
.refer-link-group textarea,
.refer-link-group .text-field-search {
  padding: var(--spacing-10) var(--spacing-16) !important;
  font: var(--body-small);
  color: var(--color-text-neutral-strong);
}
.refer-link-group .text-field-search::placeholder {
  color: var(--color-text-neutral-tertiary);
}
.refer-link-group .text-field-search {
  padding-right: var(--spacing-48) !important;
  width: 100%;
  border-radius: var(--radius-8) !important;
}
.select-input-box .MuiTextField-root .MuiFilledInput-root {
  border: 1px solid var(--color-border-neutral-strong);
  padding: var(--spacing-4) var(--spacing-4);
  border-radius: var(--radius-8);
  background-color: transparent;
  min-height: 128px;
  max-height: 540px;
  overflow: auto;
  align-items: flex-start;
  align-content: flex-start;
}
.select-input-box .MuiTextField-root .MuiFilledInput-root::-webkit-scrollbar {
  width: 14px;
}

.select-input-box
  .MuiTextField-root
  .MuiFilledInput-root::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3);
  border-radius: var(--radius-x-small, 8px);
  border: 4px solid var(--color-border-inverse-screen);
  min-height: 64px;
}

.select-input-box
  .MuiTextField-root
  .MuiFilledInput-root::-webkit-scrollbar-thumb:hover {
  background: rgba(4, 21, 39, 0.3);
  cursor: pointer;
}

.select-input-box .MuiTextField-root .MuiFilledInput-root input {
  font: var(--body-small);
  flex-shrink: 0;
  min-width: 6%;
  padding: 5px 6px;
}
.select-input-box .MuiTextField-root .MuiFilledInput-root::after,
.select-input-box .MuiTextField-root .MuiFilledInput-root::before {
  display: none;
}
