.chat-messagelink-inner {
  padding: var(--spacing-4) 0;
}

.chat-messagelink-wrapper {
  padding-left: var(--spacing-10);
}

.chat-messagelink-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--spacing-2);
  height: 100%;
  background: var(--color-bg-info-strong);
  border-radius: var(--radius-4) 0 0 var(--radius-4);
}

.message-link-content {
  padding-bottom: var(--spacing-4);
}

.message-link-content.meta-src-notExist {
  padding-bottom: 0;
}

.message-link-content p {
  margin: 0;
}

.message-link-content p.metaTitle {
  font: var(--chat-emphasized);
}

.message-link-content p.metaShortDescription {
  font: var(--chat-reglular);
}

.message-link-content p.metaTitle,
.message-link-content p.metaShortDescription {
  color: var(--color-text-neutral-strong);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-link-content p.metaDescription {
  font: var(--body-x-small);
  color: var(--color-text-neutral-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.message-link-img-container {
  padding: var(--spacing-4) 0;
}

.message-link-img {
  padding: var(--spacing-4);
  overflow: hidden;
  background: var(--slate-1000);
  border-radius: var(--radius-4);
  max-width: 406px;
  max-height: 232px;
}

.message-link-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 398px;
  max-height: 232px;
}

.chat-message-bubble-inner.chat-message-link .chat-message {
  padding-bottom: var(--spacing-32);
}

/* incoming */

.incoming .chat-metaLink {
  color: var(--color-text-info-strong);
}

/* Outgoing */

.outgoing .chat-messagelink-wrapper::before {
  background: var(--light-blue-400);
}

.outgoing .message-link-content p.metaTitle,
.outgoing .message-link-content p.metaShortDescription,
.outgoing .chat-metaLink {
  color: var(--color-text-brand-onstrong);
}

.outgoing .message-link-content p.metaDescription {
  color: var(--color-text-brand-onsecondary);
}

/* styles.css */
.see-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-weight: bold !important;
  font: var(--chat-reglular);
  padding: 0px;
  color: var(--color-text-neutral-strong);
}

.see-button:hover {
  /* color: blue; */
  text-decoration: underline;
}

.outgoing .see-button {
  color: var(--color-text-static-white);
}

.outgoing .see-button:hover {
  /* color: blue!important; */
  text-decoration: underline !important;
}

/* Skeleton Box */
.link-skeleton.skeleton-box {
  width: 100%;
  min-width: 398px;
  height: 250px;
  background-color: #ffffff50;
  border-radius: 12px;
}