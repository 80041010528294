.tableHeadSort{
cursor: pointer ;
}

.tableHeadNoSort{
    pointer-events: none; 
}

.width-1 {
    width: 1%;
}

.width-2 {
    width: 2%;
}

.width-3 {
    width: 3%;
}

.width-4 {
    width: 4%;
}

.width-5 {
    width: 5%;
}

.width-6 {
    width: 6%;
}

.width-7 {
    width: 7%;
}

.width-8 {
    width: 8%;
}

.width-9 {
    width: 9%;
}

.width-10 {
    width: 10%;
}

.width-11 {
    width: 11%;
}

.width-12 {
    width: 12%;
}

.width-13 {
    width: 13%;
}

.width-14 {
    width: 14%;
}

.width-15 {
    width: 15%;
}

.width-16 {
    width: 16%;
}

.width-17 {
    width: 17%;
}

.width-18 {
    width: 18%;
}

.width-19 {
    width: 19%;
}

.width-20 {
    width: 20%;
}

.width-21 {
    width: 21%;
}

.width-22 {
    width: 22%;
}

.width-23 {
    width: 23%;
}

.width-24 {
    width: 24%;
}

.width-25 {
    width: 25%;
}

.width-26 {
    width: 26%;
}

.width-27 {
    width: 27%;
}

.width-28 {
    width: 28%;
}

.width-29 {
    width: 29%;
}

.width-30 {
    width: 30%;
}

.width-31 {
    width: 31%;
}

.width-32 {
    width: 32%;
}

.width-32 {
    width: 32%;
}

.width-33 {
    width: 33%;
}

.width-34 {
    width: 34%;
}

.width-35 {
    width: 35%;
}

.width-36 {
    width: 36%;
}

.width-38 {
    width: 38%;
}

.width-39 {
    width: 39%;
}

.width-40 {
    width: 40%;
}

.width-41 {
    width: 41%;
}

.width-42 {
    width: 42%;
}

.width-43 {
    width: 43%;
}

.width-44 {
    width: 44%;
}

.width-45 {
    width: 45%;
}

.width-46 {
    width: 46%;
}

.width-47 {
    width: 47%;
}

.width-48 {
    width: 48%;
}

.width-49 {
    width: 49%;
}

.width-50 {
    width: 50%;
}

.width-60 {
    width: 60%;
}

.width-70 {
    width: 70%;
}

.width-80 {
    width: 80%;
}

.width-90 {
    width: 90%;
}

.width-100 {
    width: 100%;
}

.type-text-only {
    display: flex;
    min-width: 96px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-2, 2px);
    flex: 1 0;
}

.doc-table {
    min-height: 500px;
    position: relative;
    padding: var(--spacing-24);
}

.doc-table table {
    width: 100%;
}

.doc-table table td {
    font-size: .9rem;
    font-weight: 300;
    padding: 0px;
}

.doc-table table tbody tr:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
    cursor: pointer;
}

.doc-table table tbody tr:hover button {
    background: transparent !important;
}

.doc-table table thead {
    position: sticky;
    top: 182px;
    background: #FFF;
    z-index: 1;
}
.table-h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    margin: 0px;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    font: var(--body-small);
    font-weight: 600;
    text-transform: capitalize;
    word-break: break-all;
}

.table-h6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    margin: 0px;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-small);
    word-break: break-all;
}

.type-checkbox {
    display: flex;
    padding: var(--spacing-16, 16px);
    align-items: center;
    gap: var(--spacing-12, 12px);
}

.type-checkbox-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-2, 2px);
    flex: 1 0 0;
    align-self: stretch;
}

.type-checkbox-input {
    height: 20px;
    width: 20px;
    min-width: 20px;
}

.type-checkbox-input {
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    accent-color: var(--color-bg-brand-strong, #0B42CF);
    border-radius: 20px;
}

.table-cell {
    padding: var(--spacing-16, 16px);
    display: flex;
    gap: var(--spacing-12, 12px);
    align-items: center;
}


/* Start Action Icon field */
.table-action-icon {
    margin-left: -24px;
}

.table-action-main {
    display: flex;
    padding: var(--spacing-8, 8px);
    align-items: center;
    gap: var(--spacing-8, 8px);
    margin: -16px;
    position: relative;
}

/* End Action Icon field */


/* Start Date font size */

.last-modified-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-x-small);
}

/* End Date font size */


/* start file css */
.type-file {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

/* end file css */

/* start folder name css */
.type-folder-name-file {
    position: relative;
}

.type-folder-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.type-folder-name-file .badges {
    position: absolute;
    left: -7px;
    bottom: -5px;
}

/* end folder name css */

/* start Collection Name css */
.doc-collection-image {
    height: 54px;
    display: flex;
    width: 96px;
    min-width: 96px;
    object-fit: cover;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: var(--radius-x-small, 4px);
    border-bottom: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
}

/* End Collection Name css */

/* start Company Logo css */
.type-company-logo-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-2, 2px);
    width: 82px;
    height: 32px;
}

.type-company-logo {
    object-fit: contain;
    max-width: 82px;
    max-height: 32px;
}

.type-company-logo-clickup {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

/* End Company Logo css */


/* start Rating css */
.rating-five-stars {
    display: flex;
    align-items: center;
    gap: var(--spacing-6, 6px);
}

.rating-five-stars svg {
    height: 18px;
    width: 18px;
}

.rating-h5 {
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-medium);
    margin: 0px;
}

/* End Rating css */

/* start Likes css */
.type-likes {
    display: flex;
    align-items: center;
    gap: var(--spacing-12, 12px);
}

.type-likes-h6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    margin: 0px;
    font: var(--body-x-small);
}

/* End Likes css */

/* start Categories css */
.type-categories {
    display: flex;
    align-items: center;
    align-content: center;
    gap: var(--spacing-4, 4px);
    flex: 1 0 0;
    align-self: stretch;
    flex-wrap: wrap;
    position: relative;
}

/* End Categories css */

/* start Table Heading css */
.table-heading {
    display: flex;
    padding: var(--spacing-6, 6px) var(--spacing-8, 8px) var(--spacing-6, 6px) var(--spacing-16, 16px);
    align-items: center;
    gap: var(--spacing-12, 12px);
    height: 54px;
    align-items: center;
    gap: var(--spacing-4, 4px);
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-small);
    font-weight: 600;
}

.table-heading img {
    display: flex;
    padding: var(--spacing-8, 8px);
    align-items: flex-start;
    border-radius: var(--radius-large, 16px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    cursor: pointer;
}

.table-heading img:hover {
    border-radius: var(--radius-large, 16px);
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}

.table-heading img:active {
    border-radius: var(--radius-large, 16px);
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
}

.table-heading img:focus {
    border-radius: var(--radius-full, 120px);
    outline: 2px solid var(--color-border-inverse-neutral, #0D0D0D);
}

/* End Table Heading css */


.table-action-icon .dropdown-list {
    min-width: 216px !important;
}

.type-avatar-text-32 {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: var(--color-bg-inverse-neutral, #041527);
    color: var(--color-text-inverse, #FFF);
    font: var(--title-x-small);
    text-transform: capitalize;
    align-content: center;
    padding-top: 1px;  
}

.doc-table .width-54 {
    min-width: 320px;
}
