.sponsor-card .sponsor-card-text .sponsor-details-view-date span {
    color: #394046;
    font: var(--body-medium);
    letter-spacing: -0.1px;
}
.sponsor-card .sponsor-card-text .sponsor-details-view-date span:not(:first-child) {
    display: flex;
    align-items: center;
}
.sponsor-card .sponsor-card-text .sponsor-details-view-date span:not(:first-child)::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    margin: 0 6px 0px;
}
.sponsor-card.card-spacing {
    /* padding: var(--spacing-6) var(--spacing-20); */
    padding: var(--spacing-6) var(--spacing-48) var(--spacing-6) var(--spacing-20);
    border-radius: 0;
}
.has-dotmenu .sponsor-card.card-spacing {
    /* padding: var(--spacing-6) var(--spacing-48) var(--spacing-6) var(--spacing-20); */
    border-radius: 0;
    outline: none;
}
.has-dotmenu .sponsor-dot-btn {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 99;
}
.has-dotmenu:focus-visible .sponsor-dot-btn,
.has-dotmenu:hover .sponsor-dot-btn {
    opacity: 1;
    visibility: visible;
}
.sponsor-card {
    display: flex;
    border: 2px solid transparent;
    transition: all 0.5s ease;
}
.sponsor-card.card-spacing:hover {
    background-color: var(--color-bg-neutral-tertiary-hovered);
}
.sponsor-card.card-spacing:focus-visible {
    border-color: var(--color-border-inverse-neutral);
    outline: none;
}
.sponsor-card:not(.card-spacing):focus-visible {
    border-color: var(--color-border-inverse-neutral);
    outline: none;
    padding: 2px;
    margin: -2px;
    border-radius: var(--radius-8);
}
.sponsor-card .sponsor-card-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: var(--spacing-2) 0;
}
.sponsor-card .sponsor-card-text .sponsor-details-view-date {
    display: flex;
    align-items: center;
}
.sponsor-card .sponsor-card-text .sponsor-card-title {
    font: var(--body-small);
    font-weight: 600;
    color: var(--color-text-neutral-strong);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sponsor-card .sponsor-card-text .sponsor-type-text,
.sponsor-card .sponsor-card-text .sponsor-details-view-date span {
    font: var(--body-x-small);
    color: #394046;
}
.sponsor-card .sponsor-thumb-image img {
    border-radius: inherit;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.sponsor-card .sponsor-thumb-image {
    position: relative;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    width: 128px;
    border-radius: var(--radius-6);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--spacing-16);
    flex-shrink: 0;
}
.sponsor-card .sponsor-thumb-image .sponsor-duration {
    position: absolute;
    bottom: 4px;
    right: 4px;
    color: var(--color-text-static-white);
    background: var(--color-bg-blackout-strong);
    padding: 4px 8px;
    border-radius: 6px;
    font: var(--body-2x-small-bold);
}
.sponsor-card .sponsor-thumb-image.imgtype-img .thumb-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.sponsor-card .sponsor-thumb-image.imgtype-file {
    background-color: #394046;
}
.sponsor-card .sponsor-thumb-image.imgtype-file .file-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
}
.sponsor-card .sponsor-thumb-image.imgtype-sponsor {
    background-color: #1e1e1f;
}
.sponsor-card .sponsor-thumb-image.imgtype-sponsor .sponsor-icon {
    width: 72px;
    height: 28px;
    object-fit: contain;
    object-position: center;
}