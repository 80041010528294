.chat-member-cell {
	display: flex;
	padding-right: var(--spacing-4, 4px);
	align-items: center;
	gap: var(--spacing-16, 16px);
	flex: 1 0 0;
	padding: var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-20, 20px);
}
.chat-member-cell:hover{
	background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
	cursor: pointer;
}
.chat-member-cell:active{ 
	background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5); 
	cursor: pointer; 
}
.chat-member-cell-avatar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}
.chat-member-cell-text {
	display: flex;
	padding-right: var(--spacing-12, 12px);
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
}
.chat-member-cell-user-photo {
	width: 40px;
	height: 40px;  
	object-fit: cover;
	border-radius: var(--radius-full, 999px);
} 
.chat-member-cell-avatar{
	position: relative;
}
.chat-member-cell-avatar .online-status{
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.chat-member-cell-text h5 {
    display: -webkit-box;
	font: var(--body-small);
	font-weight: 600;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
	word-break: break-all;
}
.chat-member-cell-text h6 {
    display: -webkit-box;
	font: var(--body-small);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font-weight: 400;
	word-break: break-all;
}