.chat-details {
    display: flex;
    width: 400px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background: var(--color-bg-screen, #FFF);
}

.chat-details-info {
    display: flex;
    padding: var(--spacing-32, 32px) var(--spacing-20, 20px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-24, 24px);
    align-self: stretch;
}

.chat-details-container {
    display: flex;
    padding: var(--spacing-12, 12px) 0px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.chat-details-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
}

.chat-details-info-name-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-2, 2px);
    align-self: stretch;
}

.chat-details-info-name-date h4 {
    text-align: center;
    word-break: break-all;
}

.chat-details-info-name-date-in {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
}

.chat-details-info-avatar .avatar-large-in img {
    width: 100%;
    height: 100%;
}

.cstm-skeleton-box {
    width: 40%;
    height: 20px;
    margin-bottom: 12px;
}