.cstm-admin-badge-color{
  background-color: rgb(1, 0, 0);
  border: 1px solid black;
  width: 40px;
  height: 20px;
}
.cstm-edit-draft-btn{
  color: blue!important;
}
.cstm-partner-avar {
  height: 50px;
  width: 50px;
  border: 1px solid #ebebeb;
  margin-right: 10px;
  border-radius: 50px;
  background: #201e29;
  color: #fff;
  display: flex; 
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-transform: uppercase;
  min-width: 50px;
}
.cstm-pm-list .subcat-addvideo {
  margin-bottom: 10px;
}
.cstm-pm-list-msg {
  bottom: -10px;
}
.cyp-btm-btn .btn2 {
  vertical-align: top;
  margin-left: 24px;
}
.cstm-mds-partner-only .group-info-user-link {
  margin-right: 0px;
}
.cstm-mds-partner-link {
  color: #000;
  font-weight: 600;
}
.cstm-mds-partner-link:hover {
  color: #351bce;
}
