.cstm-singlevideo-singleuser-list {
    max-width: 800px;
}
.cstm-statistics-count{
    text-align: center!important;
}
.cstm-statistics-count:hover {
    color: #000 !important;
    -webkit-text-decoration: underline #351bce !important;
    text-decoration: underline #351bce !important;
}
.cstm-video-filter-custom .react-datepicker__input-container svg {
    right: 10px;
    margin-top: 9px;
    fill: #65646b; 
}
.cstm-video-filter-custom .react-datepicker__input-container input {
    width: 142px!important;
}

.statistic-lifetime-tab {
    border-bottom: #E6E6E6 1px solid;
}
.statistic-loader-img img {
    width: 80px;
    position: relative;
    top: 40%;
}
.statistic-loader-img {
    padding-top: 10%;
    text-align: center;
    height: 100%;
    padding-bottom: 10%;
}
  .cstm-video-filter-container-right {
    min-width: 120px;
}
.cstm-video-graph-container{
    height:400px !important;
}
.userstatistic-loading-image > img {
    width: 70px;
    margin-top: 18%;
  }
.userstatistic-loading-image {
    text-align: center;
  }
#menu- {
    z-index: 9999;
  }
.cstm-video-filter-custom {
	position: absolute;
	right: 24px;
	background-color: #FFF;
    border: 1px solid #E6E6E6;
	margin-top: 10px;
	display: flex;
	align-items: center;
}
.cstm-arrow-left-right {
	height: 14px;
	opacity: 0.5;
}
.cstm-video-filter-custom input {
	border: none!important;
	width: 120px!important; 
    cursor: pointer; 
}
.statistic-loading-image {
	position: absolute;
	background: #FFFFFFF2;
	width: 100%;
	text-align: center;
	height: 100%;
	left: 0;
}
.cstm-pli-bg-statistic-img {
	position: relative;
	top: 50%;
}
.cstm-pli-bg-statistic-img img {
	width: 70px;
}
.cstm-video-statistic-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #201E29;
    padding: 12px 16px;
    margin: 0px;
    font-style: normal;
  }
.cstm-video-filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
}
h2.cstn-vst {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #201E29;
}

.cstm-tab-video-statistic {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 16px;
}
.cstm-tab-video-statistic li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}
ul.cstm-tab-video-statistic li a {
    display: block;
    width: 100%;
    padding: 16px;
    background: #F6F6F6;
    border: 1px solid #E6E6E6;
}
.cstm-active-vs a {
    background: #FFF!important;
    border-bottom: 1px solid #ffffff!important;
    margin-bottom: -1px;
    padding-bottom: 40px!important;
    border-top: 3px solid #351BCE!important;
}
.videostatistic-tabs {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    padding: 33px 24.5px;
}
li.cstm-active-vs a {
    background: #FFF!important;
}
h3.csm-videostatistic-header-viewscount {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    color: #201E29;
    margin: 0px;
}
.csm-videostatistic-header-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #414049;
    margin-bottom: 10px;
    display: block;
}
.csm-videostatistic-header-subtitle {
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px; 
    line-height: 150%;
    color: #6E6D74;
}
.cstm-tab-video-statistic-main {
    margin-bottom: 32px;
}
.cstm-vs-title4 h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #201E29;
    margin-bottom: 10px;
}
.cstm-videos-statistic-list-video-main-box {
    display: flex;
    align-items: center;
    gap: 16px;
}
.cstm-videos-statistic-list-video-main-box-img {
    height: 74px;
    width: 135px;
    min-width: 135px;
    max-width: 135px;
}
.cstm-videos-statistic-list-video-main-box-img img{
    border-radius: 10px; 
    height: 74px;
    width: 135px;
    min-width: 135px;
    max-width: 135px;
}
.cstm-videostatistic-videolist-container tr:hover {
    background: transparent!important;
}
.cstm-videostatistic-videolist-container tr {
    border: 0px!important;
}
h3.cstm-vlm-title {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #201E29;
    font-family: 'Inter', sans-serif;
}
p.cstm-vlm-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #6E6D74;
    margin: 0px;
}
.video-statistic-info-content-main {
    padding: 24px;
    background-color: #FFF;
    font-family: 'Inter', sans-serif;
    position: relative;
}
.cstm-no-data-found {
	text-align: center;
	color: red;
	font-size: 16px;
	min-height: 400px;
	
	display: flex;
	align-items: center;
	justify-content: center;
}
.cstm-sb-w {
	width: 180px;
}
.cstm-vc-w {
    width: 180px;
}
.cstm-sr {
    width: 100px;
}
.cstm-sr-w {
    width: 50px;
    text-align: center;
}
.cstm-videostatistic-videolist-container th, 
.cstm-videostatistic-videolist-container td {
    padding: 12px 16px;
    font-family: 'Inter', sans-serif;
}
.cstm-ve-w {
    width: 150px;
    text-align: center!important;
}
.cstm-videostatistic-videolist-container th {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #6E6D74;
    font-family: 'Inter', sans-serif;
}
.cstm-videostatistic-videolist-container td {
    border-bottom: 1px solid #E6E6E6;
}
span.cstm-vs-icon {
    margin-left: 13.52px;
    cursor: pointer;
}
.cstm-videostatistic-videolist-container .css-rqglhn-MuiTable-root {
    margin-bottom: 20px;
}
select.cstm-video-select-field {
    border: none!important;
    padding: 0px;
    height: auto;
    background-image: url(../../Images/ArrowDownVideo.png); 
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #201E29;
}
h4.cstm-video-select-title {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #5C5B63;
    margin-bottom: 2px;
}

.users-info-sidebar .card .card-header {
    border-bottom: 1px solid #E6E6E6;
}