.radio-button input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.radio-button label {
  margin: 0px;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
  color: var(--color-text-neutral-strong, #060d14);
  font: var(--body-medium);
}

.radio-button label>span {
  width: 20px;
  height: 20px;
  display: block;
  justify-content: center;
  border: 1px solid var(--color-border-neutral-strong);
  margin-right: 0px;
  border-radius: var(--radius-full);
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 1;
  position: relative;
  cursor: pointer;
}

.radio-button input:checked+label>span {
  background-color: var(--color-bg-brand-strong);
  border: 1p solid transparent;
}

.radio-button input:checked+label>span:hover {
  background-color: var(--color-bg-brand-strong-hovered);
}

.radio-button input:checked+label>span:active {
  background-color: var(--color-bg-brand-strong-pressed);
}

.radio-button input+label>span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: var(--spacing-8);
  height: var(--spacing-8);
  background-color: var(--color-bg-static-white);
  border-radius: var(--radius-full);
}

.radio-button input:checked+label>span:before {
  opacity: 1;
}

.radio-label {
  font: var(--body-medium);
  color: var(--color-text-neutral-strong);
  margin: 0;
}