.chips-filter {
    display: inline-flex;
    background-color: transparent;
    padding: var(--spacing-8, 8px) var(--spacing-12, 12px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-2, 2px);
    border-radius: var(--radius-full, 999px);
    border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.20));
    align-items: center;
    transition: all .5s ease;
}
.chips-filter .chip-container {
    padding: var(--spacing-2) var(--spacing-4);
    font: var(--body-small)
}
.chips-filter:hover {
    border-color: var(--color-border-neutral-secondary-hovered);
}
.chips-filter:focus {
    border-color: var(--color-border-neutral-secondary-pressed);
}
.chips-filter.active-chips-filter {
    background-color: var(--color-bg-neutral-secondary);
    border-color: var(--color-border-neutral-tertiary);
}
.chips-filter.active-chips-filter:hover {
    background-color: var(--color-bg-neutral-secondary-hovered);
    border-color: var(--color-border-neutral-tertiary-hovered);
}
.chips-filter.active-chips-filter:focus {
    background-color: var(--color-bg-neutral-secondary-pressed);
    border-color: var(--color-border-neutral-tertiary-pressed);
}
.chips-filter-dropdown-wrapper .dropdown-list {
    margin: var(--spacing-12) 0;
}