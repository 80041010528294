.chatmessage-voicemessage-inner .chat-message {
  padding: var(--spacing-8) var(--spacing-12);
  width: 296px;
}

.chatmessage-wave {
  gap: var(--spacing-8);
  width: 100%;
}

.chatmessage-wave p {
  margin: 0;
  color: var(--color-text-neutral-tertiary);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.3px;
}

.outgoing .chatmessage-wave p {
  color: var(--color-text-brand-onstrong);
}


.chatmessage-voicemessage-inner.outgoing .chatmessage-advanced svg path {
  fill: var(--color-icon-brand-onstrong);
}