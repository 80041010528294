.chatlistcell-sec {
  padding: var(--spacing-8) var(--spacing-8)
    var(--spacing-8) var(--spacing-20);
  background: var(--color-bg-neutral-tertiary);
  gap: var(--spacing-16);
  width: 100%;
  max-width: 768px;
  cursor: pointer;
  border: none;
}

.chatlistcell-sec:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.chatlistcell-sec:active {
  background: var(--color-bg-neutral-tertiary-pressed);
}


/* Container Design */
.chatlistcell-container {
  gap: var(--spacing-16);
  flex: 1 0 auto;
  width: calc(100% - 64px);
}
.chatlistcell-content {
  flex: 1 0 auto;
  width: calc(100% - 64px);
}
.chatlistcell-content h5 {
  font: var(--body-small);
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.chatlistcell-content p {
  color: var(--color-text-neutral-secondary);
  font: var(--body-small);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}