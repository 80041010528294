
  .cstm-doc-pdf {
    height: calc(100vh - 56px);
    overflow-y: auto;
}

.cstm-doc-pdf::-webkit-scrollbar {
    width: 0px;
  }
 
.doc-preview-img {
    overflow: hidden;
    margin: 56px 16px 0px;
    height: calc(100vh - 56px);
}


.doc-preview-media {
    /* margin: 72px 16px 24px 16px; */
    height: calc(100vh - 56px);
    margin: 56px 16px 0px 16px;
}

.doc-preview-img .react-transform-wrapper {
    text-align: center;
    width: 100%;
    height: 100%;
}
.doc-preview-img  .react-transform-component {
    width: inherit;
    height: 100%;
}
.doc-preview-img  .react-transform-component  img {
    max-height: calc(100vh - 56px);

    margin: auto;
} 
/* .custom-transform-component {
    touch-action: pan-y;
    overscroll-behavior-x: none;
} */

.doc-preview-media .react-transform-wrapper {
    margin: auto;
    width: initial; 
}
.doc-preview-media .react-transform-component {
    /* margin: auto; */
    width: 100%;
    /* width: unset */
}


.doc-modal-only-left .react-transform-component {
    /* margin: auto; */
    /* width: 100%; */
}
.doc-modal-only-left-top-center .doc-modal-icon img {
    filter: invert(99%) sepia(0%) saturate(2476%) hue-rotate(199deg) brightness(100%) contrast(119%);
}

.doc-modal-only-left-top-left .doc-modal-icon {
    min-width: 40px;
}

.doc-modal-only-left-top-right button {
    border: none;
    font: var(--body-small);
    font-weight: 600;
    line-height: 24px;
}


.doc-preview-media .react-transform-wrapper .react-pdf__Page__canvas {
    /* width: 100% !important;
    height: calc(100vh - 72px) !important;
    margin: auto;
    padding-bottom: 16px;
    object-fit: contain; */
    margin: auto;
}

.react-pdf__Page {
    background: transparent !important;
    margin: 20px auto !important;
    min-width: unset !important;
}

.react-pdf__Page__textContent.textLayer {
    display: block;
    margin: auto;
}

.react-pdf__Page__annotations.annotationLayer {
    display: block !important;
    margin: auto;
    right: 0;
}

.doc-management-main-banner-detials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.have-docs-you-title {
    margin: 0px;
    color: var(--color-text-static-white, #FFF);
    font: var(--title-small)
}

.docs-no-data-only {
    height: inherit !important;
}

.wereon-sub-title {
    margin: 0px;
    overflow: hidden;
    color: var(--color-text-static-white, #FFF);
    text-overflow: ellipsis;
    font: var(--body-small);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
}

.submit-your-document-btn {
    display: flex;
    padding: var(--spacing-space-medium, 16px) var(--spacing-space-x-large, 24px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-small, 8px);
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-static-white, #FFF);
    color: var(--color-text-static-darkgray, #060D14);
    font: var(--body-large-bold);
}

.submit-your-document-btn:hover:hover {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-static-white-pressed);
    color: var(--color-text-static-darkgray) !important;
}

.fresh-updates-title {
    color: var(--color-text-neutral-strong);
    font: var(--title-large);
    margin-bottom: 0px;
    padding: 24px 0px;
}

.fresh-updates-slider {
    padding: 0px 24px;
    margin: 0px -36px;
    overflow: hidden;
}

.all-docs-main-section {
    padding: 24px 0px;
}

.docs-menu-section {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: 0px;
    margin: 0px;
    list-style: none;
    border-bottom: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
}

.docs-menu-section li {
    display: flex;
}

.docs-menu-section li a {
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-large-bold);
    padding: var(--spacing-12, 12px);
    align-items: center;
    gap: var(--spacing-2, 2px);
    position: relative;
}

.docs-menu-section li a:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}

.docs-menu-section li a:active {
    background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5);
}

.docs-menu-section li a.active:before {
    content: '';
    width: 100%;
    height: 4px;
    background: var(--color-text-brand-strong, #0B42CF);
    position: absolute;
    left: 0;
    bottom: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.all-docs-main-section-tabs {
    margin-bottom: 24px;
}

.all-docs-main-section-search {
    margin-bottom: 24px;
}

.all-docs-main-section-filters {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
}

.all-docs-main-section-filters button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4, 4px);
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.20));
    background-color: var(--color-bg-static-white);
}

.all-docs-main-section-filters button:hover {
    border: 1px solid var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.50));
}

.all-docs-main-section-filters button:active {
    border: 1px solid var(--color-border-neutral-secondary-pressed, rgba(13, 13, 13, 0.70));
}


.all-docs-main-section-filters .active {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-inverse-neutral, #041527);
    color: var(--color-text-inverse, #FFF);
}

.all-docs-main-section-search {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all-docs-main-section-search-left {
    display: flex;
    gap: 24px;
    align-items: center;
}

.all-docs-main-section-search-right {
    position: relative;
}


/* Start Slider */
.fresh-updates-slider .slick-slide {
    padding: 24px 12px;
}

.fresh-updates-slider-cont {
    transition: transform .8s;
    position: relative;
    transform: translateZ(0);
}

.fresh-updates-slider-cont-img {
    border-radius: 12px;
    aspect-ratio: 16/8;
    overflow: hidden;
    border: 1px solid var(--color-bg-static-white-pressed);
    transform: translateZ(0);
    background: var(--gray-800, #394046);
    cursor: pointer;
}

.fresh-updates-slider-cont:hover .fresh-updates-slider-cont-img img {
    transform: scale(1.05);
}

.fresh-updates-slider-cont:hover .fresh-updates-slider-cont-img video {
    transform: scale(1.05);
}

.fresh-updates-slider-cont-img img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* object-fit: cover; */
    transition: transform .8s;
}

.fresh-updates-slider-cont-img video {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform .8s;
}

.fresh-updates-slider-cont-file-icon {
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: 1;
}

.fresh-updates-slider-cont-file-icon img {
    height: 48px;
    width: 48px;
    object-fit: cover;
}

.fresh-updates-slider-cont-dot {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.fresh-updates-slider-cont-img-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    position: relative;
    padding-right: 40px;
    margin-bottom: 4px;
}

.fresh-updates-slider-title {
    font: var(--title-small);
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    margin: 0px;
}

.fresh-updates-slider-cont-by {
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-small);
    margin-bottom: 8px;
}

.fresh-updates-slider-cont-text {
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font: var(--body-small);
}

.fresh-updates-slider button.slick-arrow {
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
    background: var(--color-bg-static-white, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04), 0px 4px 25px 0px rgba(4, 21, 39, 0.10);
    height: 36px;
    width: 36px;
    top: 115px;
    right: -6px;
    z-index: 1;
}

.fresh-updates-slider button.slick-arrow:hover {
    border: 1px solid var(--color-border-neutral-tertiary-hovered, rgba(13, 13, 13, 0.30));
}

.fresh-updates-slider .slick-next:after {
    background: url(../Images/icn_m_chevron_right.svg);
}

.fresh-updates-slider .slick-arrow:after {
    content: '';
    height: 20px;
    position: absolute;
    width: 20px;
    top: 7px;
    left: 7px;
}

.fresh-updates-slider .slick-arrow:before {
    display: none !important;
}

.fresh-updates-slider .slick-prev {
    left: -6px;
    z-index: 1;
}

.fresh-updates-slider .slick-prev:after {
    background: url(../Images/icn_m_chevron_left.svg);
}

.fresh-updates-slider .slick-disabled {
    display: none !important;
}

/* End Slider */

.doc-detial-main {
    width: 100%;
    max-width: 712px;
    margin: auto;
    padding: 0px 32px 40px 32px;
}

.doc-detial-main hr {
    border-color: #e5e5e5;
    margin: 0px;
}

.submit-you-document {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: var(--spacing-40, 40px) 0px;
    gap: 93px;
}

.submit-you-document-left h2 {
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--title-large);
    letter-spacing: -0.36px;
    margin: 0px;
}

.submit-you-document-left p {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-medium);
    margin: 0px;
}

.submit-you-document-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.submit-you-document-right {
    min-width: 160px;
}

.select-document-type {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.doc-detail-title-sub {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.doc-detail-title-sub h3 {
    margin: 0px;
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--title-medium);
    letter-spacing: -0.24px;
}

.doc-detail-title-sub p {
    margin: 0px;
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-small);
}

.doc-detail-select-label {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
}

.doc-detail-select-label button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4, 4px);
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.20));
    background-color: var(--color-bg-static-white);
}

.doc-detail-select-label button:hover {
    border: 1px solid var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.50));
}

.doc-detail-select-label button:active {
    border: 1px solid var(--color-border-neutral-secondary-pressed, rgba(13, 13, 13, 0.70));
}

.doc-detail-select-label .active {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-inverse-neutral, #041527);
    color: var(--color-text-inverse, #FFF);
}

.examples-of-documents {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.describe-your-document {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.describe-your-textarea {
    width: 100%;
}

.doc-detail-btn {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    align-items: flex-start;
    justify-content: space-between;
}

.btn-back {
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    display: flex;
    padding: var(--spacing-space-medium, 16px) var(--spacing-space-x-large, 28px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-2-x-small, 8px);
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--body-large-bold);
    background-color: transparent;
}

.btn-back:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered);
    color: var(--color-text-neutral-strong, #060D14);
}

.btn-submit {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-brand-strong, #0B42CF);
    display: flex;
    padding: var(--spacing-16, 15px) var(--spacing-24, 28px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8, 8px);
    color: var(--color-text-brand-onstrong, #FFF);
    text-align: center;
    font: var(--body-large-bold);
    border: none;
}

.btn-submit:hover {
    background: var(--color-bg-brand-strong-hovered);
    color: var(--color-text-static-white);
}

.btn-submit:active {
    background: var(--color-bg-brand-strong-pressed, #052D93);
    color: var(--color-text-brand-onstrong, #FFF);
}


.btn-disabled-sumit {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-disabled-strong, #DADDE0);
    display: flex;
    padding: var(--spacing-16, 15px) var(--spacing-24, 28px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-8, 8px);
    color: var(--color-text-disabled-onstrong, #84898F);
    text-align: center;
    font: var(--body-large-bold);
    border: none;
    pointer-events: none;
    position: relative;
}

.examples-of-documents-blog {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    display: grid;

}

.drag-files-upload {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.drag-files-upload-box {
    display: flex;
    padding: var(--spacing-48, 48px) var(--spacing-32, 32px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-24, 24px);
    align-self: stretch;
    border-radius: var(--radius-medium, 12px);
    border-radius: var(--radius-medium, 12px);
    border: 2px dashed var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    margin: 0px;
    cursor: pointer;
}

.drag-files-upload-box:hover {
    border: 2px dashed var(--color-border-neutral-strong-hovered, rgba(13, 13, 13, 0.50));
}

.drag-files-upload-box:active {
    border: 2px dashed var(--color-border-neutral-strong-pressed, rgba(13, 13, 13, 0.70));
}

.active-drag-files {
    border: 2px dashed var(--color-border-danger-strong, #DB1D26) !important;
}

.drag-files-upload-box-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.drag-files-upload-box-top-icon {
    width: 100%;
    height: 48px;
    text-align: center;
}

.drag-files-upload-box-top-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.drag-files-upload-box-top-info h5 {
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--title-small);
    margin: 0px;
}

.drag-files-upload-box-top-info p {
    color: var(--color-text-neutral-tertiary, #84898F);
    text-align: center;
    font: var(--body-x-small);
    margin: 0px;
}

.browse-files-btn {
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-strong, rgba(13, 13, 13, 0.30));
    display: flex;
    padding: var(--spacing-space-small, 12px) var(--spacing-20, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-3-x-small, 6px);
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    font: var(--body-large-bold);
    background-color: transparent;
}

.browse-files-btn:hover {
    border: 1px solid var(--color-border-neutral-strong-hovered);
    color: var(--color-text-neutral-strong, #060D14);
}

.drag-files-upload-error {
    color: var(--color-text-danger-strong, #DB1D26);
    font: var(--body-x-small);
}

.drag-files-upload-load {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-2, 2px);
    align-self: stretch;
}

.drag-files-upload-load-box {
    display: flex;
    padding: var(--spacing-8, 8px) var(--spacing-4, 4px);
    align-items: center;
    gap: var(--spacing-12, 12px);
    align-self: stretch;
    border-radius: var(--radius-medium, 12px);
    position: relative;
    overflow: hidden;
}

.drag-files-upload-load-box-info h5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    font: var(--title-x-small);
    margin: 0px;
}

.drag-files-upload-load-box-info h6 {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-small);
    margin: 0px;
}

.drag-files-upload-load-box-info {
    flex: 1;
}

.drag-files-upload-load-box-img {
    min-width: 48px;
}

.drag-files-upload-load-box-img img {
    min-width: 48px;
    width: 48px;
    height: 48px;
    object-fit: cover;
}

.drag-files-upload-load-box-icon {
    min-width: 40px;
    display: flex;
    padding: var(--spacing-space-2-x-small, 8px);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    cursor: pointer;
}

.drag-files-fill {
    background: var(--color-bg-neutral-secondary, #EAEDF1);
}

.drag-files-fill {
    background: var(--color-bg-neutral-secondary, #EAEDF1);
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
}

.your-document-main {
    display: flex;
    max-width: 648px;
    padding: 40px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin: auto;
}

.your-document-main-icon {
    width: 100%;
    height: 164px;
    text-align: center;
}

/* Start Loading page */
.doc-management-main-banner-loading {
    width: 100%;
    height: 120px;
    margin-bottom: 24px;
    border-radius: 12px;
}

.fresh-updates-slider-loading {
    grid-gap: 40px 24px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    display: grid;
    padding: 24px 0px;
}

.fresh-updates-slider-post-loading {
    position: relative;
}

.fresh-updates-slider-post-img-loading {
    border-radius: 12px;
    margin-bottom: 16px;
    aspect-ratio: 16/8;
    width: 100%;
}

.fresh-updates-slider-post-title-loading {
    width: 100%;
    height: 25px;
}

.fresh-updates-slider-partnesh5-loading {
    width: 35%;
    height: 15px;
}

.fresh-updates-slider-partnesh6-loading {
    width: 100%;
    height: 50px;
}

.docs-table-only-in-loading {
    width: 100%;
    height: 60px;
    margin-bottom: 16px;
}

.submit-you-document-loading {
    width: 100%;
    height: 148px;
    margin: 24px 0px;
    border-radius: 12px;
}

.doc-detail-select-label-loading {
    display: flex;
    align-items: center;
    gap: 10px 10px;
    align-self: stretch;
    flex-wrap: wrap;
    margin: 24px 0px;
}

.doc-detail-select-label-in-loading {
    width: 100px;
    height: 40px;
    border-radius: 50px;
}

.examples-of-documents-blog-loading {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    display: grid;
}

.drag-files-upload-loading {
    width: 100%;
    height: 306px;
    border-radius: 12px;
    margin: 24px 0px;
}

.describe-your-document-loading {
    width: 100%;
    height: 144px;
    border-radius: 12px;
    margin: 24px 0px;
}

.doc-detail-btn-loading {
    display: flex;
    padding: var(--spacing-40, 40px) 0px;
    align-items: flex-start;
    justify-content: space-between;
}

.doc-detail-btn-loading-in-loading {
    width: 30%;
    height: 58px;
    border-radius: 50px;
}
/* .doc-file-modal-only {
    background: var(--color-bg-blackout-secondary);
} */

.doc-file-modal-only {
    background: transparent;
}

.doc-modal-only {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    background: var(--black-900, rgba(13, 13, 13, 0.90));
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0px !important;
    max-height: initial !important;
}

.doc-modal-only-left {
    width: 75%;
    position: relative;
}

.doc-modal-only-right {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-text-static-white, #FFF);
}

.doc-modal-only-left-sldier {
    width: 100%;
    height: 100%;

}


.doc-modal-only-left-top {
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 1;
    display: flex;
    height: 56px;
    padding: 0px 8px;
    justify-content: space-between;
    background-color: #000;
    align-items: center;
    flex-shrink: 0;
    top: 0;
}

.doc-modal-lsi {
    height: 100%;
    margin: 0px 10px;
    overflow: hidden;
    position: relative;
}

.doc-modal-only-left-sldier-in {
    display: flex !important;
    align-items: center;
    /* align-items: flex-end; */
    justify-content: center;
    height: 100%;
    margin: auto;
}

.doc-modal-only-left-sldier-in img {
    pointer-events: none;
    max-width: inherit;
    width: 100%;
}

.doc-modal-only-left-sldier .slick-slider {
    height: 100%;
    transition: 0.5s all;
}

.doc-modal-only-left-sldier .slick-list {
    height: 100%
}

.doc-modal-only-left-sldier .slick-track {
    height: 100%
}

.doc-modal-only-left-sldier .slick-track .slick-slide>div {
    height: 100%;
}

.doc-modal-only-left-top-left {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px;
    width: 40%;
}

h5.doc-modal-filename {
    margin: 0px;
    color: var(--color-text-static-white, #FFF);
    font: var(--body-small);
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}

.doc-modal-icon {
    background: transparent;
    padding: 8px;
    border: none;
    border-radius: 50px;
}

.doc-modal-icon:hover {
    background: var(--color-bg-lightening-tertiary-hovered, rgba(255, 255, 255, 0.10));
}

.doc-modal-icon:active {
    background: var(--color-bg-lightening-tertiary-pressed, rgba(255, 255, 255, 0.20));
}

.doc-modal-only-left-top-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.doc-modal-cont {
    display: flex;
    width: 48px;
    padding: 2px 0px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-x-small, 4px);
    background: var(--color-bg-blackout-secondary, rgba(13, 13, 13, 0.50));
    flex: 1 0 0;
    color: var(--color-text-static-white, #FFF);
    text-align: center;
    font: var(--body-small-bold)
}

.doc-modal-only-left-top-right {
    display: flex;
    padding: 8px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-16, 16px);
    width: 40%;
}

.doc-modal-btn {
    display: flex;
    padding: var(--spacing-space-2-x-small, 8px) var(--spacing-16, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-space-4-x-small, 4px);
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-static-white, #FFF);
    color: var(--color-text-static-darkgray, #060D14);
    text-align: center;
    font: var(--body-medium-bold);
}

.doc-modal-btn:hover {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-static-white-pressed);
    color: var(--color-text-static-darkgray) !important;
}

.doc-modal-only-left-sldier button.slick-arrow {
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.80));
    height: 40px;
    width: 40px;
    z-index: 1;
}

.doc-modal-only-left-sldier button.slick-arrow:hover {
    background: var(--color-bg-lightening-tertiary-hovered, rgba(255, 255, 255, 0.10));
}

.doc-modal-only-left-sldier button.slick-arrow:active {
    background: var(--color-bg-lightening-tertiary-pressed, rgba(255, 255, 255, 0.20));
}

/* .doc-modal-only-left-sldier  button.slick-arrow:hover {
    background: var(--color-bg-lightening-secondary-hovered, rgba(255, 255, 255, 0.30));
}
.doc-modal-only-left-sldier  button.slick-arrow:active {
    background: var(--color-bg-lightening-secondary-pressed, rgba(255, 255, 255, 0.40));
} */
.doc-modal-only-left-sldier .slick-next:after {
    background: url(../Images/doc-slider-right.svg);
}

.doc-modal-only-left-sldier .slick-next {
    right: 16px
}

.doc-modal-only-left-sldier .slick-prev:after {
    background: url(../Images/doc-slider-left.svg);
}

.doc-modal-only-left-sldier .slick-prev {
    left: 16px
}

.doc-modal-only-left-sldier .slick-arrow:after {
    content: '';
    height: 24px;
    position: absolute;
    width: 24px;
    top: 8px;
    left: 8px;
}

.doc-modal-only-left-sldier .slick-arrow:before {
    display: none !important;
}

.doc-modal-only-left-page {
    padding: 10px 16px 10px 24px;
    gap: 4px;
    align-items: center;
    border-radius: 28px;
    background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.80));
    position: fixed;
    bottom: 16px;
    left: 0;
    right: 0;
    text-align: center;
    display: inline-flex;
    width: fit-content;
    margin: auto;
    color: var(--color-text-static-white, #FFF);
    text-align: center;
    font: var(--body-small);
    font-weight: 600;
}

.doc-page-cont {
    display: flex;
    width: 32px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-x-small, 4px);
    background: var(--color-bg-blackout-secondary, rgba(13, 13, 13, 0.50));
}

.doc-page-cont2 {
    width: 25px;
}

.doc-modal-tabs {
    padding: 8px 20px 0px 20px;
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.doc-modal-tabs .docs-menu-section li {
    width: 100%;
}

.doc-modal-tabs .docs-menu-section li a {
    width: 100%;
    text-align: center;
}

.doc-modal-icon-btn {
    display: flex;
    padding: 8px 0px;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
}

.doc-modal-icon-btn ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.doc-modal-icon-fill {
    display: flex;
    padding: var(--spacing-space-2-x-small, 8px);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    width: 40px;
    min-width: 40px;
    cursor: pointer;
}

.doc-modal-icon-fill:hover {
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}

.doc-modal-tabs1 {
    width: 100%;
    height: 100%;
}

.doc-modal-icon-box {
    display: flex;
    padding: var(--spacing-16, 16px) var(--spacing-20, 20px);
    align-items: center;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
    border-bottom: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.10));
}

.doc-modal-icon-box-photo img {
    width: 40px;
    min-width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}

.doc-modal-icon-box-icon img {
    width: 24px;
    min-width: 24px;
    height: 24px;
    cursor: pointer;
}

.doc-modal-icon-box-intup input {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0;
    overflow: hidden;
    color: var(--color-text-neutral-tertiary, #84898F);
    text-overflow: ellipsis;
    font: var(--body-large);
    height: auto;
    padding: 0px;
    background: transparent;
    border-radius: 0px;
}

.doc-modal-icon-box-intup input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font: var(--body-large);
}

.doc-modal-icon-box-intup input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font: var(--body-large);
    opacity: 1;
}

.doc-modal-icon-box-intup input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font: var(--body-large);
    opacity: 1;
}

.doc-modal-icon-box-intup input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font: var(--body-large);
}

.doc-modal-icon-box-intup input::-ms-input-placeholder {
    /* Microsoft Edge */
    font: var(--body-large);
}

.doc-modal-icon-box-intup {
    flex: 1;
}

.doc-modal-info-cont-main {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    overflow: auto;
    height: calc(100vh - 307px);
}

.doc-modal-info-cont-main::-webkit-scrollbar {
    width: 5px;
}

.doc-modal-info-cont-main::-webkit-scrollbar-track {
    background: var(--color-bg-neutral-secondary);
}

.doc-modal-info-cont-main::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--color-bg-neutral-secondary-pressed);
}

.doc-modal-info-cont {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
}

.doc-modal-info-cont-photo img {
    width: 40px;
    min-width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50px;
}

.doc-modal-info-cont-detail-head {
    display: flex;
    align-items: center;
    gap: var(--spacing-8, 8px);
    align-self: stretch;
}

.doc-modal-info-cont-detail-head h5 {
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--title-x-small);
    margin: 0px;
}

.doc-modal-info-cont-detail-head h6 {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
    margin: 0px;
}

.doc-modal-info-cont-detail-text {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-medium);
}

.doc-modal-info-cont-detail-text a {
    color: var(--color-text-info-strong, #007BE5);
    font: var(--body-medium);
}

.doc-modal-no-data {
    display: flex;
    padding: var(--spacing-48, 48px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-24, 24px);
    flex: 1 0 0;
    align-self: stretch;
    height: calc(100% - 132px);
}

.doc-modal-no-data-text {
    color: var(--color-text-neutral-secondary, #394046);
    text-align: center;
    font: var(--body-small);
    margin: 0px;
}

.doc-modal-tabs2 {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.doc-modal-tabs2::-webkit-scrollbar {
    width: 5px;
}

.doc-modal-tabs2::-webkit-scrollbar-track {
    background: var(--color-bg-neutral-secondary);
}

.doc-modal-tabs2::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--color-bg-neutral-secondary-pressed);
}

.doc-details-info {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
}

.doc-details-info-top {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.doc-details-info-top-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
}

.doc-details-info-top-photo {
    display: flex;
    min-width: var(--avatar-large, 48px);
    height: var(--avatar-large, 48px);
    justify-content: center;
    align-items: center;
}

.doc-details-info-top-photo img {
    width: 48px;
    min-width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50px;
}

.doc-details-info-top-info h5 {
    margin: 0px;
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--title-small)
}

.doc-details-info-top-info h6 {
    margin: 0px;
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
}

.doc-details-info-bottom {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-medium);
}

.sidebar .doc-modal-only-left {
    width: 100%;
}

.sidebar .doc-modal-only-right {
    display: none;
}

.sidebar .doc-modal-only-left-sldier-video {
    padding: 72px 126px;
}

img.ili {
    display: none;
}

.sidebar img.ili {
    display: block;
}

.sidebar img.iri {
    display: none;
}

.hrdtl {
    border-color: #e5e5e5;
    margin: 0px 16px;
}

.doc-details-experience {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
}

.doc-details-experience-banner {
    position: relative;
    height: 180px;
    width: 100%;
}

.doc-exp-banner-img {
    border-radius: var(--radius-medium, 12px);
    object-fit: cover;
    height: 180px;
    width: 100%;
}

.doc-experiece-label {
    position: absolute;
    left: 8px;
    top: 8px;
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-static-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(4, 21, 39, 0.30), 0px 0px 1px 0px rgba(4, 21, 39, 0.40);
    display: inline-flex;
    padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-6, 6px);
    color: var(--color-text-static-darkgray, #060D14);
    font: var(--Regular);
    letter-spacing: -0.3px;
}

.doc-experiece-label b {
    font-weight: 600;
}

.doc-details-experience-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    width: 100%;
}

.doc-details-experience-cont h5 {
    margin: 0px;
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--title-small);
}

.doc-details-experience-cont h6 {
    margin: 0px;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-small);
}

.doc-exp-cur {
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-small);
}

.doc-details-file-list {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
}

.doc-details-file-list-box {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    width: 100%;
}

.doc-details-file-list-box-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
    word-break: break-all;
}

.doc-details-file-list-box-right img {
    min-width: 32px;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.doc-details-file-list-box-left h5 {
    margin: 0px;
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-small);
    font-weight: 500;
}

.doc-details-file-list-box-left h6 {
    margin: 0px;
    color: var(--color-text-neutral-secondary, #394046);
    font: var(--body-x-small);
}

.doc-modal-only-left-sldier-video {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    padding: 122px 36px;
}

.doc-modal-only-left-sldier-video video {
    max-width: 100%;
}

.doc-video-list-box {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    gap: 8px;
}

.doc-video-list {
    display: flex;
    padding-right: var(--spacing-24, 0px);
    align-items: center;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
    border-radius: 10px;
}

.doc-video-list-right {
    display: flex;
    padding: var(--spacing-2, 2px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-4, 4px);
    flex: 1 0 0;
}

.doc-video-list-right h4 {
    margin: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    font: var(--title-x-small);
}

.doc-video-list-left img {
    width: 104px;
    min-width: 104px;
    height: 56px;
    border-radius: var(--radius-8, 8px);
    object-fit: cover;
}

.doc-modal-file {
    height: 100%;
    overflow-x: hidden;
    display: grid !important;
}

.doc-modal-file::-webkit-scrollbar {
    /* width: 5px; */
    width: 0px;
}

.doc-modal-file::-webkit-scrollbar-track {
    background: var(--color-bg-neutral-secondary);
}

.doc-modal-file::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: var(--color-bg-neutral-secondary-pressed);
}

.doc-modal-only-left-file-in {
    display: flex !important;
    align-items: center;
    justify-content: center;
    /* height: 100%; */
    margin: auto;
}

.doc-modal-only-left-file-in img {
    max-width: inherit;
    width: 100%;
}

.only-file-doc {
    padding-top: 72px;
}

/* Doc Popup End */

.cstm-slider-arrow-only {
    position: absolute;
    z-index: 1;
    top: 50%;
    border-radius: var(--radius-full, 120px);
    background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.80));
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cstm-slider-arrow-only:hover {
    background: var(--color-bg-lightening-secondary-hovered, rgba(255, 255, 255, 0.30));
}

.cstm-slider-arrow-only:active {
    background: var(--color-bg-lightening-secondary-pressed, rgba(255, 255, 255, 0.40));
}


.cstm-modal-slider-left {
    left: 16px;
}

.cstm-modal-slider-right {
    right: 16px;
}