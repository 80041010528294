.chat-title {
  gap: var(--spacing-6);
}

.chat-title span {
    color: var(--color-text-neutral-strong);
    font: var(--body-small);
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}