.toast-notification{
    display: flex;
    width: 100%;
    padding: var(--spacing-12, 12px) var(--spacing-12, 12px) var(--spacing-12, 12px) var(--spacing-16, 16px);
    align-self: center;
    gap: var(--spacing-16, 16px);
    border-radius: var(--radius-small, 8px);
    box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04), 0px 4px 25px 0px rgba(4, 21, 39, 0.10);
    box-sizing: border-box;
}
.toast-notification.medium{
    max-width: 400px;
}
.toast-notification.small{
    max-width: 296px;
}
.toast-notification .action-text {
    font: var(--body-small);
    font-weight: 600;
    padding: 0 var(--spacing-8);
    text-decoration: none;
    color: var(--color-text-neutral-onstrong-strong);
}
.toast-notification.neutral .action-text {
    color: var(--color-text-inverse);
}
.toast-notification.info .action-text {
    color: var(--color-text-neutral-onstrong-strong);
}
.toast-notification.success .action-text {
    color: var(--color-text-success-onstrong);
}
.toast-notification.warning .action-text {
    color: var(--color-text-warning-onstrong);
}
.toast-notification.danger .action-text {
    color: var(--color-text-danger-onstrong);
}

.toast-notification.neutral{
    background: var(--color-bg-inverse-toast, #1E1E1E);
}
.toast-notification.info{
    background: var(--color-bg-info-strong, #0D99FF);
}
.toast-notification.success{
    background: var(--color-bg-success-strong, #14AE5C);
}
.toast-notification.warning{
    background: var(--color-bg-warning-strong, #FAC505);
}
.toast-notification.danger{
    background: var(--color-bg-danger-strong, #F1343D);
}

.toast-notification.warning h6{
    color: var(--color-text-warning-onstrong, #0D0D0D);
}
.toast-notification.warning .tnd-description{
    color: var(--color-text-warning-onsecondary, rgba(13, 13, 13, 0.80));
}
.toast-notification.warning .toast-notification-body-icon svg path {
    fill: var(--color-text-warning-onstrong, #0D0D0D);
}
.toast-notification .toast-notification-close svg {
    width: 20px;
    height: auto;
}
.toast-notification.warning .toast-notification-close svg path {
    fill: var(--color-text-warning-onstrong, #0D0D0D);
}
.toast-notification.undefined{
    background: var(--color-bg-inverse-toast, #1E1E1E);
}
.toast-notification-cancel {
    display: flex;
    padding: 0px var(--spacing-8, 8px);
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    color: var(--color-text-inverse, #FFF);
    font: var(--body-small);
    font-weight: 600;
}
.toast-notification.warning .progress-bar-info {
    color: var(--color-bg-inverse-toast, #1E1E1E)!important;
}
.toast-notification.warning .toast-notification-cancel {
    color: var(--color-bg-inverse-toast, #1E1E1E)!important;
}
.toast-notification-body {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}
.toast-notification-body-icon {
    display: flex;
}
.toast-notification-body-icon svg path {
    fill: var(--color-text-inverse, #FFF);
}

.toast-notification-body-text {
    display: flex;
    padding-right: var(--spacing-4, 4px);
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}
.toast-notification-body-text h6 {
    color: var(--color-text-inverse, #FFF);
    font:var(--body-x-small);
    font-weight: 600;
}
.tnd-description{
    color: var(--color-text-inverse, #FFF);
    font:var(--body-x-small);
}
.toast-notification-close {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    cursor: pointer;
    padding-right: 4px;
    align-self: flex-start;
}
.toast-notification-close svg path {
    fill: var(--color-text-inverse, #FFF);
}

