.video-small-wrapper {
    width: 100%;
}
.video-small-wrapper .video-small-card {
    padding-right: 48px;
}
.video-small-wrapper .video-dot-btn {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition: all .5s ease;
}
.video-small-wrapper:hover .video-dot-btn {
    opacity: 1;
    visibility: visible;
}
.video-small-card:focus-visible::before {
    content: '';
    display: block;
    position: absolute;
    inset: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    outline: 2px solid #000;
    border-radius: var(--radius-10);
}

.video-small-card:focus-visible {
    position: relative;
    outline: none;
}

.video-small-card {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-20);
}

.video-small-card .video-small-thumb-img.video-img-type-small {
    flex-shrink: 0;
    max-width: 176px;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    width: 100%;
    height: 100%;
}

.video-small-card .video-small-thumb-img img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: var(--radius-6);
}

.video-small-card .video-small-details-wrapper {
    padding: var(--spacing-2) 0;
    width: 100%;
}
.video-small-card .video-small-details-wrapper .download-paused-text,
.video-small-card .video-small-details-wrapper .download-video-text {
    font: var(--body-small);
    font-weight: 600;
    color: var(--color-text-neutral-strong);
    margin-top: var(--spacing-2);
}
.video-small-card .video-small-details-wrapper .download-video-text {
    color: var(--color-text-success-strong);
}
.video-small-card .video-small-details-wrapper .video-small-card-title {
    font: var(--body-large);
    font-weight: 600;
    color: var(--color-text-neutral-strong);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    margin-bottom: var(--spacing-4);
}

.video-small-card .video-small-details-wrapper .video-details-view-date {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-2);
}

.video-small-card .video-small-details-wrapper .video-card-title {
    font: var(--title-2x-small);
    color: var(--color-text-neutral-strong);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: var(--spacing-4);
}

.video-small-card .video-small-details-wrapper .video-type-text,
.video-small-card .video-small-details-wrapper .video-details-view-date span {
    font: var(--body-small);
    color: #394046;
}

.video-small-card .video-small-details-wrapper .video-details-view-date span:not(:first-child) {
    display: flex;
    align-items: center;
}

.video-small-card .video-small-details-wrapper .video-details-view-date span:not(:first-child)::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    margin: 0 6px 0px;
}
.video-small-card .video-small-thumb-img .video-duration {
    position: absolute;
    bottom: 4px;
    right: 4px;
    color: var(--color-text-static-white);
    background: var(--color-bg-blackout-strong);
    padding: 4px 8px;
    border-radius: 6px;
    font: var(--body-2x-small-bold);
}
.has-dotmenu .video-dot-btn {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
.has-dotmenu:focus-visible .video-dot-btn,
.has-dotmenu:hover .video-dot-btn {
    opacity: 1;
    visibility: visible;
}