.create-group-member-model .model-header-popup {
    border-bottom: 0;
}

.create-group-member-model .model-popup {
    max-height: 720px;
    height: 100%;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

.create-group-member-model .model-popup .chat-list-container {
    height: 100%;
}

.create-group-member-model .model-search-container {
    padding: 0 var(--spacing-24) var(--spacing-20);
    width: 100%;
}

.create-group-member-model .model-search-container .text-field-search-main {
    max-width: 100%;
    width: 100%;
}

/* Group list */
.model-create-member-list {
    padding: var(--spacing-6) var(--spacing-20);
    gap: var(--spacing-16);
    width: 100%;
}

.model-group-member-name {
    flex: 1 1 auto;
    max-width: 332px;
    padding-right: var(--spacing-16);
}

.model-group-member-name h5 {
    width: 100%;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.hr-spacing-8 {
    height: var(--spacing-8);
}

.group-selected-members {
    margin: var(--spacing-16) 0 0;
    max-height: 88px;
    flex-direction: column-reverse;
}

.group-selected-inner {
    gap: var(--spacing-6) var(--spacing-4);
    flex-wrap: wrap;
}

/* Cropping tool */
.model-cropping-tool {
    width: 96px;
    height: 96px;
}

.model-image-cropper .model-popup {
    max-height: 720px;
    height: auto;
}

.model-image-cropper .model-popup.popup-size-480 {
    max-width: 420px;
    width: auto;
}

.model-image-cropper .cropImgDis {
    min-width: 380px;
}

.model-image-cropper .model-header-popup {
    border-bottom: 1px solid var(--color-border-neutral-tertiary, rgba(13, 13, 13, 0.1));
}

/* Add member confirmation  */

.add-member-in-group-confirmation .model-popup {
    height: auto;
}

.model-add-conf-body {
    padding: var(--spacing-24);
}

.model-add-conf-content {
    padding-bottom: var(--spacing-24);
}

.model-add-conf-content h3 {
    padding-bottom: var(--spacing-8);
}

.model-add-conf-content p {
    font: var(--body-medium);
    color: var(--color-text-neutral-secondary);
}

.model-add-conf-content p span {
    font: var(--body-medium-bold);
    color: var(--color-text-neutral-strong);
}