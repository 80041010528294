.spinners.white2 {
    border-color: var(--color-bg-lightening-secondary-pressed);
    border-style: solid;
    border-top-color: var(--color-bg-static-white);
    border-radius: 50px;
    animation: spin 1s linear infinite;
    box-sizing: border-box;
}

.spinners.brand {
    border-color: var(--color-bg-neutral-secondary-pressed);
    border-style: solid;
    border-top-color: var(--color-bg-brand-strong);
    border-radius: 30px;
    animation: spin 1s linear infinite;
    box-sizing: border-box;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(1turn);
    }
}

.spinners.wh-16 {
    width: 16px;
    height: 16px;
    border-width: 2px;
}

.spinners.wh-20 {
    width: 20px;
    height: 20px;
    border-width: 2px;
}

.spinners.wh-24 {
    width: 24px;
    height: 24px;
    border-width: 2px;
}

.spinners.wh-32 {
    width: 32px;
    height: 32px;
    border-width: 3px;
}

.spinners.wh-40 {
    width: 40px;
    height: 40px;
    border-width: 3px;
}

.spinners.wh-48 {
    width: 48px;
    height: 48px;
    border-width: 3px;
}