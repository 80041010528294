.chat-notification-cell {
    display: flex;
    padding: var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-20, 20px);
    align-items: center;
    gap: var(--spacing-16, 16px);
    align-self: stretch;
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    max-width: 504px;
    box-sizing: border-box;
}
.chat-notification-cell-text {
    display: flex;
    padding-right: var(--spacing-12, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-2, 2px);
    flex: 1 0 0;
}
.chat-notification-cell-switch {
    display: flex;
    padding: 12px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-10, 10px);
    box-sizing: border-box;
}
.chat-notification-cell-text h5 {
    font: var(--body-small);
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong);
    text-overflow: ellipsis;
    word-break: break-all;
}
.chat-notification-cell-text p {
    font: var(--body-small);   
    color: var(--color-text-neutral-secondary);
}