.chips {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4, 4px);
    border-radius: var(--radius-full, 120px);
    border: 1px solid var(--color-border-neutral-secondary, rgba(13, 13, 13, 0.20));
    color: var(--color-text-neutral-strong, #060D14);
    text-align: center;
    cursor: pointer;
    font: var(--body-small);
    background-color: var(--color-text-static-white);
}

.chips:focus-visible::before {
    content: '';
    display: block;
    position: absolute;
    inset: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    outline: 2px solid var(--color-text-neutral-strong);
    border-radius: 555px;
}

.chips:focus-visible {
    position: relative;
}

.chips:hover {
    border: 1px solid var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.50));
}

.chips:active {
    border: 1px solid var(--color-border-neutral-secondary-hovered, rgba(13, 13, 13, 0.50));
}

.active-chips {
    background: var(--color-bg-inverse-neutral, #041527);
    color: var(--color-text-inverse, #FFF);
}

.chips.small {
    padding: var(--spacing-4, 4px) var(--spacing-10, 10px);
    font: var(--body-x-small);
}
.chips.medium {
    padding: var(--spacing-6, 6px) var(--spacing-12px, 12px);
}
.chips.large {
    padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
}
.chips.large .chip-container {
    padding: var(--spacing-2) var(--spacing-4);
}
.chips.medium .chip-container,
.chips.small .chip-container {
    padding: 0 var(--spacing-4);
}

.chipa-show-all {
    position: relative;
}

.chips.small svg {
    width: 16px;
    height: 16px;
}

.chipa-show-all:after {
    background-image: url(../../../Images/icn_m_chevron_down.svg);
    content: '';
    position: relative;
    height: 20px;
    width: 20px;
    background-size: cover;
    top: 0px;
    right: 0px;
}

.chips.small.chipa-show-all:after {
    height: 16px;
    width: 16px;
}

.chipa-show-all-open .dropdown-list {
    min-width: 240px !important;
    left: 0;
    margin-top: 18px;
}

.chips.active {
    border-radius: var(--radius-full, 999px);
    background: var(--color-bg-inverse-neutral, #041527);
    color: var(--color-text-inverse, #FFF);
}