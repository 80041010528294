.chat-details-members-heading-tabs {
    display: flex;
    padding: 0px var(--spacing-20, 20px);
    align-items: flex-start;
    align-self: stretch;
}
.chat-details-members-wrapper {
    display: flex;
    padding: var(--spacing-4, 4px);
    gap: var(--spacing-4, 4px);
    flex-wrap: wrap;
}

.chat-media-empty {
    display: flex;
    flex: 1;
    height: 100%;
}