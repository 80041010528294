.chat-details-media {
    display: flex;
    align-items: center;
    border-radius: var(--radius-4, 4px);
    position: relative;
    aspect-ratio: 1/1;
    width: calc(33.42% - 3px);
    max-width: 160px;
}
.chat-details-media-img {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-4, 4px);
    object-fit: cover;  
}
.chat-details-media-play-icon {
    display: flex;
    width: var(--ActionIconSizeLarge, 44px);
    height: var(--ActionIconSizeLarge, 44px);
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-full, 999px);
    background: var(--color-bg-blackout-strong, rgba(13, 13, 13, 0.80));
    position: absolute;
    cursor: pointer;
    align-content: center;
    margin: auto;
    left: 0;
    right: 0;
}