.model-popup-main {
  background: var(--color-bg-blackout-secondary);
  display: flex;
  width: 100%;
  height: 100%;
  padding: var(--spacing-24, 24px) 0px; /* Orignal var(--spacing-40, 40px) 0px, Changed as per mui figma */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
}

.model-popup {
  border-radius: var(--radius-12, 12px);
  background: var(--color-bg-elevated);
  box-shadow: var(--shadow-small);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 720px;
}

.z-10 {
  z-index: 10;
}

.popup-size-480 {
  max-width: 480px;
  width: 100%;
}

.popup-size-648 {
  max-width: 648px;
  width: 100%;
}

.popup-size-880 {
  max-width: 880px;
  width: 100%;
}

.popup-size-1200 {
  max-width: 1200px;
  width: 100%;
}

.fadein {
  opacity: 1;
  transition: all 0.3s ease-in;
  visibility: visible;
}

.fadeout {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
  visibility: hidden;
}

@media (max-height: 768px) {
  .model-popup {
    max-height: 100%;
  }
}
