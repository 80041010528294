.chat-floating-button{
    position: relative;
    width: var(--spacing-48);
    height: var(--spacing-48);
    border-radius: var(--radius-full);
    box-shadow: 0px 2px 10px 0px rgba(4, 21, 39, 0.04), 0px 4px 25px 0px rgba(4, 21, 39, 0.10);
    background: var(--color-bg-elevated);
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s all;
}

.chat-floating-button .chat-badge{
    position: absolute;
    top: -8px;
    right: -8px;
}

.chat-floating-button:hover{
    border: 1px solid var(--color-border-neutral-tertiary);
}

.chat-floating-button:focus-visible{
    box-shadow: var(--shadow-small);
}

