.chat-details-members {
  display: flex;
  width: 400px;
  flex-direction: column;
  flex: 1 0 0;
  background: var(--color-bg-screen, #fff);
  height: 100%;
}

.chat-details-members-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 12px;
}

.chat-details-members-list .chat-member-cell {
  width: 100%;
  box-sizing: border-box;
}

.chat-details-members-heading {
  display: flex;
  flex-direction: column;
}

.chat-details-members-heading-search {
  display: flex;
  padding: 0px var(--spacing-20, 20px) var(--spacing-20, 20px)
    var(--spacing-20, 20px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.chat-details-members-heading-title {
  display: flex;
  padding: var(--spacing-8, 8px) var(--spacing-12, 12px)
    var(--spacing-8, 8px) var(--spacing-8, 8px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  position: relative;
}

.chat-details-members-heading-title h4 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 264px;
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060d14);
  text-align: center;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
