.chat-accordion {
    display: flex;
    width: 100%;
    padding: var(--spacing-4, 4px) 0px;
    align-items: center;
    gap: var(--spacing-24, 24px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    cursor: pointer;
}

.chat-accordion-in {
    display: flex;
    padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
    align-items: center;
    gap: var(--spacing-24, 24px);
    flex: 1 0;
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
    justify-content: space-between;
}
.chat-accordion-in:hover{ 
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
}
.chat-accordion-arrow {
    display: flex;
    align-items: center;
}
.chat-accordion-in h5{
    color: var(--color-text-neutral-strong, #060D14);
    font: var(--body-medium);
    font-weight: 600;
}