/* Fonts */
@font-face {
  font-family: "Muller";
  src: url("../Assets/fonts/Muller-Medium.eot");
  src: url("../Assets/fonts/Muller-Medium.eot?#iefix") format("embedded-opentype"),
    url("../Assets/fonts/Muller-Medium.woff2") format("woff2"),
    url("../Assets/fonts/Muller-Medium.woff") format("woff"),
    url("../Assets/fonts/Muller-Medium.ttf") format("truetype"),
    url("../Assets/fonts/Muller-Medium.svg#Muller-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.mds-model-box-main {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
}
.mds-model-box-body {
  padding: 16px;
  background: linear-gradient(0deg, #01143f 0%, #0e327a 100%);
  border-radius: 20px;
  max-width: 640px;
  max-height: 840px;
  /* height: calc(100vh - 80px); */
  width: 100%;
}
.close-btn {
  text-align: right;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}
.mds-model-logo {
  text-align: center;
  margin: 8px 0 24px;
}
.mds-model-heading {
  max-width: 412px;
  margin: 0 auto 45px;
}
.mds-model-heading h3 {
  font-family: "Muller";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%; /* or 38px */
  text-align: center;
  color: #ffffff;
}
.mds-model-main-img {
  max-width: 446px;
  margin: 0 auto 45px;
}
.mds-model-main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mds-model-button-container {
  max-width: 390px;
  margin: 0 auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.mds-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 4px;
  background: #f6f6f6;
  border-radius: 400px;
  text-decoration: none;
  transition: 0.5s all;
}
.mds-button:hover {
  background: #e4e4e4;
}

.mds-button p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #201e29;
  margin-bottom: 0;
}
.mds-button p span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: -5px;

}

@media (max-width: 1439.98px) {
  .mds-model-box-main {
  padding: 40px;
  }
  .mds-model-box-body {
    padding: 16px;
    max-width: 370px;
    width: 100%;
    /* overflow-y: scroll; */
    height:auto;
}
  .mds-model-logo {
    text-align: center;
    margin: 0 0 16px;
  }
  .mds-model-logo img {
    width: 100%;
    height: 32px;
  }
  .mds-model-heading {
    margin: 0 auto 24px;
  }
  .mds-model-heading h3 {
    font-size: 24px;
  }
  .mds-model-main-img {
    max-width: 240px;
    margin: 0 auto 24px;
  }
  .mds-button p {
    font-size: 16px;
  }
  .mds-button {
    padding: 7px 16px;
  }
  .mds-model-button-container {
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

@media (max-width:919.98px){
  .mds-model-box-body {
    max-width: 450px;
}
  .mds-model-heading {
    max-width: 300px;
  }

}
@media (max-width:767.98px){
  .mds-model-box-body {
    max-width: 360px;
  }
  .mds-model-heading h3 {
    font-size: 20px;
  }
  .mds-button p {
    font-size: 14px;
  }
  .mds-button p span {
    font-size: 10px;
}
.mds-model-heading {
  max-width: 252px;
}
}

@media (max-width:575.98px){
  
  .mds-model-logo img {
    width: 100%;
    height: 24px;
  }
  
  .mds-model-heading h3 {
    font-size: 18px;
  }
  .mds-model-button-container {
    margin: 0 auto 16px;
    gap: 12px;
    flex-wrap: wrap;
}
}

@media (max-width:374.98px){
  .mds-model-box-main {
    padding: 20px;
    }
}