.chat-file-cell {
    display: flex;
    padding-right: var(--spacing-4, 4px);
    align-items: center;
    gap: var(--spacing-16, 16px);
    flex: 1 0 0;
    padding: var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-8, 8px) var(--spacing-20, 20px);
}
.chat-file-cell:hover{
	background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
	cursor: pointer;
}
.chat-file-cell:active{ 
	background: var(--color-bg-neutral-tertiary-pressed, #DCE0E5); 
	cursor: pointer; 
}

.chat-file-cell-file {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-8, 8px);
    background: var(--color-bg-neutral-secondary, #EAEDF1);
    box-sizing: border-box; 
}

.chat-file-cell-text h5 {
    font: var(--body-small);
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-text-neutral-strong, #060D14);
    text-overflow: ellipsis;
    word-break: break-all;
}

.chat-file-cell-text h6 {
    font: var(--body-small);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--color-text-neutral-secondary, #394046);
    text-overflow: ellipsis;
    font-weight: 400;
    word-break: break-all;
}