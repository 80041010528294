.mute-notification-data-list {
  display: flex;
  padding-top: var(--spacing-8, 8px);
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.mute-notification-data {
  display: flex;
  padding: var(--spacing-24, 24px);
  align-items: flex-start;
  flex: 1 0 0;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.new-chat-container {
  display: flex;
  padding: 0px var(--spacing-24, 24px) var(--spacing-12, 12px) var(--spacing-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-12, 12px);
  align-self: stretch;
  background: var(--color-bg-screen, #fff);
  margin-top: -1px;
}

.border-botton-none {
  border-bottom: none !important;
}

.new-chat-container-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.new-chat-container-list-in {
  display: flex;
  padding: var(--spacing-8, 8px) 0px;
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
}

.new-chat-container-list-in h5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--color-text-brand-strong, #0b42cf);
  text-overflow: ellipsis;
  font: var(--body-medium);
  font-weight: 400;
  cursor: pointer;
}

.new-chat-container-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-8, 8px);
  align-self: stretch;
  width: 100%;
}

.new-chat-container-search .text-field-search-main {
  max-width: 100%;
}

.chat-list-container {
  overflow: auto;
  display: flex;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
  height: 100%;
  transition: height 0.15s ease-out;
}

.chat-list-inner {
  width: 100%;
}

.chat-list-container .chat-member-cell {
  width: 100%;
}

.new-chat-emptystate {
  display: flex;
  padding: var(--spacing-12, 12px) 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
  height: 100%;
}

.new-chat-modelbox .model-popup {
  height: 100%;
}

/* Leave change popup */
.channel-group-wrapper {
  width: 100%;
  padding: var(--spacing-24);
  overflow: auto;
}

.channel-group-wrapper h3 {
  color: var(--color-text-neutral-strong);
  padding-bottom: var(--spacing-8);
}

.channel-group-wrapper p {
  color: var(--color-text-neutral-secondary);
  font: var(--body-medium);
}

.channel-group-wrapper p b {
  color: var(--color-text-neutral-primary);
}

.delete-group-checkbox-wrapper {
  padding-top: var(--spacing-24);
}


.reactions-chips {
  display: flex;
  padding: var(--spacing-24, 24px) var(--spacing-24, 24px) var(--spacing-12, 12px) var(--spacing-24, 24px);
  align-items: flex-start;
  gap: var(--spacing-6, 6px);
  align-self: stretch;
  flex-wrap: wrap;
}

.reactions-list {
  display: flex;
  padding: var(--spacing-12, 12px);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  transition: height 0.15s ease-out;
}

.reactions-list-box {
  display: flex;
  padding: var(--spacing-8, 8px);
  align-items: center;
  gap: var(--spacing-16, 16px);
  align-self: stretch;
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0.00));
}

.reactions-list-box:hover {
  border-radius: var(--radius-8, 8px);
  background: var(--color-bg-neutral-tertiary-hovered, #EAEDF1);
  cursor: pointer;
}

.reactions-list-box-container {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  height: 44px;
  padding-right: var(--spacing-12, 12px);
  flex-direction: column;
  gap: var(--spacing-2, 2px);
}

.reactions-list-box-container h5 {
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0;
  font: var(--title-x-small);
  overflow: hidden;
  color: var(--color-text-neutral-strong, #060D14);
  text-overflow: ellipsis;
}

.reactions-list-box-container p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--color-text-neutral-secondary, #394046);
  text-overflow: ellipsis;
  font: var(--Regular);
  letter-spacing: -0.3px;
  word-break: break-word;
}

.reactions-list-box-emoji {
  width: 40px;
  color: var(--color-text-neutral-strong, #060D14);
  text-align: center;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}