.messagebarText-container .ql-mention-list-container ul {
  background: var(--color-bg-elevated, #fff);
  border-radius: var(--radius-small, 8px);
  box-shadow: 0 2px 6px 0 #04152733;
  display: flex;
  flex-direction: column;
  max-height: 280px;
  padding: var(--spacing-12, 12px) 0 !important;
  overflow: auto;
  width: 304px;
}

.messagebarText-container .ql-mention-list-container ul::-webkit-scrollbar {
  width: 14px;
}

.messagebarText-container .ql-mention-list-container ul::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3);
  border-radius: var(--radius-x-small, 8px);
  border: 4px solid var(--color-border-inverse-screen);
}

.messagebarText-container .ql-mention-list-container ul::-webkit-scrollbar-thumb:hover {
  background: rgba(4, 21, 39, 0.3);
  cursor: pointer;
}

.messagebarText-container .ql-mention-list-container ul li {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
  align-items: center;
  gap: var(--spacing-12, 12px);
  background: var(--color-bg-neutral-tertiary, rgba(255, 255, 255, 0));
  font: var(--body-medium);
  box-sizing: border-box;
  word-break: break-all;
}

.messagebarText-container .ql-mention-list-container ul li:hover {
  background: var(--color-bg-neutral-tertiary-hovered, #eaedf1);
}

.messagebarText-container .ql-mention-list-container ul li.selected {
  background: var(--color-bg-neutral-tertiary-pressed, #dce0e5);
}

.messagebarText-container .ql-editor ul {
  padding: 0px;
}

.messagebarText-container .ql-editor ul li:before {
  font-size: 21px;
  vertical-align: middle;
}

.messagebarText-container .ql-editor ul li {
  padding-left: 14px;
  font: var(--body-medium);
}

.messagebarText-container .ql-editor p {
  font: var(--body-medium);
}

.messagebarText-container .ql-editor {
  overflow: auto;
  max-height: 200px;
}

.messagebarText-container .ql-editor::-webkit-scrollbar {
  width: 6px;
}

.messagebarText-container .ql-editor::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3);
  border-radius: var(--radius-x-small, 8px);
}

.messagebarText-container>div {
  word-break: break-word;
  /* line-height: 24px; */
  padding: 0px;
}

.messagebarText-container.ql-container.ql-snow {
  border: inherit;
  outline: none;
}

.messagebarText-container .ql-editor.ql-blank::before {
  left: 0;
  font: var(--body-medium);
}

.MessageBarTextInput.MessageBarTextInput--multiLine {
  position: inherit !important;
}

.messagebarText-container textarea {
  font-weight: 400 !important;
  font: var(--body-medium) !important;
}

.messagebarText-container textarea:focus {
  outline: none;
}

.messagebarText-container textarea::placeholder {
  color: var(--color-text-neutral-tertiary);
}

.messagebarText-container textarea::-webkit-scrollbar {
  width: 14px;
}

.messagebarText-container textarea::-webkit-scrollbar-thumb {
  background: rgba(4, 21, 39, 0.3);
  border-radius: var(--radius-x-small, 8px);
  border: 4px solid var(--color-border-inverse-screen);
}

.messagebarText-container textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(4, 21, 39, 0.3);
  cursor: pointer;
}

.messagebarText-sec {
  position: relative;
  padding: 3px 12px 6px 0px;
}

.MessageBarTextInput {
  padding: 0;
  resize: none;
  width: 100%;
  height: 24px;
  min-height: 8px;
  font: var(--body-medium) !important;
  font-weight: 400 !important;
  color: var(--color-text-neutral-strong);
  border: none;
  background: transparent;
  border-radius: 0 !important;
  word-break: break-word;
  overflow: hidden;
}

.MessageBarTextInput:focus {
  outline: none;
}

.MessageBarTextInput::placeholder {
  color: var(--color-text-neutral-tertiary);
}

/* Mention profile Setup */
.hasMantionDropdown .dropdown-list {
  bottom: 100%;
  left: -24px;
  min-width: 304px;
  max-width: 304px;
  position: absolute;
}

.messagebarText-container ul li .avtar-wrapper img {
  vertical-align: text-bottom;
}

.mention-highlight {
  color: var(--color-icon-info-strong);
  /* Add any additional styling as needed */
}