.chip-cross-icon {
  padding: var(--spacing-4);
  background: var(--color-bg-neutral-tertiary);
  border-radius: var(--radius-full);
  display: flex;
  cursor: pointer;
  outline: none;
  border: none;
}

.chip-cross-icon:hover {
  background: var(--color-bg-neutral-tertiary-hovered);
}

.chip-cross-icon:active {
  background: var(--color-bg-neutral-tertiary-pressed);
}

.chip-cross-icon:focus {
    outline: 2px solid var(--color-border-inverse-neutral);
}
