.chatHeading-sec {
  padding: var(--spacing-8) var(--spacing-12) var(--spacing-8) var(--spacing-20);
  border-bottom: 1px solid var(--color-border-neutral-tertiary);
  gap: var(--spacing-16);
  max-width: 840px;
  /* width: 240px; */
}

.chatHeading-left {
  gap: var(--spacing-8);
  flex: 1 0 auto;
  width: calc(100% - 136px);
}

.chatHeading-input {
  padding: var(--spacing-4) 0;
  gap: var(--spacing-12);
  max-width: calc(100% - 44px);
}

.chat-text {
  margin: 0;
  /* max-width: 240px; */
  font: var(--title-small);
  color: var(--color-text-neutral-strong);
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  flex: 1 1 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  width: 100%;
  word-break: break-all;
}

.chat-muted {
  /* padding-top: var(--spacing-4); */
  display: flex;
}

/* More Icon */
.chatHeading-sec .dropdown-list {
  position: absolute;
  top: 40px;
  right: -16px;
  z-index: 3;
  width: 220px;
}


/* / While group / */
.chatHeading-sec.Group {
  padding: var(--spacing-8) var(--spacing-12) var(--spacing-8) var(--spacing-20);
}