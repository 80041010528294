.hashtag {
    display: inline-flex;
    align-items: flex-start;
    color: var(--color-text-brand-strong, #0B42CF);
    cursor: pointer;
    padding: 0px;
    border: none;
    background-color: transparent;
}

.hashtag:focus-visible::before {
    content: '';
    display: block;
    position: absolute;
    inset: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    outline: 2px solid var(--color-text-neutral-strong);
    border-radius: var(--radius-4);
}

.hashtag:focus-visible {
    position: relative;
}

.hashtag:hover {
    text-decoration-line: underline;
    color: var(--color-text-brand-strong-hovered, #0735AB);
}

.hashtag:active {
    text-decoration-line: underline;
    color: var(--color-text-brand-strong-pressed, #052D93);
}

/* .hashtag:focus{
    color: var(--color-text-brand-strong, #0B42CF);
    border-radius: var(--radius-x-small, 4px);
    outline: 2px solid var(--color-border-inverse-neutral, #0D0D0D);
    outline-offset: 2px;
    text-decoration-line: inherit;
} */

.hashtag.small {
    font: var(--body-small);
}

.hashtag.xsmall {
    font: var(--body-x-small);
}